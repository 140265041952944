import { USER_DATA, USER_IS_LOGGED_IN } from "consts/local";
import { isLoggedIn } from "modules/user";
import PropTypes from "prop-types";
import React from "react";
import * as redux from "react-redux";
import { getMobileAppPlans, getMobileMarketingPlans } from "reducers/Select";
import {
  getUserProfile,
  getUserSuits,
  loginWithGoogle,
  updateUserProfile,
} from "reducers/user";
import { setToLocalStore } from "../store/localStore";

export class RoutedComponent extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const { getUserProfile, updateUserProfile } = this.props;
    const pathname = this.props.location?.pathname;

    if (pathname === "/auth/signup") {
      window.location.replace("https://www.appgain.io/RequestDemo?signup=true");
    }

    const authToken = this.props.location?.query?.authtoken;
    const showsol = this.props.location?.query?.showsol;

    if (authToken) {
      // if a user already logged in logout him first to prevent any conflicts
      if (isLoggedIn()) {
        localStorage.removeItem("authToken");
        localStorage.removeItem(USER_DATA);
        localStorage.removeItem(USER_IS_LOGGED_IN);
      }

      setToLocalStore("authToken", authToken);
      setToLocalStore(USER_IS_LOGGED_IN, true);
      getUserProfile().then((res) => {
        setToLocalStore(USER_DATA, res.value.body.user);
        // used in when login with google make login and get user data from local storage

        let queryString = localStorage.getItem("signUpQueryString");

        if (queryString) {
          queryString = JSON.parse(queryString);
          updateUserProfile({
            queryString,
            email: res.value.body.user.email,
          })
            .then(() => {
              localStorage.removeItem("signUpQueryString");
            })
            .catch((err) => {
              console.error("Error updating user signUpQueryString", err);
            });
        }
        this.context.router.push("/");
      });
    }

    if (showsol) {
      setToLocalStore("showsol", true);
    }
  }

  componentDidMount() {
    // Go to Top
    window.scrollTo(0, 0);
    this.checkLogin(this.props.route);
  }

  checkLogin(route) {
    if (route?.private && !isLoggedIn()) {
      this.context.router.push("/auth/login");
    } else if (route?.auth && isLoggedIn()) {
      this.context.router.push("/");
    }
  }
}

// Attach restoreSettings action to the Component
export function connect(mapStateToProps = () => ({}), mapActionCreators = {}) {
  const extendedActionCreators = Object.assign([], mapActionCreators, {
    getUserProfile,
    getUserSuits,
    getMobileAppPlans,
    getMobileMarketingPlans,
    loginWithGoogle,
    updateUserProfile,
  });
  return redux.connect(mapStateToProps, extendedActionCreators);
}

RoutedComponent.propTypes = {
  getUserProfile: PropTypes.func,
  loginWithGoogle: PropTypes.func,
  updateUserProfile: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.object,
  }),
  route: PropTypes.object,
};
