import {
  DetailsList,
  FileUploadInput,
  Form,
  PrimaryButton,
  SpinLoader,
  TextBox,
} from "components";
import _ from "lodash";
import { required, trimm } from "modules/validations";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  bundleIosId,
  getAppBackendConfig,
  removeCurrentSuit,
  updateAppBackendConfig,
  uploadIOSFiles,
} from "reducers/user";
import { Field, reduxForm } from "redux-form";
import { Toast } from "../../../../../modules/toast";
import classes from "../Setting.scss";

export class Ios extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      initialize: false,
      pfx: {},
      visible: "hidden",
    };
  }

  async componentWillMount() {
    const subdomain = window.location.pathname.split("/")[2];
    const {
      getAppBackendConfig,
      user: { activeSuitId, suit, suits },
    } = this.props;

    const currentSuit = suit || suits.find((suit) => suit.id === activeSuitId);

    getAppBackendConfig(subdomain, currentSuit.appbackend_userId).then(
      (result) => {
        if (_.get(result, "value.body.push.ios")) {
          const iosFiles = result.value.body.push.ios;
          const production = _.find(iosFiles, ({ production }) => production);
          const development = _.find(iosFiles, ({ production }) => !production);
          this.setState({ old: { production, development } });
        }
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.user.appbackend &&
      nextProps.user.appbackend.push &&
      nextProps.user.appbackend.push.ios
    ) {
      const { ios } = nextProps.user.appbackend.push;
      if (ios && !this.state.initialize) {
        const production = ios.filter(
          (currentIos) => currentIos.production === true
        );
        const pfxProd = production[0].pfx;
        const prodPass = production[0].password;
        const development = ios.filter(
          (currentIos) => currentIos.production === false
        );
        const pfxDev = development[0].pfx;
        const devPass = development[0].password;
        this.setState({ pfx: { prod: pfxProd, dev: pfxDev } });
        nextProps.initialize({
          dev: pfxDev || undefined,
          dist: pfxProd || undefined,
          "dev-password": devPass || undefined,
          "dist-password": prodPass || undefined,
        });
        this.setState({ initialize: true });
      }
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      form,
      user: { activeSuitId, suits },
      getAppBackendConfig,
      uploadIOSFiles,
    } = this.props;

    let SuitArr = suits.filter((suit) => suit.id === activeSuitId);
    const appbackend_userId = SuitArr[0].appbackend_userId;
    const { values } = form.ios;
    var project = window.location.href.split("/")[4];

    const data = {
      dev: values.dev,
      dist: values.dist,
    };

    const passwords = {
      ...(values["dev-password"] && { "dev-password": values["dev-password"] }),
      ...(values["dist-password"] && {
        "dist-password": values["dist-password"],
      }),
    };

    if (this.state.old) {
      const { production, development } = this.state.old;

      if (production === data.dist && development === data.dev) {
        return;
      }

      const files = _.pickBy({
        dev: development.pfx === data.dev ? null : data.dev,
        dist: production.pfx === data.dist ? null : data.dist,
      });

      const passwords = {
        ...(values["dev-password"] && {
          "dev-password": values["dev-password"],
        }),
        ...(values["dist-password"] && {
          "dist-password": values["dist-password"],
        }),
      };

      if (Object.keys(files).length !== 0) {
        uploadIOSFiles(
          activeSuitId,
          project,
          files,
          appbackend_userId,
          passwords
        )
          .then(() => {
            Toast.success(I18n.t("PlatformsPage_nav_2_updatedMsg"));
            getAppBackendConfig(project, appbackend_userId);
          })
          .then(() => {});
      } else {
        Toast.success(I18n.t("PlatformsPage_nav_2_updatedMsg"));
        getAppBackendConfig(project, appbackend_userId);
      }
    } else {
      uploadIOSFiles(activeSuitId, project, data, appbackend_userId, passwords)
        .then(() => {
          Toast.success(I18n.t("PlatformsPage_nav_2_updatedMsg"));
          getAppBackendConfig(project, appbackend_userId);
        })
        .then(() => {});
    }
  };

  visibleTool = () => {
    if (this.state.visible === "hidden") {
      this.setState({ visible: "visible" });
    } else {
      this.setState({ visible: "hidden" });
    }
  };

  render() {
    const {
      user: { isPending },
      valid,
      pristine,
      submitting,
    } = this.props;
    const {
      pfx: { prod, dev },
    } = this.state;

    const { appbackend } = this.props.user;

    return (
      <div>
        {isPending && <SpinLoader />}
        <Form onSubmit={this.handleSubmit}>
          {appbackend ? (
            <div>
              <div className="flex">
                <div className={classes.NPlatForms}>
                  <strong
                    style={{
                      color: "#575d65",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    {I18n.t("PlatformsPage_nav_2_uploadDev")} *.p12
                  </strong>
                  <Field
                    name="dev"
                    component={FileUploadInput}
                    validate={[required, trimm]}
                    iconClass="fa fa-file-o"
                    placeholder={dev || I18n.t("PlatformsPage_nav_2_uploadDev")}
                  />
                </div>

                <Field
                  className={classes.passwordInput}
                  readOnly={pristine}
                  name="dev-password"
                  component={TextBox}
                  placeholder="Development Key Password (optional)"
                />

                <div className={classes.NPlatForms}>
                  <strong
                    style={{
                      color: "#575d65",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    {I18n.t("PlatformsPage_nav_2_uploadProd")} *.p12
                  </strong>
                  <Field
                    name="dist"
                    component={FileUploadInput}
                    validate={[required, trimm]}
                    iconClass="fa fa-file-o"
                    placeholder={
                      prod || I18n.t("PlatformsPage_nav_2_uploadProd")
                    }
                  />
                </div>

                <Field
                  className={classes.passwordInput}
                  readOnly={pristine}
                  name="dist-password"
                  component={TextBox}
                  placeholder="Production Key Password (optional)"
                />
              </div>
              <div style={{ marginBottom: "30px" }} className={classes.details}>
                <DetailsList
                  data={[
                    {
                      key: I18n.t("PlatformsPage_nav_2_DataList_1"),
                      value: appbackend.ios_dev_pushCert_expirydate || "NA",
                      canCopy: false,
                    },
                  ]}
                />
                <DetailsList
                  data={[
                    {
                      key: I18n.t("PlatformsPage_nav_2_DataList_2"),
                      value: appbackend.ios_dist_pushCert_expirydate || "NA",
                      canCopy: false,
                    },
                  ]}
                />
              </div>
              <div className={classes.Form}>
                <PrimaryButton
                  value={I18n.t("saveChangesBtn")}
                  type="submit"
                  disabled={pristine || !valid || submitting}
                />
              </div>
            </div>
          ) : null}
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: "ios",
})(
  connect(
    (store) => ({
      user: store.user,
      form: store.form,
    }),
    {
      getAppBackendConfig,
      bundleIosId,
      uploadIOSFiles,
      updateAppBackendConfig,
      removeCurrentSuit,
    }
  )(Ios)
);
