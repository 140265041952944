import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import PropTypes from "prop-types";
import React from "react";
import OutsideClickHandler from "../OutsideClickHandler";
import classes from "./Input.scss";

// TODO: implement debounce on validation
// TODO: implement chars count
class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],

      showEmojiPicker: false,
    };
  }

  handleCloseEmojiPicker = () => this.setState({ showEmojiPicker: false });

  handleEmojiSelect = (emoji) => {
    const { value, onEmojiChange } = this.props;
    onEmojiChange(value + emoji.native);
  };

  handleOnChange = (e) => {
    const { validations, onChange } = this.props;

    const value = e.target.value;

    if (!validations || validations.length === 0) {
      return onChange(e);
    }

    const errors = [];
    validations.forEach((validation) => {
      const error = validation(value);
      if (error) {
        errors.push(error);
      }
    });

    this.setState({ errors }, () => {
      this.props.onErrorsChange &&
        this.props.onErrorsChange(this.props.name, errors);
    });

    onChange(e);
  };

  handleToggleEmojiPicker = () =>
    this.setState({ showEmojiPicker: !this.state.showEmojiPicker });

  render() {
    const { label, value, helper, emoji, textArea, readOnly, ...rest } =
      this.props;

    const { errors, showEmojiPicker } = this.state;

    return (
      <div className={classes.inputContainer}>
        <label>{label}</label>
        {textArea ? (
          <textArea
            className={`${errors.length > 0 && classes.error} ${
              classes.textArea
            }`}
            value={value}
            onChange={this.handleOnChange}
            readOnly={readOnly}
            {...rest}
          ></textArea>
        ) : (
          <input
            className={errors.length > 0 && classes.error}
            value={value}
            onChange={this.handleOnChange}
            readOnly={readOnly}
            {...rest}
          />
        )}

        <p className={classes.helper}>{helper}</p>
        <div className={classes.errorsContainer}>
          {errors.map((error) => (
            <p key={error} className={classes.error}>
              {error}
            </p>
          ))}
        </div>
        <OutsideClickHandler onOutsideClick={this.handleCloseEmojiPicker}>
          {emoji && (
            <button
              type="button"
              className={classes.emojiButton}
              onClick={this.handleToggleEmojiPicker}
            >
              <span className={classes.icon}>😀</span>
            </button>
          )}
          <div className={classes.emojiPickerContainer}>
            {showEmojiPicker && (
              <Picker native onSelect={this.handleEmojiSelect} />
            )}
          </div>
        </OutsideClickHandler>
      </div>
    );
  }
}

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  helper: PropTypes.string,
  validations: PropTypes.array,
  emoji: PropTypes.bool,
  onEmojiChange: PropTypes.func,
  textArea: PropTypes.bool,
};

export default Input;
