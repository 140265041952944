import { CustomSelector } from "components";
import TextBox from "components/Form/TextBox/TextBox";
import { endofspicialChar, minLength, required } from "modules/validations";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { BsTrashFill } from "react-icons/bs";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { createNewEvent } from "reducers/events";
import { getAppBackendConfig } from "reducers/user";
import { Field, reduxForm } from "redux-form";
import { v4 as uuidv4 } from "uuid";
import { Toast } from "../../../modules/toast";
import classes from "./CreateEvent.scss";

const minLength2 = minLength(2);

const keyTypeOptions = [
  {
    value: "String",
    label: "String",
  },
  {
    value: "Number",
    label: "Number",
  },
  {
    value: "Boolean",
    label: "Boolean",
  },
];

class CreateEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      keys: [],
    };
  }

  onKeyNameChange = (keyId, newValue) => {
    this.setState((prevState) => {
      const oldKeys = prevState.keys;
      const newKeys = oldKeys.map((key) => {
        if (key.id === keyId) {
          return { ...key, value: newValue };
        }
        return key;
      });

      return {
        keys: newKeys,
      };
    });
  };

  onKeyTypeChange = (keyId, newValue) => {
    this.setState((prevState) => {
      const oldKeys = prevState.keys;
      const newKeys = oldKeys.map((key) => {
        if (key.id === keyId) {
          return { ...key, type: newValue };
        }
        return key;
      });

      return {
        keys: newKeys,
      };
    });
  };

  onSubmit = (formValues) => {
    const {
      handleCreateEventClose,
      user: { appbackend, suit, suits, activeSuitId },
      subdomain,
      getAppBackendConfig,
      createNewEvent,
    } = this.props;
    const { keys } = this.state;

    const currentSuit = suit || suits.filter(suit.id === activeSuitId)[0];
    let appBackend = appbackend;

    const { event, action } = formValues;

    const data = { events: event };

    if (action) {
      data.actions = action.split(",");
    }

    if (keys.length > 0) {
      data.values = keys.map((key) => ({
        value: key.value,
        type: key.type.value,
      }));
    }

    this.setState({ loader: true }, async () => {
      try {
        if (!appBackend) {
          const response = await getAppBackendConfig(
            subdomain,
            currentSuit.appbackend_userId
          );
          appBackend = response.value.body;
        }

        const { serverURL, appId, masterKey } = appBackend;

        await createNewEvent({
          serverURL: serverURL + "fe",
          appId,
          masterKey,
          data,
        });
        Toast.success(I18n.t("eventCreatedSuccessfully"));
        handleCreateEventClose();
      } catch (error) {
        this.setState({ loader: false });
        handleCreateEventClose();
      }
    });
  };

  handleAddKey = () => {
    this.setState((prevState) => {
      return {
        keys: [
          ...(prevState?.keys || []),
          { id: uuidv4(), value: "", type: keyTypeOptions[0] },
        ],
      };
    });
  };

  removeKey = (keyId) => {
    this.setState((prevState) => {
      const newKeys = prevState.keys.filter((key) => key.id !== keyId);
      return {
        keys: newKeys,
      };
    });
  };

  render() {
    const { keys } = this.state;
    return (
      <div>
        <Modal
          bsSize="large"
          centered
          show={this.props.show}
          onHide={this.props.handleCreateEventClose}
          dialogClassName={classes.createEventModal}
        >
          <Modal.Header closeButton bsClass={classes.modalHeader}>
            <Modal.Title bsClass={classes.modalTitle}>
              Add New Event
            </Modal.Title>
          </Modal.Header>

          <Modal.Body bsClass={classes.modalBody}>
            <form
              className={classes.form}
              onSubmit={this.props.handleSubmit(this.onSubmit)}
            >
              <Field
                name="event"
                label="Event Name"
                validate={[required, minLength2]}
                placeholder="Enter Event Name"
                component={TextBox}
              />
              <Field
                name="action"
                label="Event Action"
                placeholder="For multiple actions, separate them with comma ,"
                component={TextBox}
                validate={[endofspicialChar]}
              />
              <div className={classes.keysContainer}>
                <button
                  className={classes.addKeyBtn}
                  onClick={this.handleAddKey}
                >
                  Add Key
                </button>
                {keys.map((key) => {
                  const { id, value, type } = key;
                  return (
                    <div key={id} className={classes.keyRow}>
                      <input
                        className={classes.input}
                        type="text"
                        value={value}
                        onChange={(e) =>
                          this.onKeyNameChange(id, e.target.value)
                        }
                        required
                        placeholder="Key Name"
                      />
                      <CustomSelector
                        options={keyTypeOptions}
                        onChange={(newValue) =>
                          this.onKeyTypeChange(id, newValue)
                        }
                        value={type}
                        required
                      />
                      <button
                        className={classes.removeBtn}
                        onClick={() => this.removeKey(id)}
                      >
                        <BsTrashFill className={classes.removeBtnIcon} />
                      </button>
                    </div>
                  );
                })}
              </div>

              <Modal.Footer bsClass={classes.modalFooter}>
                <button
                  className={classes.cancelBtn}
                  disabled={this.state.loader}
                  onClick={this.props.handleCreateEventClose}
                >
                  Cancel
                </button>
                <button
                  className={classes.createBtn}
                  disabled={
                    (this.props.form && this.props.form.syncErrors) ||
                    this.state.loader
                  }
                  type="submit"
                >
                  Create
                </button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default reduxForm({
  form: "CreateEvent",
})(
  connect(
    (store) => ({
      form: store.form.CreateEvent,
      user: store.user,
    }),
    {
      createNewEvent,
      getAppBackendConfig,
    }
  )(CreateEvent)
);

CreateEvent.propTypes = {
  handleCreateEventClose: PropTypes.func,
  show: PropTypes.bool,
  handleSubmit: PropTypes.func,
  form: PropTypes.object,
  user: PropTypes.object,
  createNewEvent: PropTypes.func,
  getAppBackendConfig: PropTypes.func,
  subdomain: PropTypes.string,
};
