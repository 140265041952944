import KPI from "api/KPI";
import { RESET_ERRORS } from "reducers/user";

export const GET_KPI = "GET_KPI";

// ------------------------------------
// Actions
// ------------------------------------

export function getKPI(suitId, apiKey, subdomain, data) {
  return {
    type: GET_KPI,
    payload: KPI.getKPI(suitId, apiKey, subdomain, data),
  };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  isPending: true,
  result: null,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function KPIReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_KPI}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_KPI}_REJECTED`:
      return {
        isPending: false,
      };

    case `${GET_KPI}_FULFILLED`: //success
      return {
        ...state,
        isPending: false,
        result: action.payload.body.result,
      };

    default:
      return state;
  }
}
