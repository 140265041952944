import React from "react";
import { Carousel } from "react-bootstrap";
import login_content_1 from "static/login-content/login-content-1.png";
import login_content_2 from "static/login-content/login-content-2.png";
import login_content_3 from "static/login-content/login-content-3.png";
import login_content_4 from "static/login-content/login-content-4.png";

import classes from "./Slider.scss";
export default class ControlledCarousel extends React.Component {
  setElementRef = (element) => {
    this.element = element;
  };
  element = null;
  render() {
    return (
      <div ref={this.setElementRef} className={classes["slider__auth"]}>
        <Carousel
          interval={2000}
          controls={true}
          indicators={false}
          onSelect={this.handleSelect}
        >
          <Carousel.Item>
            <img alt="900x500" src={login_content_1} />
            <Carousel.Caption>
              <p>Track revenue and events of Acquisition Campaigns</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="900x500" src={login_content_2} />
            <Carousel.Caption>
              <p>
                Know all about your user from the moment he installs your app!
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="900x500" src={login_content_3} />
            <Carousel.Caption>
              <p>
                Track Lost Users and re-target them through omnichannel
                marketing
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="900x500" src={login_content_4} />
            <Carousel.Caption>
              <p>Advanced rich push notifications that boost your revenue </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}
