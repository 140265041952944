import { Col, Form, FormControl, Row, TextBox } from "components";
import $ from "jquery";
import _ from "lodash";
import { isValidUrl, required, validUrl } from "modules/validations";
import React from "react";
import DateTime from "react-datetime";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Field, change, reduxForm } from "redux-form";
import classes from "../CreateAppPush.scss";
import DateContainer from "./custom/DateContainer/DateContainer";
import TextboxContainer from "./custom/TextboxContainer/TextBoxContainer";
import URLContainer from "./custom/URLContainer/URLContainer";

const urlOptions = [
  { value: "url", label: I18n.t("URL") },
  {
    value: "deepLink",
    label: I18n.t("appDeepLink"),
  },
  {
    value: "smartLink",
    label: I18n.t("smartLink"),
  },
  {
    value: "microPage",
    label: I18n.t("microPage"),
  },
];

const isValidDate = (dateString) => {
  let date = new Date(dateString);
  return Object.prototype.toString.call(date) === "[object Date]" &&
    isNaN(date.getTime())
    ? I18n.t("createAppPush_PushcontentStep_validdateMsg")
    : undefined;
};

const isnumber = (value) =>
  value &&
  (isNaN(Number(value)) ||
    (!isNaN(Number(value)) && !Number.isInteger(Number(value))) ||
    (!isNaN(Number(value)) &&
      Number.isInteger(Number(value)) &&
      Number(value) < 0))
    ? I18n.t("createAppPush_PushOptionStep_positiveNumNote")
    : undefined;

const defaultDate = new Date();

class PushOptionsStep extends React.Component {
  componentWillMount() {
    if (
      !(
        this.props.pushOptionsStep.values.optionals &&
        this.props.pushOptionsStep.values.optionals.expire_date &&
        this.props.pushOptionsStep.values.optionals.expire_date.value
      )
    ) {
      this.props.dispatch(
        change("pushOptionsStep", "optionals.expire_date.value", defaultDate)
      );

      if (!this.props.optionsLoaded) {
        this.props.dispatch(
          change("pushOptionsStep", "optionals", this.props.optionals)
        );
        this.props.handleChange("optionsLoaded", true);
      }
    }
  }

  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).hide().fadeIn(250);
  }

  dispatchRedux = (variableName, value) => {
    this.props.dispatch(
      change("pushOptionsStep", `optionals.${variableName}`, {
        ...this.props.pushOptionsStep.values.optionals[variableName],
        value,
      })
    );
  };

  handleRadioSelected = (id) => {
    const variableName = id.toLowerCase().replace(" ", "_");
    if (
      this.props.pushOptionsStep.values.optionals &&
      this.props.pushOptionsStep.values.optionals[variableName] &&
      this.props.pushOptionsStep.values.optionals[variableName].selected
    ) {
      $(`.options-${variableName}`).slideToggle(500, () => {
        this.props.dispatch(
          change("pushOptionsStep", `optionals.${variableName}`, {
            ...this.props.pushOptionsStep.values.optionals[variableName],
            selected: false,
          })
        );
      });
    } else {
      if (
        this.props.pushOptionsStep.values.optionals &&
        this.props.pushOptionsStep.values.optionals[variableName]
      ) {
        this.props.dispatch(
          change("pushOptionsStep", `optionals.${variableName}`, {
            ...this.props.pushOptionsStep.values.optionals[variableName],
            selected: true,
          })
        );
      } else {
        this.props.dispatch(
          change("pushOptionsStep", `optionals.${variableName}`, {
            selected: true,
          })
        );
      }
    }
  };
  renderCheckbox = (label) => {
    const optionals = this.props.pushOptionsStep.values.optionals;
    const variableName = label.toLowerCase().replace(" ", "_");
    const isSelected = Boolean(_.get(optionals, [variableName, "selected"]));
    return (
      <div
        style={{ marginTop: "1rem" }}
        key={label}
        className={classes["checkbox-container"]}
      >
        <input
          type="checkbox"
          id={label}
          checked={isSelected}
          onClick={() => this.handleRadioSelected(label)}
        />
        <p className={classes["checkbox-label"]}>
          {label === "URL" ? "Call to Action" : label}
        </p>
      </div>
    );
  };

  renderRadioButton = (id, label, key, name) => {
    const optionals = this.props.pushOptionsStep.values.optionals;
    const variableName = label.toLowerCase().replace(" ", "_");
    const isSelected = Boolean(_.get(optionals, [variableName, "selected"]));
    return (
      <div
        key={key}
        className={`${isSelected ? classes["hightlight-checked"] : ""} ${
          classes["radion-container"]
        }`}
        onClick={() => {
          this.handleRadioSelected(label);
        }}
      >
        <input
          id={id}
          name="option"
          type="checkbox"
          checked={Boolean(_.get(optionals, [variableName, "selected"]))}
        />
        <span className={classes["span-label"]}> {name}</span>
      </div>
    );
  };

  renderRadioButtons = () => {
    const {
      type = "appPush",
      urlType,
      selectedUrl,
      handleOptionSelectUrl,
      handleLinkSelect,
      smartLinks: { smartlinks },
      MicroPages: { landingpages: microPages },
    } = this.props;

    const labels = ["Badge", /*'Expire Date',*/ "Custom Sound", "URL"];
    const optionals = this.props.pushOptionsStep.values.optionals;
    return (
      <div>
        {labels.map((label, key) => {
          const labelLower = label.toLowerCase().replace(" ", "_");
          return (
            <div key={key}>
              {!label.toLowerCase().includes("url") && (
                <Row>
                  <Col xs={6}>
                    <Field
                      validate={required}
                      name={`optionals.${labelLower}`}
                      component={() =>
                        this.renderRadioButton(
                          label,
                          label,
                          `${label}${key}`,
                          label
                        )
                      }
                      type="checkbox"
                      value={label}
                    />
                  </Col>
                  <Col xs={6}>
                    {label.toLowerCase().includes("date") && (
                      <div>
                        <Row>
                          <DateTime
                            defaultValue={defaultDate}
                            onChange={(val) =>
                              this.dispatchRedux(labelLower, val)
                            }
                            className={`${classes["modify-datetime"]} ${
                              classes.datetime
                            } ${
                              optionals &&
                              optionals[labelLower] &&
                              optionals[labelLower].selected
                                ? classes["transform-in"]
                                : ""
                            }`}
                          />
                        </Row>
                        {optionals &&
                          optionals[labelLower] &&
                          optionals[labelLower].selected &&
                          optionals[labelLower].value !== undefined &&
                          isValidDate(optionals[labelLower].value) && (
                            <Row>
                              <div
                                className={`${classes.errorAlert} ${
                                  optionals &&
                                  optionals[labelLower] &&
                                  isValidDate(optionals[labelLower].value)
                                    ? classes["transform-in"]
                                    : ""
                                }`}
                              >
                                {isValidDate(optionals[labelLower].value)}
                              </div>
                            </Row>
                          )}
                      </div>
                    )}

                    {label.toLowerCase().includes("title") && (
                      <div
                        className={`${classes["input-control"]} ${
                          optionals &&
                          optionals[labelLower] &&
                          optionals[labelLower].selected
                            ? classes["transform-in"]
                            : ""
                        }`}
                      >
                        <Field
                          name={"temp.title"}
                          onChange={(e, val) =>
                            this.dispatchRedux(labelLower, val)
                          }
                          placeholder={I18n.t(
                            "createAppPush_PushMessageStep_title_placeholder"
                          )}
                          validate={required}
                          component={TextBox}
                        />
                      </div>
                    )}
                    {label.toLowerCase().includes("badge") && (
                      <div
                        className={`${classes["input-control"]} ${
                          optionals &&
                          optionals[labelLower] &&
                          optionals[labelLower].selected
                            ? classes["transform-in"]
                            : classes["display_none"]
                        }`}
                      >
                        <Field
                          onChange={(e, val) =>
                            this.dispatchRedux(labelLower, val)
                          }
                          name={"temp.badge"}
                          hint={I18n.t(
                            "createAppPush_PushMessageStep_badge_hint"
                          )}
                          placeholder={I18n.t(
                            "createAppPush_PushMessageStep_badge_placeholder"
                          )}
                          validate={[isnumber, required]}
                          component={TextBox}
                        />
                      </div>
                    )}
                    {label.toLowerCase().includes("sound") && (
                      <div
                        className={`${classes["input-control"]} ${
                          optionals &&
                          optionals[labelLower] &&
                          optionals[labelLower].selected
                            ? classes["transform-in"]
                            : ""
                        }`}
                      >
                        <Field
                          name={"temp.custom_sound"}
                          onChange={(e, val) =>
                            this.dispatchRedux(labelLower, val)
                          }
                          placeholder={I18n.t(
                            "createAppPush_PushMessageStep_sound_placeholder"
                          )}
                          validate={required}
                          component={TextBox}
                        />
                      </div>
                    )}
                    {label.toLowerCase().includes("ios") && (
                      <div
                        className={`${classes["input-control"]} ${
                          optionals &&
                          optionals[labelLower] &&
                          optionals[labelLower].selected
                            ? classes["transform-in"]
                            : ""
                        }`}
                      >
                        <Field
                          name={"temp.ios_category"}
                          validate={required}
                          onChange={(e, val) =>
                            this.dispatchRedux(labelLower, val)
                          }
                          placeholder={I18n.t(
                            "createAppPush_PushMessageStep_iosCategory_placeholder"
                          )}
                          component={TextBox}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              )}
              {(this.props.pushMessageStep.values.type
                .toLowerCase()
                .includes("video") ||
                this.props.pushMessageStep.values.type
                  .toLowerCase()
                  .includes("youtube") ||
                this.props.pushMessageStep.values.type
                  .toLowerCase()
                  .includes("gif") ||
                (this.props.pushMessageStep.values.type
                  .toLowerCase()
                  .includes("web") &&
                  this.props.pushMessageStep.values.type
                    .toLowerCase()
                    .includes("view"))) &&
                label.toLowerCase().includes("url") && (
                  <div>
                    <Row>
                      <Col xs={6}>
                        <Field
                          validate={required}
                          name={`optionals.${labelLower}`}
                          component={() =>
                            this.renderRadioButton(
                              label,
                              label,
                              `${label}${key}`,
                              "Call2Action"
                            )
                          }
                          type="checkbox"
                          value={label}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Row>
                          <div
                            className={`${classes["input-control"]} ${
                              optionals &&
                              optionals[labelLower] &&
                              optionals[labelLower].selected
                                ? classes["transform-in"]
                                : ""
                            }`}
                          >
                            <Col xs={5}>
                              <FormControl
                                componentClass="select"
                                value={urlType}
                                validate={[validUrl, required]}
                                onChange={(e) => {
                                  handleOptionSelectUrl(e);
                                  this.dispatchRedux(
                                    labelLower,
                                    e.target.value === "smartLink"
                                      ? smartlinks[0].url
                                      : e.target.value === "deepLink" ||
                                          e.target.value === "url"
                                        ? ""
                                        : e.target.value === "microPage"
                                          ? microPages[0].url
                                          : null
                                  );
                                  this.props.dispatch(
                                    change(
                                      "pushOptionsStep",
                                      "action.appPush.call2action",
                                      e.target.value === "smartLink"
                                        ? smartlinks[0].url
                                        : e.target.value === "deepLink" ||
                                            e.target.value === "url"
                                          ? ""
                                          : e.target.value === "microPage"
                                            ? microPages[0].url
                                            : null
                                    )
                                  );
                                }}
                              >
                                {urlOptions.map(
                                  (el) =>
                                    (type !== "webPush" ||
                                      el.label !== I18n.t("app deep link")) && (
                                      <option key={el.value} value={el.value}>
                                        {el.label}
                                      </option>
                                    )
                                )}
                              </FormControl>
                            </Col>
                            <Col xs={7}>
                              {urlType === "url" ? (
                                <Field
                                  name={"action.appPush.call2action"}
                                  placeholder={I18n.t("enterURL")}
                                  validate={[validUrl, required]}
                                  component={TextBox}
                                  value={
                                    this.props.config.action.appPush.call2action
                                  }
                                  onChange={(e, value) => {
                                    this.dispatchRedux(labelLower, value);
                                  }}
                                />
                              ) : urlType === "deepLink" &&
                                type !== "webPush" ? (
                                <Field
                                  name={"action.appPush.call2action"}
                                  placeholder={I18n.t("selectHint")}
                                  validate={[validUrl, required]}
                                  component={TextBox}
                                  onChange={(e, value) => {
                                    this.dispatchRedux(labelLower, value);
                                  }}
                                  deepLinkBuilder
                                  FiledNotexist={true}
                                  onlymdp={true}
                                />
                              ) : urlType === "smartLink" ? (
                                <FormControl
                                  componentClass="select"
                                  validate={required}
                                  value={selectedUrl}
                                  onChange={(e) => {
                                    handleLinkSelect(e);

                                    this.props.dispatch(
                                      change(
                                        "pushOptionsStep",
                                        "action.appPush.call2action",
                                        e.target.value
                                      )
                                    );
                                  }}
                                >
                                  {smartlinks.map((link) => (
                                    <option key={link.id} value={link.url}>
                                      {link.name}
                                    </option>
                                  ))}
                                </FormControl>
                              ) : urlType === "microPage" ? (
                                <FormControl
                                  componentClass="select"
                                  value={selectedUrl}
                                  validate={required}
                                  onChange={(e) => {
                                    handleLinkSelect(e);
                                    this.props.dispatch(
                                      change(
                                        "pushOptionsStep",
                                        "action.appPush.call2action",
                                        e.target.value
                                      )
                                    );
                                  }}
                                >
                                  {microPages.map((page) => (
                                    <option key={page.id} value={page.url}>
                                      {page.name}
                                    </option>
                                  ))}
                                </FormControl>
                              ) : null}
                            </Col>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      handleChange,
      type = "appPush",
      urlType,
      handleOptionSelectUrl,
      handleOptionSelectValue,
      urlValue,
      smartLinks: { smartlinks },
      MicroPages: { landingpages: microPages },
    } = this.props;

    const labels = ["Badge", /*'Expire Date',*/ "Custom Sound", "URL"];
    const optionals = this.props.pushOptionsStep.values.optionals;

    return (
      <Form>
        <div>
          {labels.map((label, key) => {
            const labelLower = label.toLowerCase().replace(" ", "_");
            return (
              <div key={key}>
                {!label.toLowerCase().includes("url") && (
                  <Field
                    validate={required}
                    name={`optionals.${labelLower}`}
                    component={() => this.renderCheckbox(label)}
                    type="checkbox"
                    value={label}
                  />
                )}
                {label.toLowerCase().includes("date") &&
                  _.get(optionals, [labelLower, "selected"]) && (
                    <DateContainer
                      label={label}
                      validate={[required, isValidDate]}
                      labelLower={labelLower}
                    />
                  )}
                {label.toLowerCase().includes("badge") &&
                  _.get(optionals, [labelLower, "selected"]) && (
                    <TextboxContainer
                      label={label}
                      name="action.appPush.badge"
                      value={this.props.config.action.appPush.badge}
                      rightBottomHint="Works only on IOS"
                      placeholder={I18n.t(
                        "createAppPush_PushMessageStep_badge_placeholder"
                      )}
                      onChange={(e, val) => this.dispatchRedux(labelLower, val)}
                      labelLower={labelLower}
                      validate={[isnumber, required]}
                    />
                  )}
                {label.toLowerCase().includes("sound") &&
                  _.get(optionals, [labelLower, "selected"]) && (
                    <TextboxContainer
                      label={label}
                      name={"action.appPush.sound"}
                      onChange={(e, val) => this.dispatchRedux(labelLower, val)}
                      placeholder={I18n.t(
                        "createAppPush_PushMessageStep_sound_placeholder"
                      )}
                      validate={required}
                      value={this.props.config.action.appPush.sound}
                      labelLower={labelLower}
                    />
                  )}
                {label.toLowerCase().includes("ios") &&
                  _.get(optionals, [labelLower, "selected"]) && (
                    <TextboxContainer
                      label={label}
                      labelLower={labelLower}
                      name={"action.appPush.ios_category"}
                      validate={required}
                      onChange={(e, val) => this.dispatchRedux(labelLower, val)}
                      placeholder={I18n.t(
                        "createAppPush_PushMessageStep_iosCategory_placeholder"
                      )}
                    />
                  )}

                {(this.props.pushMessageStep.values.type
                  .toLowerCase()
                  .includes("video") ||
                  this.props.pushMessageStep.values.type
                    .toLowerCase()
                    .includes("youtube") ||
                  this.props.pushMessageStep.values.type
                    .toLowerCase()
                    .includes("gif") ||
                  (this.props.pushMessageStep.values.type
                    .toLowerCase()
                    .includes("web") &&
                    this.props.pushMessageStep.values.type
                      .toLowerCase()
                      .includes("view"))) &&
                  label.toLowerCase().includes("url") && (
                    <div>
                      <Field
                        validate={required}
                        name={`optionals.${labelLower}`}
                        component={() => this.renderCheckbox(label)}
                        type="checkbox"
                        value={label}
                      />
                      {_.get(optionals, [labelLower, "selected"]) && (
                        <URLContainer
                          label={label}
                          labelLower={labelLower}
                          name={"action.appPush.call2action"}
                          validate={[isValidUrl, required]}
                          urlType={urlType}
                          urlValue={urlValue}
                          smartlinks={smartlinks}
                          microPages={microPages}
                          onChangeURLField={(e, value) => {
                            handleChange(`action.appPush.url`, value);
                            this.props.dispatch(
                              change(
                                "pushOptionsStep",
                                `action.appPush.call2action`,
                                value
                              )
                            );
                          }}
                          urlOptions={urlOptions
                            .filter(
                              (option) =>
                                !(
                                  (option.value === "microPage" &&
                                    !microPages) ||
                                  (option.value === "smartLink" && !smartlinks)
                                )
                            )
                            .map(
                              ({ label, value }) =>
                                (type !== "webPush" ||
                                  label !== I18n.t("app deep link")) && {
                                  label,
                                  value,
                                }
                            )}
                          onChangeCategory={(selectedCategory) => {
                            const selectedType = selectedCategory.value;
                            const url =
                              selectedType === "smartLink"
                                ? _.get(smartlinks, "[0].url", null)
                                : selectedType === "deepLink" ||
                                    selectedType === "url"
                                  ? ""
                                  : selectedType === "microPage"
                                    ? _.get(microPages, "[0].url", null)
                                    : null;
                            const name =
                              selectedType === "smartLink"
                                ? _.get(smartlinks, "[0].name", null)
                                : selectedType === "deepLink" ||
                                    selectedType === "url"
                                  ? ""
                                  : selectedType === "microPage"
                                    ? _.get(microPages, "[0].name", null)
                                    : null;

                            const { label, value } = selectedCategory;
                            handleOptionSelectUrl(label, value);
                            handleOptionSelectValue(name, url);

                            this.props.dispatch(
                              change(
                                "pushOptionsStep",
                                `action.appPush.call2action`,
                                url
                              )
                            );
                          }}
                          onChangeURLValue={(selectedSmartLinkUrl) => {
                            const { label, value } = selectedSmartLinkUrl;
                            handleOptionSelectValue(label, value);

                            const url =
                              urlType.value === "smartLink"
                                ? _.get(smartlinks, "[0].url", null)
                                : _.get(microPages, "[0].url", null);

                            this.props.dispatch(
                              change(
                                "pushOptionsStep",
                                `action.appPush.call2action`,
                                url
                              )
                            );
                          }}
                        />
                      )}
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "pushOptionsStep",
  destroyOnUnmount: false,
})(
  connect((store) => ({
    pushOptionsStep: store.form.pushOptionsStep,
    smartLinks: store.smartlink,
    MicroPages: store.MicroPages,
    templates: store.template,
    pushMessageStep: store.form.pushMessageStep,
  }))(PushOptionsStep)
);
