import PropTypes from "prop-types";
import React from "react";
import { ChromePicker } from "react-color";
import Label from "../../Label";
import OutsideClickHandler from "../../OutsideClickHandler/OutsdieClickHandler";
import classes from "./ColorPicker.scss";

/**
 * ColorPicker component.
 * This component provides a color picker that allows the user to select a color.
 * The selected color is passed to the `handleChange` prop.
 * @extends React.PureComponent
 */
class ColorPicker extends React.PureComponent {
  /**
   * Initialize component state and bind methods.
   * @param {Object} props - The component props.
   */
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  /**
   * Close the color picker.
   */
  close = () => {
    this.setState({ open: false });
  };

  /**
   * Handle color change event from the color picker.
   * @param {Object} color - The color object from the color picker.
   */
  handleColorChange = (color) => {
    this.props.handleChange(color.hex);
  };

  /**
   * Toggle the open state of the color picker.
   */
  toggleOpen = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  /**
   * Render the component.
   * @returns {ReactElement} - HTML markup for the component.
   */
  render() {
    const { value, label } = this.props;
    const { open } = this.state;
    return (
      <div>
        {label && <Label>{label}</Label>}
        <OutsideClickHandler onOutsideClick={this.close}>
          <div className={classes.contain}>
            <input
              value={value}
              className={classes.input}
              onClick={this.toggleOpen}
              readOnly
            />
            <span
              className={classes.colorButton}
              style={{ backgroundColor: value }}
              onClick={this.toggleOpen}
            />
            {open && (
              <div className={classes.popOver}>
                <ChromePicker
                  color={value}
                  onChangeComplete={this.handleColorChange}
                />
              </div>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    );
  }
}

export default ColorPicker;

ColorPicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
};
