import { I18n } from "react-redux-i18n";

export const deepLinkConfig = [
  {
    name: "DeepLinks",
    label: I18n.t("App Deep Links"),
    noDeeplink: true,
    fields: [
      {
        name: "DeepLinks",
        label: I18n.t("Select App Deep Links"),
      },
    ],
    handler: ({ DeepLinks }) => `${DeepLinks}`,
  },
  {
    name: "google app store",
    label: I18n.t("google app store"),
    mobileOnly: true,

    fields: [
      {
        name: "googleappstore",
        label: I18n.t("google app store"),
      },
    ],
    handler: ({ googleappstore }) => `${googleappstore}`,
  },
  {
    name: "apple app store",
    label: I18n.t("apple app store"),
    mobileOnly: true,
    fields: [
      {
        name: "appleappstore",
        label: I18n.t("apple app store"),
      },
    ],
    handler: ({ appleappstore }) => `${appleappstore}`,
  },
  {
    name: "Stripe Pay",
    label: I18n.t("Stripe Pay"),
    mdponly: true,
    fields: [
      {
        name: "stripeform",
        label: I18n.t("Price"),
      },
    ],
    handler: ({
      stripeform,
      projectname,
      stipekey,
      suitid,
      subdomain,
      currency,
    }) =>
      `stripepay://${stripeform}&${projectname}&${stipekey}&${suitid}&${subdomain}&${
        currency || "USD"
      }`,
  },
  {
    name: "Lead Form",
    label: I18n.t("Lead Form"),
    mdponly: true,
    fields: [
      {
        name: "name",
      },
      {
        name: "email",
      },
      {
        name: "address",
      },
      {
        name: "age",
      },
      {
        name: "suitid",
      },
      {
        name: "subdomain",
      },
    ],
    handler: ({ subdomain, suitid, name, email, address, age }) =>
      `leadform://suitID=${suitid}&project_sub_domain=${subdomain}${
        name ? "&label=Name&type=text&value=name" : ""
      }${email ? "&label=Email&type=email&value=email" : ""}${
        address ? "&label=Address&type=text&value=address" : ""
      }${age ? "&label=Age&type=text&value=age" : ""}`,
  },
  {
    name: "email",
    label: I18n.t("send an email"),
    nomail: true,
    fields: [
      {
        name: "email",
        label: I18n.t("email address"),
      },
    ],
    handler: ({ email }) => `mailto:${email}`,
  },
  {
    name: "sms",
    label: I18n.t("send a sms"),
    mobileOnly: true,
    fields: [
      {
        name: "phone",
        label: I18n.t("phone number"),
      },
      {
        name: "message",
        label: I18n.t("message to send"),
      },
    ],
    handler: ({ phone, message }) => `sms:${phone}&body=${message}`,
    customHandlers: {
      ios: ({ phone, message }) => `sms:${phone}&body=${message}`,
      android: ({ phone, message }) => `sms:${phone}?body=${message}`,
    },
  },
  {
    name: "whatsApp",
    label: I18n.t("contact on WhatsApp"),
    mobileOnly: true,
    fields: [
      {
        name: "phone",
        label: I18n.t("phone number"),
      },
    ],
    handler: ({ phone }) => `https://api.whatsapp.com/send?phone=${phone}`,
  },
  {
    name: "phone_call",
    label: I18n.t("Phone Call"),
    mobileOnly: true,
    fields: [
      {
        name: "dial",
        label: I18n.t("phone number"),
      },
    ],
    handler: ({ dial }) => `tel:${dial}`,
  },
  {
    name: "openpopup",
    label: I18n.t("Open Popup"),
    smartLink: true,
    fields: [
      {
        label: I18n.t("Title"),
        name: "title",
      },
      {
        label: I18n.t("Text"),
        name: "text",
      },
    ],
    handler: ({ title, text }) =>
      `Openpopup://param?title=${title}&text=${text}`,
  },

  {
    label: I18n.t("User Profile (@user)"),
    name: "twitter_user_screen_name",
    mobileOnly: true,
    fields: [
      {
        label: I18n.t("atuser"),
        name: "usr",
      },
    ],
    handler: ({ usr }) => `twitter://user?screen_name=${usr}`,
  },

  {
    label: I18n.t("Post a tweet"),
    name: "twitter_post_twit",
    mobileOnly: true,
    fields: [
      {
        label: I18n.t("Tweet Message"),
        name: "msg",
      },
    ],
    handler: ({ msg }) => `twitter://post?message=${msg}`,
  },

  {
    label: I18n.t("Post a tweet (in reply)"),
    name: "twitter_post_twit_in_reply",
    mobileOnly: true,
    fields: [
      {
        label: I18n.t("Tweet Message"),
        name: "msg",
      },
    ],
    handler: ({ msg }) => `twitter://post?message=${msg}&in_reply`,
  },

  {
    label: I18n.t("Search in twitter"),
    name: "twitter_search",
    mobileOnly: true,
    fields: [
      {
        label: I18n.t("hsearch"),
        name: "q",
      },
    ],
    handler: ({ q }) => `twitter://search?query=${q}`,
  },
  {
    label: I18n.t("Share on Facebook Messenger"),
    name: "fb_messenger",
    mobileOnly: true,
    fields: [
      {
        label: I18n.t("HTTP Link"),
        name: "httpLink",
      },
      {
        label: I18n.t("Facebook App ID"),
        name: "fai",
      },
    ],
    handler: ({ httpLink, fai }) =>
      `fb-messenger://share/?link=${httpLink}&app_id=${fai}`,
  },

  {
    label: I18n.t("Open Facebook Page"),
    name: "fb_page",
    mobileOnly: true,
    fields: [
      {
        label: I18n.t("Page ID"),
        name: "pageID",
      },
    ],
    handler: ({ pageID }) => `fb_page://page/${pageID}`,
  },

  {
    label: I18n.t("Share on WhatsApp"),
    name: "whatsapp_share",
    mobileOnly: true,
    fields: [
      {
        label: I18n.t("Message or URL "),
        name: "mesg",
      },
    ],
    handler: ({ mesg }) => `whatsapp://send?text=${mesg}`,
  },

  // whatsapp://send?text=[Text or a URL ]
  // open FB Page in format : fb://page/[pageID]
  // fb-messenger://share/?link=[http link]&app_id=[FB app ID]
  // &message=${sh}
];
