import { config } from "consts/api";
import { userData } from "modules/user";
import superagent from "superagent";
import { getFromLocalStore } from "../store/localStore";
import { logoutUser } from "./user";

const superAgentInstance = (
  method,
  link,
  data = null,
  query = null,
  auth = true
) => {
  const request = superagent(method, link).set("Accept", "application/json");

  const user = userData();
  const authToken = getFromLocalStore("authToken");

  if (auth && user) {
    if (config.AUTH_TOKEN) {
      request.set("authToken", user.auth_token);
    } else {
      request.auth(user.email, user.password);
    }
  } else if (authToken) {
    request.set("authToken", authToken);
  }

  if (data) request.send(data);
  if (query) request.query(query);

  request.on("response", (response) => {
    if (response.status === 401) {
      request.abort();
      logoutUser();
    }
  });

  request.on("error", (error) => {
    if (error.status === 0) {
      console.log("Request aborted");
    }
  });

  return request;
};

export default superAgentInstance;

export const generateToken = () => {
  const user = userData();
  return config.AUTH_TOKEN
    ? user.auth_token
    : `Basic ${btoa(`${user.email}:${user.password}`)}`;
};
