import {
  DataBlock,
  DetailsList,
  PrimaryButton,
  SecondaryButton,
  SpinLoader,
} from "components";
import _ from "lodash";
import React from "react";
import { I18n } from "react-redux-i18n";
import {
  clearDemoData,
  fillDemoData,
  getCurrentSuit,
  updateSuit,
} from "reducers/user";
import { connect } from "routes/routedComponent";
import { Toast } from "../../../../modules/toast";
import classes from "./../Setting.scss";

class DeepLinking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      displayDelete: false,
      displayCreate: false,
      lock: false,
      ios: "",
      android: "",
      apple: "",
      google: "",
    };
  }

  editFunction = () => {
    const {
      user: { suit },
    } = this.props;

    this.setState({
      ios: suit.apple_deep_link_prefix ? suit.apple_deep_link_prefix : "NA",
      android: suit.android_deep_link_prefix
        ? suit.android_deep_link_prefix
        : "NA",
      apple: suit.apple_app_id ? suit.apple_app_id : "NA",
      google: suit.google_play_id ? suit.google_play_id : "NA",
    });
    if (this.state.lock) {
      this.setState({ lock: false });
    } else {
      this.setState({ lock: true });
    }
    this.setState({ show: !this.state.show });
  };

  handleSave = () => {
    const {
      updateSuit,
      user: { activeSuitId, suit },
      getCurrentSuit,
    } = this.props;
    let data = _.pickBy({
      android_deep_link_prefix: ["NA", suit.android_deep_link_prefix].includes(
        this.state.android
      )
        ? null
        : this.state.android,
      apple_app_id: ["NA", suit.apple_app_id].includes(this.state.apple)
        ? null
        : this.state.apple,
      apple_deep_link_prefix: ["NA", suit.apple_deep_link_prefix].includes(
        this.state.ios
      )
        ? null
        : this.state.ios,
      google_play_id: ["NA", suit.google_play_id].includes(this.state.google)
        ? null
        : this.state.google,
      reset_fields: [],
      status: "active",
      suit_name: suit.name,
    });
    updateSuit(activeSuitId, { ...data })
      .then(() => {
        Toast.success(I18n.t("ProjectSettingPage_deepLinking_updateMsg"));
        getCurrentSuit(activeSuitId);
      })
      .then(this.setState({ lock: false }));
  };

  render() {
    const {
      user: { suit, isPending },
    } = this.props;

    let changedFields = {};
    if (suit) {
      changedFields = _.pickBy({
        android_deep_link_prefix: [
          "NA",
          suit.android_deep_link_prefix,
        ].includes(this.state.android)
          ? null
          : this.state.android,
        apple_app_id: ["NA", suit.apple_app_id].includes(this.state.apple)
          ? null
          : this.state.apple,
        apple_deep_link_prefix: ["NA", suit.apple_deep_link_prefix].includes(
          this.state.ios
        )
          ? null
          : this.state.ios,
        google_play_id: ["NA", suit.google_play_id].includes(this.state.google)
          ? null
          : this.state.google,
        reset_fields: [],
        status: "active",
        suit_name: suit.name,
      });
    }

    return (
      <section>
        {isPending && <SpinLoader />}

        {suit ? (
          <div className={classes.new}>
            <DataBlock
              title="ProjectSettingPage_deepLinking_DataBlock_title"
              titleClass="bg-grey-lightest"
            >
              <DetailsList
                data={[
                  {
                    key: "iosDeepLink",
                    value: suit.apple_deep_link_prefix || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_deepLinking_DetailsList_1_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    inputField: (
                      <input
                        value={this.state.ios}
                        onChange={(e) => this.setState({ ios: e.target.value })}
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder="myapp://"
                        type="text"
                      />
                    ),
                  },
                  {
                    key: "AppStoreURL",
                    value: suit.apple_app_id || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_deepLinking_DetailsList_2_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    inputField: (
                      <input
                        value={this.state.apple}
                        onChange={(e) =>
                          this.setState({ apple: e.target.value })
                        }
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder={I18n.t(
                          "ProjectSettingPage_deepLinking_DetailsList_2_input_placeholder"
                        )}
                        type="text"
                      />
                    ),
                  },
                  {
                    key: "androidDeepLink",
                    value: suit.android_deep_link_prefix || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_deepLinking_DetailsList_3_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    inputField: (
                      <input
                        value={this.state.android}
                        onChange={(e) =>
                          this.setState({ android: e.target.value })
                        }
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder="myapp://"
                        type="text"
                      />
                    ),
                  },
                  {
                    key: "PlayStoreURL",
                    value: suit.google_play_id || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_deepLinking_DetailsList_4_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    inputField: (
                      <input
                        value={this.state.google}
                        onChange={(e) =>
                          this.setState({ google: e.target.value })
                        }
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder="com.myapp"
                        type="text"
                      />
                    ),
                  },
                ]}
              />
              {this.state.lock === true ? (
                <PrimaryButton
                  value={I18n.t("saveBtn")}
                  disabled={Object.keys(changedFields).length <= 3}
                  onClick={() => {
                    this.handleSave();
                  }}
                />
              ) : (
                <SecondaryButton
                  value={I18n.t("edit")}
                  onClick={() => {
                    this.editFunction();
                  }}
                />
              )}
            </DataBlock>
          </div>
        ) : null}
      </section>
    );
  }
}
export default connect(
  (store) => ({
    user: store.user,
    select: store.select,
  }),
  {
    getCurrentSuit,
    fillDemoData,
    updateSuit,
    clearDemoData,
  }
)(DeepLinking);
