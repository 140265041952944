import { Checkbox } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import classes from "./Menu.scss";

class Menu extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.display) {
      document.addEventListener("click", this.handleClick, false);
    } else {
      document.removeEventListener("click", this.handleClick, false);
    }
  }

  handleClick = (e) => {
    // check if the click is outside the parent container which includes the btn and menu
    if (!this.node.parentNode.contains(e.target)) {
      this.props.clearMenu();
      return;
    }
  };

  render() {
    return (
      <div
        style={{ display: this.props.display ? "block" : "none" }}
        ref={(node) => {
          this.node = node;
          this.props.setRef(node);
        }}
        className={classes.menuContainer}
      >
        <p className={classes.menuHeader}>
          Select the columns that you want to display in the table
        </p>
        <div>
          {this.props.labels &&
            this.props.labels.map((label, idx) => (
              <div key={idx} className={classes.menuRow}>
                <Checkbox
                  className={classes.menuCheckbox}
                  checked={label.selected}
                  onClick={() => {
                    this.props.onColumnToggle(idx);
                  }}
                />
                {label.name}
              </div>
            ))}
        </div>
        <p onClick={this.props.onResetColumns}>Reset to Defaults</p>
      </div>
    );
  }
}

export default Menu;

Menu.propTypes = {
  display: PropTypes.bool.isRequired,
  labels: PropTypes.array.isRequired,
  onColumnToggle: PropTypes.func.isRequired,
  onResetColumns: PropTypes.func.isRequired,
  clearMenu: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
};
