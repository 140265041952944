import PrimaryButton from "components/Form/Buttons/PrimaryButton/PrimaryButton";
import $ from "jquery";
import React, { Component } from "react";
import { Checkbox, Col, FormControl, Modal, Row } from "react-bootstrap";
import ReactDOM from "react-dom";
import { I18n } from "react-redux-i18n";
import classes from "./LanguageModal.scss";

class LanguageModal extends Component {
  state = {
    searchText: "",
  };
  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).hide().slideDown(500);
  }
  render() {
    const { languages } = this.props;

    return (
      <div>
        <Modal
          bsClass={classes["create-event-modal"]}
          centered
          dialogClassName={classes["dialog-classname"]}
          show={this.props.show}
        >
          <div className={classes["create-event-modal-header"]}>
            <div>{I18n.t("createAppPush_LangleSelector_Modal_title")}</div>
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={this.props.closeLanguageModal}
            />
          </div>
          <hr
            style={{
              margin: "0",
              borderColor: "#cecece",
            }}
          />

          <Modal.Body>
            <FormControl
              placeholder={I18n.t(
                "createAppPush_LangleSelector_Modal_search_placeholder"
              )}
              type="text"
              style={{ marginBottom: "1rem" }}
              value={this.state.searchText}
              onChange={(e) => this.setState({ searchText: e.target.value })}
            />
            <div
              style={{
                height: "450px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Row>
                {Object.values(languages)
                  .filter(({ name }) =>
                    name
                      .toLowerCase()
                      .includes(this.state.searchText.toLowerCase())
                  )
                  .map(({ name, code, selected, important }, index) => (
                    <Col key={index} xs={3}>
                      <div className={classes["lang-container"]}>
                        <Checkbox
                          disabled={
                            important ||
                            (Object.values(languages).filter(
                              ({ selected }) => selected
                            ).length === 4 &&
                              !selected)
                          }
                          checked={selected}
                          onClick={() => this.props.onClickLanguage(code)}
                        />
                        <div>{name}</div>
                      </div>
                    </Col>
                  ))}
              </Row>
            </div>
            <div
              className="text-primary"
              style={{ fontSize: "1.65rem", color: "#1d86f1" }}
            >
              {I18n.t("createAppPush_LangleSelector_Modal_selectNote")}
            </div>
            <div className={classes["create-event-btns-container"]}>
              <PrimaryButton
                disabled={
                  Object.values(languages).filter(({ selected }) => selected)
                    .length > 4
                }
                style={{
                  marginTop: "1rem",
                }}
                onClick={this.props.closeLanguageModal}
                value={I18n.t("createAppPush_LangleSelector_Modal_selectBtn")}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default LanguageModal;
