import moment from "moment";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import classes from "./ViewPlanFeatures.scss";

const dateFormat = "DD MMM YY";

export default function ViewPlanFeatures({
  suit,
  display,
  onClose,
  onConfirm,
  disabled,
}) {
  let product = suit.products_usage;

  return display ? (
    <div
      className="modal-container"
      style={{ height: 200, display: "initial" }}
    >
      <Modal
        className={classes.modalStyle}
        show={display}
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className={classes.modalHeader}>
          <Modal.Title id="contained-modal-title">
            {"Plan Usage"} <span>{" (Updates every 24 hours)"}</span>
          </Modal.Title>
          <Modal.Title id="contained-modal-title"></Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modalBody}>
          <div className={classes.description}>
            {Object.keys(product).map((key, index) => {
              if (key == "date_range") return;
              return (
                <div key={index} className={classes.row}>
                  <span>{product[key].label}</span>
                  <span>
                    {product[key].usage || product[key].usage == 0
                      ? product[key].usage
                      : ""}
                    {product[key].limit ? " / " + product[key].limit : ""}{" "}
                    <span className={classes.hint}>
                      {product[key].second_label
                        ? product[key].second_label
                        : ""}
                    </span>
                  </span>
                </div>
              );
            })}

            {product.date_range && (
              <div className={classes.dateRange}>
                <span>
                  {product &&
                    `Plan Usage is calculated from ${moment
                      .utc(product.date_range.start)
                      .local()
                      .format(dateFormat)} until ${moment
                      .utc(product.date_range.end)
                      .local()
                      .format(dateFormat)}.`}
                </span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className={classes.modalFooter}>
          <Button
            style={{ color: "#a6a2a2 !important" }}
            onClick={onClose}
            className={classes.modalCancel}
          >
            Close
          </Button>
          <Button
            style={{ color: "white" }}
            disabled={disabled}
            onClick={onConfirm}
            className={classes.modalConfirm}
          >
            Upgrade
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  ) : (
    <div></div>
  );
}
