import { Icon } from "antd";
import PropTypes from "prop-types";
import React from "react";
import classes from "./MultiValueInput.scss";

class MultiValueInput extends React.Component {
  handleKeyDown = (e) => {
    const { onValuesChange } = this.props;
    const inputValue = e.target.value;
    if (e.key === "Enter" && inputValue !== "") {
      if (onValuesChange) {
        onValuesChange([...this.props.values, inputValue]);
      }
      e.target.value = "";
    }
  };

  handleDelete = (index) => {
    const { onValuesChange } = this.props;
    const { values } = this.props;
    const newValues = [...values];
    newValues.splice(index, 1);
    if (onValuesChange) {
      onValuesChange(newValues);
    }
  };

  render() {
    const { values, placeholder, children, style } = this.props;

    return (
      <div style={style} className={classes.multiValueInputContainer}>
        <div className={classes.domainsContainer}>
          {values.map((value, index) => (
            <span key={index} className={classes.domain}>
              <span className={classes.domainName}>{value}</span>
              <button
                className={classes.removeDomain}
                onClick={() => this.handleDelete(index)}
              >
                <Icon
                  type="close-circle"
                  theme="twoTone"
                  twoToneColor="#f20000"
                  style={{ fontSize: "16px" }}
                />
              </button>
            </span>
          ))}
        </div>
        <input
          type="text"
          onKeyDown={this.handleKeyDown}
          placeholder={placeholder}
        />
        {children}
      </div>
    );
  }
}

export default MultiValueInput;

MultiValueInput.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  onValuesChange: PropTypes.func,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
};
