import PropTypes from "prop-types";
import React from "react";

class RootContainer extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.context.router.push("/home");
  }

  render() {
    return <span></span>;
  }
}

export default RootContainer;
