import events from "api/events";
import _ from "lodash";

import { NUMBER, STRING } from "consts/TableColumnTypes";

// ------------------------------------
// Constants
// ------------------------------------

const CREATE_NEW_EVENT = "CREATE_NEW_EVENT";
const LIST_EVENTS = "LIST_EVENTS";
const EVENTS_CHECK_DATA_TABLE_ROW = "EVENTS_CHECK_DATA_TABLE_ROW";
const EVENTS_CHECK_ALL_DATA_TABLE_ROWS = "EVENTS_CHECK_ALL_DATA_TABLE_ROWS";
const EVENTS_ON_COLUMN_TOGGLE = "EVENTS_ON_COLUMN_TOGGLE";
const EVENTS_ON_RESET_COLUMNS = "EVENTS_ON_RESET_COLUMNS";
const EVENTS_TOGGLE_ROW_ACTION_MENU = "EVENTS_TOGGLE_ROW_ACTION_MENU";
const EVENTS_CLEAR_ACTION_MENU = "EVENTS_CLEAR_ACTION_MENU";
const SORT_EVENTS_TABLE = "SORT_EVENTS_TABLE";
const DELETE_EVENT_BY_NAME = "DELETE_EVENT_BY_NAME";
const CLEAR_ALL_EVENTS = "CLEAR_ALL_EVENTS";
const DELETE_EVENT_BULK_BY_NAME = "DELETE_EVENT_BULK_BY_NAME";

// ------------------------------------
// Actions
// ------------------------------------

export function listEvents(data) {
  return {
    type: LIST_EVENTS,
    payload: events.listEvents(data),
  };
}

export function createNewEvent(data) {
  return {
    type: CREATE_NEW_EVENT,
    payload: events.createNewEvent(data),
  };
}

export function deleteEvent(data) {
  return {
    type: DELETE_EVENT_BY_NAME,
    payload: events.deleteEventByName(data),
  };
}

// data = {suitId, apiKey, eventNames}
// data.eventNames = {"event_names": ["test1", "test2"]}
export function deleteEventBulk(data) {
  return {
    type: DELETE_EVENT_BULK_BY_NAME,
    payload: events.deleteEventBulkByName(data),
  };
}

export function clearEvents(data) {
  return {
    type: CLEAR_ALL_EVENTS,
    payload: events.clearEvents(data),
  };
}

// DataTable

export function checkDataTableRow(idx) {
  return {
    type: EVENTS_CHECK_DATA_TABLE_ROW,
    payload: idx,
  };
}
export function checkAllTableRows(newChecked) {
  return {
    type: EVENTS_CHECK_ALL_DATA_TABLE_ROWS,
    payload: newChecked,
  };
}
export function onColumnToggle(idx) {
  return {
    type: EVENTS_ON_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function toggleRowActionMenu(idx) {
  return {
    type: EVENTS_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}

export function clearActionMenu(idx) {
  return {
    type: EVENTS_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: EVENTS_ON_RESET_COLUMNS,
  };
}

export function sortTable(name, type) {
  return {
    type: SORT_EVENTS_TABLE,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  };
}
function prepareEventsTableDate(tableData) {
  const labels = [
    {
      name: "Event Name",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "event_name",
      defaultValue: "",
    },
    {
      name: "Count",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: "event_count",
      defaultValue: 0,
    },
    {
      name: "Users",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: "users_count",
    },
  ];

  const rows = tableData.map((row) => ({
    checked: false,
    name: _.get(row, "event_name"),
    actionsMenu: false,
    values: [
      _.get(row, labels[0].varName, labels[0].defaultValue),
      _.get(row, labels[1].varName, labels[1].defaultValue),
      _.get(row, labels[2].varName, labels[2].defaultValue),
    ],
  }));

  return {
    labels,
    rows: _.sortBy(rows, (row) => row.values[1]),
    actions: [{ type: "delete" }],
  };
}

// ------------------------------------
// Initial State
// ------------------------------------
const initialState = {
  events: null,
  eventsTable: null,
  isPending: false,
  error: false,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function selectReducer(state = initialState, action) {
  switch (action.type) {
    case `${CREATE_NEW_EVENT}_PENDING`:
    case `${DELETE_EVENT_BY_NAME}_PENDING`:
    case `${DELETE_EVENT_BULK_BY_NAME}_PENDING`:
    case `${CLEAR_ALL_EVENTS}_PENDING`:
    case `${LIST_EVENTS}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${CREATE_NEW_EVENT}_REJECTED`:
    case `${DELETE_EVENT_BY_NAME}_REJECTED`:
    case `${DELETE_EVENT_BULK_BY_NAME}_REJECTED`:
    case `${CLEAR_ALL_EVENTS}_REJECTED`:
    case `${LIST_EVENTS}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case `${CREATE_NEW_EVENT}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };

    case `${LIST_EVENTS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        events: action.payload.body.result,
        eventsTable: prepareEventsTableDate(action.payload.body.result),
        eventsCount: action.payload.body.count,
      };

    case `${DELETE_EVENT_BY_NAME}_FULFILLED`:
    case `${DELETE_EVENT_BULK_BY_NAME}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };

    case `${CLEAR_ALL_EVENTS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case EVENTS_CHECK_DATA_TABLE_ROW:
      return {
        ...state,
        eventsTable: {
          ...state.eventsTable,
          rows: state.eventsTable.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, checked: !row.checked }
              : row;
          }),
        },
      };

    case EVENTS_CHECK_ALL_DATA_TABLE_ROWS:
      return {
        ...state,
        eventsTable: {
          ...state.eventsTable,
          rows: state.eventsTable.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    case EVENTS_ON_COLUMN_TOGGLE:
      return {
        ...state,
        eventsTable: {
          ...state.eventsTable,
          labels: state.eventsTable.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };

    case EVENTS_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        eventsTable: {
          ...state.eventsTable,
          rows: state.eventsTable.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, actionsMenu: !row.actionsMenu }
              : row;
          }),
        },
      };

    case EVENTS_CLEAR_ACTION_MENU:
      return {
        ...state,
        eventsTable: {
          ...state.eventsTable,
          rows: state.eventsTable.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };

    case EVENTS_ON_RESET_COLUMNS:
      return {
        ...state,
        eventsTable: {
          ...state.eventsTable,
          labels: state.eventsTable.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };

    case SORT_EVENTS_TABLE: {
      const indexOfKey = state.eventsTable.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.eventsTable.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.eventsTable.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        eventsTable: {
          ...state.eventsTable,
          rows: tableRows,
        },
      };
    }

    default:
      return state;
  }
}
