import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { I18n } from "react-redux-i18n";
import classes from "./PrimaryButton.scss";

/**
 * PrimaryButton is a custom button component that wraps the Ant Design Button.
 *
 * @component
 * @param {Object} props - The properties that define the PrimaryButton component.
 * @param {boolean} props.pending - If true, the button will show a loading spinner.
 * @param {string|node} props.value - The text or node to be displayed inside the button.
 * @param {node} props.icon - The icon to be displayed at the start of the button.
 * @param {Object} props.style - The CSS styles to apply to the button.
 * @param {function} props.onClick - The function to execute when the button is clicked.
 * @param {boolean} props.disabled - If true, the button will be disabled.
 * @param {boolean} props.block - If true, the button will take up the full width of its container.
 * @param {string} props.className - The CSS class to apply to the button.
 * @param {string} props.type - The type of the button (e.g., "submit", "reset", "button").
 * @param {string} props.size - The size of the button (e.g., "small", "medium", "large").
 * @param {node} props.children - The child nodes of the button.
 * @param {boolean} props.danger - If true, the button will be styled as a danger button.
 * @param {string} props.id - The id of the button.
 * @param {node} props.icon2 - The icon to be displayed at the end of the button.
 * @returns {node} The PrimaryButton component.
 */
export default function PrimaryButton({
  pending,
  value,
  icon,
  style,
  onClick,
  disabled,
  block,
  className,
  type,
  size,
  children,
  danger,
  id,
  icon2,
}) {
  return (
    <Button
      id={id}
      htmlType={type}
      style={style}
      className={`${className} ${classes.primaryButton} ${
        danger ? classes.danger : ""
      }`}
      onClick={onClick}
      disabled={disabled}
      size={size}
      block={block}
      loading={pending}
      type={danger ? "danger" : "primary"}
    >
      {icon}
      {typeof value === "string" ? I18n.t(value) : value}
      {icon2}
      {children}
    </Button>
  );
}

PrimaryButton.propTypes = {
  pending: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  style: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  danger: PropTypes.bool,
  id: PropTypes.string,
  icon2: PropTypes.node,
};

PrimaryButton.defaultProps = {
  type: "button",
  size: "large",
  danger: false,
};
