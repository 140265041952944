import { Dropdown, Icon, Menu } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocale } from "react-redux-i18n";
import { getFromLocalStore, setToLocalStore } from "../../../store/localStore";
import classes from "./LanguageSwitcher.scss";

class LanguageSwitcher extends Component {
  state = {
    value: getFromLocalStore("locale") || "en",
  };

  handleChange(value) {
    if (value === this.state.value) return;

    this.setState({ value: value });
    this.props.dispatch(setLocale(value));
    setToLocalStore("locale", value);
    location.reload();
  }

  render() {
    const { value } = this.state;

    const menu = (
      <Menu>
        <Menu.Item key="0">
          <span
            onClick={() => {
              this.handleChange("en");
            }}
          >
            English
          </span>
        </Menu.Item>
        <Menu.Item key="1">
          <span
            onClick={() => {
              this.handleChange("ar");
            }}
          >
            العربية
          </span>
        </Menu.Item>
        <Menu.Item key="3">
          <span
            onClick={() => {
              this.handleChange("tr");
            }}
          >
            Türkiye
          </span>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={["click"]}>
        <span className={classes.languageSwitcher}>
          <Icon style={{ color: "#212121", fontSize: "20px" }} type="global" />
          {value} <Icon type="down" />
        </span>
      </Dropdown>
    );
  }
}
export default connect()(LanguageSwitcher);

LanguageSwitcher.propTypes = {
  dispatch: PropTypes.func,
};
