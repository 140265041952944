import { Spin } from "antd";
import PropTypes from "prop-types";
import React from "react";
import createRef from "react-create-ref";
import EmailEditor from "react-email-editor";
import { connect } from "react-redux";
import { PrimaryButton, SecondaryButton } from "../../../../components";
import { Toast } from "../../../../modules/toast";
import retailGainImage from "../../../../static/RetailGainOnboarding/retail-onboarding-step-two.svg";
import {
  createSuitInvoiceTemplate,
  getSuitInvoiceTemplate,
  updateSuitInvoiceTemplate,
} from "../api/RetailGainOnBoarding.api";
import classes from "./UnlayerTemplateStep.module.scss";
import defaultInvoiceTemplate from "./defaultInvoiceTemplate.json";

class UnlayerTemplateStep extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = createRef();

    this.state = {
      currentTemplate: null,
      loading: false,
    };
  }

  async componentDidMount() {
    const {
      user: { activeSuitId },
    } = this.props;

    this.setState({ loading: true });
    const currentTemplate = await getSuitInvoiceTemplate(activeSuitId);
    this.setState({ loading: false });

    if (currentTemplate) {
      this.setState({ currentTemplate });
    }
  }

  handleEditorReady = () => {
    const { currentTemplate } = this.state;

    if (currentTemplate) {
      this.editorRef.current.editor.loadDesign(currentTemplate?.unlayer_design);
    } else {
      this.editorRef.current.editor.loadDesign(defaultInvoiceTemplate);
    }
  };

  exportHtml = () => {
    const { currentTemplate } = this.state;

    this.editorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;

      const invoiceTemplate = {
        template_name: "invoicetemplate",
        type: "email",
        unlayer_design: design,
        content: html,
      };

      if (currentTemplate) {
        await this.updateTemplate(invoiceTemplate);
      } else {
        await this.createTemplate(invoiceTemplate);
      }

      this.props.onNextStep();
    });
  };

  updateTemplate = async (invoiceTemplate) => {
    const {
      user: { activeSuitId },
    } = this.props;

    const { currentTemplate } = this.state;

    this.setState({ loading: true });

    await updateSuitInvoiceTemplate(
      activeSuitId,
      currentTemplate.template_id,
      invoiceTemplate
    );

    this.setState({ loading: false });

    Toast.success("Invoice template updated successfully");
  };

  createTemplate = async (invoiceTemplate) => {
    const {
      user: { activeSuitId },
    } = this.props;

    this.setState({ loading: true });

    await createSuitInvoiceTemplate(activeSuitId, invoiceTemplate);

    this.setState({ loading: false });

    Toast.success("Invoice template created successfully");
  };

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <div className={classes.unlayerTemplateStep}>
          <img
            className={classes.unlayerTemplateStep__image}
            src={retailGainImage}
            alt="Retail Gain"
          />

          <p className={classes.unlayerTemplateStep__title}>
            Design your Invoice(1/3)
          </p>

          <EmailEditor
            ref={this.editorRef}
            editorId="retailGainOnboardingUnlayerEditor"
            style={{
              width: "100%",
            }}
            onReady={this.handleEditorReady}
          />

          <div className={classes.unlayerTemplateStep__buttonsContainer}>
            <SecondaryButton onClick={this.props.onPreviousStep}>
              Back
            </SecondaryButton>

            <PrimaryButton onClick={this.exportHtml}>continue</PrimaryButton>
          </div>
        </div>
      </Spin>
    );
  }
}

export default connect((store) => ({
  user: store.user,
}))(UnlayerTemplateStep);

UnlayerTemplateStep.propTypes = {
  user: PropTypes.object,
  onNextStep: PropTypes.func,
  onPreviousStep: PropTypes.func,
};
