/**
 * @description get json parsed version for item in localStorage
 *
 * @param key
 * @return {json}
 */
export const getFromLocalStore = (key) => JSON.parse(localStorage.getItem(key));

/**
 * @description set json string value for key in localStorage
 *
 * @param key
 * @param value
 * @return {json}
 */
export const setToLocalStore = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));

/**
 * @description delete key from localStorage
 *
 * @param keys
 * @return {json}
 */
export const deleteFromLocalStore = (keys) =>
  Array.isArray(keys)
    ? keys.forEach((key) => localStorage.removeItem(key))
    : localStorage.removeItem(keys);
