import EP, { config } from "consts/api";
import _ from "lodash";
import api from "modules/api";
import { isLoggedIn, userData } from "modules/user";
const userId = () => isLoggedIn() && userData().user_id;
export default class User {
  static loginUser = ({ email, password, recaptcha }) =>
    api("post", EP.USER.LOGIN(), { recaptcha }, false, false).auth(
      email,
      password
    );
  static generateAPIKey = (data, appId) =>
    api("put", EP.USER.GENERATE(appId), data);
  // static googleLogin = () => api('post', EP.USER.GOOGLELOGIN())

  static registerUser = (data) => api("post", EP.USER.REGISTER(), data);

  static forgotPassword = (data) =>
    api("post", EP.USER.FORGOT_PASSWORD(), data);

  static resetPassword = (data, token) =>
    api("post", EP.USER.RESETPASSWORD(token), data);
  static resendConfirmation = (email, recaptcha) =>
    api("post", EP.USER.RESEND_CONFIRMATION(email), { recaptcha });

  static getUserSuits = () => {
    const user = userData();

    if (user !== null) {
      return api("get", EP.USER.APPS()).set("authToken", user.auth_token);
    }
  };

  // static getUserSuits = () => api('get', EP.USER.APPS())

  static getUserProfile = () => api("get", EP.USER.USER_LINK());
  static updateUserProfile = (data, query = "") => {
    let changeEmailLock = false;
    if (data.queryString) {
      return api("put", EP.USER.USER_LINK(changeEmailLock), { ...data }, query);
    }
    if (data.email !== data.newemail && data.newemail !== undefined) {
      changeEmailLock = true;
      return api(
        "put",
        EP.USER.USER_LINK(changeEmailLock),
        { ...data, email: data.email, newemail: data.newemail },
        query
      );
    } else {
      return api(
        "put",
        EP.USER.USER_LINK(changeEmailLock),
        { ...data, email: data.email },
        query
      );
    }
  };
  // static updateUserProfile = (data, query = '') =>
  // api('put', EP.USER.USER_LINK(), { ...data, email: data.email }, query)
  static getCurrentSuit = (suitId) => api("get", EP.USER.APP(suitId));
  static createSuit = (data) => api("post", EP.USER.APPS(), data);

  static updateSuit = (suitId, data) => api("put", EP.USER.APP(suitId), data);

  static deleteSuit = (suitId) => api("delete", EP.USER.APP(suitId));

  static getAppBackendConfig = (subdomain, appbackend_userId) =>
    api(
      "get",
      EP.APPBACKEND.GET_CONFIG(userId(), subdomain, appbackend_userId)
    );

  static updateAppBackendConfig = (suitId, data, appbackend_userId) =>
    api(
      "put",
      EP.APPBACKEND.UPDATE_APPBACKEND_CONFIG(
        userId(),
        suitId,
        appbackend_userId
      ),
      data
    );
  static restartApp = (userId, subdomain, appbackend_userId) =>
    api(
      "get",
      EP.APPBACKEND.Restart_APP_Config(userId, subdomain, appbackend_userId)
    );
  static advancedConfig = (userId, appId, data, appbackend_userId) =>
    api(
      "put",
      EP.APPBACKEND.Advanced_Config(userId, appId, appbackend_userId),
      data
    );
  static bundleIosId = (suitId, bundleId, subdomain, appbackend_userId) =>
    api(
      "put",
      EP.APPBACKEND.Bundle_Ios_Id(suitId, userId(), appbackend_userId),
      {
        push: {
          ios: [
            {
              pfx: `cert/${suitId}.dev.p12`,
              bundleId: bundleId,
              production: false,
            },
            {
              pfx: `cert/${suitId}.dist.p12`,
              bundleId: bundleId,
              production: true,
            },
          ],
        },
        subdomain: subdomain,
      }
    );

  static uploadIosFiles = (
    suitId,
    subdomain,
    data,
    appbackend_userId,
    passwords
  ) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      const name = `${suitId}.${key}.p12`;
      formData.append(key + ".p12", data[key], name);
      Object.keys(passwords).forEach((passwordKey) => {
        formData.append(passwordKey, passwords[passwordKey]);
      });
    });
    return api(
      "post",
      EP.APPBACKEND.UPLOAD_IOS_FILES(
        userId(),
        suitId,
        subdomain,
        appbackend_userId
      ),
      formData
    );
  };
  static getUsersCounts = (appName) =>
    api("get", EP.APPBACKEND.GET_CONFIG(appName))
      .set("X-Parse-REST-API-Key", "REST-B7A5BF0E-1B9B-592C-B858-6B3EB1BA7ABA")
      .set("X-Parse-Application-Id", "APP-5663B0CC-7061-52D4-B5EA-356F2352B937")
      .set("X-Parse-Master-Key", "MASTER-6488050B-0F19-5CAA-AD3C-C0A937B252B8");

  static getInvoices = (customer) => {
    return api("get", EP.STRIPE.INVOICES())
      .query({ customer, limit: 100 })
      .set("Authorization", config.STRIPE_INVOICES_KEY);
  };
  static submitStripeToken = (stripeKey, userId) =>
    api("post", EP.STRIPE.TOKEN(userId), { stripeKey }, false, false);

  static deletUser = (data, auth) => {
    return api("delete", EP.USER.DELETEUSER(), data, false, false).set(
      "Authorization",
      auth
    );
  };

  static addon = (suitId, apiKey, data) => {
    api("post", EP.USER.addON(suitId), data)
      .set("appApiKey", apiKey)
      .set("Content-Type", "application/json");
  };

  static FillDemoData = (suitId) => {
    const user = userData();
    return api("post", EP.USER.FillDemoData(suitId)).set(
      "authToken",
      user.auth_token
    );
  };

  static ClearDemoData = (suitId) => {
    const user = userData();
    return api("delete", EP.USER.ClearDemoData(suitId)).set(
      "authToken",
      user.auth_token
    );
  };

  static checkSubscribed = (serverURL, appId, suitId, masterKey, apiKey) => {
    return api("POST", EP.USER.checkSubscribed(serverURL, suitId, apiKey))
      .set("x-parse-application-id", appId)
      .set("x-parse-master-key", masterKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");
  };

  static getAppCurrency = (serverURL, appId, suitId, masterKey, apiKey) => {
    return api("POST", EP.USER.getAppCurrency(serverURL, suitId, apiKey))
      .set("x-parse-application-id", appId)
      .set("x-parse-master-key", masterKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");
  };
  static upgradePlan = (
    suit_id,
    plan_id,
    infra_type,
    coupon,
    subscription_type
  ) => {
    return api(
      "PUT",
      EP.USER.upgradePlan(suit_id, plan_id),
      _.pickBy({ infra_type, coupon, subscription_type })
    )
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");
  };

  static addSubUser = (data) =>
    api("POST", EP.USER.SubUser(), data)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  static getSubUsers = () =>
    api("GET", EP.USER.SubUser())
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  static editSubUser = (data) =>
    api("PUT", EP.USER.SubUser(), data)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  static deleteSubUser = (data) =>
    api("DELETE", EP.USER.SubUser(), data)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  // payments config
  static updatePaymentConfig = (suitId, data) =>
    api("put", EP.USER.PAYMENT_CONFIG(suitId), data);

  static getPaymentConfig = (suitId) =>
    api("get", EP.USER.PAYMENT_CONFIG(suitId));
}
