import React from "react";
import BounceLoader from "react-spinners/BounceLoader";
import classes from "./Loader.scss";

const Loader = () => {
  return (
    <div className={classes["loader-container"]}>
      <BounceLoader
        loading={true}
        aria-label="Loading"
        size={100}
        color="#024da1"
      />
    </div>
  );
};

export default Loader;
