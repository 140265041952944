import { getSuitBySubdomain } from "modules/user";
import moment from "moment";
import React from "react";
import { Button } from "react-bootstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { I18n } from "react-redux-i18n";
import classes from "./DateFilter.scss";

class DateFilter extends React.Component {
  state = {
    type: "this-month",
    fromDate: moment().startOf("month").format("YYYY-MM-DD"),
    toDate: moment().endOf("month").format("YYYY-MM-DD"),
    lastType: "this-month",
    projectCreatedDate: moment.utc(
      getSuitBySubdomain(this.props.subdomain).created_at
    ),
  };

  componentWillMount() {
    if (this.props.initial) {
      let updatedValues = { lastType: this.props.initial };
      let values = this.props.values;
      if (values) {
        if (values.fromDate) updatedValues.fromDate = values.fromDate;
        if (values.toDate) updatedValues.toDate = values.toDate;
      }
      this.setState(updatedValues, () => {
        this.onSelectReadyMade(this.props.initial);
      });
    }
  }

  // if passed initial values, set them as default
  // i.e initialValues=['2022-10-04','2023-08-12']
  componentDidMount() {
    if (this.props.initialValues) {
      const [start, end] = this.props.initialValues;
      this.setState({
        fromDate: start,
        toDate: end,
        lastType: "custom",
        type: "custom",
      });
    }
  }

  onAddFilter = () => {
    this.props.onDateChanged(
      this.state.fromDate,
      this.state.toDate,
      this.state.type
    );
    this.setState({
      open: false,
      lastType: this.state.type,
    });
  };

  onExitFilter = () => {
    this.setState({
      type: this.state.lastType,
      fromDate: null,
      toDate: null,
      open: false,
      error: null,
    });
  };

  onSelectOneDate = (key, value) => {
    this.setState({
      [key]: moment(value).format("YYYY-MM-DD"),
      type: "custom",
    });
  };

  onSelectReadyMade = (type) => {
    let fromDate = null;
    let toDate = null;
    const formatDate = "YYYY-MM-DD";

    if (type === "custom") {
      fromDate = moment(this.state.fromDate).format(formatDate);
      toDate = moment(this.state.toDate).format(formatDate);
    } else if (type === "yesterday") {
      fromDate = moment().add(-1, "day").format(formatDate);
      toDate = moment().format(formatDate);
    } else if (type === "today") {
      fromDate = moment().format(formatDate);
      toDate = moment().add(1, "day").format(formatDate);
    } else if (type === "last-month") {
      fromDate = moment()
        .subtract(1, "month")
        .startOf("month")
        .format(formatDate);
      toDate = moment().subtract(1, "month").endOf("month").format(formatDate);
    } else if (type === "this-month") {
      fromDate = moment().startOf("month").format(formatDate);
      toDate = moment().endOf("month").format(formatDate);
    } else if (type === "all-time") {
      fromDate = moment(this.state.projectCreatedDate).format(formatDate);
      toDate = moment().format("YYYY-MM-DD");
    }

    this.setState({ type, fromDate, toDate });
  };

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    let disabledLogic =
      !this.state.fromDate ||
      !this.state.toDate ||
      (this.state.fromDate &&
        this.state.toDate &&
        new Date(this.state.fromDate).getTime() >
          new Date(this.state.toDate).getTime());
    return (
      <div className={classes["date-filter-container"]}>
        {this.props.isPending ? (
          <div className={classes["skeleton-date-filter"]} />
        ) : (
          <div id="date-filter">
            <Button
              onClick={this.toggleOpen}
              bsStyle="primary"
              disabled={this.props.disabled}
            >
              <i className="fa fa-calendar-o" aria-hidden="true" />
              {this.state.type === "custom"
                ? `${
                    (this.state.fromDate &&
                      moment(this.state.fromDate).format("YYYY-MM-DD")) ||
                    "Start"
                  } : ${
                    (this.state.toDate &&
                      moment(this.state.toDate).format("YYYY-MM-DD")) ||
                    "End"
                  }`
                : this.state.type.replace("-", " ")}{" "}
              <i className="fa fa-caret-down" aria-hidden="true" />
            </Button>
            <div
              className={`${!this.state.open ? classes["hidden-filter"] : ""}`}
            >
              <p style={{ fontSize: "13px", fontWeight: "bold" }}>
                {I18n.t("ReportsPage_dataFilterDropdown_dateRange")}
              </p>
              <div>
                <div>
                  <DayPickerInput
                    placeholder={I18n.t(
                      "ReportsPage_dataFilterDropdown_dateRange_from_placeholder"
                    )}
                    value={this.state.fromDate}
                    onDayChange={(day) => this.onSelectOneDate("fromDate", day)}
                  />
                  {!this.state.fromDate && (
                    <i className="fa fa-calendar-o" aria-hidden="true" />
                  )}
                </div>
                <div>
                  <DayPickerInput
                    placeholder={I18n.t(
                      "ReportsPage_dataFilterDropdown_dateRange_to_placeholder"
                    )}
                    value={this.state.toDate}
                    onDayChange={(day) => this.onSelectOneDate("toDate", day)}
                  />
                  {!this.state.toDate && (
                    <i className="fa fa-calendar-o" aria-hidden="true" />
                  )}
                </div>
              </div>
              {this.state.type === "custom" &&
                this.state.fromDate &&
                this.state.toDate &&
                new Date(this.state.fromDate).getTime() >
                  new Date(this.state.toDate).getTime() && (
                  <span className="text-danger">
                    <strong>
                      {I18n.t(
                        "ReportsPage_dataFilterDropdown_dateRange_to_placeholder"
                      )}
                    </strong>
                    {I18n.t("ReportsPage_dataFilterDropdown_dateRange_note")}
                    <strong>
                      {I18n.t(
                        "ReportsPage_dataFilterDropdown_dateRange_from_placeholder"
                      )}
                    </strong>
                  </span>
                )}
              <div>
                {this.props.yesterday ? (
                  <Button
                    onClick={() => this.onSelectReadyMade("yesterday")}
                    bsStyle={
                      this.state.type === "yesterday" ? "primary" : "default"
                    }
                    block
                  >
                    {I18n.t("ReportsPage_dataFilterDropdown_Yesterday")}
                  </Button>
                ) : (
                  <Button
                    onClick={() => this.onSelectReadyMade("today")}
                    bsStyle={
                      this.state.type === "today" ? "primary" : "default"
                    }
                    block
                  >
                    {I18n.t("today")}
                  </Button>
                )}
              </div>
              <div>
                <Button
                  bsStyle={
                    this.state.type === "this-month" ? "primary" : "default"
                  }
                  onClick={() => this.onSelectReadyMade("this-month")}
                  block
                >
                  {I18n.t("ReportsPage_dataFilterDropdown_ThisMonth")}
                </Button>
              </div>
              <div>
                <Button
                  bsStyle={
                    this.state.type === "last-month" ? "primary" : "default"
                  }
                  onClick={() => this.onSelectReadyMade("last-month")}
                  block
                >
                  {I18n.t("ReportsPage_dataFilterDropdown_LastMonth")}
                </Button>
              </div>
              <div>
                <Button
                  bsStyle={
                    this.state.type === "all-time" ? "primary" : "default"
                  }
                  onClick={() => this.onSelectReadyMade("all-time")}
                  block
                >
                  {I18n.t("ReportsPage_dataFilterDropdown_AllTime")}
                </Button>
              </div>
              <div>
                <div className={`pull-left ${classes.cancelBtnContainer}`}>
                  <Button onClick={this.onExitFilter}>
                    {I18n.t("cancelBtn")}
                  </Button>
                </div>

                <div className={`pull-right ${classes.applyBtnContainer}`}>
                  <Button
                    disabled={disabledLogic}
                    bsStyle={"primary"}
                    onClick={this.onAddFilter}
                  >
                    {I18n.t("ReportsPage_dataFilterDropdown_ApplyBtn")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DateFilter;
