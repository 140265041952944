import EP from "consts/api";
import api from "modules/api";

export default class Push {
  static getSuitPushStatistics = (suitId, data) =>
    api("get", EP.PUSHSTATICTICS(suitId), false, data);
  static getSuitPushs = (suitId, type) =>
    api("get", EP.LISTPUSH(suitId), false, { type });
  static getSuitPushsSearch = (suitId, nameVal, type) =>
    api("get", EP.SEARCHPUSH(suitId, nameVal), false, { type });
  static getWebPushAnalytics = (suitId, data) =>
    api("get", EP.WEBPUSHSUBSCRIBERS(suitId), false, data);
  static getCampaignAnalytics = (suitId, campaignId, data) =>
    api("get", EP.Campaign_ANALYTICS(suitId, campaignId), false, data);
  static deleteCampain = (suitId, campaignId) => {
    return api("delete", EP.DELETEPUSH(suitId, campaignId)).set(
      "Accept",
      "application/json"
    );
  };

  static deleteCampaignBulk = (suitId, campaignIds) =>
    api("delete", EP.PUSH_DELETE_BULK(suitId), campaignIds).set(
      "Accept",
      "application/json"
    );

  static getSuitAppPushs = (suitId, data) =>
    api("get", EP.getSuitAppPushs(suitId, data));

  static getSuitWebPushs = (suitId, data) =>
    api("get", EP.getSuitWebPushs(suitId, data));

  static getSuitSMS = (suitId, data) => api("get", EP.getSuitSMS(suitId, data));

  static getSuitEmail = (suitId, apiKey, data) =>
    api("get", EP.getSuitEmail(suitId, apiKey, data));

  // SMART LINKS
  static createTargetList = (suitId, data, query = "upload_files=true") => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key], data.file.name);
    });
    return api("post", EP.TARGET_LIST.ALL(suitId), formData, query);
  };

  static listTargetList = (suitId) => {
    return api("get", EP.TARGET_LIST.ALL(suitId)).set(
      "Accept",
      "application/json"
    );
  };

  static deleteTargetList = (suitId, targetListName) => {
    return api("delete", EP.TARGET_LIST.SINGLE(suitId, targetListName)).set(
      "Accept",
      "application/json"
    );
  };

  // deleteTargetListBulk = {"sms_target_lists": ["test", "test"]} names
  static deleteTargetListBulk = (suitId, targetListNames) => {
    return api("delete", EP.TARGET_LIST.BULK(suitId), targetListNames).set(
      "Accept",
      "application/json"
    );
  };

  static downloadTargetList = (suitId, targetListName) => {
    return api("get", EP.TARGET_LIST.DOWNLOAD(suitId, targetListName)).set(
      "Accept",
      "application/json"
    );
  };
}
