import { userData } from "modules/user";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  PostalCodeElement,
  injectStripe,
} from "react-stripe-elements";
import { submitStripeToken } from "reducers/user";
import { Toast } from "../../../modules/toast";
import Label from "../../Label";
import classes from "./StripeForm.scss";

class StripeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      error: false,
      valid: true,
      errormessage: null,
    };
  }

  handleSubmit = async () => {
    this.setState({ show: true });

    try {
      const { token } = await this.props.stripe.createToken();
      console.log("token", token);

      if (!token) {
        return Toast.error(I18n.t("you enter invalid credit card data"));
      }

      if (token && token.error) {
        Toast.error(token.error.message);
      } else if (token) {
        try {
          await this.props.submitStripeToken(token.id, userData().user_id);
          this.props.submitForm();
        } catch (error) {
          this.props.resetSubmit && this.props.resetSubmit();
        }
      } else {
        this.props.resetSubmit && this.props.resetSubmit();
      }
    } catch (error) {
      console.error("error", error);
      Toast.error(I18n.t("you enter invalid credit card data"));
    }
  };

  render() {
    const { edit } = this.props;
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          this.handleSubmit();
        }}
      >
        {edit ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <div className={classes.inputRow}>
              <Label>{I18n.t("Card number")}</Label>
              <CardNumberElement />
            </div>

            <div className={classes.inputRow}>
              <Label>{I18n.t("Expiration date")}</Label>
              <CardExpiryElement />
            </div>

            <div className={classes.inputRow}>
              <Label>{I18n.t("Postal code")}</Label>
              <PostalCodeElement />
            </div>

            <div className={classes.inputRow}>
              <Label>{I18n.t("CVC")}</Label>
              <CardCVCElement />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <div className={classes.inputRow}>
              <Label>{I18n.t("Card number")}</Label>
              <CardNumberElement />
            </div>

            <div className={classes.inputRow}>
              <Label>{I18n.t("Expiration date")}</Label>
              <CardExpiryElement />
            </div>
            <div className={classes.inputRow}>
              <Label>{I18n.t("CVC")}</Label>
              <CardCVCElement />
            </div>
            <div className={classes.inputRow}>
              <Label>{I18n.t("Postal code")}</Label>
              <PostalCodeElement />
            </div>
          </div>
        )}
      </form>
    );
  }
}

export default injectStripe(
  connect(() => ({}), { submitStripeToken })(StripeForm)
);
