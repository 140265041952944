import {
  Col,
  CustomSelector,
  CustomSelectorReduxForm,
  Label,
  Row,
  TextBox,
} from "@/components";
import {
  endofspicialChar,
  hasWhiteSpaces,
  required,
} from "@/modules/validations";
import timezoneOptions from "consts/timezoneOptions";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import DateTime from "react-datetime";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Field, getFormValues, initialize, reduxForm } from "redux-form";
import AI from "../../../../../api/AI";
import { SpinLoader } from "../../../../../components";
import SuggestBestTimeBtn from "../../../../../components/SuggestBestTimeBtn/SuggestBestTimeBtn";
import { MOBILE_APP } from "../../../../../consts/suitTypes";
import { Toast } from "../../../../../modules/toast";
import { identifySuitType } from "../../../../../modules/user";
import {
  TARGET_TYPES,
  frequencyOptions,
  repeatOptions,
  sendOptions,
  targetOptions,
} from "../CreateWebPush";
import classes from "../CreateWebPush.scss";

class PushSendStep extends React.Component {
  state = {
    AILoading: false,
  };

  componentDidMount() {
    // initialize the form with empty values

    this.props.initialize("pushSendStep", {});
  }

  suggestBestTime = async () => {
    const {
      user: { appbackend },
      handleStartTimeChange,
      targetType,
      time,
      sendNow,
      myFormValues,
    } = this.props;

    if (!appbackend) return Toast.error(I18n.t("AppBackendNotConfigured"));

    const { appId, masterKey, serverURL } = appbackend;

    // validate sendNow
    if (sendNow) return Toast.error(I18n.t("selectSendLaterFirst"));

    // validate user picked start time first
    if (!time.start) return Toast.error(I18n.t("pickStartTimeFirst"));

    try {
      this.setState({
        AILoading: true,
      });

      let promise;

      switch (targetType.value) {
        case "noTarget":
          promise = AI.getAISuggestionsForApp(serverURL, appId, masterKey);
          break;
        case "userId": {
          const selectedUserId = myFormValues.action_targetTypeUserId;
          promise = AI.getAISuggestionsForUser(
            serverURL,
            appId,
            masterKey,
            selectedUserId
          );
          break;
        }
        case "segments": {
          const selectedSegmentId = myFormValues.action.webPush.segmentId.value;

          promise = AI.getAISuggestionForSegment(
            serverURL,
            appId,
            masterKey,
            selectedSegmentId
          );
          break;
        }
      }

      const response = await promise;
      let topHour;
      if (targetType.value === "noTarget") {
        topHour = response.body.results[0].value;
      } else if (
        targetType.value === "segments" ||
        targetType.value === "userId"
      ) {
        topHour = response.body.results[0].top_hour;
      }

      // convert top hour to moment
      const hours = Math.floor(topHour);
      const minutes = Math.round((topHour % 1) * 60);

      const selectedStartDate = time.start;

      const newDate = moment(selectedStartDate)
        .startOf("day")
        .hour(hours)
        .minute(minutes)
        .second(0);

      handleStartTimeChange(newDate);
    } catch (error) {
      console.error(`Error in AI suggestion`, error);
    } finally {
      this.setState({
        AILoading: false,
      });
    }
  };

  render() {
    const {
      sendNow,
      toggleSendNow,
      repeat,
      toggleRepeat,
      handleTimeZoneChange,
      handleStartTimeChange,
      handleEndTimeChange,
      handleFrequencyChange,
      time,
      targetType,
      handleSelectTarget,
      handleChange,
      segmentOptions,
      user: { suit },
    } = this.props;

    const { AILoading } = this.state;

    const selectedTargetType = targetType.value;
    const showDateError =
      time?.start && time?.end && new Date(time.start) > new Date(time.end);

    const suitType = identifySuitType(suit?.plan_summary?.offers_values);

    const showAI = suitType === MOBILE_APP;

    return (
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "24px",
        }}
      >
        {AILoading && <SpinLoader />}
        <div
          className={`${
            selectedTargetType === TARGET_TYPES.NO_TARGET
              ? classes.gridOneColumn
              : classes.gridTwoColumn
          }`}
        >
          <CustomSelector
            label={I18n.t("sendto")}
            value={targetType}
            options={targetOptions}
            onChange={(newValue) => handleSelectTarget(newValue)}
          />

          {selectedTargetType === TARGET_TYPES.USER_ID && (
            <Field
              label={I18n.t("UserId")}
              name="action_targetTypeUserId"
              placeholder={I18n.t("UserId_placeholder")}
              validate={[required, hasWhiteSpaces, endofspicialChar]}
              component={TextBox}
              rightBottomHint={I18n.t("UserID_hint")}
              onChange={(e, value) => {
                handleChange(`action.webPush.userId`, value);
              }}
            />
          )}

          {selectedTargetType === TARGET_TYPES.SEGMENTS && (
            <Field
              label={I18n.t("segment")}
              height={48}
              name="action.webPush.segmentId"
              options={segmentOptions}
              component={CustomSelectorReduxForm}
              validate={[required]}
              onChange={(value) => {
                handleChange("action.webPush.segmentId", value.value);
              }}
              placeholder={I18n.t("segment_placeholder")}
            />
          )}
        </div>

        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            alignItems: "flex-end",
            gap: "1rem",
          }}
        >
          <CustomSelector
            style={{ flex: 1 }}
            label={I18n.t("whenToSend")}
            value={sendNow ? sendOptions[0] : sendOptions[1]}
            options={sendOptions}
            onChange={(selected) => {
              if (
                (sendNow && selected.value === "sendNow") ||
                (!sendNow && selected.value === "sendLater")
              ) {
                return;
              }
              toggleSendNow();
            }}
          />

          {showAI && <span>OR</span>}

          {showAI && <SuggestBestTimeBtn onClick={this.suggestBestTime} />}
        </div>

        {!sendNow && (
          <div style={{ marginTop: "1rem" }}>
            <div>
              <CustomSelector
                label={I18n.t("Repeat")}
                value={repeat ? repeatOptions[1] : repeatOptions[0]}
                options={repeatOptions}
                onChange={(selected) => {
                  if (
                    (repeat && selected.value === "repeat") ||
                    (!repeat && selected.value === "oneTime")
                  ) {
                    return;
                  }
                  toggleRepeat();
                }}
              />

              <Row style={{ marginTop: "1rem" }}>
                <Col xs={repeat ? 4 : 6}>
                  <div className="form-row form-margin">
                    <Label className={classes["my-form-label"]}>
                      {repeat ? I18n.t("Starton") : I18n.t("when")}
                    </Label>
                    <div>
                      <DateTime
                        isValidDate={(currentDate) => {
                          return currentDate.isAfter(
                            DateTime.moment().subtract(1, "day")
                          );
                        }}
                        value={time.start}
                        onChange={handleStartTimeChange}
                      />
                    </div>
                  </div>
                </Col>

                {repeat && (
                  <Col xs={4}>
                    <div>
                      <Label>{I18n.t("Endon")}</Label>
                      <div>
                        <DateTime
                          isValidDate={(currentDate) => {
                            return currentDate.isAfter(
                              DateTime.moment().subtract(1, "day")
                            );
                          }}
                          value={time.end}
                          onChange={handleEndTimeChange}
                        />
                      </div>
                    </div>
                  </Col>
                )}

                <Col xs={repeat ? 4 : 6}>
                  <CustomSelector
                    label={I18n.t("TimeZone")}
                    options={timezoneOptions}
                    value={time.timezone}
                    onChange={handleTimeZoneChange}
                  />
                </Col>
              </Row>

              {repeat && !sendNow && (
                <Row>
                  <Col xs={repeat ? 4 : 6}>
                    <CustomSelector
                      label={I18n.t("Sendevery")}
                      value={
                        time?.frequency === "day"
                          ? frequencyOptions[0]
                          : time?.frequency === "week"
                            ? frequencyOptions[1]
                            : null
                      }
                      options={frequencyOptions}
                      onChange={(selected) => {
                        handleFrequencyChange(selected.value);
                      }}
                    />
                  </Col>
                </Row>
              )}
            </div>

            {showDateError && (
              <div style={{ color: "red" }}>{I18n.t("dateselectHint")}</div>
            )}
          </div>
        )}
      </form>
    );
  }
}

export default reduxForm({
  form: "pushSendStep",
})(
  connect(
    (state) => ({
      user: state.user,
      myFormValues: getFormValues("pushSendStep")(state),
    }),
    { initialize }
  )(PushSendStep)
);

PushSendStep.propTypes = {
  sendNow: PropTypes.bool,
  toggleSendNow: PropTypes.func,
  repeat: PropTypes.bool,
  toggleRepeat: PropTypes.func,
  handleTimeZoneChange: PropTypes.func,
  handleStartTimeChange: PropTypes.func,
  handleEndTimeChange: PropTypes.func,
  handleFrequencyChange: PropTypes.func,
  time: PropTypes.object,
  targetType: PropTypes.object,
  handleSelectTarget: PropTypes.func,
  handleChange: PropTypes.func,
  segmentOptions: PropTypes.array,
  initialize: PropTypes.func,
};
