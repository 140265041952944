import EP from "consts/api";
import superAgentInstance from "../modules/api";

export default class UploadCsvAppUsers {
  static uploadAppUsersCsv = (suitId, data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key], data.file);
    });
    return superAgentInstance("post", EP.APPUSER.UploadCsv(suitId), formData);
  };
}
