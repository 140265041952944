import api from "modules/api";
import EP from "consts/api";

export default class UninstallMonitor {
  static getData = (serverURL, appId, suitId, masterKey, apiKey, start, end) =>
    api(
      "POST",
      EP.UNINSTALL_MONITOR.getData(serverURL, suitId, apiKey, start, end)
    )
      .set("x-parse-application-id", appId)
      .set("x-parse-master-key", masterKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  static followUpUsers = (
    serverURL,
    appId,
    suitId,
    masterKey,
    apiKey,
    start,
    end
  ) =>
    api(
      "POST",
      EP.UNINSTALL_MONITOR.followUpUsers(serverURL, suitId, apiKey, start, end)
    )
      .set("x-parse-application-id", appId)
      .set("x-parse-master-key", masterKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  static cleanUpUsers = (serverURL, appId, suitId, masterKey, apiKey, email) =>
    api(
      "post",
      EP.UNINSTALL_MONITOR.cleanUpUsers(serverURL, suitId, apiKey, email)
    )
      .set("x-parse-application-id", appId)
      .set("x-parse-master-key", masterKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");
}
