import api from "modules/api";
import EP from "consts/api";

export default class LandingPages {
  static getSuitLandingPages = (suitId, apiKey, data, page) =>
    api("get", EP.LANDINGPAGES.getSuitLandingPages(suitId, apiKey, data, page));

  static getCurrentLandingPage = (suitId, landingId) =>
    api("get", EP.LANDINGPAGES.SINGLE(suitId, landingId));

  static createLandingPage = (suitId, data) =>
    api("post", EP.LANDINGPAGES.ALL(suitId), data);

  static updateLandingPage = (suitId, landingId, data) =>
    api("put", EP.LANDINGPAGES.SINGLE(suitId, landingId), data);

  static deleteLandingPage = (suitId, landingId) =>
    api("delete", EP.LANDINGPAGES.SINGLE(suitId, landingId));

  static deleteMicroPageBulk = (suitId, landingPagesIds) =>
    api("delete", EP.LANDINGPAGES.BULK(suitId), landingPagesIds);

  static getSuitLandingPagesStatistics = (suitId, data) =>
    api("get", EP.LANDINGPAGES.STASTICS(suitId), false, data);

  static getLandingPageAnalytics = (suitId, landingId, data) =>
    api("get", EP.LANDINGPAGES.ANALYTICS(suitId, landingId), false, data);
  // search box function api
  static getSuitLandingPagesBySearch = (suitId, nameVal) =>
    api("get", EP.LANDINGPAGES.SEARCH(suitId, nameVal));
}
