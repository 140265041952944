import api from "modules/api";
import EP from "consts/api";

export default class Events {
  static listEvents = (data) =>
    api("GET", EP.EVENTS.LIST_EVENTS(data))
      .set("appApiKey", data.apiKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  static createNewEvent = ({ serverURL, appId, masterKey, data }) => {
    return api("POST", EP.EVENTS.CREATE_EVENT(serverURL), data)
      .set("x-parse-application-id", appId)
      .set("x-parse-master-key", masterKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");
  };

  static getEvent = (data) =>
    api("GET", EP.EVENTS.GET_EVENT(data))
      .set("appApiKey", data.apiKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  static deleteEventByName = (data) =>
    api("DELETE", EP.EVENTS.DELETE_EVENT_BY_NAME(data))
      .set("appApiKey", data.apiKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  static deleteEventBulkByName = (data) =>
    api("DELETE", EP.EVENTS.DELETE_EVENT_BULK_BY_NAME(data), data.eventNames)
      .set("appApiKey", data.apiKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  static clearEvents = (data) =>
    api("DELETE", EP.EVENTS.CLEAR_ALL_EVENTS(data))
      .set("appApiKey", data.apiKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");
}
