import { CustomSelector, SpinLoader } from "components";
import React from "react";
import Notifications from "react-notification-system-redux";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig, setNotifyConfig } from "reducers/config";
import { connect } from "routes/routedComponent";
import classes from "./WhatsappConfig.scss";
import MetaWhatsApp from "./components/MetaWhatsApp";
import TwilioWhatsapp from "./components/TwilioWhatsapp";

const TWILIO_WHATSAPP = "WHATSAPP-TWILIO";
const META_WHATSAPP = "META";

const whatsappOptions = [
  {
    value: TWILIO_WHATSAPP,
    label: I18n.t("PlatformsPage_nav_5_whatsappOption_1"),
  },
  {
    value: META_WHATSAPP,
    label: I18n.t("PlatformsPage_nav_5_whatsappOption_Meta"),
  },
];

class WhatsappConfig extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      initialized: false,
      requires_auth: false,
      requires_authVendors: false,
      disabled: true,
      whatsAppType: TWILIO_WHATSAPP,
      validateSelect: false,
    };
  }

  async componentDidMount() {
    const {
      config: { notify },
      user: { activeSuitId },
      getNotifyConfig,
    } = this.props;

    let whatsapp;

    if (notify && notify.channels && notify.channels.whatsapp) {
      whatsapp = notify.channels.whatsapp;
    } else {
      const response = await getNotifyConfig(activeSuitId);
      whatsapp = response.value.body.channels.whatsapp;
    }

    if (whatsapp.connection_info.type === META_WHATSAPP.toLowerCase()) {
      this.setState({ whatsAppType: META_WHATSAPP });
    }
  }

  handleEdit = () => {
    this.setState({
      disabled: false,
    });
  };

  handleModify = (type, value = false) => {
    if (value) {
      this.setState({ requires_auth: true });

      this.props.change("requires_authVendors", true);
    }
    if (!value) {
      this.setState({ requires_auth: false });
      this.props.change("requires_authVendors", false);
    }
  };

  handleSave = () => {
    this.setState({
      disabled: true,
    });
  };

  handleSelectWhatsAppVendor = (newValue) => {
    this.setState({ whatsAppType: newValue.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      setNotifyConfig,
      getNotifyConfig,
      form,
      user: { activeSuitId },
    } = this.props;

    const { whatsAppType } = this.state;

    const values = form.nconfig.values;
    const typeValue = whatsAppType;
    let connection_info;

    if (typeValue === TWILIO_WHATSAPP) {
      connection_info = {
        connection_info: {
          type: "WHATSAPP-TWILIO",
          account_sid: values.account_sid,
          sender: values.sender,
          auth_token: values.auth_token,
        },
      };
    }

    let conInfoClone = { ...connection_info.connection_info };

    for (let key in conInfoClone) {
      if (
        typeof conInfoClone[key] === "string" &&
        conInfoClone[key].includes("**")
      ) {
        delete conInfoClone[key];
      }
    }

    connection_info.connection_info = conInfoClone;

    let Notifconfigdata = {
      channels: {
        whatsapp: {
          ...connection_info,
          vendor: values.vendor,
        },
      },
    };

    setNotifyConfig(activeSuitId, Notifconfigdata)
      .then(() => {
        this.props.showNotification(
          {
            title: "",
            message: I18n.t("PlatformsPage_nav_5_whatsappOption_updateMsg"),
            position: "bc",
            autoDismiss: 5,
          },
          "success"
        );
        getNotifyConfig(activeSuitId);
      })
      .then(() => {
        this.handleSave();
      });
  };

  render() {
    const { whatsAppType, isPending: whatsAppPending } = this.state;

    const {
      config: { isPending: configPending },
    } = this.props;

    return (
      <div className={classes.whatsAppConfig}>
        {(configPending || whatsAppPending) && <SpinLoader />}

        <div className={classes.vendorSelector}>
          <label>{I18n.t("PlatformsPage_ServiceVendor")}</label>
          <div>
            <CustomSelector
              options={whatsappOptions}
              onChange={this.handleSelectWhatsAppVendor}
              value={whatsappOptions.find(
                (option) => option.value === whatsAppType
              )}
            />
          </div>
        </div>

        <div>
          {whatsAppType === TWILIO_WHATSAPP && (
            <TwilioWhatsapp
              handleModify={this.handleModify}
              handleSubmit={this.handleSubmit}
              handleEdit={this.handleEdit}
            />
          )}

          {whatsAppType === META_WHATSAPP && (
            <MetaWhatsApp vendor={whatsAppType} />
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
    config: store.config,
    form: store.form,
  }),
  {
    setNotifyConfig,
    getNotifyConfig,
    showNotification: Notifications.show,
  }
)(WhatsappConfig);
