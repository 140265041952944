import { Col, Modal, Row } from "components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import { disableTourSlider, getUserSuits } from "reducers/user";
import AppMarketer1 from "static/AppMarketer1.png";
import Automator from "static/Automator.png";
import apppushicon from "static/apppushicon.png";
import emailsec from "static/emailsec.png";
import mask from "static/mask.png";
import mdpicon from "static/mdpicon.png";
import mdpiconn from "static/mdpiconn.png";
import mdpsec from "static/mdpsec.png";
import sdpsec from "static/sdpsec.png";
import secimglogo from "static/secimglogo.png";
import secondslidericon from "static/secondslidericon.png";
import smartdeeplinkicon from "static/smartdeeplinkicon.png";
import smdeepicon from "static/smdeepicon.png";
import smssec from "static/smssec.png";
import splashicon from "static/splashicon.png";
import webpushicon from "static/webpushicon.png";
import classes from "./PrjectGuid.scss";

let index = 0;

class ProjectGuid extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.swiper = null;
    props.getUserSuits();
    this.state = {
      valid: true,
      done: true,
      show: false,
    };
  }

  // used in show and hide welcome tour in home page in normal login and login with google
  componentWillReceiveProps(nextProps) {
    let NewSuit = nextProps.user.suits;
    if (NewSuit) {
      if (NewSuit.length === 0) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  goNext = () => {
    if (this.swiper) {
      this.swiper.slideNext();
    }
    if (index < 5) {
      index = index + 1;
      this.setState({ valid: false });
    }
    if (index === 4) {
      this.setState({ done: false });
    }
  };

  goPrev = () => {
    if (this.swiper) {
      this.swiper.slidePrev();
    }
    if (index !== 0) {
      index = index - 1;
      this.setState({ done: true });
    }
    if (index === 0) {
      this.setState({ valid: true });
    }
  };

  handelswiper(node) {
    if (node) {
      this.swiper = node.swiper;
    }
  }

  hide = () => {
    this.setState({ show: false }, () => {
      this.props.disableTourSlider();
    });
  };

  show = () => {
    this.setState({ show: true });
  };

  render() {
    const params = {
      pagination: {
        el: ".swiper-pagination",
        clickable: false,
      },
    };

    return (
      <div>
        <Modal
          bsSize="large"
          show={this.state.show}
          dialogClassName={classes.addModal}
        >
          <Modal.Body>
            <div className="mainContent">
              <Swiper {...params} ref={(node) => this.handelswiper(node)}>
                <div className={classes.slide}>
                  <Row>
                    <Col md={4}>
                      <img className={classes.maskicon} src={AppMarketer1} />
                    </Col>
                    <Col md={3}>
                      <img
                        className={classes.secondslidesmallicon}
                        src={smdeepicon}
                      />
                      <p className={classes.sdltext}>
                        <Translate value="Smart Deeplink" />
                      </p>
                    </Col>
                    <Col md={3}>
                      <img
                        className={classes.secondslidesmallicon}
                        src={mdpiconn}
                      />
                      <p className={classes.sdltext}>
                        <Translate value="Mobile Landing Pages" />
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <p className={classes.bulidmobileappsfaster}>
                      <Translate value="Your App is crushing your ROI with Appgain" />
                    </p>

                    <p className={classes.bodycontant}>
                      <Translate value="Reduce Churn, Keep your users connected, Reach your users on every platform, with deep detailed analytics that would help you Improve your ROI" />
                    </p>
                  </Row>
                </div>
                {/* /////////////////////////////////////// */}
                <div className={classes.slide}>
                  <Row>
                    <Col md={3}>
                      <img className={classes.imgico} src={secimglogo} />
                    </Col>
                    <Col md={8}>
                      <img
                        className={classes.secondslidesmallicon}
                        src={smssec}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={sdpsec}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={mdpsec}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={emailsec}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <p className={classes.retain}>
                      {" "}
                      <Translate value="Acquire Mobile App Users" />
                    </p>
                    <p className={classes.secondSlideContant}>
                      <Translate value="Make your app discoverable, acquire users with less cost, increase your mobile app marketing campaign’s CTR and conversion rate" />
                    </p>
                  </Row>
                </div>
                {/* ////////////////////////////////////////////// */}
                <div className={classes.slide}>
                  <Row>
                    <Col md={7}>
                      <img
                        className={classes.secondslidesmallicon}
                        src={Automator}
                      />
                      {/* <img
                        className={classes.secondslidesmallicon}
                        src={parse}
                      /> */}
                      <img
                        className={classes.secondslidesmallicon}
                        src={smartdeeplinkicon}
                      />
                    </Col>
                    <Col md={4}>
                      <img className={classes.maskicon} src={mask} />
                    </Col>
                  </Row>
                  <Row>
                    <p className={classes.retain}>
                      <Translate value="Activate Your App Users" />
                    </p>
                    <p className={classes.secondSlideContant}>
                      <Translate
                        value="Deliver the best user experience with personalized user onboarding, 
                    Shorter signup flow Automate user activation campaign"
                      />
                    </p>
                  </Row>
                </div>
                {/* //////////////////////////////////////////// */}
                <div className={classes.slide}>
                  <Row>
                    <Col md={3}>
                      <img
                        className={classes.secondslidericon}
                        src={secondslidericon}
                      />
                    </Col>
                    <Col md={8}>
                      <img
                        className={classes.secondslidesmallicon}
                        src={apppushicon}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={smartdeeplinkicon}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={webpushicon}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={mdpicon}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <p className={classes.retain}>
                      <Translate value="Retain" />
                    </p>
                    <p className={classes.secondSlideContant}>
                      <Translate
                        value="Keep the user coming back to your app, Deliver personalized content and
offers to them, Reward them for referring the app to their friends,
automatically respond to user events like an abandoned cart"
                      />
                    </p>
                  </Row>
                </div>
                {/* ////////////////////////////////////////////////// */}
                <div className={classes.slide}>
                  <Row>
                    <Col md={2} />
                    <img className={classes.splashicon} src={splashicon} />
                    <Col md={1} />
                  </Row>
                  <Row>
                    <p className={classes.secondSlideContant}>
                      <Translate
                        value="To send push or email messages, you will need to create an
                      app on AppGainio"
                      />
                    </p>
                    <button
                      className={classes.createproject}
                      onClick={() => {
                        this.props.disableTourSlider();
                        this.context.router.push(`/create-new-project`);
                      }}
                    >
                      <Translate value="Create your first project" />
                    </button>
                  </Row>
                </div>
              </Swiper>
              {this.state.done && (
                <a
                  className={classes.skip}
                  onClick={this.state.valid ? this.hide : this.goPrev}
                >
                  <Translate value={this.state.valid ? "Skip" : "Back"} />
                </a>
              )}
              <button
                onClick={this.state.done ? this.goNext : this.hide}
                className={classes.next}
              >
                <Translate value={this.state.done ? "Next" : "Done"} />
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    getUserSuits,
    disableTourSlider,
  }
)(ProjectGuid);
