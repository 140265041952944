import { v4 as uuidv4 } from "uuid";
import timezones from "./timezones";

const timezoneOptions = Object.entries(timezones).map(([timezone, offset]) => ({
  label: `${timezone} (GMT${offset})`,
  value: uuidv4(),
  name: timezone,
  offset,
}));

export default timezoneOptions;
