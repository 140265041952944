import DatePicker from "components/Form/DatePicker/DatePicker";
import $ from "jquery";
import React, { Component } from "react";
import DateTime from "react-datetime";
import { Field } from "redux-form";
import classes from "../../../CreateAppPush.scss";

const defaultDate = new Date();

export default class DateContainer extends Component {
  componentDidMount() {
    $(this.component).hide().slideToggle(500);
  }
  render() {
    return (
      <div
        ref={(component) => (this.component = component)}
        className={`options-${this.props.labelLower} ${classes["datepicker-container"]}`}
      >
        <Field
          validate={this.props.validate}
          after={DateTime.moment().subtract(1, "day")}
          defaultDate={defaultDate}
          name={`optionals.${this.props.labelLower}`}
          component={DatePicker}
        />
      </div>
    );
  }
}
