import PropTypes from "prop-types";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router";

const AppContainer = (props) => {
  const { history, routes, routerKey, store } = props;

  return (
    <Provider store={store}>
      <Router history={history} key={routerKey}>
        {routes}
      </Router>
    </Provider>
  );
};

AppContainer.propTypes = {
  history: PropTypes.object.isRequired,
  routes: PropTypes.object.isRequired,
  routerKey: PropTypes.number,
  store: PropTypes.object.isRequired,
};

export default AppContainer;
