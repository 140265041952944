import { Confirm, DataBlock } from "components";
import React from "react";
import { I18n } from "react-redux-i18n";
import { clearDemoData, fillDemoData } from "reducers/user";
import { connect } from "routes/routedComponent";
import { PrimaryButton, SpinLoader } from "../../../../components";
import { Toast } from "../../../../modules/toast";
import classes from "./../Setting.scss";

class Operations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayDelete: false,
      displayCreate: false,
      displayConfirm: false,
      displayConfirm2: false,
    };
  }

  onModalConfirm = () => {
    this.handleCreateButton();
    this.setState({ displayConfirm: false });
  };

  onModalConfirmClear = () => {
    this.handleDeleteButton();
    this.setState({ displayConfirm2: false });
  };

  cancelConfirm = () => {
    this.setState({ displayConfirm: false });
  };

  cancelConfirm2 = () => {
    this.setState({ displayConfirm2: false });
  };

  handleCreateButton = () => {
    const {
      user: { activeSuitId },
      fillDemoData,
    } = this.props;

    fillDemoData(activeSuitId).then(() => {
      Toast.success(I18n.t("ProjectSettingPage_operations_PopulatedMsg"));
    });

    this.handleCreateDismiss();
  };

  handleCreateDismiss = () => {
    this.setState({ displayCreate: false });
  };

  handleDeleteButton = () => {
    const {
      user: { activeSuitId },
      clearDemoData,
    } = this.props;

    clearDemoData(activeSuitId).then(() => {
      Toast.success(I18n.t("ProjectSettingPage_operations_clearedMsg"));
    });

    this.handleDismiss();
  };

  handleDismiss = () => {
    this.setState({ show: false, displayDelete: false });
  };

  render() {
    const {
      user: { isPending, suit },
    } = this.props;

    return (
      <div>
        <Confirm
          onConfirm={this.onModalConfirm}
          onClose={this.cancelConfirm}
          display={this.state.displayConfirm}
          title="PopulateData"
          description="ProjectSettingPage_operations_populateNote1"
          btnText="PopulateData"
          btnType="primary"
          isPending={isPending}
        />
        <Confirm
          onConfirm={this.onModalConfirmClear}
          onClose={this.cancelConfirm2}
          display={this.state.displayConfirm2}
          title="PopulateData"
          description="ProjectSettingPage_operations_populateNote2"
          btnText="ProjectSettingPage_operations_populate2_btn"
          btnType="critical"
          isPending={isPending}
        />
        {isPending && <SpinLoader />}

        {suit && (
          <div>
            {suit?.type === "mobile_app" && (
              <div className={classes.new}>
                <DataBlock
                  title="ProjectSettingPage_operations_DataBlock_title"
                  titleClass="bg-grey-lightest"
                >
                  <h4
                    style={{
                      color: "#030d18",
                      fontSize: "16px",
                      marginLeft: "27px",
                      marginTop: "-30px",
                    }}
                  >
                    {I18n.t("ProjectSettingPage_operations_DataBlock_title2")}
                  </h4>
                  <p
                    style={{
                      color: "#7c8c9b",
                      fontSize: "16px",
                      marginLeft: "27px",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    {I18n.t("ProjectSettingPage_operations_DataBlock_desc")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <PrimaryButton
                      onClick={() => this.setState({ displayConfirm: true })}
                    >
                      {I18n.t("PopulateData")}
                    </PrimaryButton>
                    <PrimaryButton
                      danger
                      onClick={() => this.setState({ displayConfirm2: true })}
                    >
                      {I18n.t("ProjectSettingPage_operations_populate2_btn")}
                    </PrimaryButton>
                  </div>
                </DataBlock>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    fillDemoData,
    clearDemoData,
  }
)(Operations);
