import { Modal, Spin } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { I18n } from "react-redux-i18n";
import classes from "./confirm.scss";

const Confirm = ({
  isPending = false,
  onClose,
  onConfirm,
  btnType2,
  onConfirm2,
  display,
  description,
  translatedDescription,
  title,
  btnText,
  btnText2,
  btnType,
}) => (
  <Modal
    title={I18n.t(title)}
    visible={display}
    onOk={onConfirm}
    onCancel={onClose}
    okText={I18n.t("Confirm")}
    closable={false}
    centered
    footer={
      <div className={classes.buttonsContainer}>
        <button
          key="cancel"
          className={`${classes.cancelBtn} ${classes.btn}`}
          onClick={onClose}
          disabled={isPending}
        >
          {I18n.t("Cancel")}
        </button>

        {btnText2 && btnType2 && btnType2 === "primary" ? (
          <button
            key="confirm2"
            className={`${classes.normalConfirm} ${classes.btn}`}
            onClick={onConfirm2}
            disabled={isPending}
          >
            {I18n.t(btnText2)}
          </button>
        ) : null}

        {btnType && btnType === "critical" ? (
          <button
            key="confirm"
            className={`${classes.criticalConfirm} ${classes.btn}`}
            onClick={onConfirm}
            disabled={isPending}
          >
            {I18n.t(btnText)}
          </button>
        ) : (
          <button
            key="confirm"
            className={`${classes.normalConfirm} ${classes.btn}`}
            onClick={onConfirm}
            disabled={isPending}
          >
            {I18n.t(btnText)}
          </button>
        )}
      </div>
    }
  >
    <Spin spinning={isPending}>
      {description && (
        <div className={classes.content}>
          <p>{I18n.t(description)}</p>
        </div>
      )}
      {translatedDescription && (
        <div className={classes.content}>
          <p>{translatedDescription}</p>
        </div>
      )}
    </Spin>
  </Modal>
);

export default Confirm;

Confirm.propTypes = {
  isPending: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  btnType2: PropTypes.string,
  onConfirm2: PropTypes.func,
  display: PropTypes.bool,
  description: PropTypes.string,
  translatedDescription: PropTypes.string,
  title: PropTypes.string,
  btnText: PropTypes.string,
  btnText2: PropTypes.string,
  btnType: PropTypes.string,
};
