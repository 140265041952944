import api from "modules/api";
import EP, { config } from "consts/api";
import { userData } from "modules/user";
import _ from "lodash";

export default class Select {
  // static getPlans = type => userType =>
  //   api('get', EP.PLANS.ALL(), false, {
  //     status: 'active',
  //     user_type: userType,
  //     project_type: type,
  //   })
  static getPlans = (type) => (userType) => {
    const user = userData();
    // MODIFICATION HERE TO REPLACE PROJECT_TYPE TO OFFER_VALUES BECAUSE OF APPSUMO
    return api(
      "get",
      EP.PLANS.ALL(),
      false,
      _.pickBy({
        status: "active",
        offers_values: "app boost",
        user_type: userType,
        project_type: /*type === 'mobile_app' ? null :*/ type,
        // offers_values: type === 'mobile_app' ? 'app boost' : null,
      })
    ).set("authToken", user.auth_token);
  };

  static getCreaditCard = (stripeCustomerId) => {
    return api("get", EP.STRIPE.StripeInfo(stripeCustomerId)).set(
      "Authorization",
      config.StripSecretKey
    );
  };
}
