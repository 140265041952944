import PropTypes from "prop-types";
import React, { Component } from "react";

/**
 * OutsideClickHandler component.
 * This component listens for clicks that occur somewhere in the document, outside of the element itself.
 * @extends Component
 */
export default class OutsideClickHandler extends Component {
  /**
   * Add event listener for mousedown event.
   */
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Remove event listener for mousedown event.
   */
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref.
   * @param {Object} node - The node to be set as the wrapper ref.
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  /**
   * Handle the click event.
   * If the click occurs outside of the wrapper ref, call the onOutsideClick prop.
   * @param {Object} event - The event object.
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onOutsideClick();
    }
  };

  /**
   * Render the component.
   * @returns {ReactElement} - HTML markup for the component.
   */
  render() {
    return <div ref={this.setWrapperRef}>{this.props.children}</div>;
  }
}

OutsideClickHandler.propTypes = {
  children: PropTypes.element.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
};
