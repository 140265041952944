import { Checkbox } from "antd";
import PropTypes from "prop-types";
import React from "react";
import Label from "../Label";

// generate a unique id for each checkbox
let id = 0;
const generateId = () => {
  id += 1;
  return id;
};

/**
 * CustomCheckboxReduxForm is a custom checkbox component for use with Redux Form.
 * It uses the Checkbox component from the Ant Design library.
 *
 * @param {Object} props - The properties that define the component.
 * @param {Object} props.input - The input properties provided by Redux Form.
 * @param {Function} props.input.onChange - The function to be called when the checkbox state changes.
 * @param {boolean} props.input.value - The current value of the checkbox.
 * @param {Object} rest - The rest of the properties passed to the component.
 * @returns {JSX.Element} The rendered component.
 */
const CustomCheckboxReduxForm = ({ input: { onChange, value }, ...rest }) => {
  // generate a unique id for each checkbox
  const checkboxId = generateId();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <Checkbox
        id={checkboxId?.toString()}
        checked={value ? true : false}
        onChange={(e) => {
          onChange(e);
          rest?.onChange && rest.onChange(e);
        }}
        {...rest}
      />
      {rest?.label && (
        <Label
          style={{
            cursor: "pointer",
          }}
          id={checkboxId?.toString()}
        >
          {rest.label}
        </Label>
      )}
    </div>
  );
};

CustomCheckboxReduxForm.propTypes = {
  input: PropTypes.object,
};

export default CustomCheckboxReduxForm;
