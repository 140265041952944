import api from "modules/api";
import EP, { config } from "../consts/api";

export default class UserScreen {
  static getAppUsers = (subDomain, skip, limit, count) =>
    api("get", EP.USERSCREEN.APPUSERS(subDomain, skip, limit, count))
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);

  static getMobileUserInfo = (subDomain, userId) =>
    api("get", EP.USERSCREEN.mobileUserInfo(subDomain, userId))
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);

  static getAppUsersByFilter = (subdomain, skip, limit, match) =>
    api("POST", EP.USERSCREEN.getAppUsersByFilter(), {
      subdomain,
      skip,
      limit,
      match,
    }).set("x-parse-application-id", `${config.applicationID}`);

  static createUserProfile = (
    userId,
    subdomain,
    email,
    name,
    phone,
    activeSuitId
  ) => {
    return api(
      "post",
      EP.USERSCREEN.ADDUSERPROFILE(
        userId,
        subdomain,
        email,
        name,
        phone,
        activeSuitId
      )
    )
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);
  };
}
