import { Checkbox, Modal } from "antd";
import {
  Col,
  FormControl,
  FormGroup,
  Grid,
  Label,
  PrimaryButton,
  Row,
  SecondaryButton,
} from "components";
import { deepLinkConfig } from "consts/deeplinkBuilder";
import validConsts from "consts/validations";
import _ from "lodash";
import { getActiveSuit, getSubdomainFromId, getSuitById } from "modules/user";
import React from "react";
import { I18n } from "react-redux-i18n";
import Select from "react-select";
import { getSuitDeepLinks, refershData } from "reducers/deeplink";
import { getAppBackendConfig, getCurrentSuit } from "reducers/user";
import { connect } from "routes/routedComponent";

const validationDeeplinkEmail = validConsts.regex.validationDeeplinkEmail;
let flag = false;
var counter = 0;
let numval;
let messaval;

class DeepLinkBuilder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selected: "select",
      selectedDeepLink: undefined,
      fields: {},
      phone: false,
      message: false,
      noStripeKey: false,
      condition: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  componentDidMount() {
    const {
      getSuitDeepLinks,
      getAppBackendConfig,
      getCurrentSuit,
      user: { activeSuitId, suits },
    } = this.props;
    if (
      _.get(this.props, "user.suit") &&
      _.get(this.props, "user.appbackend") &&
      _.get(this.props, "smartlinks.smartlinks")
    ) {
      return;
    }
    getCurrentSuit(activeSuitId);
    const subdomain = getSubdomainFromId(activeSuitId, suits);
    const { type } = getActiveSuit(activeSuitId, suits);
    if (type && type === "mobile_app") {
      let SuitArr = suits.filter((suit) => suit.id === activeSuitId);
      const appbackend_userId = SuitArr[0].appbackend_userId;

      getAppBackendConfig(subdomain, appbackend_userId).then(() => {
        const {
          user: { appbackend },
        } = this.props;
        if (appbackend) {
          getSuitDeepLinks(
            appbackend.serverURL + "fe",
            appbackend.appId,
            appbackend.masterKey
          );
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.state.condition === false) {
      const { refershData } = this.props;
      refershData();
    }
  }

  handleFieldChange = (val, name, select) => {
    this.setState((prevState) => ({
      fields: { ...prevState.fields, [name]: val },
    }));

    if (counter != 1) {
      flag = false;
    } else {
      if (val == null) {
        this.setState({ noStripeKey: true });
        flag = false;
      }
    }

    switch (select) {
      case "sms": {
        if (name === "phone") {
          numval = !isNaN(val);
        }
        if (name === "message") {
          messaval = val;
        }
        flag = numval && messaval;
        break;
      }
      case "email": {
        if (validationDeeplinkEmail.test(val)) {
          flag = true;
        } else {
          flag = false;
        }
        break;
      }
      case "whatsApp": {
        if (name === "phone" && val) {
          flag = !isNaN(val);
        }
        break;
      }
      case "phone_call": {
        if (name === "dial" && val) {
          flag = !isNaN(val);
        }
        break;
      }
      default: {
        if (val) {
          flag = true;
        }
      }
    }
  };

  handleHide = () => {
    this.setState({ showModal: false });
    flag = false;
  };

  handleSelect = (e) => {
    this.setState({
      selected: e.target.value,
      fields: {},
      phone: false,
      message: false,
      noStripeKey: false,
    });
    numval = false;
    messaval = false;
    flag = false;
  };

  handleShow = () => {
    this.setState({ showModal: true });
    flag = false;
  };

  handleSubmit = () => {
    const { callBack, type } = this.props;
    const { fields, selected } = this.state;
    const { handler, customHandlers } = deepLinkConfig.find(
      (option) => option.name === selected
    );

    callBack(
      (type &&
        customHandlers &&
        customHandlers[type] &&
        customHandlers[type](fields)) ||
        handler(fields)
    );

    this.handleHide();
  };

  stipepayfunc(selected, suit) {
    if (selected === "Stripe Pay") {
      this.handleFieldChange(suit.stripe_key, "stipekey", selected);
      this.handleFieldChange(suit.name, "projectname", selected);
      this.handleFieldChange(suit.id, "suitid", selected);
      this.handleFieldChange(suit.subdomain, "subdomain", selected);
    }
  }

  validate = () => {
    const { fields, selected } = this.state;
    return (
      selected !== "select" &&
      deepLinkConfig
        .find((option) => option.name === selected)
        .fields.every((field) => fields[field.name])
    );
  };

  render() {
    const { showModal, selected, noStripeKey } = this.state;
    const {
      type,
      notIn,
      deeplink: { deeplinks },
      user: { appbackend, activeSuitId },
      nomail,
      noDeeplink,
      onlymdp,
    } = this.props;

    const suit = getSuitById(activeSuitId);
    const { subdomain } = suit;

    if (suit && !suit.stripe_key && !noStripeKey) {
      this.setState({ noStripeKey: true });
    }

    let listdeeplink = [];
    if (deeplinks && appbackend && deeplinks.length !== 0 && deeplinks.body) {
      listdeeplink = deeplinks.body.results;
    } // const valid = false

    if (suit.type === "mobile_marketing") {
      for (let i = 0; i < deepLinkConfig.length; i++) {
        if (
          deepLinkConfig[i].name === "google app store" ||
          deepLinkConfig[i].name === "apple app store"
        ) {
          deepLinkConfig.splice(i, 1);
        }
      }
    }

    return (
      <div>
        <SecondaryButton
          value={I18n.t("Action Selector")}
          onClick={this.handleShow}
        />

        <Modal
          visible={showModal}
          onCancel={this.handleHide}
          footer={null}
          title={I18n.t("Action Selector")}
        >
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label>{I18n.t("Select Action")}</Label>
                  <FormControl
                    componentClass="select"
                    onChange={this.handleSelect}
                  >
                    <option value="select">{I18n.t("select")}</option>
                    {deepLinkConfig.map((option) =>
                      type === "web" && option.mobileOnly ? null : (notIn ===
                          true &&
                          option.smartLink) ||
                        (nomail === true && option.nomail) ||
                        (noDeeplink === true &&
                          option.noDeeplink) ? null : suit.type ===
                          "mobile_marketing" &&
                        option.name === "DeepLinks" ? null : onlymdp &&
                        option.mdponly ? null : (
                        <option key={option.name} value={option.name}>
                          {option.label}
                        </option>
                      )
                    )}
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {selected !== "select" &&
                selected !== "DeepLinks" &&
                selected !== "apple app store" &&
                selected !== "google app store" &&
                deepLinkConfig
                  .find((option) => option.name === selected)
                  .fields.map((field, index) =>
                    selected !== "Lead Form" ? (
                      <Col key={index} xs={12}>
                        <FormGroup
                          onChange={() => {
                            this.stipepayfunc(selected, suit);
                          }}
                        >
                          <Label> {field.label}</Label>
                          {selected === "Stripe Pay" ? (
                            <FormControl
                              min="1"
                              type="number"
                              onChange={(e) => {
                                this.handleFieldChange(
                                  e.target.value,
                                  field.name,
                                  selected
                                );
                              }}
                              placeholder={field.label}
                            />
                          ) : (
                            <FormControl
                              onChange={(e) => {
                                this.handleFieldChange(
                                  e.target.value,
                                  field.name,
                                  selected
                                );
                              }}
                              placeholder={field.label}
                            />
                          )}
                          {selected === "Stripe Pay" && (
                            <div>
                              <br />
                              <strong>Currency</strong>
                              <FormControl
                                componentClass="select"
                                onChange={(e) => {
                                  this.handleFieldChange(
                                    e.target.value,
                                    "currency",
                                    selected
                                  );
                                }}
                              >
                                <option key={1} value={"USD"}>
                                  USD
                                </option>
                                <option key={2} value={"AED"}>
                                  AED
                                </option>
                              </FormControl>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    ) : (
                      <FormGroup
                        key={index}
                        onChange={() => {
                          this.handleFieldChange(
                            activeSuitId,
                            "suitid",
                            selected
                          );
                          this.handleFieldChange(
                            subdomain,
                            "subdomain",
                            selected
                          );
                        }}
                      >
                        {index === 0 && (
                          <Col xs={12}>
                            <Label>Select Lead form Item</Label>
                          </Col>
                        )}
                        {field.name === "name" && (
                          <Col xs={6}>
                            <Checkbox
                              value="name"
                              onChange={(e) => {
                                e.target.checked
                                  ? this.handleFieldChange(
                                      e.target.value,
                                      field.name,
                                      selected
                                    )
                                  : this.handleFieldChange(
                                      false,
                                      field.name,
                                      selected
                                    );
                              }}
                            >
                              Name
                            </Checkbox>
                          </Col>
                        )}
                        {field.name === "email" && (
                          <Col xs={6}>
                            <Checkbox
                              value="email"
                              onChange={(e) => {
                                e.target.checked
                                  ? this.handleFieldChange(
                                      e.target.value,
                                      field.name,
                                      selected
                                    )
                                  : this.handleFieldChange(
                                      false,
                                      field.name,
                                      selected
                                    );
                              }}
                            >
                              Email
                            </Checkbox>
                          </Col>
                        )}
                        {field.name === "address" && (
                          <Col xs={6}>
                            <Checkbox
                              value="address"
                              onChange={(e) => {
                                e.target.checked
                                  ? this.handleFieldChange(
                                      e.target.value,
                                      field.name,
                                      selected
                                    )
                                  : this.handleFieldChange(
                                      false,
                                      field.name,
                                      selected
                                    );
                              }}
                            >
                              Address
                            </Checkbox>
                          </Col>
                        )}
                        {field.name === "age" && (
                          <Col xs={6}>
                            <Checkbox
                              value="age"
                              onChange={(e) => {
                                e.target.checked
                                  ? this.handleFieldChange(
                                      e.target.value,
                                      field.name,
                                      selected
                                    )
                                  : this.handleFieldChange(
                                      false,
                                      field.name,
                                      selected
                                    );
                              }}
                            >
                              Age
                            </Checkbox>
                          </Col>
                        )}
                      </FormGroup>
                    )
                  )}
              {selected !== "select" &&
                selected === "DeepLinks" &&
                selected !== "apple app store" &&
                selected !== "google app store" &&
                deepLinkConfig
                  .find((option) => option.name === selected)
                  .fields.map((field, index) => (
                    <Col key={index} xs={12}>
                      <Select
                        style={{ paddingBottom: "1rem" }}
                        options={listdeeplink.map((link) => ({
                          label: link.name,
                          value: link.URL,
                        }))}
                        value={
                          this.state.selectedDeepLink || {
                            label: "Select DeepLink",
                            value: "NA",
                          }
                        }
                        onChange={(option) => {
                          flag = false;
                          this.setState({
                            condition: true,
                            selectedDeepLink: option,
                          });
                          this.handleFieldChange(
                            option.value,
                            field.name,
                            selected
                          );
                        }}
                      />
                    </Col>
                  ))}
              {selected !== "select" &&
                selected !== "DeepLinks" &&
                selected !== "apple app store" &&
                selected == "google app store" &&
                deepLinkConfig
                  .find((option) => option.name === selected)
                  .fields.map((field, index) => (
                    <Col key={index} xs={12}>
                      <FormGroup>
                        <FormControl
                          componentClass="select"
                          onChange={(e) => {
                            this.handleFieldChange(
                              e.target.value || false,
                              field.name,
                              selected
                            );
                          }}
                        >
                          <option value={false}>{I18n.t("Select")}</option>
                          {suit.google_play_id && (
                            <option value={suit.google_play_id || false}>
                              {suit.google_play_id}
                            </option>
                          )}
                        </FormControl>
                      </FormGroup>
                    </Col>
                  ))}

              {selected !== "select" &&
                selected !== "DeepLinks" &&
                selected !== "google app store" &&
                selected == "apple app store" &&
                deepLinkConfig
                  .find((option) => option.name === selected)
                  .fields.map((field, index) => (
                    <Col key={index} xs={12}>
                      <FormGroup>
                        <FormControl
                          componentClass="select"
                          onChange={(e) => {
                            this.handleFieldChange(
                              e.target.value || false,
                              field.name,
                              selected
                            );
                          }}
                        >
                          <option value={false}>{I18n.t("Select")}</option>
                          {suit.apple_app_id && (
                            <option value={suit.apple_app_id || false}>
                              {suit.apple_app_id}
                            </option>
                          )}
                        </FormControl>
                      </FormGroup>
                    </Col>
                  ))}
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "24px",
                marginTop: "50px",
              }}
            >
              <SecondaryButton
                value={I18n.t("Cancel")}
                onClick={this.handleHide}
              />
              <PrimaryButton
                value={I18n.t("Submit")}
                disabled={!flag}
                onClick={this.handleSubmit}
              />
            </div>
          </Grid>
        </Modal>
      </div>
    );
  }
}
export default connect(
  (store) => ({
    deeplink: store.deeplink,
    user: store.user,
  }),
  {
    getSuitDeepLinks,
    getAppBackendConfig,
    refershData,
    getCurrentSuit,
  }
)(DeepLinkBuilder);
