import { Checkbox, Modal, Tooltip } from "antd";
import { Copyicon } from "components";
import {
  BOOLEAN,
  CURRENCY,
  DATE_TYPE,
  HYPERLINK,
  IMAGE,
  NUMBER,
  OBJECT,
  STRING,
  TEMPLATE_MOBILE,
  URL,
} from "consts/TableColumnTypes";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { MarvelDevices } from "react-css-devices";
import { BiExport, BiImport } from "react-icons/bi";
import { BsGear } from "react-icons/bs";
import { FaMobileScreen } from "react-icons/fa6";
import { FiChevronDown, FiChevronUp, FiMoreVertical } from "react-icons/fi";
import { I18n } from "react-redux-i18n";
import { connect } from "routes/routedComponent.js";
import sortIcon from "static/sortIcon.svg";
import exportCSV from "../../modules/exportCSV";
import { Toast } from "../../modules/toast";
import ActionMenu from "./ActionMenu";
import classes from "./DataTable.scss";
import Menu from "./Menu";

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_open: false,
      selectedFile: null,
      multiMenu: false,
    };
  }

  onActionClick = (idx) => {
    return (actionName, key) => {
      this.props.handleActionClicked(idx, actionName, key);
    };
  };

  onMenuClicked = (e) => {
    e.preventDefault();
    const node = this.columnsMenu;
    if (this.state.menu_open) {
      node.style.maxHeight = "0px";
    } else {
      node.style.maxHeight = "200px";
    }
    setTimeout(() => this.setState({ menu_open: !this.state.menu_open }), 500);
  };

  clearMenu = () => {
    const node = this.columnsMenu;
    node.style.maxHeight = "0px";
    setTimeout(() => this.setState({ menu_open: false }), 500);
  };

  onMultiMenuClicked = () => {
    this.setState({ multiMenu: !this.state.multiMenu });
  };

  clickMoreEventDetails = (content) => {
    const parsedContent = content ? JSON.parse(content) : null;
    if (!parsedContent) {
      return Toast.info(I18n.t("dataTable_noDataMsg"));
    }
    this.setState({ showMoreDetails: true, content: parsedContent });
  };

  clickPreview = (content) => {
    this.setState({ showPreview: true, content });
  };

  exportToCSV = () => {
    const { rows, labels } = this.props.tableData;
    const checkedRows = rows.filter((row) => row.checked);

    const notSelectedLabels = labels
      .filter((label) => !label.selected)
      .map((label) => label.name);

    const dataToExport =
      checkedRows.length > 0
        ? checkedRows.map((row) => _.omit(row, ["checked"]))
        : rows.map((row) => _.omit(row, ["checked"]));

    const rowObjArray = dataToExport.map((row) => {
      const obj = {};
      row.values.forEach((value, idx) => {
        obj[labels[idx].name] = value;
      });
      return _.omit(obj, notSelectedLabels);
    });

    exportCSV(rowObjArray, this.props.csvFileName);
  };

  multiActionClick = (name, segments) => {
    this.setState({ multiMenu: false }, () =>
      this.props.multiActionClick(name, segments)
    );
  };

  route = () => {};

  // render export to csv button
  renderCSV = () => {
    const rowsChecked = this.props.tableData
      ? this.props.tableData.rows.filter((row) => row.checked)
      : [];

    return this.props.isPending ? (
      <div
        key="csv"
        className={`${classes["skeletonized-btn-csv"]} ${classes["skeleton-animation"]}`}
      />
    ) : (
      <div key="csv" className={classes.exportBtnContainer} id="export-btn">
        <button
          className={classes.exportBtn}
          disabled={!this.props.tableData}
          onClick={
            this.props.customExport ? this.props.customExport : this.exportToCSV
          }
        >
          <BiImport className={classes.icon} />
          {rowsChecked.length > 0
            ? `${I18n.t("exportSelected")}`
            : `${I18n.t("exportAll")}`}
        </button>
      </div>
    );
  };

  renderFilterButton = () => {
    return this.props.isPending ? (
      <div
        key="filterBtn"
        className={`${classes["skeletonized-btn-csv"]} ${classes["skeleton-animation"]}`}
      />
    ) : (
      <div key="filterBtn">
        <button
          style={{ position: "relative" }}
          className={classes["outline-button"]}
          onClick={this.props.uploadCSV}
        >
          <i
            className={`fa fa-cloud-upload ${classes["download-icon"]}`}
            aria-hidden="true"
          />
        </button>
      </div>
    );
  };

  renderGear = () => {
    const { menu_open } = this.state;
    return this.props.isPending ? (
      <div
        key="gear"
        className={`${classes["skeletonized-btn-gear"]} ${classes["skeleton-animation"]}`}
      />
    ) : (
      <div key="gear" className={classes.gearBtnContainer} id="gear-btn">
        <button
          className={classes.gearBtn}
          ref={(component) => (this.gearBtn = component)}
          onClick={this.onMenuClicked}
        >
          <BsGear />
          {I18n.t("SubUserPage_tableSetting")}
          {menu_open ? <FiChevronUp /> : <FiChevronDown />}
        </button>
        <Menu
          setRef={(component) => {
            this.columnsMenu = component;
          }}
          gearBtn={this.gearBtn}
          display={this.state.menu_open}
          onColumnToggle={this.props.onColumnToggle}
          onResetColumns={this.props.onResetColumns}
          clearMenu={this.clearMenu}
          labels={this.props.tableData && this.props.tableData.labels}
        />
      </div>
    );
  };

  renderPreview = () => {
    if (this.state.showPreview) {
      return (
        <div>
          <Modal
            visible={this.state.showPreview}
            footer={null}
            onCancel={() => {
              this.setState({ showPreview: false });
            }}
            destroyOnClose
            centered
            className={classes.previewModal}
          >
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                position: "relative",
              }}
            >
              <MarvelDevices
                deviceName={"iphone6plus"}
                color={"white"}
                transform={0.8}
                orientation={"portrait"}
              >
                {/*Paste your content here*/}
                <img src={"http://via.placeholder.com/667x375"} />
              </MarvelDevices>
              <div
                style={{
                  width: "336.8px",
                  height: "594px",
                  backgroundColor: "#fff",
                  position: "absolute",
                  top: "87px",
                  borderRadius: "3px",
                  left: "18px",
                  zIndex: "1",
                }}
              >
                <iframe
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    overflow: "auto",
                  }}
                  srcDoc={this.state.content}
                  title="Preview Email"
                ></iframe>
              </div>
            </div>
          </Modal>
        </div>
      );
    } else if (this.state.showMoreDetails) {
      const dataToRender = this.state.content;
      if (!dataToRender) return Toast.info(I18n.t("dataTable_noDataMsg"));

      if (dataToRender.channel && dataToRender.channel === "email") {
        return (
          <Modal
            visible={this.state.showMoreDetails}
            footer={null}
            onCancel={() => {
              this.setState({ showMoreDetails: false });
            }}
            destroyOnClose
            centered
            className={classes.previewModal}
          >
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                position: "relative",
              }}
            >
              <MarvelDevices
                deviceName={"iphone6plus"}
                color={"white"}
                transform={0.8}
                orientation={"portrait"}
              >
                <img src={"http://via.placeholder.com/667x375"} />
              </MarvelDevices>
              <div
                style={{
                  width: "336.8px",
                  height: "594px",
                  backgroundColor: "#fff",
                  position: "absolute",
                  top: "87px",
                  borderRadius: "3px",
                  left: "18px",
                  zIndex: "1",
                }}
              >
                <iframe
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    overflow: "auto",
                  }}
                  srcDoc={dataToRender.message}
                  title="Preview Email"
                ></iframe>
              </div>
            </div>
          </Modal>
        );
      }
      // handle array data
      else if (Array.isArray(dataToRender)) {
        if (dataToRender.length > 0) {
          return (
            <Modal
              visible={this.state.showMoreDetails}
              footer={null}
              onCancel={() => {
                this.setState({ showMoreDetails: false });
              }}
              destroyOnClose
              centered
            >
              <div className={classes.extraDetails}>
                {dataToRender.map((item, index) => {
                  if (typeof item === "object") {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        {Object.entries(item).map(([key, value], index2) => {
                          return (
                            <div
                              key={index2}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "6px",
                              }}
                            >
                              <p>{key}</p> : <p>{value}</p>
                            </div>
                          );
                        })}
                      </div>
                    );
                  } else {
                    return (
                      <div key={index}>
                        <p>{index + 1} : </p> <p>{item}</p>
                      </div>
                    );
                  }
                })}
              </div>
            </Modal>
          );
        }
      } else {
        // handle object data
        const newArrTable = Object.entries(dataToRender);
        return (
          <Modal
            visible={this.state.showMoreDetails}
            footer={null}
            onCancel={() => {
              this.setState({ showMoreDetails: false });
            }}
            destroyOnClose
            centered
          >
            <div
              style={{
                backgroundColor: "white",
                margin: "4rem 0",
                maxHeight: "700px",
                overflowY: "auto",
              }}
            >
              <div className={classes.extraDetails}>
                {newArrTable.map((row, index) => (
                  <div key={index}>
                    <p>{row[0]} : </p> <p>{row[1]}</p>
                  </div>
                ))}
              </div>
            </div>
          </Modal>
        );
      }
    }
    return null;
  };

  // render upload File Input to the Parent And takes an function as props to set parent state when file changes
  renderUpload = () => {
    return this.props.isPending ? (
      <div
        key="upload"
        className={`${classes["skeletonized-btn-upload"]} ${classes["skeleton-animation"]}`}
      />
    ) : (
      <div key="upload" className={classes.importBtnContainer} id="import-btn">
        <button className={classes.importBtn} onClick={this.props.uploadCSV}>
          <BiExport className={classes.icon} />
          {I18n.t("SubUserPage_Import")}
        </button>
      </div>
    );
  };

  render() {
    const {
      tableData,
      multiRowsActions,
      order,
      filterContainer,
      renderFilterContainer,
      isPending,
      globalChecked,
      onGlobalCheckboxClicked,
      onSortClicked,
      checkDataTableRow,
      toggleRowActionMenu,
      largeActionMenu,
      actions,
      clearActionMenu,
      clickables,
      showCopyTemplate,
      disableSorting,
    } = this.props;

    const { multiMenu } = this.state;

    let selectedLabelsIndexes = null;
    let labelsToRender = null;
    let hasSelectedMultiRows = null;
    let selectedRowsCnt = 0;
    let selectedRows = null;

    if (tableData) {
      selectedLabelsIndexes = tableData.labels
        .map((label, idx) => (label.selected ? idx : null))
        .filter((index) => index !== null);
      // ------------------------------------- //
      labelsToRender =
        tableData.labels &&
        tableData.labels.length > 0 &&
        tableData.labels.filter((label) => label.selected);

      selectedRows = _.get(this.props, ["tableData", "rows"]).filter(
        (data) => data.checked
      );
      selectedRowsCnt = selectedRows.length;
      hasSelectedMultiRows = multiRowsActions && selectedRows.length > 1;
    }

    return (
      <div className={classes.dataTable}>
        {this.renderPreview()}
        <div className={classes.dataTableHeader}>
          {order
            ? order.map((element) => {
                switch (element.type) {
                  case "gear":
                    return this.renderGear();
                  case "csv":
                    return this.renderCSV();
                  case "upload":
                    return this.renderUpload();
                  case "filter":
                    return this.renderFilterButton();
                  default:
                    return element.renderComponent();
                }
              })
            : ["gear", "csv"].map((el) => {
                switch (el) {
                  case "gear":
                    return this.renderGear();
                  case "csv":
                    return this.renderCSV();
                  default:
                    return null;
                }
              })}

          {hasSelectedMultiRows && (
            <div className={classes.multiActionBtnContainer}>
              <button
                className={classes.multiActionBtn}
                onClick={this.onMultiMenuClicked}
              >
                <span className={classes.count}>{selectedRowsCnt}</span>
                Select Action
                <i
                  className={`fa fa-caret-${!multiMenu ? "down" : "up"}`}
                  aria-hidden="true"
                ></i>
              </button>
              {multiMenu && (
                <ActionMenu
                  largeActionMenu={largeActionMenu}
                  clearActionMenu={this.onMultiMenuClicked}
                  display={multiMenu}
                  actions={multiRowsActions}
                  onActionClick={this.multiActionClick}
                />
              )}
            </div>
          )}
        </div>

        {filterContainer ? renderFilterContainer() : null}

        <div className={classes.dataTableContainer} id="table-container">
          <table
            className={`${classes.table} ${
              this.props.stickyHeader && classes.sticky
            }`}
            style={
              this.props.fixed
                ? { tableLayout: "fixed" }
                : { tableLayout: "auto" }
            }
          >
            {isPending ? (
              <thead>
                {[0, 0, 0, 0, 0].map((_, index) => (
                  <tr key={index}>
                    {[0, 0, 0, 0].map((_, index) => (
                      <th
                        key={index}
                        scope="col"
                        className={`${classes["skeletonized-header-label"]} ${classes["skeleton-animation"]}`}
                      />
                    ))}
                  </tr>
                ))}
              </thead>
            ) : (
              <thead className={classes.tableHead}>
                <tr>
                  {onGlobalCheckboxClicked && (
                    <th style={{ width: "50px" }}>
                      <Checkbox
                        className={classes.dataTableCheckbox}
                        checked={globalChecked}
                        onClick={onGlobalCheckboxClicked}
                      />
                    </th>
                  )}

                  <th style={{ width: "50px" }}>No</th>
                  {labelsToRender &&
                    labelsToRender.map((_, idx) => (
                      <th key={idx} scope="col">
                        <div className={classes.tableHeadCell}>
                          {labelsToRender[idx].name}
                          {labelsToRender[idx].has_sort && !disableSorting && (
                            <img
                              className={classes["rotate-icon-90"]}
                              src={sortIcon}
                              alt="sort"
                              onClick={() =>
                                onSortClicked(
                                  labelsToRender[idx].name,
                                  labelsToRender[idx].type,
                                  labelsToRender[idx].varName
                                )
                              }
                            />
                          )}
                          {labelsToRender[idx].hasTooltip && (
                            <Tooltip
                              placement="right"
                              title={labelsToRender[idx].tooltipBehind}
                            >
                              <i
                                className={`fa fa-question ${classes["more-info-icon"]}`}
                                aria-hidden="true"
                              />
                            </Tooltip>
                          )}
                        </div>
                      </th>
                    ))}
                  {labelsToRender &&
                    labelsToRender.length > 0 &&
                    tableData &&
                    tableData.actions && (
                      <th>
                        <FiMoreVertical />
                      </th>
                    )}
                </tr>
              </thead>
            )}
            {!isPending && (
              <tbody className={classes.tableBody}>
                {tableData?.rows?.map((row, rowIdx) => {
                  const dataColsToRender =
                    tableData.rows[rowIdx] &&
                    tableData.rows[rowIdx].values.filter(
                      (_, idx) =>
                        selectedLabelsIndexes &&
                        selectedLabelsIndexes.includes(idx)
                    );
                  return (
                    <tr key={rowIdx}>
                      {checkDataTableRow && (
                        <td>
                          <Checkbox
                            className={classes.dataTableCheckbox}
                            checked={tableData.rows[rowIdx].checked}
                            onClick={() => checkDataTableRow(rowIdx)}
                          />
                        </td>
                      )}

                      <td>{rowIdx + 1}</td>

                      {dataColsToRender?.map((col, index) => {
                        const item = col;
                        const nextItem = dataColsToRender[index + 1];
                        const currentLabel = labelsToRender[index];
                        const labelType = currentLabel.type;
                        const labelName = currentLabel.name;
                        const labelVarName = currentLabel.varName;

                        const isClickable = Object.keys(
                          clickables || []
                        ).includes(labelVarName);

                        const handleClick =
                          clickables?.[labelVarName] || function () {};

                        return (
                          <td key={index} data-label={labelName}>
                            {labelType === OBJECT && (
                              <i
                                className={`${classes["mobile-preview"]} fa fa-external-link`}
                                onClick={() => this.clickMoreEventDetails(item)}
                                aria-hidden="true"
                              />
                            )}

                            {/* image type */}
                            {labelType === IMAGE && (
                              <img
                                className={`${classes.imageTableCell}`}
                                src={item}
                                alt={nextItem}
                                width={46}
                                height={46}
                              />
                            )}

                            {/* template mobile type */}
                            {labelType === TEMPLATE_MOBILE && (
                              <Tooltip title={I18n.t("preview")}>
                                <FaMobileScreen
                                  onClick={() => this.clickPreview(item)}
                                  className={classes["mobile-preview"]}
                                />
                              </Tooltip>
                            )}
                            {showCopyTemplate &&
                              labelType === TEMPLATE_MOBILE && (
                                <Copyicon value={item} />
                              )}

                            {/* boolean type */}
                            {labelType === BOOLEAN && (
                              <div
                                className={`${classes.tableCell} ${
                                  isClickable ? classes.clickable : ""
                                }`}
                                onClick={
                                  isClickable ? () => handleClick(row) : null
                                }
                              >
                                {typeof item === "boolean" &&
                                  item.toString().toUpperCase()}
                                {typeof item === "string" && item}
                              </div>
                            )}

                            {(labelType === STRING ||
                              labelType === NUMBER ||
                              labelType === URL ||
                              labelType === DATE_TYPE ||
                              labelType === CURRENCY ||
                              labelType === HYPERLINK) && (
                              <div
                                className={`${classes.tableCell} ${
                                  isClickable ? classes.clickable : ""
                                }`}
                                onClick={
                                  isClickable ? () => handleClick(row) : null
                                }
                              >
                                {item}
                                {labelType === URL && item && (
                                  <Copyicon value={item} />
                                )}
                              </div>
                            )}
                          </td>
                        );
                      })}
                      {labelsToRender &&
                        labelsToRender.length > 0 &&
                        tableData &&
                        tableData.actions && (
                          <td
                            style={{
                              maxWidth: "10px",
                              textAlign: "center",
                              verticalAlign: "center",
                              position: "relative",
                            }}
                            scope="col"
                          >
                            <FiMoreVertical
                              onClick={() => {
                                if (!tableData.rows[rowIdx].actionsMenu) {
                                  toggleRowActionMenu(rowIdx);
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            />
                            {tableData.rows[rowIdx].actionsMenu && (
                              <ActionMenu
                                largeActionMenu={largeActionMenu}
                                onActionClick={this.onActionClick(rowIdx)}
                                actions={actions}
                                clearActionMenu={() => clearActionMenu(rowIdx)}
                              />
                            )}
                          </td>
                        )}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  }
}

export default connect(undefined, {})(DataTable);

DataTable.propTypes = {
  /** tableData: object that contains the data to be displayed in the table */
  tableData: PropTypes.object,
  /** order: array of objects that contains the order of the buttons in the table header */
  order: PropTypes.array,
  /** filterContainer: boolean that indicates whether to render the filter container or not */
  filterContainer: PropTypes.bool,
  /** renderFilterContainer: function that renders the filter container */
  renderFilterContainer: PropTypes.func,
  /** isPending: boolean that indicates whether the table is pending or not */
  isPending: PropTypes.bool,
  /** globalChecked: boolean that indicates whether the global checkbox is checked or not */
  globalChecked: PropTypes.bool,
  /** onGlobalCheckboxClicked: function that handles the global checkbox click */
  onGlobalCheckboxClicked: PropTypes.func,
  /** onSortClicked: function that handles the sort click */
  onSortClicked: PropTypes.func,
  /** checkDataTableRow: function that handles the row checkbox click */
  checkDataTableRow: PropTypes.func,
  /** handleItemClick: function that handles the item click */
  handleItemClick: PropTypes.func,
  /** clickable: boolean that indicates whether the table item is clickable or not */
  clickable: PropTypes.bool,
  /** toggleRowActionMenu: function that handles the row action menu toggle */
  toggleRowActionMenu: PropTypes.func,
  /** largeActionMenu: boolean that indicates whether the action menu is large or not */
  largeActionMenu: PropTypes.bool,
  /** actions: array of objects that contains the actions to be displayed in the action menu */
  actions: PropTypes.array,
  /** clearActionMenu: function that handles the action menu clear */
  clearActionMenu: PropTypes.func,
  /** multiRowsActions: array of objects that contains the actions to be displayed in the multi action menu */
  multiRowsActions: PropTypes.array,
  /** multiActionClick: function that handles the multi action menu click */
  multiActionClick: PropTypes.func,
  /** csvFileName: string that contains the name of the csv file to be exported */
  csvFileName: PropTypes.string,
  /** customExport: function that handles the custom export */
  customExport: PropTypes.func,
  /** uploadCSV: function that handles the csv upload */
  uploadCSV: PropTypes.func,
  /** clickableID: boolean that indicates whether the ID is clickable or not */
  clickableID: PropTypes.bool,
  /** clickableName: boolean that indicates whether the name is clickable or not */
  clickableName: PropTypes.bool,
  /** clickableCampaignID: boolean that indicates whether the campaign ID is clickable or not */
  clickableCampaignID: PropTypes.bool,
  /** onColumnToggle: function that handles the column toggle */
  onColumnToggle: PropTypes.func,
  /** onResetColumns: function that handles the columns reset */
  onResetColumns: PropTypes.func,
  /**
   * clickables: object that contains the clickable columns and their handlers
   * example: { column1: handler1, column2: handler2, ...}
   * column1 and column2 are the columns indexes that are clickable
   * handler1 and handler2 are the handlers that handle the click on the columns they recieve the row data as a parameter
   */
  clickables: PropTypes.object,
  stickyHeader: PropTypes.bool,
};
