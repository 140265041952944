import api from "modules/api";
import EP from "consts/api";

export default class AppPush {
  static sendAppPush = (serverUrl, apiKey, data) =>
    api("post", EP.APP_PUSH.SEND_APP_PUSH(serverUrl), data).set({
      appApiKey: apiKey,
    });
  static sendAppPushScheduler = (apiKey, data) =>
    api("post", EP.APP_PUSH.SEND_APP_PUSH_SCHEDULER(), data).set({
      appApiKey: apiKey,
    });
}
