import { message } from "antd";

export const Toast = {
  error: (messageText) => {
    message.error(messageText, 5);
  },
  success: (messageText) => {
    message.success(messageText, 5);
  },
  warning: (messageText) => {
    message.warning(messageText, 5);
  },
  info: (messageText) => {
    message.info(messageText, 5);
  },
};
