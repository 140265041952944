import React from "react";
import { FormGroup } from "react-bootstrap";
import DateTime from "react-datetime";

export default function TextArea({ input, meta, ...props }) {
  let additional = {};

  additional.defaultValue = props.defaultDate;

  if (props.after) {
    additional.isValidDate = (currentDate) => {
      return currentDate.isAfter(props.after);
    };
  }

  return (
    <FormGroup>
      <DateTime
        {...additional}
        onChange={(val) => input.onChange({ ...input.value, value: val })}
      />
      <div>
        {meta.touched && meta.error && (
          <span style={{ fontSize: "13px" }} className="text-danger">
            {meta.error}
          </span>
        )}
      </div>
    </FormGroup>
  );
}
