import { Colors } from "consts";
import timezoneOptions from "consts/timezoneOptions";
import lodash from "lodash";
import { I18n } from "react-redux-i18n";
import { locale } from "./i18n";
import { Toast } from "./toast";

export function isMacintosh() {
  return navigator.platform.indexOf("Mac") > -1;
}

export function statusToColor(status) {
  switch (status) {
    case "Online":
      return Colors.brandSuccess;
    case "Busy":
      return Colors.brandDanger;
    case "Away":
      return Colors.brandWarning;
    default:
    case "Offline":
      return Colors.grayLighter;
  }
}

export const numToString = (num) =>
  num +
  (num % 10 > 3 ? "th" : num % 10 === 3 ? "rd" : num % 10 === 2 ? "nd" : "st");

export const formatNumber = (num) => {
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

/**
 * This function is used to get the default timezone from the suit of the user.

  The function will return the timezone in the format of object as below:
  {
    name: "Asia/Kuala_Lumpur",
    offset: "+08:00"
    value: "something",
    label: "Asia/Kuala_Lumpur (GMT+08:00)"
  }

  The function will return null if the timezone is not found.

  params:
    - suit: object
  return:
    - timezone: object
 */
export const getDefaultTimezoneFromSuit = (suit) => {
  if (!suit) return null;

  const suitDefaultTimezone = suit.defaultTimeZone;
  const suitDefaultTimezoneOption = timezoneOptions.find(
    (timezone) =>
      timezone.offset === suitDefaultTimezone?.offset &&
      timezone.name === suitDefaultTimezone?.timezone
  );

  return suitDefaultTimezoneOption || null;
};

export const removeNullAndEmpty = (obj) => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === "") {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      if (lodash.isEmpty(obj[key])) {
        delete obj[key];
      }

      removeNullAndEmpty(obj[key]);
    }
  }
};

export const formatPrice = (price) => {
  return price.toFixed(2);
};

export const generateUniqueInvoiceNumber = () => {
  const timestamp = Math.floor(Date.now() / 1000); // Seconds-based timestamp
  const randomSuffix = Math.floor(Math.random() * 1000)
    .toString()
    .padStart(3, "0"); // 3-digit suffix
  return timestamp + randomSuffix;
};

/**
 * Converts a file to base64 encoding.
 *
 * @param {File} file - The file to be converted.
 * @return {Promise<string>} A promise that resolves to the base64 encoded string.
 */
export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

/**
 * Adds a suffix to a number based on the locale or English rules.
 * @param {number} number - The number to add a suffix to.
 * @returns {string} The number with the appropriate suffix added.
 */
export const addNumberSuffix = (number) => {
  const isArabic = locale() === "ar";

  if (isArabic) {
    const arabicSuffixes = [
      "",
      "الأول",
      "الثاني",
      "الثالث",
      "الرابع",
      "الخامس",
      "السادس",
      "السابع",
      "الثامن",
      "التاسع",
      "العاشر",
    ];
    const lastDigit = number % 10;
    return arabicSuffixes[lastDigit];
  }

  if (number % 10 === 1 && number % 100 !== 11) {
    return number + "st";
  } else if (number % 10 === 2 && number % 100 !== 12) {
    return number + "nd";
  } else if (number % 10 === 3 && number % 100 !== 13) {
    return number + "rd";
  } else {
    return number + "th";
  }
};

export const checkSMSBalance = (suit) => {
  if (suit?.sms_count > 0) {
    return true;
  }

  Toast.error(I18n.t("smsBalanceInsufficientError"));
  return false;
};

export const checkEmailBalance = (suit) => {
  if (suit?.email_count > 0) {
    return true;
  }

  Toast.error(I18n.t("emailBalanceInsufficientError"));
  return false;
};
