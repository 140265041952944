import { config } from "consts/api";
import _lodash from "lodash";

/**
 * @description creates push initial config
 *
 * @return object
 */
export const createInitConfig = (type, appId, apiKey) => ({
  title: `sending ${type}`,
  url: `${config.NOTIFY_HOST}/${appId}/send`,
  action: {
    [type]: {
      message: "",
    },
    campaign_name: "",
  },
  authenticatin_header: {
    name: "appapikey",
    value: apiKey,
  },
});

/**
 * @description handle change of config
 *
 * @return object
 */

export const handleLanguageLocale = (value, curConfig) => {
  const newConfig = _lodash.cloneDeep(curConfig);
  _lodash.set(newConfig, value);
  return _lodash.omitBy(newConfig, _lodash.isUndefined);
};

export const handleChangeConfig = (type, value, curconfig, initialized) => {
  const config = _lodash.cloneDeep(curconfig);

  switch (type) {
    case "action":
      _lodash.set(config, type, value);
      if (value && value.appPush && !initialized) {
        // loop through value.apppush if key contains alert-ar
        _lodash.forEach(value.appPush, (val, key) => {
          if (key.includes("-")) {
            // set value to value.apppush[key]
            // set after char - to new value
            let code = key.split("-");
            if (code[0] === "alert") {
              _lodash.assign(config.action.appPush, {
                [`message-${code[1]}`]: value.appPush[`alert-${code[1]}`],
              });
            } else if (code[0] === "title") {
              _lodash.assign(config.action.appPush, {
                [`title-${code[1]}`]: value.appPush[`title-${code[1]}`],
              });
            }
          }
        });
      }
      if (value && value.appPush && value.appPush.title && !initialized) {
        _lodash.assign(config.action.appPush, {
          "title-en": value.appPush.title,
        });
      }
      if (value && value.appPush && value.appPush.message && !initialized) {
        _lodash.assign(config.action.appPush, {
          "message-en": value.appPush.message,
        });
      }
      break;
    case "optionals":
      _lodash.assign(config, value);
      break;
    default:
      _lodash.set(config, type, value);
      break;
  }

  return config;
};

/**
 * @description guard wizard step changes
 *
 * @return object
 */
export const shouldWizardChange = (
  newStep,
  curStep,
  sequence,
  curConfig,
  form
) => {
  if (form) {
    if (form.pushMessageStep && curStep === "message") {
      if (sequence.indexOf(newStep) === 2) {
        return false;
      } else if (form.pushMessageStep.syncErrors) {
        return false;
      } else {
        return true;
      }
    }

    if (form.pushContentStep && curStep === "content") {
      if (sequence.indexOf(curStep) > sequence.indexOf(newStep)) {
        return true;
      }
      if (form.pushContentStep.syncErrors) {
        return false;
      } else {
        return true;
      }
    }

    if (form.pushPublishStep && curStep === "publish") {
      if (
        newStep !== undefined &&
        sequence.indexOf(curStep) > sequence.indexOf(newStep)
      ) {
        return true;
      } else if (form.pushPublishStep.syncErrors) {
        return false;
      } else {
        return true;
      }
    }

    if (form.pushOptionsStep && curStep === "options") {
      if (sequence.indexOf(curStep) > sequence.indexOf(newStep)) {
        return true;
      }
      const optionals = form.pushOptionsStep.values.optionals;
      const keys = ["title", "custom_sound", "ios_category", "badge", "url"];
      for (let i = 0; i < keys.length; ++i) {
        if (
          optionals &&
          optionals[keys[i]] &&
          optionals[keys[i]].selected &&
          form.pushOptionsStep.syncErrors &&
          form.pushOptionsStep.syncErrors.temp &&
          form.pushOptionsStep.syncErrors.temp[keys[i]]
        ) {
          return false;
        }
      }
      if (
        optionals &&
        optionals.expire_date &&
        optionals.expire_date.selected &&
        !optionals.expire_date.value
      ) {
        return false;
      }
      return true;
    }

    if (form.pushSendStep) {
      if (sequence.indexOf(curStep) > sequence.indexOf(newStep)) {
        return true;
      } else if (form.pushSendStep.syncErrors) {
        return false;
      } else {
        return true;
      }
    }
  }
};

/**
 * @description guard wizard step changes
 *
 * @return object
 */
export const formatMessage = (
  { message, template_name: templateName, url },
  templates
) => {
  const handledTemplate = templateName
    ? templates
        .find((template) => template.template_name === templateName)
        .content.replace(/\$ message/g, message)
    : message;

  const handledUrl = url ? `${handledTemplate} ${url}` : handledTemplate;

  return handledUrl;
};
