import Reports from "api/Reports";
import { RESET_ERRORS } from "reducers/user";

export const GET_REPORTS = "GET_REPORTS";

// Action Creator
export function getReports(suitId, apiKey, subdomain, start, end) {
  return {
    type: GET_REPORTS,
    payload: Reports.getReports(suitId, apiKey, subdomain, start, end),
  };
}

const initialState = {
  result: {},
  isPending: true,
  error: false,
};

// Reducer
export default function ReportsReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_REPORTS}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_REPORTS}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: true,
        result: null,
      };
    case `${GET_REPORTS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        result: action.payload.body.result,
        error: false,
      };
    default:
      return state;
  }
}
