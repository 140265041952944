import TextBox from "components/Form/TextBox/TextBox";
import $ from "jquery";
import React, { Component } from "react";
import { Field } from "redux-form";
import classes from "../../../CreateAppPush.scss";

export default class TextBoxContainer extends Component {
  componentDidMount() {
    $(this.component).hide().slideToggle(500);
  }
  render() {
    return (
      <div
        ref={(component) => (this.component = component)}
        className={`options-${this.props.labelLower} ${classes["options-textbox-container"]}`}
      >
        <Field
          onChange={this.props.onChange}
          name={this.props.name}
          rightBottomHint={this.props.rightBottomHint}
          placeholder={this.props.placeholder}
          validate={this.props.validate}
          component={TextBox}
        />
      </div>
    );
  }
}
