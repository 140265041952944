import React from "react";

const HW_CONFIG_ACCOUNT = "7vYVkx";
const ELEMENT_ID = "headway-updates-widget";

function HeadwayWidget() {
  if (!HW_CONFIG_ACCOUNT) {
    return null;
  }

  const initWidget = (element) => {
    if (element) {
      window.Headway.init({
        selector: `#${ELEMENT_ID}`,
        account: HW_CONFIG_ACCOUNT,
      });
    }
  };

  return (
    <div style={{ display: "flex" }} id={ELEMENT_ID} ref={initWidget}>
      What's New
    </div>
  );
}

export default HeadwayWidget;
