import api from "modules/api";
import EP from "consts/api";

export default class MobileAttributes {
  static getFilters = (suitId, apiKey) =>
    api("get", EP.MOBILE_ATTRIBUTES.listSmartLinks(suitId)).set({
      appApiKey: apiKey,
    });
  static getCardsData = (suitId, apiKey, start, end, filter, value) => {
    return api(
      "get",
      EP.MOBILE_ATTRIBUTES.getCardsData(suitId, start, end, filter, value)
    ).set({
      appApiKey: apiKey,
    });
  };
  static getChartData = (suitId, apiKey, start, end, filter, value) =>
    api(
      "get",
      EP.MOBILE_ATTRIBUTES.getChartData(suitId, start, end, filter, value)
    ).set({
      appApiKey: apiKey,
    });
  static getTableData = (suitId, apiKey, start, end, filter, value) =>
    api(
      "get",
      EP.MOBILE_ATTRIBUTES.getTableData(suitId, start, end, filter, value)
    ).set({
      appApiKey: apiKey,
    });
}
