import { TextBox } from "components";
import $ from "jquery";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { I18n } from "react-redux-i18n";
import Select from "react-select";
import { Field } from "redux-form";
import classes from "../../../CreateAppPush.scss";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuList: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

export default class URLContainer extends Component {
  componentDidMount() {
    $(this.component).hide().slideToggle(500);
  }
  render() {
    const {
      onChangeCategory,
      onChangeURLValue,
      onChangeURLField,
      urlType,
      urlOptions,
      validate,
      urlValue,
      smartlinks,
      microPages,
    } = this.props;
    return (
      <div
        ref={(component) => (this.component = component)}
        className={`options-${this.props.labelLower} ${classes["options-textbox-container"]}`}
      >
        <Row>
          <Col xs={5}>
            <div>
              <Select
                styles={customStyles}
                className={classes["select-style"]}
                value={urlType}
                onChange={onChangeCategory}
                options={urlOptions}
              />
            </div>
          </Col>
          <Col xs={7}>
            <div>
              {urlType.value === "url" ? (
                <Field
                  name={`action.appPush.call2action`}
                  className={classes["optional-image-url-input"]}
                  placeholder={I18n.t("Enter your url")}
                  validate={validate}
                  component={TextBox}
                  onChange={onChangeURLField}
                />
              ) : urlType.value === "deepLink" ? (
                <Field
                  name={`action.appPush.call2action`}
                  placeholder={I18n.t("select from action selector")}
                  validate={validate}
                  component={TextBox}
                  onChange={onChangeURLField}
                  deepLinkBuilder
                  FiledNotexist
                  onlymdp
                />
              ) : urlType.value === "smartLink" ? (
                <Select
                  styles={customStyles}
                  className={classes["select-style"]}
                  value={urlValue}
                  onChange={onChangeURLValue}
                  options={smartlinks.map((link) => ({
                    label: link.name,
                    value: link.url,
                  }))}
                />
              ) : urlType.value === "microPage" ? (
                <Select
                  styles={customStyles}
                  className={classes["select-style"]}
                  value={urlValue}
                  onChange={onChangeURLValue}
                  options={microPages.map((link) => ({
                    label: link.name,
                    value: link.url,
                  }))}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
