import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import "./ActionMenu.scss";
import classes from "./ActionMenu.scss";

class ActionMenu extends Component {
  static propTypes = {
    actions: PropTypes.array.isRequired,
    largeActionMenu: PropTypes.bool,
    clearActionMenu: PropTypes.func.isRequired,
    onActionClick: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const node = this.node;
    node.style.height = "0px";
    setTimeout(() => (node.style.height = node.scrollHeight + "px"), 0);
    document.addEventListener("click", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  handleClick = (e) => {
    const node = this.node;
    if (!node.contains(e.target)) {
      node.style.height = "0px";
      setTimeout(() => this.props.clearActionMenu(), 500);
    }
  };

  render() {
    const { actions, largeActionMenu } = this.props;
    const actionMenuClasses = classNames("action-menu-data-table", {
      [`${classes["div-filter-category"]}`]: true,
      [`${classes["div-filter-category-large"]}`]: largeActionMenu,
    });

    return (
      <div
        ref={(node) => (this.node = node)}
        className={actionMenuClasses}
        style={{ transition: "height 0.5s" }}
      >
        {actions.map((action, index) => (
          <p
            key={index}
            style={{ textTransform: "capitalize" }}
            className={classNames({
              [`${classes["hover-danger"]}`]:
                action.name.toLowerCase() === "delete",
            })}
            onClick={() => {
              this.props.onActionClick(action.name, action.key);
            }}
          >
            {action.name}
          </p>
        ))}
      </div>
    );
  }
}

export default ActionMenu;
