import React from "react";
import classes from "./FileUploadInput.scss";

const adaptFileEventToValue = (delegate) => (e) => {
  delegate(e.target.files[0]);
};

const FileUploadInput = ({
  input: { value: omitValue, onChange, onBlur, meta, ...inputProps },
  iconClass,
  hint,
  label,
  infoText,
  style,
  shadow,
  ...props
}) => {
  return (
    <div style={style || {}}>
      {label && <label className={classes.space}>{label}</label>}
      <div className={classes.wrapper}>
        <div>
          <input
            style={{
              boxShadow: shadow,
            }}
            readOnly
            type="text"
            className={classes.readOnly}
            value={omitValue.name ? omitValue.name : props.placeholder}
          />
          <button type="button" className={classes.button}>
            <i className={iconClass} />
          </button>
          <input
            onChange={adaptFileEventToValue(onChange)}
            onBlur={adaptFileEventToValue(onBlur)}
            type="file"
            className={classes.file}
            {...inputProps}
            {...props}
          />
          {infoText && (
            <div className="tooltip" data-placement="left" title={infoText}>
              <i className="fa fa-info help-icon" />
            </div>
          )}
        </div>
        {hint && <p>{hint}</p>}
      </div>
    </div>
  );
};
export default FileUploadInput;
