import SmartLinks from "api/SmartLinks";
import {
  CURRENCY,
  DATE_TYPE,
  NUMBER,
  OBJECT,
  STRING,
  URL,
} from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";
import { RESET_ERRORS } from "reducers/user";

// ------------------------------------
// Constants
// ------------------------------------
const GET_SUIT_SMARTLINKS = "GET_SUIT_SMARTLINKS";
const GET_SUIT_SMARTLINKS_DATA_TABLE = "GET_SUIT_SMARTLINKS_DATA_TABLE";
const GET_CURRENT_SMARTLINK = "GET_CURRENT_SMARTLINK";
const GET_SMARTLINK_CHART_DATA = "GET_SMARTLINK_CHART_DATA";
const GET_SMARTLINK_HITS_LOGS = "GET_SMARTLINK_HITS_LOGS";
const GET_SMARTLINK_ANALYTICS = "GET_SMARTLINK_ANALYTICS";
const REMOVE_CURRENT_SMARTLINK = "REMOVE_CURRENT_SMARTLINK";
const CREATE_SMARTLINK = "CREATE_SMARTLINK";
const UPDATE_SMARTLINK = "UPDATE_SMARTLINK";
const DELETE_SMARTLINK = "DELETE_SMARTLINK";
const GET_SUIT_SMARTLINKS_STATISTICS = "GET_SUIT_SMARTLINKS_STATISTICS";
// /////////////////
const SMART_DEEP_LINK_TOGGLE_ROW_ACTION_MENU =
  "SMART_DEEP_LINK_TOGGLE_ROW_ACTION_MENU";
const CUSTOMER_SMART_DEEP_LINK_CHECK_DATATABLE_ROW =
  "CUSTOMER_SMART_DEEP_LINK_CHECK_DATATABLE_ROW";
const CUSTOMER_SMART_DEEP_LINK_COLUMN_TOGGLE =
  "CUSTOMER_SMART_DEEP_LINK_COLUMN_TOGGLE";
const CUSTOMER_SMART_DEEP_LINK_RESET_COLUMNS =
  "CUSTOMER_SMART_DEEP_LINK_RESET_COLUMNS";
const SORT_CUSTOMER_SMART_DEEP_LINK = "SORT_CUSTOMER_SMART_DEEP_LINK";
const CUSTOMER_SMART_DEEP_LINK_CHECK_ALL_TABLE_ROWS =
  "CUSTOMER_SMART_DEEP_LINK_CHECK_ALL_TABLE_ROWS";
const CUSTOMER_SMART_DEEP_LINK_CLEAR_ACTION_MENU =
  "CUSTOMER_SMART_DEEP_LINK_CLEAR_ACTION_MENU";
const UPLOAD_BULK_SMARTLINKS = "UPLOAD_BULK_SMARTLINKS";
const DELETE_SMARTLINK_BULK = "DELETE_SMARTLINK_BULK";
const SET_SMART_DEEP_LINKS_META_DATA = "SET_SMART_DEEP_LINKS_META_DATA";

// ------------------------------------
// Actions
// ------------------------------------

export function setSmartDeepLinksMetaData(data) {
  return {
    type: SET_SMART_DEEP_LINKS_META_DATA,
    payload: data,
  };
}

export function getSuitSmartlinks(suitId, apiKey, data, page) {
  return {
    type: GET_SUIT_SMARTLINKS,
    payload: SmartLinks.getSuitSmartLinks(suitId, apiKey, data, page),
  };
}
export function uploadCsvSDL(suitId, data, email) {
  return {
    type: UPLOAD_BULK_SMARTLINKS,
    payload: SmartLinks.uploadBulkSDL(suitId, data, email),
  };
}

export function getSuitSmartDeepLinks(suitId, apiKey, data, page) {
  return {
    type: GET_SUIT_SMARTLINKS_DATA_TABLE,
    payload: SmartLinks.getSuitSmartLinks(suitId, apiKey, data, page),
  };
}

// search
export function getSuitSmartLinksBySearch(suitId, nameVal) {
  return {
    type: GET_SUIT_SMARTLINKS,
    payload: SmartLinks.getSuitSmartLinksBySearch(suitId, nameVal),
  };
}

export function getCurrentSmartlink(suitId, smartLinkId) {
  return {
    type: GET_CURRENT_SMARTLINK,
    payload: SmartLinks.getCurrentSmartLink(suitId, smartLinkId),
  };
}

export function getSmartLinkChartData(suitId, smartLinkId, filter = "") {
  return {
    type: GET_SMARTLINK_CHART_DATA,
    payload: SmartLinks.getSmartLinkChartData(suitId, smartLinkId, filter),
  };
}

export function getSmartLinkHitsLogs(suitId, smartLinkId) {
  return {
    type: GET_SMARTLINK_HITS_LOGS,
    payload: SmartLinks.getSmartLinkHitsLogs(suitId, smartLinkId),
  };
}

export function getSmartLinkAnalytics(suitId, smartLinkId, data) {
  return {
    type: GET_SMARTLINK_ANALYTICS,
    payload: SmartLinks.getSmartLinkAnalytics(suitId, smartLinkId, data),
  };
}

export function removeCurrentSmartlink() {
  return {
    type: REMOVE_CURRENT_SMARTLINK,
  };
}

export function createSmartlink(suitId, data) {
  return {
    type: CREATE_SMARTLINK,
    payload: SmartLinks.createSmartLink(suitId, data),
  };
}

export function updateSmartlink(suitId, smartLinkId, data) {
  return {
    type: UPDATE_SMARTLINK,
    payload: SmartLinks.updateSmartLink(suitId, smartLinkId, data),
  };
}

export function deleteSmartLink(appId, smartLinkId) {
  return {
    type: DELETE_SMARTLINK,
    payload: SmartLinks.deleteSmartLink(appId, smartLinkId),
  };
}

// "smartlinks_list": ["test1", "test2", "test3"]
export function deleteSmartLinkBulk(appId, smartLinksIds) {
  return {
    type: DELETE_SMARTLINK_BULK,
    payload: SmartLinks.deleteSmartLinkBulk(appId, smartLinksIds),
  };
}

export function getSuitSmartLinksStatistics(suitId, data) {
  return {
    type: GET_SUIT_SMARTLINKS_STATISTICS,
    payload: SmartLinks.getSuitSmartLinksStatistics(suitId, data),
  };
}

// /////////////////

export function toggleRowActionMenu(index) {
  return {
    type: SMART_DEEP_LINK_TOGGLE_ROW_ACTION_MENU,
    payload: index,
  };
}

export function checkDataTableRow(idx) {
  return {
    type: CUSTOMER_SMART_DEEP_LINK_CHECK_DATATABLE_ROW,
    payload: idx,
  };
}

export function onColumnToggle(idx) {
  return {
    type: CUSTOMER_SMART_DEEP_LINK_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: CUSTOMER_SMART_DEEP_LINK_RESET_COLUMNS,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_CUSTOMER_SMART_DEEP_LINK,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

export function checkAllTableRows(newChecked) {
  return {
    type: CUSTOMER_SMART_DEEP_LINK_CHECK_ALL_TABLE_ROWS,
    payload: newChecked,
  };
}

export function clearActionMenu(idx) {
  return {
    type: CUSTOMER_SMART_DEEP_LINK_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

// ------------------------------------
// Initial State
// ------------------------------------
const initialState = {
  smartLinks: [],
  smartlinksCount: 0,
  tableData: null,

  // cashing
  page: 1,
  fromDate: null,
  toDate: null,

  smartlink: null,
  statistics: null,
  analytics: null,
  analyticsChartData: null,
  analyticsHitsLogs: null,
  isPending: false,
  error: false,
  message: null,
  status: null,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function smartlinkReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };

    case `${GET_CURRENT_SMARTLINK}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
        currPending: true,
      };

    case `${GET_SUIT_SMARTLINKS}_PENDING`:
    case `${GET_SUIT_SMARTLINKS_DATA_TABLE}_PENDING`:
    case `${GET_SMARTLINK_CHART_DATA}_PENDING`:
    case `${GET_SMARTLINK_HITS_LOGS}_PENDING`:
    case `${GET_SMARTLINK_ANALYTICS}_PENDING`:
    case `${CREATE_SMARTLINK}_PENDING`:
    case `${UPDATE_SMARTLINK}_PENDING`:
    case `${DELETE_SMARTLINK}_PENDING`:
    case `${GET_SUIT_SMARTLINKS_STATISTICS}_PENDING`:
    case `${DELETE_SMARTLINK_BULK}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_SUIT_SMARTLINKS}_REJECTED`:
    case `${GET_SUIT_SMARTLINKS_DATA_TABLE}_REJECTED`:
    case `${GET_CURRENT_SMARTLINK}_REJECTED`:
    case `${GET_SMARTLINK_CHART_DATA}_REJECTED`:
    case `${GET_SMARTLINK_HITS_LOGS}_REJECTED`:
    case `${GET_SMARTLINK_ANALYTICS}_REJECTED`:
    case `${CREATE_SMARTLINK}_REJECTED`:
    case `${UPDATE_SMARTLINK}_REJECTED`:
    case `${DELETE_SMARTLINK}_REJECTED`:
    case `${GET_SUIT_SMARTLINKS_STATISTICS}_REJECTED`:
    case `${DELETE_SMARTLINK_BULK}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
        currPending: false,
      };
    case `${GET_SUIT_SMARTLINKS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        smartlinks: action.payload.body.smartlinks,
        smartlinksCount: action.payload.body.count,
      };
    case `${GET_CURRENT_SMARTLINK}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        smartlink: action.payload.body,
        currPending: false,
      };
    case REMOVE_CURRENT_SMARTLINK:
      return {
        ...state,
        smartlink: null,
        analytics: null,
      };
    case `${CREATE_SMARTLINK}_FULFILLED`:
    case `${UPDATE_SMARTLINK}_FULFILLED`:
    case `${DELETE_SMARTLINK}_FULFILLED`:
    case `${UPLOAD_BULK_SMARTLINKS}_FULFILLED`:
    case `${DELETE_SMARTLINK_BULK}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        message: action.payload.body.message,
        status: action.payload.body.status,
      };
    case `${GET_SUIT_SMARTLINKS_STATISTICS}_FULFILLED`:
      return {
        ...state,
        statistics: action.payload.body,
        isPending: false,
      };
    case `${GET_SMARTLINK_CHART_DATA}_FULFILLED`:
      return {
        ...state,
        analyticsChartData: action.payload.body,
        isPending: false,
      };
    case `${GET_SMARTLINK_HITS_LOGS}_FULFILLED`:
      return {
        ...state,
        analyticsHitsLogs: action.payload.body,
        isPending: false,
      };
    case `${GET_SMARTLINK_ANALYTICS}_FULFILLED`:
      return {
        ...state,
        analytics: action.payload.body,
        isPending: false,
      };
    // /////
    case SMART_DEEP_LINK_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.tableData.rows[rowIdx],
                  actionsMenu: !state.tableData.rows[rowIdx].actionsMenu,
                }
              : state.tableData.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_SMART_DEEP_LINK_CHECK_DATATABLE_ROW:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.tableData.rows[rowIdx],
                  checked: !state.tableData.rows[rowIdx].checked,
                }
              : state.tableData.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_SMART_DEEP_LINK_COLUMN_TOGGLE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };

    case CUSTOMER_SMART_DEEP_LINK_RESET_COLUMNS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };

    case SORT_CUSTOMER_SMART_DEEP_LINK: {
      const indexOfKey = state.tableData.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.tableData.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: tableRows,
        },
      };
    }

    case CUSTOMER_SMART_DEEP_LINK_CHECK_ALL_TABLE_ROWS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    case CUSTOMER_SMART_DEEP_LINK_CLEAR_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };
    case `${GET_SUIT_SMARTLINKS_DATA_TABLE}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        tableData: prepareTableDate(action.payload.body.smartlinks),
        smartLinks: action.payload.body.smartlinks,
        smartlinksCount: action.payload.body.count,
      };
    case SET_SMART_DEEP_LINKS_META_DATA:
      return {
        ...state,
        page: action.payload.page,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate,
      };
    default:
      return state;
  }
}

function prepareTableDate(tableData) {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "Name",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "name",
    },
    {
      name: "URL",
      has_sort: false,
      type: URL,
      selected: true,
      varName: "url",
    },
    {
      name: "HITS",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: "clicks",
    },
    {
      name: "DATE CREATED",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "created_at",
    },
    {
      name: "SLUG",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "slug",
      defaultValue: "N/A",
    },
    {
      name: "MEDIA SOURCE",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "media_source",
      defaultValue: "N/A",
    },
    {
      name: "TAG",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "tag",
      defaultValue: "N/A",
    },
  ];

  const rows = tableData.map((row) => ({
    checked: false,
    actionsMenu: false,
    id: row.id,

    values: labels.map((label) => {
      if (label.type === DATE_TYPE) {
        return moment(moment.utc(_.get(row, label.varName, label.defaultValue)))
          .local()
          .format(dateFormat);
      } else if (label.type === OBJECT) {
        return JSON.stringify(_.get(row, label.varName, label.defaultValue));
      }
      return _.get(row, label.varName, label.defaultValue);
    }),
  }));

  let byDefaultSortedRows = _.sortBy(rows, (row) =>
    new Date(row.values[3]).getTime()
  );
  _.reverse(byDefaultSortedRows);

  return { labels, rows: byDefaultSortedRows, actions: [{ type: "delete" }] };
}
