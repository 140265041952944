import React, { Component } from "react";
import classes from "./WhatsAppButton.module.scss";

class WhatsAppButton extends Component {
  render() {
    const whatsappLink = `https://wa.me/966549740945`;

    return (
      <a
        href={whatsappLink}
        className={classes.whatsappButton}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp"></i>
      </a>
    );
  }
}

export default WhatsAppButton;
