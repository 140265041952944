import React, { Component } from "react";
import { Button, FormControl, Label } from "react-bootstrap";
import classes from "./GIF.scss";

export default class GIF extends Component {
  state = {
    searchVal: "",
    isPending: false,
  };
  onSearchChange = (e) => {
    this.setState({ searchVal: e.target.value });
  };
  fetchGifs = () => {
    this.setState({ isPending: true }, () => {
      // Call Api to get Gifs from tenor
      const limit = 36;
      const apikey = "7F6Y5FIG5KUP";
      const url = `https://api.tenor.com/v1/search?tag=${this.state.searchVal}&key=${apikey}&limit=${limit}&anon_id=39885f6143c04dc8a10f8ad5fea1806b`;
      fetch(url)
        .then((res) => res.json())
        .then(({ results }) => {
          this.setState({ isPending: false });
          this.props.fetchGifs(results);
        })
        .catch(() => {
          this.setState({ isPending: false });
          this.props.resetGifs();
        });
    });
  };
  render() {
    return (
      <div className={classes["input-container"]}>
        <Label className={classes["my-form-label"]}>
          Tenor GIFs (Optional)
        </Label>
        <div style={{ position: "relative" }}>
          <FormControl
            className={classes["input-form"]}
            placeholder="Enter Keyword"
            type="text"
            value={this.state.searchVal}
            onChange={this.onSearchChange}
          />
          <div className={classes["search-btn"]}>
            <Button
              disabled={
                this.state.isPending || this.state.searchVal.length === 0
              }
              onClick={this.fetchGifs}
              block
              bsStyle="primary"
            >
              Search
            </Button>
          </div>
        </div>
        <p className={classes["hintSty"]}>
          Powered by{" "}
          <a target="_blank" href="https://tenor.com/" rel="noreferrer">
            Tenor GIFs
          </a>
        </p>
      </div>
    );
  }
}
