import appboost from "api/appboost";
import { CURRENCY, DATE_TYPE, NUMBER, STRING } from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";
import { RESET_ERRORS } from "reducers/user";

const GET_SUMM = "GET_SUMM";
const GET_Daily = "GET_Daily";
const GET_ACTIVE = "GET_ACTIVE";
const GET_RNT = "GET_RNT";
const GET_PURCHACETRANSACTIONS = "GET_PURCHACETRANSACTIONS";
const GET_PURCHACETRANSACTIONS_COUNT = "GET_PURCHACETRANSACTIONS_COUNT";
const GET_UNINS = "GET_UNINS";
const GET_TPOINT = "GET_TPOINT";
const GET_ACQU = "GET_ACQU";
const APPBOOST_BEGIN_AUTH_PENDING = "APPBOOST_BEGIN_AUTH_PENDING";
const APPBOOST_CLEAR_AUTH_PENDING = "APPBOOST_CLEAR_AUTH_PENDING";
const APPBOOST_UNMOUNT = "APPBOOST_UNMOUNT";
const APPBOOST_REVENUE_METRICS = "APPBOOST_REVENUE_METRICS";
const TOGGLE_ROW_ACTION_MENU = "TOGGLE_ROW_ACTION_MENU";
const CUSTOMER_CHECK_DATATABLE_ROW = "CUSTOMER_CHECK_DATATABLE_ROW";
const CUSTOMER_COLUMN_TOGGLE = "CUSTOMER_COLUMN_TOGGLE";
const CUSTOMER_RESET_COLUMNS = "CUSTOMER_RESET_COLUMNS";
const SORT_CUSTOMER = "SORT_CUSTOMER";
const CUSTOMER_CHECK_ALL_TABLE_ROWS = "CUSTOMER_CHECK_ALL_TABLE_ROWS";
const CUSTOMER_CLEAR_ACTION_MENU = "CUSTOMER_CLEAR_ACTION_MENU";
// / ------------------------------------
// Actions
// ------------------------------------

export function getSummaryData(serv, suitId, apiKey, data, appid, master) {
  return {
    type: GET_SUMM,
    payload: appboost.getSummaryData(serv, suitId, apiKey, data, appid, master),
  };
}

export function getDailyRevenueData(serv, suitId, apiKey, data, appid, master) {
  return {
    type: GET_Daily,
    payload: appboost.getDailyRevenueData(
      serv,
      suitId,
      apiKey,
      data,
      appid,
      master
    ),
  };
}

export function getRnTData(serv, suitId, apiKey, data, appid, master) {
  return {
    type: GET_RNT,
    payload: appboost.getRnTData(serv, suitId, apiKey, data, appid, master),
  };
}
export function getPurchasedTransaction(
  serv,
  suitId,
  apiKey,
  data,
  appid,
  master,
  limitVal,
  skipVal
) {
  return {
    type: GET_PURCHACETRANSACTIONS,
    payload: appboost.getPurchasedTransaction(
      serv,
      suitId,
      apiKey,
      data,
      appid,
      master,
      limitVal,
      skipVal
    ),
  };
}

export function getPurchasedTransactionCount(
  serv,
  suitId,
  apiKey,
  data,
  appid,
  master
) {
  return {
    type: GET_PURCHACETRANSACTIONS_COUNT,
    payload: appboost.getPurchasedTransactionCount(
      serv,
      suitId,
      apiKey,
      data,
      appid,
      master
    ),
  };
}

export function getActiveUsersData(serv, suitId, apiKey, data, appid, master) {
  return {
    type: GET_ACTIVE,
    payload: appboost.getActiveUsersData(
      serv,
      suitId,
      apiKey,
      data,
      appid,
      master
    ),
  };
}

export function getUninstallData(serv, suitId, apiKey, data, appid, master) {
  return {
    type: GET_UNINS,
    payload: appboost.getUninstallData(
      serv,
      suitId,
      apiKey,
      data,
      appid,
      master
    ),
  };
}

export function getTouchPointData(suitId, apiKey, data) {
  return {
    type: GET_TPOINT,
    payload: appboost.getTouchPointData(suitId, apiKey, data),
  };
}

export function getAcquData(suitId, apiKey, data) {
  return {
    type: GET_ACQU,
    payload: appboost.getAcquData(suitId, apiKey, data),
  };
}

export function getRevenueMetrics(
  serverURL,
  appId,
  suitId,
  masterKey,
  apiKey,
  fromDate,
  toDate
) {
  return {
    type: APPBOOST_REVENUE_METRICS,
    payload: appboost.getRevenueMetrics(
      serverURL,
      appId,
      suitId,
      masterKey,
      apiKey,
      fromDate,
      toDate
    ),
  };
}

export function beginAuthPending() {
  return {
    type: APPBOOST_BEGIN_AUTH_PENDING,
  };
}

export function clearAuthPending() {
  return {
    type: APPBOOST_CLEAR_AUTH_PENDING,
  };
}

export function unmountAppBoost() {
  return {
    type: APPBOOST_UNMOUNT,
  };
}
export function toggleRowActionMenu(index) {
  return {
    type: TOGGLE_ROW_ACTION_MENU,
    payload: index,
  };
}

export function checkDataTableRow(idx) {
  return {
    type: CUSTOMER_CHECK_DATATABLE_ROW,
    payload: idx,
  };
}

export function onColumnToggle(idx) {
  return {
    type: CUSTOMER_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: CUSTOMER_RESET_COLUMNS,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_CUSTOMER,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

export function checkAllTableRows(newChecked) {
  return {
    type: CUSTOMER_CHECK_ALL_TABLE_ROWS,
    payload: newChecked,
  };
}
export function clearActionMenu(idx) {
  return {
    type: CUSTOMER_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

function prepareTableDate(tableData) {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "USER ID",
      has_sort: true,
      type: STRING,
      selected: true,
      varName: "userId",
    },
    {
      name: "PRODUCT NAME",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "productName",
    },
    {
      name: "AMOUNT",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: "amount",
    },
    {
      name: "DATE",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "updatedAt",
    },
    {
      name: "SMART LINK ID",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "smartlink_id",
    },
    {
      name: "AMOUNT",
      has_sort: false,
      type: NUMBER,
      selected: false,
      varName: "amount",
    },
    {
      name: "CURRENCY",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "currency",
    },
    {
      name: "PLATFORM",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "platform",
    },
  ];

  const rows = tableData.map((row) => ({
    checked: false,
    actionsMenu: false,
    userId: _.get(row, "userId"),
    values: [
      _.get(row, labels[0].varName, labels[0].defaultValue),
      _.get(row, labels[1].varName, labels[1].defaultValue),
      Math.round(_.get(row, labels[2].varName, labels[2].defaultValue)),

      moment(moment.utc(_.get(row, labels[3].varName, labels[3].defaultValue)))
        .local()
        .format(dateFormat),

      _.get(row, labels[4].varName, labels[4].defaultValue),
      _.get(row, labels[5].varName, labels[5].defaultValue),
      _.get(row, labels[6].varName, labels[6].defaultValue),
      _.get(row, labels[7].varName, labels[7].defaultValue),
    ],
  }));
  return { labels, rows };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  result: null,
  dailyRev: null,
  RnT: null,
  purchasedTransaction: null,
  purchasedTransactionCount: 0,
  activeUsers: null,
  uninstall: null,
  total: null,
  totalAcqu: [],
  revenueMetrics: null,
  authPending: false,
  isPending: false,
  isPendingRevenueMetrics: false,
  isPendingSummary: false,
  isPendingTouchPointRev: false,
  isPendingDailyRev: false,
  isPendingActiveUsersChart: false,
  isPendingRnTChart: false,
  isPendingPurchaseTransactionsTable: false,
  isPendingUninstallChart: false,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function appboostReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case APPBOOST_UNMOUNT:
      return {
        ...initialState,
      };
    case `${APPBOOST_REVENUE_METRICS}_PENDING`:
      return {
        ...state,
        isPendingRevenueMetrics: true,
      };

    case `${GET_SUMM}_PENDING`:
      return {
        ...state,
        isPendingSummary: true,
        result: {},
        dailyRev: {},
        total: {},
        error: false,
      };
    case APPBOOST_BEGIN_AUTH_PENDING:
      return {
        ...state,
        authPending: true,
      };
    case APPBOOST_CLEAR_AUTH_PENDING:
      return {
        ...state,
        authPending: false,
      };
    case `${GET_Daily}_PENDING`:
      return {
        ...state,
        isPendingDailyRev: true,
        result: {},
        dailyRev: {},
        total: {},
        error: false,
      };
    case `${GET_RNT}_PENDING`:
      return {
        ...state,
        isPendingRnTChart: true,
        RnT: {},
        error: false,
      };
    case `${GET_PURCHACETRANSACTIONS}_PENDING`:
      return {
        ...state,
        isPendingPurchaseTransactionsTable: true,
        purchasedTransaction: null,
        error: false,
      };
    case `${GET_ACTIVE}_PENDING`:
      return {
        ...state,
        isPendingActiveUsersChart: true,
        activeUsers: {},
        error: false,
      };

    case `${GET_UNINS}_PENDING`:
      return {
        ...state,
        isPendingUninstallChart: true,
        uninstall: {},
        error: false,
      };

    case `${GET_TPOINT}_PENDING`:
      return {
        ...state,
        isPendingTouchPointRev: true,
        result: {},
        dailyRev: {},
        total: {},
        error: false,
      };

    case `${GET_ACQU}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case `${GET_Daily}_REJECTED`:
    case `${GET_SUMM}_REJECTED`:
    case `${GET_TPOINT}_REJECTED`:
      return {
        ...state,
        isPendingSummary: false,
        isPendingDailyRev: false,
        isPendingTouchPointRev: false,
        result: null,
        dailyRev: null,
        total: null,
        error: true,
      };
    case `${APPBOOST_REVENUE_METRICS}_REJECTED`:
      return {
        ...state,
        isPendingRevenueMetrics: false,
        error: true,
      };

    case `${GET_ACTIVE}_REJECTED`:
      return {
        ...state,
        error: true,
        isPendingActiveUsersChart: false,
        activeUsers: null,
      };

    case `${GET_RNT}_REJECTED`:
      return {
        ...state,
        isPendingRnTChart: false,
        RnT: null,
        error: true,
      };

    case `${GET_PURCHACETRANSACTIONS}_REJECTED`:
      return {
        ...state,
        isPendingPurchaseTransactionsTable: false,
        purchasedTransaction: null,
        error: true,
      };
    case `${GET_UNINS}_REJECTED`:
      return {
        ...state,
        uninstall: null,
        isPendingUninstallChart: false,
        error: true,
      };

    case `${GET_ACQU}_REJECTED`:
    case `${GET_SUMM}_FULFILLED`:
      return {
        ...state,
        isPendingSummary: false,
        result: action.payload.body.result,
      };

    case `${GET_Daily}_FULFILLED`:
      return {
        ...state,
        isPendingDailyRev: false,
        dailyRev: action.payload.body.result,
      };

    case `${GET_RNT}_FULFILLED`:
      return {
        ...state,
        isPendingRnTChart: false,
        error: false,
        RnT: action.payload.body.result,
      };
    case `${GET_PURCHACETRANSACTIONS}_FULFILLED`:
      return {
        ...state,
        isPendingPurchaseTransactionsTable: false,
        purchasedTransaction: prepareTableDate(action.payload.body.results),
        error: false,
      };
    case `${GET_PURCHACETRANSACTIONS_COUNT}_FULFILLED`:
      return {
        ...state,
        purchasedTransactionCount: action.payload.body.count,
      };

    case `${APPBOOST_REVENUE_METRICS}_FULFILLED`:
      return {
        ...state,
        isPendingRevenueMetrics: false,
        revenueMetrics: action.payload.body.result,
      };

    case `${GET_ACTIVE}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        isPendingActiveUsersChart: false,
        activeUsers: action.payload.body.result,
      };

    case `${GET_UNINS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        isPendingUninstallChart: false,
        uninstall: action.payload.body.result,
      };

    case `${GET_TPOINT}_FULFILLED`:
      return {
        ...state,
        isPendingTouchPointRev: false,
        total: action.payload.body.total_revenue,
      };

    case `${GET_ACQU}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        totalAcqu: action.payload.body.total_installs,
      };
    case TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        purchasedTransaction: {
          ...state.purchasedTransaction,
          rows: state.purchasedTransaction.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.purchasedTransaction.rows[rowIdx],
                  actionsMenu:
                    !state.purchasedTransaction.rows[rowIdx].actionsMenu,
                }
              : state.purchasedTransaction.rows[rowIdx];
          }),
        },
      };
    case CUSTOMER_CHECK_DATATABLE_ROW:
      return {
        ...state,
        purchasedTransaction: {
          ...state.purchasedTransaction,
          rows: state.purchasedTransaction.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.purchasedTransaction.rows[rowIdx],
                  checked: !state.purchasedTransaction.rows[rowIdx].checked,
                }
              : state.purchasedTransaction.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_COLUMN_TOGGLE:
      return {
        ...state,
        purchasedTransaction: {
          ...state.purchasedTransaction,
          labels: state.purchasedTransaction.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };

    case CUSTOMER_RESET_COLUMNS:
      return {
        ...state,
        purchasedTransaction: {
          ...state.purchasedTransaction,
          labels: state.purchasedTransaction.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };

    case SORT_CUSTOMER: {
      const indexOfKey = state.purchasedTransaction.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.purchasedTransaction.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.purchasedTransaction.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.purchasedTransaction.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.purchasedTransaction.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        purchasedTransaction: {
          ...state.purchasedTransaction,
          rows: tableRows,
        },
      };
    }

    case CUSTOMER_CHECK_ALL_TABLE_ROWS:
      return {
        ...state,
        purchasedTransaction: {
          ...state.purchasedTransaction,
          rows: state.purchasedTransaction.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    default:
      return state;
  }
}
