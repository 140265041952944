import React, { Component } from "react";
import { BsMagic } from "react-icons/bs";
import classes from "./SuggestBestTimeBtn.module.scss";

class SuggestBestTimeBtn extends Component {
  render() {
    return (
      <button
        className={classes.suggestBestTimeBtn}
        type="button"
        onClick={this.props.onClick}
      >
        <BsMagic />
        Suggest best time using AI
      </button>
    );
  }
}

export default SuggestBestTimeBtn;
