/* eslint-disable no-undef */
import { config } from "consts/api";
import createBrowserHistory from "history/lib/createBrowserHistory";
import pace from "pace-js";
import Raven from "raven-js";
import React from "react";
import ReactDOM from "react-dom";
import { useRouterHistory } from "react-router";
import AppContainer from "./containers/AppContainer";
import routes from "./routes/index";
import createStore from "./store/createStore";

Raven.config(config.SENTRY_URL).install();

export const browserHistory = useRouterHistory(createBrowserHistory)({
  basename: __BASENAME__,
});

export const store = createStore();

const Routes = routes(store);
const root = document.getElementById("root");

ReactDOM.render(
  <AppContainer store={store} history={browserHistory} routes={Routes} />,
  root
);

pace.start();
