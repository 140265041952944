import EP, { config } from "../consts/api";
import superAgentInstance from "../modules/api";

export default class DigitalInvoice {
  static getDigitalInvoices = (subdomain, data) =>
    superAgentInstance("POST", EP.DIGITALINOVICE.INVOICES(subdomain, data)).set(
      "x-parse-application-id",
      `${config.applicationID}`
    );

  static getMobileUserDigitalInvoices = (subDomain, userId) =>
    superAgentInstance(
      "get",
      EP.DIGITALINOVICE.mobileUserInvoices(subDomain, userId)
    )
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);

  static createDigitalInvoice = (subDomain, data) =>
    superAgentInstance(
      "post",
      EP.DIGITALINOVICE.createInvoice(subDomain),
      data
    ).set("x-parse-application-id", `${config.applicationID}`);

  static deleteDigitalInvoice = (subdomain, invoiceId) =>
    superAgentInstance("POST", EP.DIGITALINOVICE.deleteInvoice(), {
      subdomain,
      invoiceId,
    }).set("x-parse-application-id", `${config.applicationID}`);

  static shareDigitalInvoiceViaSMS = (subdomain, invoiceNumber, appApiKey) =>
    superAgentInstance("POST", EP.DIGITALINOVICE.shareViaSMS(), {
      subdomain,
      invoiceNumber,
      appApiKey,
    }).set("x-parse-application-id", `${config.applicationID}`);

  static shareDigitalInvoiceViaWhatsApp = (
    subdomain,
    invoiceNumber,
    appApiKey
  ) =>
    superAgentInstance("POST", EP.DIGITALINOVICE.shareViaWhatsApp(), {
      subdomain,
      invoiceNumber,
      appApiKey,
    }).set("x-parse-application-id", `${config.applicationID}`);

  static collectInvoice = (subdomain, invoiceNumber) =>
    superAgentInstance("POST", EP.DIGITALINOVICE.collectInvoice(), {
      subdomain,
      invoiceNumber,
    }).set("x-parse-application-id", `${config.applicationID}`);
}
