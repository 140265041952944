import EP from "consts/api";
import api from "modules/api";
import { userData } from "modules/user";

export default class MediaLibrary {
  static GetMediaLibrary = (suitId) => {
    const user = userData();
    return api("get", EP.MediaLibrary.GetMediaLibrary(suitId)).set(
      "authToken",
      user.auth_token
    );
  };
  static DeleteMedia = (suitId) => {
    const user = userData();

    return api("delete", EP.MediaLibrary.DeleteMedia(suitId)).set(
      "authToken",
      user.auth_token
    );
  };
}
