import { Col, FormControl, Row } from "components";
import React from "react";
import Notifications from "react-notification-system-redux";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig, setNotifyConfig } from "reducers/config";
import { connect } from "routes/routedComponent";
import classes from "./EmailConfig.scss";
import AWS_SES from "./components/AWS_SES";
import SMTPSEC from "./components/SMTPSEC";
import SendGrid from "./components/SendGrid";

const EmailOptions = [
  { value: "AWS_SES", label: I18n.t("PlatformsPage_nav_4_emailOption_1") },
  { value: "SendGrid", label: I18n.t("PlatformsPage_nav_4_emailOption_2") },
  { value: "SMTP", label: I18n.t("PlatformsPage_nav_4_emailOption_3") },
];
class EmailConfig extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      initialized: false,
      requires_auth: false,
      requires_authVendors: false,
      disabled: true,
      TypeSMS: "AWS-SMS",
      TypeEmail: "AWS_SES",
      validateSelect: false,
    };
  }
  async componentDidMount() {
    const {
      config: { notify },
      user: { activeSuitId },
      getNotifyConfig,
    } = this.props;

    if (notify && notify.channels && notify.channels.email) return;

    await getNotifyConfig(activeSuitId);
  }

  handleEdit = () => {
    this.setState({
      disabled: false,
    });
  };

  handleModify = (type, value = false) => {
    if (value) {
      this.setState({ requires_auth: true });

      this.props.change("requires_authVendors", true);
    }
    if (!value) {
      this.setState({ requires_auth: false });
      this.props.change("requires_authVendors", false);
    }
  };
  handleSave = () => {
    this.setState({
      disabled: true,
    });
  };
  handleSelectEmail = (e) => {
    this.setState({ TypeEmail: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      setNotifyConfig,
      form,
      user: { activeSuitId },
    } = this.props;
    const { TypeEmail } = this.state;
    let values = form.nconfig.values,
      typeValue = TypeEmail,
      connection_info;
    {
      typeValue === "AWS_SES" &&
        (connection_info = {
          connection_info: {
            access_key_ID: values.access_key_ID,
            type: "AWS-SES",
            aws_region: values.aws_region,
            secret_access_key: values.secret_access_key,
            from_email: values.from_email,
          },
        });
    }

    {
      typeValue === "SendGrid" &&
        (connection_info = {
          connection_info: {
            from_email: values.from_email,
            type: "SEND-GRID",
            api_key: values.api_key,
          },
        });
    }

    {
      typeValue === "SMTP" &&
        (connection_info = {
          connection_info: {
            password: values.password,
            serverURL: values.serverURL,
            security_type: "SSL",
            type: "SMTP",
            user_name: values.user_name,
            port: parseInt(values.port) || 456,
            requires_auth: values.requires_auth,
          },
        });
    }

    let conInfoClone = { ...connection_info.connection_info };

    for (let key in conInfoClone) {
      if (
        typeof conInfoClone[key] == "string" &&
        conInfoClone[key].includes("**")
      ) {
        delete conInfoClone[key];
      }
    }

    connection_info.connection_info = conInfoClone;

    let Notifconfigdata = {
      channels: {
        email: {
          ...connection_info,
          vendor: values.vendor,
        },
      },
    };
    setNotifyConfig(activeSuitId, Notifconfigdata)
      .then(() => {
        this.props.showNotification(
          {
            title: "",
            message: I18n.t("PlatformsPage_nav_4_emailOption_emailMsg"),
            position: "bc",
            autoDismiss: 5,
          },
          "success"
        );
        return getNotifyConfig(activeSuitId);
      })
      .then(() => {
        this.handleSave();
      });
  };
  render() {
    const { TypeEmail, validateSelect } = this.state;
    const {
      config: { notify, isPending },
    } = this.props;

    if (notify && notify.channels && notify.channels.email && !validateSelect) {
      let emailtype = null;
      emailtype = notify.channels.email.connection_info.type;

      if (emailtype === "AWS-SES") {
        this.setState({ TypeEmail: "AWS_SES" });
      } else if (emailtype === "SEND-GRID") {
        this.setState({ TypeEmail: "SendGrid" });
      } else {
        this.setState({ TypeEmail: "SMTP" });
      }
      this.setState({ validateSelect: true });
    }
    return (
      <div style={{ marginTop: "20px" }}>
        {isPending && (
          <div className={classes["loader-container"]}>
            <div className={classes["loader"]}>
              <i className="fa fa-spinner" aria-hidden="true" />
            </div>
          </div>
        )}
        <Row>
          <Col md={6}>
            <label> {I18n.t("PlatformsPage_ServiceVendor")}</label>
            <br />
            <FormControl
              componentClass="select"
              value={TypeEmail}
              onChange={this.handleSelectEmail}
            >
              {EmailOptions.map((el) => (
                <option key={el.value} value={el.value}>
                  {el.label}
                </option>
              ))}
            </FormControl>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {TypeEmail === "AWS_SES" && (
              <AWS_SES
                handleSubmit={this.handleSubmit}
                // disabled={this.state.disabled}
                handleEdit={this.handleEdit}
              />
            )}
            {TypeEmail === "SMTP" && (
              <SMTPSEC
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                // disabled={this.state.disabled}
                handleEdit={this.handleEdit}
              />
            )}
            {TypeEmail === "SendGrid" && (
              <SendGrid
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                // disabled={this.state.disabled}
                handleEdit={this.handleEdit}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
    config: store.config,
    form: store.form,
  }),
  {
    setNotifyConfig,
    getNotifyConfig,
    showNotification: Notifications.show,
  }
)(EmailConfig);
