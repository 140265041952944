import api from "modules/api";
import EP from "consts/api";
import { config } from "consts/api";

export default class LeadForm {
  static getLeadForm = (suitId, apiKey, landingpageId) =>
    api("post", EP.LEADFORM.ALLForm(suitId, apiKey, landingpageId))
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);
}
