import { Icon, Layout } from "antd";
import React from "react";
const { Footer: AntFooter } = Layout;

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <AntFooter
      style={{
        height: "85px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          margin: 0,
        }}
      >
        <strong className="m-r-1">APPGAIN.IO</strong>
        <span>
          &copy; {year} Made by &nbsp;
          <Icon type="heart" theme="twoTone" twoToneColor="#eb2f96" />
          &nbsp; Delaware, USA
        </span>
      </p>
    </AntFooter>
  );
};

export default Footer;
