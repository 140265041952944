import api from "modules/api";
import EP from "consts/api";
import { userData } from "modules/user";

export default class userTimeline {
  static getUserTimelineInfo = (appId, userId, subDomain) => {
    const user = userData();
    return api(
      "get",
      EP.USERSCREEN.UserTimelineInfo(appId, userId, subDomain)
    ).set("authToken", user.auth_token);
  };

  static getUserLogEvents = (appId, userId, page = 1) => {
    const user = userData();
    return api("get", EP.USERSCREEN.UserLogEvents(appId, userId, page)).set(
      "authToken",
      user.auth_token
    );
  };
}
