import { Pagination } from "antd";
import { Confirm, NewBreadcrumb } from "components";
import DataTable from "components/DataTable";
import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { I18n } from "react-redux-i18n";
import {
  checkAllTableRows,
  checkDataTableRow,
  clearActionMenu,
  deleteDeepLink,
  getSuitFilteredDeepLinks,
  onColumnToggle,
  onResetColumns,
  sortTable,
  toggleRowActionMenu,
} from "reducers/deeplink";
import { getAppBackendConfig } from "reducers/user";
import { connect } from "routes/routedComponent";
import { Toast } from "../../../../../modules/toast";
import classes from "./ListDeepLinks.scss";

const INITIAL_DL_FILTER_DATA = {
  name: "",
  url: "",
};

const cleanFilterString = (str) => str.replace("_", " ");

class ListDeepLinksContainer extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      DeepLinkId: null,

      sortedBy: "DATE CREATED_1",
      globalCheck: false,

      page: 1,
      pageSize: 10,

      // filter
      showDLFilter: false,
      DLFilterData: INITIAL_DL_FILTER_DATA,
      cleanDLFilterData: null,
    };
  }

  componentDidMount() {
    this.fetchDeepLinks();
  }

  handlePaginationChange = (page) => {
    const { DLFilterData } = this.state;
    this.fetchDeepLinks(page, DLFilterData.name, DLFilterData.url);
  };

  handleGlobalCheckboxClick = () => {
    this.setState(
      (prevState) => ({ globalCheck: !prevState.globalCheck }),
      () => {
        this.props.checkAllTableRows(this.state.globalCheck);
      }
    );
  };

  handleSortClick = (key, type) => {
    const sortedBy = this.state.sortedBy === key ? `${key}_1` : key;
    this.setState({ sortedBy }, () => {
      this.props.sortTable(sortedBy, type);
    });
  };

  handleDelete = () => {
    const { deleteDeepLink, user } = this.props;
    const { DeepLinkId } = this.state;
    const { appbackend } = user;

    deleteDeepLink(
      appbackend.serverURL + "fe",
      DeepLinkId,
      appbackend.appId,
      appbackend.masterKey
    )
      .then(() => {
        Toast.success(
          I18n.t("ProjectSettingPage_listDeepLinks_deleteSuccessMsg")
        );

        this.fetchDeepLinks();
      })
      .catch((err) => {
        console.error("Error deleting deeplink:", err);
      });

    this.handleDismiss();
  };

  handleActionClick = (idx, actionName) => {
    const { deeplinks, params } = this.props;

    const deeplinkId = deeplinks.deeplinks.rows[idx].id;

    if (actionName === "Edit") {
      this.context.router.push(
        `/app/${params.subdomain}/appbackend/deeplinks/${deeplinkId}`
      );
    } else if (actionName === "Delete") {
      this.handleShow(deeplinkId);
    }
  };

  handleDismiss = () => {
    this.setState({ show: false });
  };

  handleShow = (DeepLinkId) => {
    this.setState({ show: true, DeepLinkId });
  };

  fetchDeepLinks = async (page = 1, name = "", url = "") => {
    const { getAppBackendConfig, params, user, getSuitFilteredDeepLinks } =
      this.props;
    const { activeSuitId, suits } = user;
    const { pageSize } = this.state;

    // Find the current suit
    const currentSuit = suits.find((suit) => suit.id === activeSuitId);
    if (!currentSuit) {
      console.error("Active suit not found.");
      return;
    }

    const { appbackend_userId } = currentSuit;

    try {
      // Fetch app backend configuration
      await getAppBackendConfig(params.subdomain, appbackend_userId);
      const { appbackend } = this.props.user;

      if (params.action !== "new" && appbackend) {
        await getSuitFilteredDeepLinks(
          `${appbackend.serverURL}fe`,
          appbackend.appId,
          appbackend.masterKey,
          page,
          pageSize,
          url,
          name
        );

        this.setState({ page });
      }
    } catch (error) {
      console.error("Error fetching deep links data:", error);
    }
  };

  uploadCSV = () => {
    this.context.router.push(
      `/app/${this.props.params.subdomain}/appbackend/deeplinks/upload`
    );
  };

  handleDLFilterDataChange = (e) =>
    this.setState({
      DLFilterData: {
        ...this.state.DLFilterData,
        [e.target.name]: e.target.value,
      },
    });

  handleCloseDLFilter = () => {
    this.setState({ showDLFilter: false });
  };

  handleDLFilterFormSubmit = (e) => {
    e.preventDefault();

    const { DLFilterData } = this.state;

    const cleanDLFilterData = { ...DLFilterData };

    Object.keys(DLFilterData).forEach((item) => {
      if (DLFilterData[item].trim() === "") {
        delete cleanDLFilterData[item];
      }
    });

    this.setState({ cleanDLFilterData });

    this.fetchDeepLinks(1, cleanDLFilterData.name, cleanDLFilterData.url);
    this.handleCloseDLFilter();
  };

  handleClearFilterItem = (name) => {
    const { DLFilterData, cleanDLFilterData } = this.state;

    const newCleanDLFilterData = { ...cleanDLFilterData };
    const newDLFilterData = { ...DLFilterData };

    delete newCleanDLFilterData[name];
    newDLFilterData[name] = "";

    this.setState({
      cleanDLFilterData: newCleanDLFilterData,
      DLFilterData: newDLFilterData,
    });

    this.fetchDeepLinks(1, newCleanDLFilterData.name, newCleanDLFilterData.url);
  };

  handleOpenDLFilter = () => {
    this.setState({ showDLFilter: true });
  };

  render() {
    const {
      deeplinks,
      params,
      checkDataTableRow,
      onColumnToggle,
      onResetColumns,
      toggleRowActionMenu,
    } = this.props;

    const {
      show,
      currentPage,
      globalCheck,
      showDLFilter,
      DLFilterData,
      cleanDLFilterData,
    } = this.state;

    return (
      <section className={classes.listDeepLinks}>
        <Confirm
          onConfirm={this.handleDelete}
          onClose={this.handleDismiss}
          display={show}
          title="ProjectSettingPage_listDeepLinks_delete_title"
          description="ProjectSettingPage_listDeepLinks_deleteNote"
          btnText="ProjectSettingPage_listDeepLinks_delete_title"
          btnType="critical"
          isPending={deeplinks.isPending}
        />

        <div className={classes.header}>
          <NewBreadcrumb fullPath={["App DeepLinks"]} />
          <div className={classes.headerButtons}>
            <button
              id="filterBtn"
              className={classes.filterBtn}
              onClick={this.handleOpenDLFilter}
            >
              <FiFilter /> {I18n.t("filter")}
            </button>

            <button
              className={classes.newDeepLinkBtn}
              onClick={() => {
                this.context.router.push(
                  `/app/${params.subdomain}/appbackend/deeplinks/new`
                );
              }}
            >
              <i className="fa fa-plus" />
              {I18n.t("ProjectSettingPage_listDeepLinks_createBtn")}
            </button>
          </div>
        </div>

        {cleanDLFilterData && (
          <div className={classes.selectedFilters}>
            {Object.entries(cleanDLFilterData).map(([key, value], index) => (
              <div key={index}>
                <div className={classes.filterItem}>
                  <button
                    className={classes.clearFilterButton}
                    onClick={() => this.handleClearFilterItem(key)}
                  >
                    <AiFillCloseCircle className={classes.icon} />
                  </button>
                  <p>{cleanFilterString(key)}</p> : <p>{value}</p>
                </div>
              </div>
            ))}
          </div>
        )}

        <DataTable
          checkDataTableRow={checkDataTableRow}
          onColumnToggle={onColumnToggle}
          onResetColumns={onResetColumns}
          onSortClicked={this.handleSortClick}
          onGlobalCheckboxClicked={this.handleGlobalCheckboxClick}
          toggleRowActionMenu={toggleRowActionMenu}
          largeActionMenu
          tableData={deeplinks.deeplinks}
          globalChecked={globalCheck}
          isPending={deeplinks.isPending}
          order={[{ type: "gear" }, { type: "upload" }]}
          actions={[{ name: "Delete" }, { name: "Edit" }]}
          handleActionClicked={this.handleActionClick}
          clearActionMenu={this.props.clearActionMenu}
          csvFileName="DeepLinks.csv"
          uploadCSV={this.uploadCSV}
        />

        <div className={classes.paginationRow}>
          <Pagination
            size="small"
            defaultCurrent={this.state.page}
            current={currentPage}
            onChange={this.handlePaginationChange}
            pageSize={this.state.pageSize}
            total={deeplinks.deeplinksCount}
            hideOnSinglePage
          />
        </div>

        {/* DL Filter modal */}
        <Modal
          show={showDLFilter}
          onHide={this.handleCloseDLFilter}
          dialogClassName={classes.modalDialog}
        >
          <Modal.Header bsClass={classes.modalHeader} closeButton>
            <Modal.Title bsClass={classes.modalTitle}>
              {I18n.t("SmartDeepLinkPage_DLFiltermodal_title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={classes.modalBody}>
            <form
              className={classes.form}
              onSubmit={this.handleDLFilterFormSubmit}
            >
              <div className={classes.inputsContainer}>
                <div className={classes.inputContainer}>
                  <label htmlFor="Name">{I18n.t("name")}</label>
                  <input
                    type="text"
                    id="Name"
                    placeholder={`${I18n.t("name")} ${I18n.t("option")}`}
                    name="name"
                    value={DLFilterData.name}
                    onChange={this.handleDLFilterDataChange}
                  />
                </div>
                <div className={classes.inputContainer}>
                  <label htmlFor="url">{I18n.t("url")}</label>
                  <input
                    type="text"
                    id="url"
                    placeholder={`${I18n.t("url")} ${I18n.t("option")}`}
                    name="url"
                    value={DLFilterData.url}
                    onChange={this.handleDLFilterDataChange}
                  />
                </div>
              </div>

              <div className={classes.modalFooter}>
                <button
                  type="button"
                  className={classes.closeBtn}
                  onClick={this.handleCloseDLFilter}
                >
                  {I18n.t("closeBtn")}
                </button>
                <button className={classes.applyFilterBtn} type="submit">
                  {I18n.t("ApplyFilterBtn")}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </section>
    );
  }
}

export default connect(
  (store) => ({
    deeplinks: store.deeplink,
    user: store.user,
  }),
  {
    deleteDeepLink,
    getAppBackendConfig,
    toggleRowActionMenu,
    checkDataTableRow,
    onColumnToggle,
    onResetColumns,
    sortTable,
    checkAllTableRows,
    clearActionMenu,
    getSuitFilteredDeepLinks,
  }
)(ListDeepLinksContainer);
