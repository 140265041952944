const ATTRIBUTE = "ATTRIBUTE";
const EVENT = "EVENT";

export const convertConditionsToDescriptionArray = (conditions) => {
  let descriptionArray = [];
  conditions.forEach((condition) => {
    let conditionString = "";
    if (condition.type && condition.type.value === ATTRIBUTE) {
      if (condition.operator.value === "rg") {
        conditionString +=
          condition.property.label +
          ` between ${condition.inputValue.from} and ${condition.inputValue.to}`;
        return descriptionArray.push(conditionString);
      } else {
        const inputValue = condition.inputValue.label
          ? condition.inputValue.label
          : condition.inputValue;

        conditionString +=
          condition.property.label +
          " " +
          condition.operator.label +
          " " +
          inputValue;
        return descriptionArray.push(conditionString);
      }
    } else if (condition.type && condition.type.value === EVENT) {
      const actionString = `${
        condition.action && condition.action.label
          ? `${condition.action.label} `
          : " "
      }`;
      let keysString = "";
      condition.keys.forEach((key, index) => {
        const keyString = key.value.label ? key.value.label : key.value;
        keysString += `${index !== 0 ? "," : ""}${index === 0 ? "with " : " "}${
          key.label
        } ${key.operator.label} ${keyString}`;
      });
      conditionString +=
        condition.property.label + " " + actionString + keysString;
      return descriptionArray.push(conditionString);
    } else {
      if (condition.operator.value === "rg") {
        conditionString +=
          condition.property.label +
          ` between ${condition.inputValue.from} and ${condition.inputValue.to}`;
        return descriptionArray.push(conditionString);
      } else {
        const inputValue = condition.inputValue.label
          ? condition.inputValue.label
          : condition.inputValue;

        conditionString +=
          condition.property.label +
          " " +
          condition.operator.label +
          " " +
          inputValue;
        return descriptionArray.push(conditionString);
      }
    }
  });
  return descriptionArray;
};
