import PropTypes from "prop-types";
import React from "react";
import { PrimaryButton } from "../../../../components";
import retailGainImage from "../../../../static/RetailGainOnboarding/retail-onboarding-step-one.svg";
import classes from "./WelcomeStep.module.scss";

const RETAIL_GAIN_ONBOARDING_HOME_VIDEO =
  "https://www.youtube.com/embed/iNlsXckItfs?si=9FReYWoAwgNXxPQ4";

export default function WelcomeStep({ onNextStep }) {
  return (
    <div className={classes.welcomeStep}>
      <img
        className={classes.welcomeStep__image}
        src={retailGainImage}
        alt="Retail Gain"
      />

      <p className={classes.welcomeStep__title}>Welcome to Retail Gain</p>
      <p className={classes.welcomeStep__description}>
        Start Step by Step Configuration of your Account
      </p>

      <iframe
        src={RETAIL_GAIN_ONBOARDING_HOME_VIDEO}
        title="Retail Gain"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        className={classes.welcomeStep__video}
      >
        Please upgrade your browser to view this video. Your browser does not
        support HTML5 video.
      </iframe>

      <div className={classes.welcomeStep__buttonsContainer}>
        <span aria-hidden="true" />

        <PrimaryButton onClick={() => onNextStep()}>continue</PrimaryButton>
      </div>
    </div>
  );
}

WelcomeStep.propTypes = {
  onNextStep: PropTypes.func,
  onPreviousStep: PropTypes.func,
};
