import ENDPOINTS, { config } from "../consts/api";
import superAgentInstance from "../modules/api";

export default class Categories {
  static getAllCategories = (subdomain, page, pageSize) => {
    return superAgentInstance("post", ENDPOINTS.CATEGORIES.getCategories(), {
      subdomain,
      page,
      pageSize,
    })
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);
  };

  static deleteCategory = (subdomain, objectId) => {
    return superAgentInstance("post", ENDPOINTS.CATEGORIES.deleteCategory(), {
      subdomain,
      objectId,
    })
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);
  };

  static addCategory = (subdomain, data) => {
    return superAgentInstance("post", ENDPOINTS.CATEGORIES.addCategory(), {
      subdomain,
      ...data,
    })
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);
  };

  static updateCategory = (subdomain, objectId, data) => {
    return superAgentInstance("post", ENDPOINTS.CATEGORIES.editCategory(), {
      subdomain,
      objectId,
      ...data,
    })
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);
  };
}
