import PropTypes from "prop-types";
import React from "react";
import { I18n } from "react-redux-i18n";
import classes from "./NewBreadcrumb.scss";

const NewBreadcrumb = ({ fullPath, beta }) => {
  return (
    <div className={classes.breadcrumb}>
      {fullPath.map((path, index) => {
        if (index === fullPath.length - 1) {
          return (
            <span key={index} className={classes.active}>
              {I18n.t(path)}
            </span>
          );
        } else {
          return (
            <span key={index} className={classes.inactive}>
              {I18n.t(path)}
            </span>
          );
        }
      })}

      {beta && <span className={classes.beta}>{I18n.t("beta")}</span>}
    </div>
  );
};

NewBreadcrumb.propTypes = {
  // array of strings
  fullPath: PropTypes.array.isRequired,
  // boolean
  beta: PropTypes.bool,
};

export default NewBreadcrumb;
