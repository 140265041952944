import Papa from "papaparse";

const exportCSV = (data, fileName) => {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", `${fileName}.csv`);
  const tempDiv = document.createElement("div");
  tempDiv.appendChild(a);
  document.body.appendChild(tempDiv);
  a.click();
  setTimeout(() => {
    document.body.removeChild(tempDiv);
  }, 100);
};

export default exportCSV;
