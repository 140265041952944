import _ from "lodash";
import React, { PureComponent } from "react";
import { I18n } from "react-redux-i18n";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import classes from "./AppboostDashboard.scss";

function CustomTooltip(props) {
  const { payload, active } = props;

  return active ? (
    <div className={classes["custom-tooltip-charts"]}>
      <p>
        {payload[0].name} :{" "}
        <span>{Number(payload[0].value.toFixed(1)).toLocaleString()}</span>
      </p>
    </div>
  ) : null;
}

class DailyMonthlyChart extends PureComponent {
  state = {
    mode: "daily",
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(nextProps.data, this.props.data) ||
      !_.isEqual(nextState, this.state)
    );
  }

  DataFormater = (number, currency) => {
    let forMoney = "";
    if (currency) {
      forMoney = ` ${currency}`;
    }
    if (number >= 1000) {
      return (number / 1000).toString() + "K" + forMoney;
    } else {
      return number.toString() + forMoney;
    }
  };

  handleClick = (mode) => {
    this.setState({ mode });
  };

  render() {
    const isPending = this.props.pending;

    return (
      <div className={classes["appboost-dashboard-chart"]}>
        <div className={classes["appboost-dashboard-chart-labels"]}>
          <div>
            {isPending ? (
              <p className={classes["skeleton-label-small"]} />
            ) : (
              <p>
                {I18n.t(this.props.bigLabel)}
                {this.props.hasTooltip && this.props.children}
              </p>
            )}
            {isPending ? (
              <p className={classes["skeleton-label-large"]} />
            ) : (
              <p>{I18n.t(this.props.smallLabel)}</p>
            )}
          </div>
          <div>
            {isPending ? (
              <p className={classes["skeleton-label-btn"]} />
            ) : (
              <p
                className={`${
                  this.state.mode === "daily" ? classes["active"] : ""
                }`}
                onClick={() => this.handleClick("daily")}
              >
                <strong>{I18n.t("Daily")}</strong>
              </p>
            )}
            {isPending ? (
              <p className={classes["skeleton-label-btn"]} />
            ) : (
              <p
                className={`${
                  this.state.mode === "monthly" ? classes["active"] : ""
                }`}
                onClick={() => this.handleClick("monthly")}
              >
                <strong>{I18n.t("Monthly")}</strong>
              </p>
            )}
          </div>
        </div>
        {isPending ? (
          <div className={classes["skeleton-label-chart"]} />
        ) : (
          <ResponsiveContainer width="100%" height={350}>
            {this.props.type === "area" ? (
              <AreaChart
                height={300}
                data={this.props.data[this.state.mode]}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid
                  strokeDasharray="5"
                  stroke="#8097B1"
                  opacity="0.6069999933242798"
                />
                <XAxis dataKey="date" stroke="#E0E7FF" />
                <YAxis
                  tickFormatter={(number) =>
                    this.DataFormater(number, this.props.currency)
                  }
                  yAxisId="left"
                  orientation="left"
                  stroke="#E0E7FF"
                />
                <Tooltip
                  content={<CustomTooltip />}
                  itemStyle={{ fontWeight: "bold", fontSize: "14px" }}
                />
                <Area
                  dataKey={this.props.columnKey}
                  stroke="#9C9C9D"
                  fill="#1D86F1"
                  yAxisId="left"
                />
              </AreaChart>
            ) : (
              <BarChart
                height={300}
                data={this.props.data[this.state.mode]}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <Tooltip
                  content={<CustomTooltip />}
                  itemStyle={{ fontWeight: "bold", fontSize: "14px" }}
                />
                <Bar
                  barSize={30}
                  yAxisId="left"
                  dataKey={this.props.columnKey}
                  fill="#1D86F1"
                />
              </BarChart>
            )}
          </ResponsiveContainer>
        )}
      </div>
    );
  }
}

export default DailyMonthlyChart;
