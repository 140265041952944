import Parse from "api/Parse";
import { RESET_ERRORS } from "reducers/user";

// ------------------------------------
// Constants
// ------------------------------------
export const GET_CLOUD_CODE = "GET_CLOUD_CODE";
export const SET_CLOUD_CODE = "SET_CLOUD_CODE";

// ------------------------------------
// Actions
// ------------------------------------
export function getCloudCode(subdomain, appbackend_userId) {
  return {
    type: GET_CLOUD_CODE,
    payload: Parse.getCloudCode(subdomain, appbackend_userId),
  };
}

export function setCloudCode(subdomain, code, appbackend_userId) {
  return {
    type: SET_CLOUD_CODE,
    payload: Parse.setCloudCode(subdomain, code, appbackend_userId),
  };
}

// ------------------------------------
// Initial State
// ------------------------------------
const initialState = {
  cloudCode: null,
  isPending: false,
  isPendingSave: false,
  error: false,
};
// ------------------------------------
// Reducer
// ------------------------------------
export default function parseReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_CLOUD_CODE}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${SET_CLOUD_CODE}_PENDING`:
      return {
        ...state,
        isPendingSave: true,
        error: false,
      };
    case `${GET_CLOUD_CODE}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case `${SET_CLOUD_CODE}_REJECTED`:
      return {
        ...state,
        isPendingSave: false,
        error: action.payload,
      };
    case `${GET_CLOUD_CODE}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        cloudCode: action.payload.text,
      };
    case `${SET_CLOUD_CODE}_FULFILLED`:
      return {
        ...state,
        isPendingSave: false,
      };
    default:
      return state;
  }
}
