import EP from "consts/api";
import api from "modules/api";
import { userData } from "modules/user";
export default class Parse {
  static getCloudCode = (subdomain, appbackend_userId) => {
    return api(
      "get",
      EP.PARSE.CLOUD_FUNCTION_URL(
        userData().user_id,
        subdomain,
        appbackend_userId
      )
    ).set("Accept", "text/javascript");
  };
  static setCloudCode = (subdomain, code, appbackend_userId) => {
    return api(
      "put",
      EP.PARSE.CLOUD_FUNCTION_URL(
        userData().user_id,
        subdomain,
        appbackend_userId
      ),
      code
    )
      .set("Accept", "text/javascript")
      .set("Content-Type", "text/javascript");
  };
}
