import { Confirm, DataBlock, DetailsList, PrimaryButton } from "components";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { generateAPIKey, getCurrentSuit } from "reducers/user";
import { SpinLoader } from "../../../../components";
import { Toast } from "../../../../modules/toast";
import classes from "./../Setting.scss";

class ApiAndSdk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayConfirm: false,
    };
  }

  onModalConfirm = () => {
    this.generatAPI();
    this.setState({ displayConfirm: false });
  };

  cancelConfirm = () => {
    this.setState({ displayConfirm: false });
  };

  generatAPI() {
    const {
      generateAPIKey,
      user: { suit, activeSuitId },
      getCurrentSuit,
    } = this.props;
    let data = {
      suit_name: suit.name,
      reset_fields: ["appApiKey"],
    };
    generateAPIKey(data, suit.id).then(() => {
      Toast.success(I18n.t("ApiAndSdkPage_generatedMsg"));
      getCurrentSuit(activeSuitId);
    });
  }

  render() {
    const {
      user: { suit, isPending },
    } = this.props;

    return (
      <div>
        <Confirm
          onConfirm={this.onModalConfirm}
          onClose={this.cancelConfirm}
          display={this.state.displayConfirm}
          title="ApiAndSdkPage_generate"
          description="ApiAndSdkPage_generateNote"
          btnText="ApiAndSdkPage_generate"
          btnType="primary"
          isPending={isPending}
        />

        {isPending && <SpinLoader />}

        {suit ? (
          <div className={classes.new}>
            <DataBlock title="apiAndSDK" titleClass="bg-grey-lightest">
              <DetailsList
                data={[
                  {
                    key: "ApiAndSdkPage_DetailsList_1_title",
                    value: suit.id || "NA",
                    description: I18n.t(
                      "ApiAndSdkPage_DetailsList_1_description"
                    ),
                    canCopy: true,
                    info: true,
                  },
                  {
                    key: "APIKey",
                    value: suit.api_key || "NA",
                    description: I18n.t(
                      "ApiAndSdkPage_DetailsList_2_description"
                    ),
                    canCopy: true,
                    info: true,
                  },
                ]}
              />
              <PrimaryButton
                value={I18n.t("ApiAndSdkPage_generate")}
                onClick={() => this.setState({ displayConfirm: true })}
              />
            </DataBlock>
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
    select: store.select,
  }),
  {
    generateAPIKey,
    getCurrentSuit,
  }
)(ApiAndSdk);
