import { Alert as AntdAlert } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Colors } from "./../../consts";
import classes from "./Alert.scss";

const styleToColor = {
  ["primary"]: Colors.brandPrimary,
  ["success"]: Colors.brandSuccess,
  ["info"]: Colors.brandInfo,
  ["warning"]: Colors.brandWarning,
  ["danger"]: Colors.brandDanger,
};

const Alert = (props) => {
  const { noBackground, className, children, rounded, type, ...otherProps } =
    props;

  const alertClass = classNames(className, {
    [`${classes.noBackground}`]: noBackground,
    [`${classes.notRounded}`]: !rounded,
  });
  const alertStyle = noBackground
    ? {
        borderLeft: `2px solid ${styleToColor[type] || Colors.brandSuccess}`,
      }
    : {};

  return (
    <AntdAlert
      {...otherProps}
      className={alertClass}
      style={alertStyle}
      message={children}
      type={type}
    />
  );
};

Alert.propTypes = {
  noBackground: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  rounded: PropTypes.bool,
};

Alert.defaultProps = {
  noBackground: false,
  type: "success",
  rounded: true,
};

export default Alert;
