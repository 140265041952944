import events from "api/events";
import { DATE_TYPE, OBJECT, STRING, URL } from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";

// ------------------------------------
// Constants
// ------------------------------------

const GET_EVENT = "GET_EVENT";
const EVENT_DETAILS_CHECK_DATA_TABLE_ROW = "EVENT_DETAILS_CHECK_DATA_TABLE_ROW";
const EVENT_DETAILS_CHECK_ALL_DATA_TABLE_ROWS =
  "EVENT_DETAILS_CHECK_ALL_DATA_TABLE_ROWS";
const EVENT_DETAILS_ON_COLUMN_TOGGLE = "EVENT_DETAILS_ON_COLUMN_TOGGLE";
const EVENT_DETAILS_ON_RESET_COLUMNS = "EVENT_DETAILS_ON_RESET_COLUMNS";
const EVENT_DETAILS_TOGGLE_ROW_ACTION_MENU =
  "EVENT_DETAILS_TOGGLE_ROW_ACTION_MENU";
const EVENT_DETAILS_CLEAR_ACTION_MENU = "EVENT_DETAILS_CLEAR_ACTION_MENU";
const SORT_EVENT_DETAILS_TIME = "SORT_EVENT_DETAILS_TIME";

// ------------------------------------
// Actions
// ------------------------------------

export function getEvent(data) {
  return {
    type: GET_EVENT,
    payload: events.getEvent(data),
  };
}

// DataTable

export function checkDataTableRow(idx) {
  return {
    type: EVENT_DETAILS_CHECK_DATA_TABLE_ROW,
    payload: idx,
  };
}
export function checkAllTableRows(newChecked) {
  return {
    type: EVENT_DETAILS_CHECK_ALL_DATA_TABLE_ROWS,
    payload: newChecked,
  };
}
export function onColumnToggle(idx) {
  return {
    type: EVENT_DETAILS_ON_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function toggleRowActionMenu(idx) {
  return {
    type: EVENT_DETAILS_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}

export function clearActionMenu(idx) {
  return {
    type: EVENT_DETAILS_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: EVENT_DETAILS_ON_RESET_COLUMNS,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_EVENT_DETAILS_TIME,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

function prepareEventTableDate(tableData) {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const prepareEventTableDate2 = (tableData) => {
    const labels = [
      {
        name: "Time",
        has_sort: true,
        type: DATE_TYPE,
        selected: true,
        varName: "time",
        defaultValue: "",
      },
      {
        name: "User ID",
        has_sort: false,
        type: STRING,
        selected: true,
        varName: "userId",
        defaultValue: "",
      },
      {
        name: "Smartlink ID",
        has_sort: false,
        type: STRING,
        selected: false,
        varName: "smartlink_id",
        defaultValue: "",
      },
      {
        name: "Action",
        has_sort: false,
        type: STRING,
        selected: false,
        varName: "action",
        defaultValue: "",
      },
      {
        name: "Extra Params",
        has_sort: false,
        type: OBJECT,
        selected: false,
        varName: "values.extra_params",
        defaultValue: "",
      },
      {
        name: "SDL",
        has_sort: false,
        type: URL,
        selected: false,
        varName: "values.sdl_url",
        defaultValue: "",
      },
    ];

    const rows = tableData.map((row) => {
      return {
        checked: false,
        actionsMenu: false,
        id: row.userId,
        values: labels.map((label) => {
          if (label.type === DATE_TYPE) {
            return moment(
              moment.utc(_.get(row, label.varName, label.defaultValue))
            )
              .local()
              .format(dateFormat);
          } else if (label.type === OBJECT) {
            return JSON.stringify(
              _.get(row, label.varName, label.defaultValue)
            );
          }
          return _.get(row, label.varName, label.defaultValue);
        }),
      };
    });

    return { labels, rows };
  };

  return prepareEventTableDate2(tableData);
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  eventTable: null,
  isPending: false,
  error: false,
  eventDetails: null,
  eventName: null,
  eventCount: 0,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function eventDetails(state = initialState, action) {
  switch (action.type) {
    case `${GET_EVENT}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_EVENT}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case `${GET_EVENT}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        eventDetails: action.payload.body.result,
        eventTable: prepareEventTableDate(action.payload.body.result),
        eventCount: action.payload.body.count,
        eventName: action.payload.body.event_name,
      };

    case EVENT_DETAILS_CHECK_DATA_TABLE_ROW:
      return {
        ...state,
        eventTable: {
          ...state.eventTable,
          rows: state.eventTable.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, checked: !row.checked }
              : row;
          }),
        },
      };

    case EVENT_DETAILS_CHECK_ALL_DATA_TABLE_ROWS:
      return {
        ...state,
        eventTable: {
          ...state.eventTable,
          rows: state.eventTable.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    case EVENT_DETAILS_ON_COLUMN_TOGGLE:
      return {
        ...state,
        eventTable: {
          ...state.eventTable,
          labels: state.eventTable.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };

    case EVENT_DETAILS_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        eventTable: {
          ...state.eventTable,
          rows: state.eventTable.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, actionsMenu: !row.actionsMenu }
              : row;
          }),
        },
      };

    case EVENT_DETAILS_CLEAR_ACTION_MENU:
      return {
        ...state,
        eventTable: {
          ...state.eventTable,
          rows: state.eventTable.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };

    case EVENT_DETAILS_ON_RESET_COLUMNS:
      return {
        ...state,
        eventTable: {
          ...state.eventTable,
          labels: state.eventTable.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };

    case SORT_EVENT_DETAILS_TIME: {
      const indexOfKey = state.eventTable.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case DATE_TYPE:
          tableRows = _.sortBy(state.eventTable.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        eventTable: {
          ...state.eventTable,
          rows: tableRows,
        },
      };
    }
    default:
      return state;
  }
}
