import userTimeline from "api/userTimline";
import { RESET_ERRORS } from "reducers/user";

const GET_USER_TIMELINE_INFO = "GET_USER_TIMELINE_INFO";

//  ------------------------------------
// Actions
// ------------------------------------

export function getUserTimelineInfo(appId, userId, subDomain) {
  return {
    type: GET_USER_TIMELINE_INFO,
    payload: userTimeline.getUserTimelineInfo(appId, userId, subDomain),
  };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  isPending: false,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function userTimlineReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_USER_TIMELINE_INFO}_PENDING`:
      return {
        ...state,
        isPending: true,
      };

    case `${GET_USER_TIMELINE_INFO}_REJECTED`:
      return {
        ...state,
        isPending: false,
      };

    case `${GET_USER_TIMELINE_INFO}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
}
