import React from "react";
import AceEditor from "react-ace";
import { I18n } from "react-redux-i18n";
import { getCloudCode, setCloudCode } from "reducers/parse";
import { connect } from "routes/routedComponent";
import { Toast } from "../../../../modules/toast";
import classes from "./CloudFunction.scss";

// !IMPORTANT brace imports MUST be after react-ace imports
import "brace/mode/javascript";
import "brace/theme/github";
import { DataBlock, SpinLoader } from "../../../../components";
import PrimaryButton from "../../../../components/Form/Buttons/PrimaryButton/PrimaryButton";

class CloudFunction extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: "",
    };
  }

  componentDidMount() {
    const {
      params: { subdomain },
      getCloudCode,
      user: { suits, activeSuitId },
    } = this.props;
    let SuitArr = suits.filter((suit) => suit.id === activeSuitId);
    const appbackend_userId = SuitArr[0].appbackend_userId;
    getCloudCode(subdomain, appbackend_userId).then(({ value }) =>
      this.setState({ value: value.text })
    );
  }

  onChange = (newValue) => {
    this.setState({ value: newValue });
  };

  focusChanged = () => {
    const {
      params: { subdomain },
      setCloudCode,
      user: { suits, activeSuitId },
    } = this.props;
    const { value } = this.state;
    let SuitArr = suits.filter((suit) => suit.id === activeSuitId);
    const appbackend_userId = SuitArr[0].appbackend_userId;
    setCloudCode(subdomain, value, appbackend_userId).then(() => {
      Toast.success(I18n.t("ProjectSettingPage_cloudFunction_updateMsg"));
    });
  };

  render() {
    var {
      parse: { isPending, isPendingSave },
    } = this.props;

    const { value } = this.state;

    return (
      <div>
        {isPendingSave && <SpinLoader />}

        <DataBlock
          title="ProjectSettingPage_cloudFunction_title"
          style={{
            height: "83.2vh",
          }}
        >
          {isPending && (
            <div className={classes["loading-text-replacer-block"]}>
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
              <div className={classes["loading-text"]} />
            </div>
          )}
          {!isPending && (
            <div
              style={{
                height: "100%",
              }}
            >
              <AceEditor
                mode="javascript"
                value={value}
                theme="github"
                enableBasicAutocompletion
                enableLiveAutocompletion
                onChange={this.onChange}
                width="100%"
                height="100%"
                name="UNIQUE_ID_OF_DIV"
                editorProps={{ $blockScrolling: true }}
              />
              <PrimaryButton
                disabled={!value.length}
                onClick={() => {
                  this.focusChanged();
                }}
                value={I18n.t("saveBtn")}
                style={{ marginTop: "20px" }}
              />
            </div>
          )}
        </DataBlock>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    parse: store.parse,
    form: store.form,
    user: store.user,
  }),
  {
    getCloudCode,
    setCloudCode,
  }
)(CloudFunction);
