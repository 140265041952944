import React from "react";
import iosDevice from "static/ios-device.png";
import IOSFrame from "static/ios-frame2.png";
import classes from "./DevicePreview.scss";
import { LandingPagePreview, AppPushPreview } from "./components";

const DevicePreview = ({ type, data, analytics }) => {
  var renderedFrame;
  let cssClass = classes.containerf;
  if (analytics) {
    renderedFrame = IOSFrame;
    cssClass = classes.newContainer;
  } else renderedFrame = iosDevice;
  return (
    <div className={cssClass}>
      <img src={renderedFrame} className={classes.deviceImg} />
      {type === "MLP" && (
        <LandingPagePreview analytics={analytics} data={data} />
      )}
      {(type === "appPush" || type === "sms") && (
        <AppPushPreview type={type} data={data} />
      )}
    </div>
  );
};
DevicePreview.defaultProps = {
  type: "MLP",
};

export default DevicePreview;
