import PrimaryButton from "components/Form/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "components/Form/Buttons/SecondaryButton/SecondaryButton";
import $ from "jquery";
import _ from "lodash";
import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
import LanguageModal from "./LanguageModal";
import classes from "./LanguageSelector.scss";

export default class LanguageSelector extends Component {
  state = {
    show: false,
  };
  componentDidMount() {
    this.props.setLocalization({ code: "en", name: "English" });
  }
  closeLanguageModal = () => {
    $(".modal-content").slideUp(500, () => {
      this.setState({ show: false });
    });
  };
  render() {
    return (
      <div className={classes["lang-selector"]}>
        <LanguageModal
          languages={this.props.languages}
          closeLanguageModal={this.closeLanguageModal}
          onClickLanguage={this.props.onClickLanguage}
          show={this.state.show}
        />
        <div className={classes["lang-container"]}>
          <div className={classes["langs-btns-cont"]}>
            {_.sortBy(
              Object.values(this.props.languages),
              (lang) => !lang.important
            )
              .filter(({ selected }) => selected)
              .map((lang, index) => (
                <div key={index}>
                  {this.props.selectedLocalization.code === lang.code ? (
                    <PrimaryButton
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                      onClick={() =>
                        this.props.setLocalization({
                          code: lang.code,
                          name: lang.name,
                        })
                      }
                      value={lang.name}
                    />
                  ) : (
                    <SecondaryButton
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                      onClick={() =>
                        this.props.setLocalization({
                          code: lang.code,
                          name: lang.name,
                        })
                      }
                      value={lang.name}
                    />
                  )}
                </div>
              ))}
          </div>

          <div className={classes["add-lang-btn"]}>
            <PrimaryButton
              style={{
                marginRight: "10px",
                marginBottom: "5px",
                marginTop: "5px",
              }}
              onClick={() => {
                this.setState({ show: true });
              }}
              icon={() => <i className="fa fa-plus" aria-hidden="true" />}
              value={I18n.t("createAppPush_LangleSelector_Modal_title")}
            />
          </div>
        </div>
      </div>
    );
  }
}
