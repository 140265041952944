import ENDPOINTS from "consts/api";
import api from "modules/api";
import { userData } from "modules/user";

export default class NotifyLogs {
  static getAllNotifyLogs = (appId, page, start, end, type) => {
    const user = userData();

    return api(
      "get",
      ENDPOINTS.NOTIFY_LOGS.ALL_LOGS(appId, page, start, end, type)
    ).set("authToken", user.auth_token);
  };
}
