import LeadForm from "api/LeadForm";
import { RESET_ERRORS } from "reducers/user";

export const GET_LEADFORM = "GET_LEADFORM";

// / ------------------------------------
// Actions
// ------------------------------------

export function getLeadForm(suitId, apiKey, landingpageId) {
  return {
    type: GET_LEADFORM,
    payload: LeadForm.getLeadForm(suitId, apiKey, landingpageId),
  };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  result: [],
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function leadformReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_LEADFORM}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_LEADFORM}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case `${GET_LEADFORM}_FULFILLED`: //success
      return {
        ...state,
        isPending: false,
        result: action.payload,
      };

    default:
      return state;
  }
}
