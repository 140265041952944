import { reducer as notifications } from "react-notification-system-redux";
import appReducers from "reducers";
import { combineReducers } from "redux";

const RESET_STORE = "RESET_STORE";
const RESET_PARTIAL_STORE = "RESET_PARTIAL_STORE";

export const resetStore = () => ({
  type: RESET_STORE,
});

export const resetPartialStore = (keys) => ({
  type: RESET_PARTIAL_STORE,
  payload: keys, // An array of state keys to reset
});

export const makeRootReducer = (asyncReducers) => {
  // return combineReducers({
  //   // Add sync reducers here
  //   notifications,
  //   ...appReducers,
  //   ...asyncReducers,
  // });

  const appReducersCombined = {
    notifications,
    ...appReducers,
    ...asyncReducers,
  };

  const combinedReducer = combineReducers(appReducersCombined);

  const rootReducer = (state, action) => {
    if (action.type === RESET_STORE) {
      state = undefined;
    }

    if (action.type === RESET_PARTIAL_STORE) {
      const newState = { ...state };
      action.payload.forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (newState.hasOwnProperty(key)) {
          newState[key] = undefined;
        }
      });
      return combinedReducer(newState, action);
    }
    return combinedReducer(state, action);
  };

  return rootReducer;
};

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
