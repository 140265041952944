import { Col, Form, Row } from "components";
import { required } from "modules/validations";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig } from "reducers/config";
import { Field, reduxForm } from "redux-form";
import PrimaryButton from "../../../../../../components/Form/Buttons/PrimaryButton/PrimaryButton";
import TextBox from "../../../../../../components/Form/TextBox/TextBox";
import classes from "../SMSConfig.scss";

export class Msegat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { notify } = nextProps.config;
    if (
      notify &&
      notify.channels.sms.connection_info.type === "SMS-MSEGAT" &&
      !this.state.initialized
    ) {
      nextProps.initialize({
        type: "SMS-MSEGAT",
        UserName: notify.channels.sms.connection_info.UserName || undefined,
        SenderID: notify.channels.sms.connection_info.SenderID || undefined,
        apiKey: notify.channels.sms.connection_info.apiKey || undefined,
      });

      this.setState({ initialized: true });
    }
  }
  render() {
    const { valid, handleSubmit, disabled } = this.props;

    return (
      <Form>
        <div className={classes.contain}>
          <Row>
            <Col md={6}>
              <Field
                name="UserName"
                placeholder={I18n.t("PlatformsPage_userName_placeholder")}
                validate={required}
                label={I18n.t("PlatformsPage_userName_label")}
                component={TextBox}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                name="SenderID"
                placeholder="Enter Sender ID"
                validate={required}
                label="Sender ID"
                component={TextBox}
              />
            </Col>
            <Col md={6}>
              <Field
                name="apiKey"
                placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
                validate={required}
                label={I18n.t("apiKey")}
                component={TextBox}
              />
            </Col>
          </Row>

          <PrimaryButton
            onClick={handleSubmit}
            disabled={!valid}
            style={{ marginTop: "20px" }}
          >
            {I18n.t("saveChangesBtn")}
          </PrimaryButton>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "nconfig",
})(
  connect(
    (store) => ({
      user: store.user,
      config: store.config,
      form: store.form,
    }),
    {
      getNotifyConfig,
    }
  )(Msegat)
);
