import { CURRENCY, DATE_TYPE, NUMBER, STRING } from "consts/TableColumnTypes";

/**
 * Updates the checked property of all rows in the table data object to the specified value.
 * @param {Object} tableData - The table data object to update.
 * @param {boolean} value - The value to set the checked property to.
 * @returns {Object} - The updated table data object.
 */
export const checkAllRows = (tableData, value) => {
  const newRows = tableData.rows.map((row) => ({ ...row, checked: value }));
  const newTableData = { ...tableData, rows: newRows };
  return newTableData;
};

/**
 * Toggles the checked property of a row in a table data object.
 *
 * @param {Object} tableData - The table data object.
 * @param {number} rowIndex - The index of the row to toggle.
 * @returns {Object} - The updated table data object.
 */
export const checkTableRow = (tableData, rowIndex) => {
  const newRows = tableData.rows.map((row, index) =>
    index === rowIndex ? { ...row, checked: !row.checked } : row
  );

  const newTableData = { ...tableData, rows: newRows };
  return newTableData;
};

/**
 * Clears the actions menu for a specific row in a table.
 *
 * @param {Object} tableData - The data object representing the table.
 * @param {number} rowIndex - The index of the row to clear the actions menu for.
 * @returns {Object} - The updated table data object with the actions menu cleared for the specified row.
 */
export const clearAction = (tableData, rowIndex) => {
  const newRows = tableData.rows.map((row, index) =>
    index === rowIndex ? { ...row, actionsMenu: false } : row
  );
  const newTableData = { ...tableData, rows: newRows };
  return newTableData;
};

/**
 * Toggles the selected state of a label in a table's column.
 *
 * @param {Object} tableData - The data of the table.
 * @param {number} labelIndex - The index of the label to toggle.
 * @returns {Object} - The updated table data with the toggled label.
 */
export const onColumnsToggle = (tableData, labelIndex) => {
  const newLabels = tableData.labels.map((label, index) =>
    index === labelIndex ? { ...label, selected: !label.selected } : label
  );

  const newTableData = { ...tableData, labels: newLabels };
  return newTableData;
};

/**
 * Resets all columns in the table to be selected by default.
 * @param {Object} tableData - The data object representing the table.
 * @param {Array} tableData.labels - The array of label objects for the table.
 * @returns {Object} - The updated table data object with all columns selected.
 */
export const onResetsColumns = (tableData) => {
  const newLabels = tableData.labels.map((label) => ({
    ...label,
    selected: true,
  }));

  const newTableData = { ...tableData, labels: newLabels };
  return newTableData;
};

/**
 * Toggles the actions menu for a specific row in a table.
 *
 * @param {Object} tableData - The data object representing the table.
 * @param {number} rowIndex - The index of the row to toggle the actions menu for.
 * @returns {Object} - The updated table data object with the actions menu toggled for the specified row.
 */
export const toggleRowMenu = (tableData, rowIndex) => {
  const newRows = tableData.rows.map((row, index) =>
    index === rowIndex ? { ...row, actionsMenu: !row.actionsMenu } : row
  );
  const newTableData = { ...tableData, rows: newRows };
  return newTableData;
};

/**
 * Sorts the table data based on the given column name and type.
 * @param {Object} tableData - The data of the table to be sorted.
 * @param {string} name - The name of the column to sort by.
 * @param {string} type - The type of the column to sort by.
 * @returns {Object} - The sorted table data.
 */
export const sortTable = (tableData, name, type) => {
  const finalName = name.split("_1")[0];
  const descending = name.includes("_1");

  const indexOfKey = tableData.labels.findIndex(
    (label) => label.name === finalName
  );

  const sortFunctions = {
    [NUMBER]: (a, b) => a.values[indexOfKey] - b.values[indexOfKey],
    [STRING]: (a, b) =>
      a.values[indexOfKey].localeCompare(b.values[indexOfKey]),
    [CURRENCY]: (a, b) => {
      const aCurrencyValue = parseFloat(a.values[indexOfKey].split(" ")[0]);
      const bCurrencyValue = parseFloat(b.values[indexOfKey].split(" ")[0]);
      return aCurrencyValue - bCurrencyValue;
    },
    [DATE_TYPE]: (a, b) => {
      const aDate = new Date(a.values[indexOfKey]);
      const bDate = new Date(b.values[indexOfKey]);
      return aDate.getTime() - bDate.getTime();
    },
  };

  const sortFunction = sortFunctions[type];

  const newRows = [...tableData.rows].sort(sortFunction);

  if (descending) {
    newRows.reverse();
  }

  return { ...tableData, rows: newRows };
};
