const TOURS = "tours";
export const TOURS_NAMES = {
  DIGITAL_INVOICES: "digitalInvoices",
  PRODUCTS: "products",
  RETAIL_GAIN_USERS: "retailGainUsers",
  RETAIL_GAIN_SEGMENTS: "retailGainSegments",
  CONTACT_LIST: "contactList",
  WEB_PUSH_SUBSCRIBERS: "webPushSubscribers",
  SMART_LINKS: "smartLinks",
  MICRO_PAGES: "microPages",
  COUPONS: "coupons",
  POINTS: "points",
};

// tour schema
// {
//   tourName: {
//     finished: true,
//     },
//   },

const defaultTours = {};

export const getTours = () => {
  return localStorage.getItem(TOURS)
    ? JSON.parse(localStorage.getItem(TOURS))
    : defaultTours;
};

export const setTours = (tours) => {
  localStorage.setItem(TOURS, JSON.stringify(tours));
};

export const setTour = (tour) => {
  const tours = getTours();
  setTours({ ...tours, ...tour });
};

export const getTour = (tourName) => {
  const tours = getTours();
  return tours[tourName];
};

export const isTourFinished = (tourName) => {
  const tour = getTour(tourName);
  return tour && tour.finished;
};
