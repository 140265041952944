import { RESET_ERRORS } from "reducers/user";
import Config from "../api/Config";

// ------------------------------------
// Constants
// ------------------------------------

export const GET_NOTIFY_CONFIG = "GET_NOTIFY_CONFIG";
export const SET_NOTIFY_CONFIG = "SET_NOTIFY_CONFIG";
export const GET_NOTIFY_LOGS = "GET_NOTIFY_LOGS";
export const SEND_PUSH = "SEND_PUSH";
export const DELETE_USER = "DELETE_PUSH";

// ------------------------------------
// Actions
// ------------------------------------

export function getNotifyConfig(appId) {
  return {
    type: GET_NOTIFY_CONFIG,
    payload: Config.getNotifyConfig(appId),
  };
}

export function setNotifyConfig(appId, data) {
  return {
    type: SET_NOTIFY_CONFIG,
    payload: Config.setNotifyConfig(appId, data),
  };
}

export function sendPush(data) {
  return {
    type: SEND_PUSH,
    payload: Config.sendPush(data),
  };
}

export function sendDirectPush(appId, data) {
  return {
    type: SEND_PUSH,
    payload: Config.sendDirectPush(appId, data),
  };
}

export function deleteUser(data) {
  return {
    type: DELETE_USER,
    payload: Config.deleteUser(data),
  };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  notify: null,
  logs: {
    all: null,
    push: null,
  },
  isPending: false,
  error: false,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function configReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_NOTIFY_CONFIG}_PENDING`:
    case `${SET_NOTIFY_CONFIG}_PENDING`:
    case `${GET_NOTIFY_LOGS}_PENDING`:
    case `${SEND_PUSH}_PENDING`:
    case `${DELETE_USER}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_NOTIFY_CONFIG}_REJECTED`:
    case `${SET_NOTIFY_CONFIG}_REJECTED`:
    case `${GET_NOTIFY_LOGS}_REJECTED`:
    case `${SEND_PUSH}_REJECTED`:
    case `${DELETE_USER}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case `${GET_NOTIFY_CONFIG}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        notify: action.payload.body,
      };
    case `${SET_NOTIFY_CONFIG}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case `${GET_NOTIFY_LOGS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        logs: {
          all: action.payload.body.logs,
          push: action.payload.body.results,
        },
      };
    case `${SEND_PUSH}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case `${DELETE_USER}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
}
