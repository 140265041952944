import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import classes from "./wizard.scss";

const WizardStep = ({
  active,
  complete,
  disabled,
  className,
  icon,
  successIcon,
  onClick,
  children,
}) => {
  const stepClass = classNames(
    {
      [classes["wizard-step--active"]]: active,
      [classes["wizard-step--complete"]]: complete,
      [classes["wizard-step--disabled"]]: disabled,
    },
    classes["wizard-step"],
    className
  );

  const handleClick = (event) => {
    event.preventDefault();
    if (!disabled) {
      onClick();
    }
  };

  return (
    <a href="#" className={stepClass} onClick={handleClick}>
      <div className={classes["wizard-step__icon"]}>
        {!complete ? icon : successIcon}
      </div>
      <div className={classes["wizard-step__content"]}>{children}</div>
    </a>
  );
};

WizardStep.defaultProps = {
  successIcon: <i className="fa fa-check fa-fw" />,
  onClick: () => {},
};

export default WizardStep;

WizardStep.propTypes = {
  active: PropTypes.bool,
  complete: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.node,
  successIcon: PropTypes.node,
  onClick: PropTypes.func,
  children: PropTypes.node,
};
