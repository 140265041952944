import React, { Component } from "react";
import { IoMdCheckmark } from "react-icons/io";
import classes from "./CampaignWizard.scss";
export default class CampaignWizard extends Component {
  renderRadioBox = (checked) => {
    return (
      <div className={`${checked ? classes["active-radio"] : ""}`}>
        {checked && <IoMdCheckmark size={22} color="#fff" />}
      </div>
    );
  };

  render() {
    return (
      <div className={classes["camapign-wizard"]}>
        <div className={classes["camapign-wizard-steps"]}>
          {this.props.steps.map((p, idx) => (
            <div
              key={idx}
              onClick={() => this.props.changeCurrentStep(p.value)}
              className={classes["wizard-step"]}
              style={{
                minWidth: p.width + "%",
                textAlign: "center",
              }}
            >
              {this.renderRadioBox(idx < this.props.currentStep)}
              <p>{p.label}</p>
            </div>
          ))}
        </div>
        {this.props.children}
      </div>
    );
  }
}
