import axios from "axios";
import { config } from "../../consts/api";

const { NOTIFY_HOST } = config;

export const getBusinessAccount = (accessToken) => {
  return axios.get(`${NOTIFY_HOST}/business_id?access_token=${accessToken}`);
};

export const generateLongAccessToken = (accessToken) => {
  return axios.get(`${NOTIFY_HOST}/long_token?access_token=${accessToken}`);
};

export const getAdAccount = (accessToken, businessId) => {
  return axios.get(
    `${NOTIFY_HOST}/get_business?access_token=${accessToken}&business_id=${businessId}`
  );
};

export const getCustomAudiences = (accessToken, adAccountId) => {
  return axios.get(
    `${NOTIFY_HOST}/get_id?access_token=${accessToken}&ad_account_id=${adAccountId}&payload_url_type=HASHES_OR_USER_IDS`
  );
};

export const createCustomAudiences = (
  accessToken,
  name,
  description,
  adAccountId
) => {
  return axios.post(
    `${NOTIFY_HOST}/create?access_token=${accessToken}&name=${name}&description=${description}&ad_account_id=${adAccountId}`
  );
};
