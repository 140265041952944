import api from "modules/api";
import EP from "consts/api";
import { config } from "consts/api";

export default class LastOrder {
  static getLastOrder = (subdomain, suitId, churn_days, apiKey) =>
    api("post", EP.LASTORDER.Days(subdomain, suitId, churn_days, apiKey))
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`)
      .set("Content-Type", "application/json");
}
