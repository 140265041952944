import EP from "consts/api";
import api from "modules/api";

export default class Facebook {
  static getBusinessAccount = (accessToken) =>
    api("get", EP.FACEBOOK.getBusinessAccount(accessToken));

  static getAdAccount = (accessToken, businessId) =>
    api("get", EP.FACEBOOK.getAdAccount(accessToken, businessId));

  static getCustomAudiences = (accessToken, adAccountId) =>
    api("get", EP.FACEBOOK.getCustomAudiences(accessToken, adAccountId));

  static generateLongAccessToken = (accessToken) =>
    api("get", EP.FACEBOOK.generateLongAccessToken(accessToken));

  static createCustomAudiences = (
    accessToken,
    adAccountId,
    description,
    name
  ) =>
    api(
      "post",
      EP.FACEBOOK.createCustomAudiences(
        accessToken,
        adAccountId,
        description,
        name
      )
    );

  static syncSegment = (
    accessToken,
    suitId,
    customAudienceId,
    segmentId,
    appApiKey
  ) =>
    api(
      "post",
      EP.FACEBOOK.syncSegment(accessToken, suitId, customAudienceId, segmentId)
    ).set({ appApiKey });
}
