import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import classes from "./CustomSelectorReduxForm.module.scss";

const CustomSelectorReduxForm = ({
  input,
  options,
  meta: { touched, error, warning, initial },
  onChange,
  shadow,
  label,
  style,
  ...rest
}) => {
  return (
    <div className={rest.className} style={style}>
      {label && <label className={classes.label}>{label}</label>}
      <Select
        styles={{
          control: (baseStyles, state) => {
            return state.isDisabled
              ? {
                  ...baseStyles,
                  opacity: "0.3",
                  height: "48px",
                  border: "1px solid #B9B9C7",
                }
              : {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#024da13f" : "#B9B9C7",
                  ...(rest.height
                    ? { height: rest.height }
                    : { height: "48px" }),
                  border: "1px solid #B9B9C7",
                  backgroundColor: "#FFF",
                  borderRadius: "4px",
                  boxShadow: shadow || "none",
                  "&:focus, &:focus-within, &:focus:active": {
                    border: "1px solid #024da13f",
                    backgroundColor: "#024DA119",
                  },
                  "&:hover": {
                    border: "1px solid #024da13f",
                  },
                  transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1)",
                };
          },
          input: (baseStyles) => {
            return {
              ...baseStyles,
              fontFamily: "Lato, sans-serif",
              fontWeight: "normal",
              fontSize: "16px",
              color: "#1F2F3F",
              transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1)",
            };
          },
          singleValue: (baseStyles) => {
            return {
              ...baseStyles,
              fontFamily: "Lato, sans-serif",
              fontWeight: "normal",
              fontSize: "16px",
              color: "#1F2F3F",
              "&:focus, &:active, &:focus-withing": {
                color: "#024da13f",
              },
            };
          },
          menuList: (baseStyles) => {
            return {
              ...baseStyles,
              fontFamily: "Lato, sans-serif",
              fontWeight: "500",
              fontSize: "16px",
            };
          },
        }}
        className="react-select-container"
        classNamePrefix="react-select"
        options={options}
        onChange={(value) => {
          input.onChange(value);
          onChange && onChange(value); // Call the onChange prop if it exists
        }}
        onBlur={() => input.onBlur(input.value)}
        defaultValue={initial}
        value={input?.value}
        placeholder={rest.placeholder}
        menuPlacement="auto" // Set the menuPlacement prop to 'auto'
        isLoading={rest?.loading}
        isDisabled={rest?.disabled}
      />
      {touched &&
        ((error && <span className={classes.error}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );
};

export default CustomSelectorReduxForm;

CustomSelectorReduxForm.propTypes = {
  input: PropTypes.object,
  options: PropTypes.array,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  shadow: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
};
