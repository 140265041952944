import { Label } from "@/components";
import { Modal, Upload } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { config } from "../../../../consts/api";
import { getBase64 } from "../../../../modules/helpers";

class UploadImage extends Component {
  state = {
    fileList: [],
    showPreviewImageModal: false,
    previewImage: "",
  };

  customRequest = async (data) => {
    const { suit } = this.props;
    const { file, onSuccess, onError, onProgress } = data;

    const formData = new FormData();
    formData.append("photo", file);

    try {
      onProgress({ percent: 0 });
      const response = await axios.post(
        `${config.API_HOST}/${suit?.id}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            appApiKey: suit?.api_key,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({ percent: percentCompleted });
          },
        }
      );
      onSuccess({
        url: `https://${response.data}`,
        name: file.name,
        status: "done",
        thumbUrl: `https://${response.data}`,
      });
    } catch (e) {
      console.error("error while uploading image " + file.name, e);
      onError(e);
    }
  };

  handleImageUploadChange = (info) => {
    const { onChange } = this.props;

    if (info.file.status === "removed") return;

    const uploadedFile = info.file;

    const fileList = [uploadedFile];

    this.setState({ fileList });

    onChange(fileList);
  };

  handleImageRemove = () => {
    const { onChange } = this.props;
    this.setState({ fileList: [] });

    onChange([]);
  };

  handlePreview = async (file) => {
    const imageString = await getBase64(file.originFileObj);

    this.setState({ previewImage: imageString, showPreviewImageModal: true });
  };

  render() {
    const { style, label, children } = this.props;

    return (
      <div style={style}>
        {label && <Label>{label}</Label>}
        <Upload
          multiple={false}
          onChange={this.handleImageUploadChange}
          fileList={this.state.fileList}
          customRequest={this.customRequest}
          listType="picture"
          onRemove={this.handleImageRemove}
          onPreview={this.handlePreview}
        >
          {children}
        </Upload>

        <Modal
          visible={this.state.showPreviewImageModal}
          footer={null}
          onCancel={() => this.setState({ showPreviewImageModal: false })}
          closable={false}
          centered
        >
          <img
            src={this.state.previewImage}
            alt="preview"
            style={{
              width: "100%",
            }}
          />
        </Modal>
      </div>
    );
  }
}

export default UploadImage;

UploadImage.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  suit: PropTypes.object.isRequired,
  label: PropTypes.string,
  children: PropTypes.node,
};
