import { DataBlock, Nav, NavItem } from "components";
import React, { PureComponent } from "react";
import { I18n } from "react-redux-i18n";
import { getAppBackendConfig } from "reducers/user";
import { connect } from "routes/routedComponent";
import WebPushSettings from "../../../NewSettings/WebPushSettings";
import EmailConfig from "../../../Push/Config/Email";
import SMSConfig from "../../../Push/Config/SMS";
import WhatsappConfig from "../../../Push/Config/Whatsapp";
import Android from "./Android";
import Ios from "./Ios";
import classes from "./Platforms.scss";

const ANDROID = "android";
const SMS = "sms";
const IOS = "ios";
const EMAIL = "email";
const WHATSAPP = "whatsapp";
const WEB_PUSH = "webPush";

class CommunicationChannels extends PureComponent {
  constructor() {
    super();
    this.state = {
      componentToRender: ANDROID,
      projectType: null,
    };
  }

  async componentDidMount() {
    const subdomain = window.location.pathname.split("/")[2];
    const {
      getAppBackendConfig,
      user: { activeSuitId, suits, appbackend },
    } = this.props;

    const currentSuit = suits.find((suit) => suit.id === activeSuitId);

    this.setState({ projectType: currentSuit.type });

    if (!appbackend && currentSuit.type === "mobile_app") {
      await getAppBackendConfig(subdomain, currentSuit.appbackend_userId);
    }

    if (currentSuit.type !== "mobile_app") {
      this.setState({ componentToRender: SMS });
    }
  }

  handleClick = (componentName) => {
    this.setState({ componentToRender: componentName });
  };

  renderNavigationItems = () => {
    const { componentToRender, projectType } = this.state;
    const navItems = [
      {
        name: ANDROID,
        condition: projectType !== "mobile_marketing",
        label: "AndroidPush",
      },
      {
        name: IOS,
        condition: projectType !== "mobile_marketing",
        label: "IOSPush",
      },
      { name: WEB_PUSH, condition: true, label: "WebPush" },
      { name: SMS, condition: true, label: "SMS" },
      { name: EMAIL, condition: true, label: "Email" },
      { name: WHATSAPP, condition: true, label: "Whatsapp" },
    ];

    return navItems.map(
      ({ name, condition, label }) =>
        condition && (
          <NavItem
            key={name}
            className={componentToRender === name ? classes.val : classes.val2}
            onClick={() => this.handleClick(name)}
          >
            {I18n.t(label)}
          </NavItem>
        )
    );
  };

  renderComponent = () => {
    const { componentToRender } = this.state;

    switch (componentToRender) {
      case ANDROID:
        return <Android />;
      case IOS:
        return <Ios />;
      case SMS:
        return <SMSConfig />;
      case EMAIL:
        return <EmailConfig />;
      case WHATSAPP:
        return <WhatsappConfig />;
      case WEB_PUSH:
        return <WebPushSettings />;
      default:
        return null;
    }
  };

  render() {
    return (
      <div>
        <DataBlock title="CommunicationChannels" titleClass="bg-grey-lightest">
          <div className={classes.navigationbar}>
            <Nav className={classes.nav}>{this.renderNavigationItems()}</Nav>
          </div>
          {this.renderComponent()}
        </DataBlock>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    getAppBackendConfig,
  }
)(CommunicationChannels);
