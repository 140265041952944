import api from "modules/api";
import ENDPOINTS, { config } from "../consts/api";

export default class Products {
  static getAllProducts = (subdomain, page, pageSize) => {
    return api("post", ENDPOINTS.PRODUCTS.getProducts(), {
      subdomain,
      page,
      pageSize,
    })
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);
  };

  static getProduct = (subdomain, objectId) => {
    return api("post", ENDPOINTS.PRODUCTS.getProduct(), {
      subdomain,
      objectId,
    })
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);
  };

  static deleteProduct = (subdomain, objectId) => {
    return api("delete", ENDPOINTS.PRODUCTS.deleteProduct(subdomain, objectId))
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);
  };

  static deleteProductsBulk = (data) => {
    return api("post", ENDPOINTS.PRODUCTS.deleteProductsBulk(), data).set(
      "x-parse-application-id",
      `${config.applicationID}`
    );
  };

  static addProducts = (data) => {
    return api("post", ENDPOINTS.PRODUCTS.addProducts(), data).set(
      "x-parse-application-id",
      `${config.applicationID}`
    );
  };

  static editProduct = (subdomain, objectId, data) => {
    return api("put", ENDPOINTS.PRODUCTS.editProduct(subdomain, objectId), data)
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);
  };

  static updateProduct = (subdomain, product) => {
    return api("post", ENDPOINTS.PRODUCTS.updateProduct(), {
      subdomain,
      product,
    })
      .set("x-parse-application-id", `${config.applicationID}`)
      .set("x-parse-master-key", `${config.Masterkey}`);
  };

  static searchProducts = (subdomain, search, page, pageSize) => {
    return api("post", ENDPOINTS.PRODUCTS.searchProducts(), {
      subdomain,
      search,
      page,
      pageSize,
    }).set("x-parse-application-id", `${config.applicationID}`);
  };

  static exportProducts = (subdomain, email, suitId, apiKey) => {
    return api("post", ENDPOINTS.PRODUCTS.exportProducts(), {
      subdomain,
      email,
      suitId,
      apiKey,
    }).set("x-parse-application-id", `${config.applicationID}`);
  };
}
