import { CustomSelector, SpinLoader } from "components";
import PrimaryButton from "components/Form/Buttons/PrimaryButton/PrimaryButton";
import PropTypes from "prop-types";
import React from "react";
import FacebookLogin from "react-facebook-login";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  getWhatsAppBussinessAccountIds,
  getWhatsAppPhoneNumbers,
} from "reducers/WhatsApp";
import { getNotifyConfig, setNotifyConfig } from "reducers/config";
import { updateSuit } from "reducers/user";
import {
  generateLongAccessToken,
  getBusinessAccount,
} from "../../../../../../components/AdAudience/AdAudiencelogic";
import classes from "../WhatsappConfig.scss";

class MetaWhatsApp extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedOption: null,

      accessToken: null,

      displayFacebookLogin: false,

      FBBusinessAccountOptions: [],
      FBBusinessAccount: { label: "", value: "" },
      FBBusinessAccountLoading: false,

      whatsAppBusinessAccountIdsOptions: [],
      whatsAppBusinessAccountIdsLoading: false,
      whatsAppBusinessAccountId: { label: "", value: "" },

      senderIdOptions: [],
      senderIdOptionsLoading: false,
      senderId: { label: "", value: "" },
    };

    const { activeSuitId, suit, suits } = this.props.user;

    this.currentSuit = suit || suits.find((suit) => suit.id === activeSuitId);
  }

  async componentDidMount() {
    const { showNotification } = this.props;

    if (this.currentSuit.FBSessionToken) {
      this.setState({ accessToken: this.currentSuit.FBSessionToken });
      await this.fetchFBBusinessAccounts(this.currentSuit.FBSessionToken);
    } else {
      this.setState({ accessToken: null, displayFacebookLogin: true });
      showNotification(
        {
          title: "",
          message: "Please login with facebook first",
          position: "bc",
          autoDismiss: 5,
        },
        "error"
      );
    }
  }

  fetchFBBusinessAccounts = async (accessToken) => {
    const {
      showNotification,
      user: { activeSuitId },
    } = this.props;

    try {
      this.setState({ FBBusinessAccountLoading: true });
      const response = await getBusinessAccount(accessToken);
      const FBBusinessAccountOptions = response.data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      this.setState({
        FBBusinessAccountOptions,
      });
    } catch (error) {
      console.error(error);
      showNotification(
        {
          title: "",
          message:
            "Something went wrong while fetching FB Business Accounts, Please login with facebook again",
          position: "bc",
          autoDismiss: 5,
        },
        "error"
      );
      await updateSuit(activeSuitId, {
        suit_name: this.currentSuit.name,
        FBSessionToken: null,
      });
      this.setState({ accessToken: null, displayFacebookLogin: true });
    } finally {
      this.setState({ FBBusinessAccountLoading: false });
    }
  };

  fetchWhatsAppBusinessAccounts = async (businessId) => {
    const { showNotification, getWhatsAppBussinessAccountIds } = this.props;

    try {
      this.setState({ whatsAppBussinessAccountIdsLoading: true });
      const response = await getWhatsAppBussinessAccountIds(
        this.currentSuit.id,
        businessId
      );

      const whatsAppBusinessAccountIdsOptions = response.value.body.waba.map(
        (item) => ({
          label: item.name,
          value: item.id,
        })
      );

      this.setState({
        whatsAppBusinessAccountIdsOptions,
      });
    } catch (error) {
      console.error(error);
      showNotification(
        {
          title: "",
          message:
            "Something went wrong while fetching whats app business accounts, Please login with facebook account with whats app business account",
          position: "bc",
          autoDismiss: 5,
        },
        "error"
      );
    } finally {
      this.setState({ whatsAppBusinessAccountIdsLoading: false });
    }
  };

  fetchWhatsAppPhoneNumbers = async (whatsAppBusinessAccountId) => {
    const { showNotification, getWhatsAppPhoneNumbers } = this.props;

    try {
      this.setState({ senderIdOptionsLoading: true });
      const response = await getWhatsAppPhoneNumbers(
        this.currentSuit.id,
        whatsAppBusinessAccountId
      );

      const senderIdOptions = response.value.body.numbers.map((item) => ({
        label: item.display_phone_number,
        value: item.id,
      }));

      this.setState({
        senderIdOptions,
      });
    } catch (error) {
      console.error(error);
      showNotification(
        {
          title: "",
          message:
            "Something went wrong while fetching whats app phone numbers, Please login with facebook account with whats app business account",
          position: "bc",
          autoDismiss: 5,
        },
        "error"
      );
    } finally {
      this.setState({ senderIdOptionsLoading: false });
    }
  };

  handleFBBusinessAccountChange = (newValue) => {
    this.setState({
      FBBusinessAccount: newValue,
      whatsAppBusinessAccountId: { label: "", value: "" },
      senderId: { label: "", value: "" },
    });
    this.fetchWhatsAppBusinessAccounts(newValue.value);
  };

  handleSelectedOptionChange = (newValue) => {
    this.setState({ selectedOption: newValue });
  };

  handleSenderIdChange = (newValue) => {
    this.setState({ senderId: newValue });
  };

  handleWhatsAppBusinessAccountIdChange = (newValue) => {
    this.setState({
      whatsAppBusinessAccountId: newValue,
      senderId: { label: "", value: "" },
    });
    this.fetchWhatsAppPhoneNumbers(newValue.value);
  };

  handleWhatsAppFormSubmit = async (e) => {
    e.preventDefault();

    const {
      setNotifyConfig,
      vendor,
      user: { activeSuitId },
      showNotification,
    } = this.props;

    const { selectedOption, whatsAppBusinessAccountId } = this.state;

    if (!selectedOption) {
      return showNotification(
        {
          title: "",
          message: I18n.t(
            "PlatformsPage_nav_5_whatsappOption_Meta_senderPhoneMsg"
          ),
          position: "bc",
          autoDismiss: 5,
        },
        "error"
      );
    }

    const whatsAppSenderId = selectedOption.value;

    // save the config
    const connectionInfo = {
      waba_id: whatsAppBusinessAccountId,
      sender_id: whatsAppSenderId,
      type: vendor.toLowerCase(),
    };

    const notifyConfigData = {
      channels: {
        whatsapp: {
          connection_info: connectionInfo,
          vendor: vendor.toLowerCase(),
        },
      },
    };

    await setNotifyConfig(activeSuitId, notifyConfigData);
    showNotification(
      {
        title: "",
        message: I18n.t("PlatformsPage_nav_5_whatsappOption_updateMsg"),
        position: "bc",
        autoDismiss: 5,
      },
      "success"
    );
    await getNotifyConfig(activeSuitId);
  };

  responseFacebook = async (response) => {
    const {
      updateSuit,
      user: { activeSuitId },
      showNotification,
    } = this.props;

    const shortToken = response.accessToken;

    try {
      const longTokenResponse = await generateLongAccessToken(shortToken);

      const longToken = longTokenResponse.data.access_token;

      await updateSuit(activeSuitId, {
        suit_name: this.currentSuit.name,
        FBSessionToken: longToken,
      });

      showNotification(
        {
          title: "",
          message: I18n.t(
            "ProjectSettingPage_externalIntegrations_FBLinkedMsg"
          ),
          position: "bc",
          autoDismiss: 5,
        },
        "success"
      );
      this.setState({ displayFacebookLogin: false, accessToken: longToken });
    } catch (error) {
      console.error(error);
      showNotification(
        {
          title: "",
          message: I18n.t("ProjectSettingPage_externalIntegrations_ErrMsg"),
          position: "bc",
          autoDismiss: 5,
        },
        "error"
      );
    }
  };

  saveChanges = async () => {
    const {
      setNotifyConfig,
      user: { activeSuitId },
      showNotification,
    } = this.props;

    const { whatsAppBusinessAccountId, senderId, FBBusinessAccount } =
      this.state;

    // save the config
    const connectionInfo = {
      business_id: FBBusinessAccount.value,
      waba_id: whatsAppBusinessAccountId.value,
      sender_id: senderId.value,
      type: "meta",
    };

    const notifyConfigData = {
      channels: {
        whatsapp: {
          connection_info: connectionInfo,
          vendor: "meta",
        },
      },
    };

    await setNotifyConfig(activeSuitId, notifyConfigData);
    showNotification(
      {
        title: "",
        message: I18n.t("PlatformsPage_nav_5_whatsappOption_updateMsg"),
        position: "bc",
        autoDismiss: 5,
      },
      "success"
    );
  };

  render() {
    const {
      config: { isPending: configPending },
    } = this.props;

    const {
      displayFacebookLogin,
      FBBusinessAccountOptions,
      FBBusinessAccount,
      FBBusinessAccountLoading,
      whatsAppBusinessAccountIdsOptions,
      whatsAppBusinessAccountIdsLoading,
      whatsAppBusinessAccountId,
      senderIdOptions,
      senderIdOptionsLoading,
      senderId,
    } = this.state;

    const saveButtonDisabled =
      configPending ||
      !FBBusinessAccount.value ||
      !whatsAppBusinessAccountId.value ||
      !senderId.value;

    const whatsAppBusinessIdDisabled = !FBBusinessAccount.value;

    const senderIdDisabled = !whatsAppBusinessAccountId.value;

    return (
      <form onSubmit={this.handleWhatsAppFormSubmit}>
        {configPending && <SpinLoader />}

        {displayFacebookLogin && (
          <FacebookLogin
            appId="2242381319117291"
            callback={this.responseFacebook}
            cssClass={classes.facebookConnectBtn}
            scope="ads_management,ads_read,business_management,public_profile"
          />
        )}

        {!displayFacebookLogin && (
          <div>
            <div className={classes.FieldsGridContainer}>
              <div className={classes.fieldContainer}>
                <label htmlFor="FBBusinessId">Select FB Business Account</label>
                <CustomSelector
                  options={FBBusinessAccountOptions}
                  value={FBBusinessAccount}
                  onChange={this.handleFBBusinessAccountChange}
                  loading={FBBusinessAccountLoading}
                  id="FBBusinessId"
                />
              </div>

              <div className={classes.fieldContainer}>
                <label htmlFor="whatsAppBusinessId">
                  Select Whats App Business ID
                </label>
                <CustomSelector
                  options={whatsAppBusinessAccountIdsOptions}
                  value={whatsAppBusinessAccountId}
                  onChange={this.handleWhatsAppBusinessAccountIdChange}
                  loading={whatsAppBusinessAccountIdsLoading}
                  id="whatsAppBusinessId"
                  disabled={whatsAppBusinessIdDisabled}
                  disabledMessage="select FB Business Account first"
                />
              </div>

              <div className={classes.fieldContainer}>
                <label htmlFor="senderId">Select Whats App Sender Number</label>
                <CustomSelector
                  options={senderIdOptions}
                  value={senderId}
                  onChange={this.handleSenderIdChange}
                  loading={senderIdOptionsLoading}
                  id="senderId"
                  disabled={senderIdDisabled}
                  disabledMessage="select Whats App Business ID first"
                />
              </div>
            </div>
            <PrimaryButton
              disabled={saveButtonDisabled}
              value="saveChangesBtn"
              type="submit"
              onClick={this.saveChanges}
            />
          </div>
        )}
      </form>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
    config: store.config,
    WhatsApp: store.WhatsApp,
  }),
  {
    getNotifyConfig,
    getWhatsAppPhoneNumbers,
    setNotifyConfig,
    showNotification: Notifications.show,
    updateSuit,
    getWhatsAppBussinessAccountIds,
  }
)(MetaWhatsApp);

MetaWhatsApp.propTypes = {
  user: PropTypes.object,
  config: PropTypes.object,
  WhatsApp: PropTypes.object,
  getNotifyConfig: PropTypes.func,
  getWhatsAppPhoneNumbers: PropTypes.func,
  setNotifyConfig: PropTypes.func,
  showNotification: PropTypes.func,
  updateSuit: PropTypes.func,
  getWhatsAppBussinessAccountIds: PropTypes.func,
};
