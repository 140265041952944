const colors = {
  grayBase: "#000",
  grayDarker: "#262626",
  grayDark: "#2d2d2d",
  gray: "#383838",
  grayLight: "#444444",
  grayLighter: "#5c5c5c",

  brandWhite: "white",

  brandPrimary: "#2c97de",
  brandSuccess: "#84b547",
  brandInfo: "#2dbda8",
  brandWarning: "#e76d3b",
  brandDanger: "#cc3e4a",

  brandCerulean: "#00A3E3",
  brandCuriousBlue: "#2986c7",
  brandEndaveour: "#0055a3",
  brandMinsk: "#362d88",
  brandEminence: "#742787",
  brandVioletEggplant: "#aa1985",
  brandMintGreen: "#72ff96",
  brandAquamarine: "#62ffe0",
  brandMalibu: "#6abfff",
  brandDodgerBlue: "#5e6cff",
  brandHeliotrope: "#a26bff",
  brandPerfume: "#f57ffe",

  brandSwamp: "#222D33",
  brandCaper: "#AECA89",
  brandChelseaCucumber: "#88B152",
  brandGreenDarker: "#2b2f26",
  brandGreen: "#57ba47",

  agio: {
    blueDark: "#003874",
    blue: "#034694",
    bluePale: "#1a4c82",
    bluePaleLight: "#b9ddf0",
    blueActive: "#1a70fb",
    blueLight: "#1797e1",
    greenBlue: "#33bcad",
    pink: "#ed1b52",
    blueGreyDark: "#758699",
    greyDarkest: "#353535",
    greySemiLight: "#e7e7e8",
    greyLighter: "#ebebec",
    greyMoreLighter: "#f1f2f2",
    greyLightest: "#f6f6f6",
    white: "#fff",
  },
};

export default colors;
