import "antd/dist/antd.css";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import classes from "./CustomSelector.module.scss";

const CustomSelector = ({
  value,
  onChange,
  options,
  className,
  required,
  height = "48px",
  loading,
  dataAttribute,
  name,
  disabled,
  disabledMessage,
  placeholder,
  shadow,
  label,
  style,
  isClearable,
  ...rest
}) => {
  const handleChange = (selectedOption) =>
    onChange(selectedOption, dataAttribute, name);

  return (
    <div className={`${className} ${classes.selectContainer}`} style={style}>
      {label && <label className={classes.label}>{label}</label>}
      <Select
        styles={{
          control: (baseStyles, state) => {
            return state.isDisabled
              ? {
                  ...baseStyles,
                  opacity: "0.3",
                  height,
                  border: "1px solid #B9B9C7",
                }
              : {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#024da13f" : "#B9B9C7",
                  height,
                  border: "1px solid #B9B9C7",
                  backgroundColor: "#FFF",
                  borderRadius: "4px",
                  boxShadow: shadow || "none",
                  "&:focus, &:focus-within, &:focus:active": {
                    border: "1px solid #024da13f",
                    backgroundColor: "#024DA119",
                  },
                  "&:hover": {
                    border: "1px solid #024da13f",
                  },
                  transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1)",
                };
          },
          input: (baseStyles) => {
            return {
              ...baseStyles,
              fontFamily: "Lato, sans-serif",
              fontWeight: "normal",
              fontSize: "16px",
              color: "#1F2F3F",
              transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1)",
            };
          },
          singleValue: (baseStyles) => {
            return {
              ...baseStyles,
              fontFamily: "Lato, sans-serif",
              fontWeight: "normal",
              fontSize: "16px",
              color: "#1F2F3F",
              "&:focus, &:active, &:focus-withing": {
                color: "#024da13f",
              },
            };
          },
          menuList: (baseStyles) => {
            return {
              ...baseStyles,
              fontFamily: "Lato, sans-serif",
              fontWeight: "500",
              fontSize: "16px",
            };
          },
        }}
        className="react-select-container"
        classNamePrefix="react-select"
        options={options}
        onChange={handleChange}
        value={value}
        isClearable={isClearable || false}
        required={required}
        menuPlacement="auto" // Set the menuPlacement prop to 'auto'
        isLoading={loading}
        isDisabled={disabled}
        data-my-attribute={dataAttribute}
        placeholder={placeholder}
        data-testid="custom-selector"
        {...rest}
      />
      {disabled && (
        <span className={classes.disabledMessage}>{disabledMessage}</span>
      )}
    </div>
  );
};

CustomSelector.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  height: PropTypes.string,
  loading: PropTypes.bool,
  dataAttribute: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  placeholder: PropTypes.string,
  shadow: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  isClearable: PropTypes.bool,
};

export default CustomSelector;
