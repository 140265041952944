import {
  CustomCheckboxReduxForm,
  CustomSelector,
  PrimaryButton,
  SecondaryButton,
} from "@/components";
import { Button } from "antd";
import { CustomSelectorReduxForm, EmojiPicker } from "components";
import TextArea from "components/Form/TextArea/TextArea";
import TextBox from "components/Form/TextBox/TextBox";
import { maxLength, minLength, required, validUrl } from "modules/validations";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  Field,
  change as ReduxChange,
  getFormValues,
  initialize,
  reduxForm,
} from "redux-form";
import { v4 as uuidv4 } from "uuid";
import { Label } from "../../../../../components";
import { addNumberSuffix } from "../../../../../modules/helpers";
import { extractVariablesFromTemplate } from "../../../../../modules/template";
import {
  URL_TYPES,
  messageDirectionOptions,
  urlOptions,
} from "../CreateWebPush";
import classes from "../CreateWebPush.scss";
import UploadImage from "../UploadImage";

const MAX_BUTTONS = 2;

const maxLength160 = maxLength(160);
const minLength5 = minLength(5);
const maxLength30 = maxLength(30);

class PushMessageStep extends React.Component {
  state = {
    actionButtons: [],
  };

  componentDidMount() {
    // check if there are buttons in the redux form
    const buttons = this.props.myFormValues?.action?.webPush?.actions;

    if (buttons?.length) {
      buttons.forEach(() => {
        this.handleAddButtonClick();
      });
    }
  }

  handleUploadImageChange = (fileList) => {
    if (fileList.length > 0) {
      this.props.handleChange(
        "action.webPush.image",
        fileList[0]?.response?.url
      );
    } else {
      this.props.handleChange("action.webPush.image", null);
    }
  };

  handleAddButtonClick = () => {
    // max 2 buttons
    if (this.state.actionButtons.length >= MAX_BUTTONS) return;

    const newButton = {
      id: uuidv4(),
      urlType: {
        value: URL_TYPES.URL,
        label: I18n.t("URL"),
      },
    };

    this.setState({
      actionButtons: [...this.state.actionButtons, newButton],
    });
  };

  removeButton = (id, index) => {
    const newButtons = this.state.actionButtons.filter(
      (button) => button.id !== id
    );

    this.setState({
      actionButtons: newButtons,
    });

    if (newButtons?.length === 0) {
      ReduxChange("pushMessageStep", `action.webPush.actions`, "");
    } else {
      // remove button from redux form
      ReduxChange("pushMessageStep", `action.webPush.actions[${index}]`, "");
    }
  };

  render() {
    const {
      handleChange,
      urlType,
      handleSelectUrl,
      smartLinks: { smartlinks },
      MicroPages: { landingpages: microPages },
      TemplatesObjects,
      handleURLValueChange,
      selectedTemplate,
      urlValue,
      ReduxChange,
      handleTemplateSelect,
      user: { activeSuitId, suits, suit },
      myFormValues,
    } = this.props;

    const currentSuit = suit || suits.find((suit) => suit.id === activeSuitId);

    const smartLinkOptions = smartlinks?.length
      ? smartlinks?.map((link) => ({
          label: link.name,
          value: link.url,
        }))
      : [];

    const microPagesOptions = microPages?.length
      ? microPages?.map((link) => ({
          label: link.name,
          value: link.url,
        }))
      : [];

    const templateOptions = TemplatesObjects
      ? TemplatesObjects?.map((template) => ({
          label: template.template_name,
          value: template.template_id,
          content: template.content,
        }))
      : [];

    const urlToRender = urlType?.value;

    const reachedMaxButtons = this.state.actionButtons.length >= MAX_BUTTONS;

    const templateVariables = extractVariablesFromTemplate(
      myFormValues?.action?.webPush?.message
    );
    const hasTemplateVariables = templateVariables.length > 0;

    return (
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "1rem",
        }}
      >
        <Field
          name={"action.campaign_name"}
          component={TextBox}
          label={I18n.t("CampaignName_label")}
          className={classes["campaign-name-input"]}
          validate={[required, maxLength30]}
          maxLength={30}
          onChange={(e, value) => handleChange("action.campaign_name", value)}
          placeholder={I18n.t("CampaignName_placeholder")}
        />

        <Field
          name={`action.webPush.message`}
          component={TextArea}
          label={I18n.t("Message")}
          validate={[required, minLength5, maxLength160]}
          minRecommended={5}
          maxRecommended={160}
          onChange={(e, value) => {
            handleChange(`action.webPush.message`, value);
          }}
          placeholder={I18n.t(`Message_placeholder`)}
          EmojiComponent={() => (
            <EmojiPicker
              handleChange={(emoji) => {
                const path = `action.webPush.message`;
                const value =
                  (this.props.myFormValues?.action?.webPush?.message || "") +
                  emoji;
                handleChange(path, value);
                ReduxChange("pushMessageStep", path, value);
              }}
            />
          )}
        />

        {hasTemplateVariables && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1rem",
              gap: "1rem",
            }}
          >
            <Label helper="templateVariablesHelper">
              {I18n.t("templateVariables")}
            </Label>
            {templateVariables?.map((variable, index) => {
              return (
                <Field
                  key={index}
                  name={`action.webPush.variables[$${variable}]`}
                  label={variable}
                  component={TextBox}
                  validate={[required]}
                  onChange={(e, value) => {
                    handleChange(
                      `action.webPush.variables[$${variable}]`,
                      value
                    );
                  }}
                  placeholder={variable}
                />
              );
            })}
          </div>
        )}

        <CustomSelector
          label={I18n.t("messagetemplate")}
          value={selectedTemplate}
          placeholder={I18n.t("selectTemplate")}
          onChange={(newValue) => {
            // clear sms form redux form first to avoid conflicts with template variables
            ReduxChange("pushMessageStep", "action.webPush.variables", "");

            handleTemplateSelect(newValue);
            handleChange(`action.webPush.message`, newValue.content);
            ReduxChange(
              "pushMessageStep",
              `action.webPush.message`,
              newValue.content
            );
          }}
          options={templateOptions}
        />

        {/* message direction dropdown */}
        <Field
          style={{ marginTop: "1.4rem" }}
          label={I18n.t("MessageDirection")}
          name="action.webPush.dir"
          component={CustomSelectorReduxForm}
          options={messageDirectionOptions}
          validate={[required]}
          onChange={(value) => {
            handleChange("action.webPush.dir", value.value);
          }}
          placeholder={I18n.t("select")}
        />

        {/* image uploader */}
        <UploadImage
          label={I18n.t("messageImage")}
          onChange={this.handleUploadImageChange}
          suit={currentSuit}
          style={{
            marginTop: "24px",
          }}
        >
          <SecondaryButton>{I18n.t("uploadImage")}</SecondaryButton>
        </UploadImage>

        {/* buttons */}
        <div className={classes.buttonsContainer}>
          <PrimaryButton
            onClick={this.handleAddButtonClick}
            disabled={reachedMaxButtons}
          >
            {I18n.t("AddActionButton")}
          </PrimaryButton>

          {this.state.actionButtons.map((button, index) => {
            const actionUrlTypeToRender = button.urlType?.value;

            return (
              <div key={button.id} className={classes.buttonContainer}>
                <span className={`${classes.buttonNumber} `}>
                  {addNumberSuffix(index + 1) + " " + I18n.t("button")}
                </span>

                <Button
                  type="danger"
                  shape="circle"
                  icon="delete"
                  className={`${classes.deleteButton}`}
                  onClick={() => this.removeButton(button.id, index)}
                />

                <Field
                  name={`action.webPush.actions[${index}].title`}
                  component={TextBox}
                  label={I18n.t("webPush_actionBtn_title")}
                  validate={[required]}
                  onChange={(e, value) => {
                    handleChange(
                      `action.webPush.actions[${index}].title`,
                      value
                    );
                  }}
                  placeholder={I18n.t("webPush_actionBtn_titlePlaceholder")}
                  hasTooltip
                  tooltipBehind={I18n.t("webPush_actionBtn_titleTooltip")}
                />

                <Field
                  name={`action.webPush.actions[${index}].action`}
                  component={TextBox}
                  label={I18n.t("webPush_actionBtn_action")}
                  validate={[required]}
                  onChange={(e, value) => {
                    handleChange(
                      `action.webPush.actions[${index}].action`,
                      value
                    );
                  }}
                  placeholder={I18n.t("webPush_actionBtn_actionPlaceholder")}
                  hasTooltip
                  tooltipBehind={I18n.t("webPush_actionBtn_actionTooltip")}
                />

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "16px",
                  }}
                >
                  <CustomSelector
                    label={I18n.t("URL")}
                    value={button.urlType}
                    onChange={(selectedLaunchUrl) => {
                      const newButton = {
                        ...button,
                        urlType: selectedLaunchUrl,
                      };

                      const newButtons = this.state.actionButtons.map(
                        (button) =>
                          button.id === newButton.id ? newButton : button
                      );

                      this.setState({
                        actionButtons: newButtons,
                      });

                      ReduxChange(
                        "pushMessageStep",
                        `action.webPush.actions[${index}].url`,
                        ""
                      );
                      handleChange(
                        `action.webPush.actions[${index}].url`,
                        null
                      );
                    }}
                    options={urlOptions.filter(
                      (option) => option.value !== URL_TYPES.NO_URL
                    )}
                  />

                  {actionUrlTypeToRender === URL_TYPES.URL && (
                    <Field
                      style={{ gridColumn: "2 / span 2" }}
                      name={`action.webPush.actions[${index}].url`}
                      component={TextBox}
                      label={I18n.t("URL")}
                      validate={[required, validUrl]}
                      onChange={(e, value) => {
                        handleChange(
                          `action.webPush.actions[${index}].url`,
                          value
                        );
                      }}
                      placeholder={I18n.t("webPush_actionBtn_urlPlaceholder")}
                    />
                  )}

                  {actionUrlTypeToRender === URL_TYPES.SMART_LINK && (
                    <CustomSelector
                      style={{ gridColumn: "2 / span 2" }}
                      label={I18n.t("SmartLink")}
                      options={smartLinkOptions}
                      onChange={(selectedSmartLink) => {
                        handleChange(
                          `action.webPush.actions[${index}].url`,
                          selectedSmartLink.value
                        );
                      }}
                    />
                  )}

                  {actionUrlTypeToRender === URL_TYPES.MICRO_PAGE && (
                    <CustomSelector
                      style={{ gridColumn: "2 / span 2" }}
                      label={I18n.t("MicroPage")}
                      options={microPagesOptions}
                      onChange={(selectedMicroPage) => {
                        handleChange(
                          `action.webPush.actions[${index}].url`,
                          selectedMicroPage.value
                        );
                      }}
                    />
                  )}
                </div>

                <Field
                  style={{ marginTop: "1.4rem" }}
                  name={`action.webPush.actions[${index}].icon`}
                  component={TextBox}
                  label={I18n.t("webPush_actionBtn_icon")}
                  validate={[validUrl]}
                  onChange={(e, value) => {
                    handleChange(
                      `action.webPush.actions[${index}].icon`,
                      value
                    );
                  }}
                  placeholder={I18n.t("webPush_actionBtn_iconPlaceholder")}
                />
              </div>
            );
          })}
        </div>

        <div
          className={`${
            urlToRender === URL_TYPES.NO_URL
              ? classes.gridOneColumn
              : classes.gridTwoColumn
          }`}
        >
          <CustomSelector
            label={I18n.t("URL")}
            options={urlOptions}
            value={urlType}
            onChange={handleSelectUrl}
          />
          {urlToRender === URL_TYPES.URL && (
            <Field
              label={I18n.t("URL")}
              name={`action.webPush.url`}
              placeholder={I18n.t("urlPlaceholder")}
              validate={[required, validUrl]}
              component={TextBox}
              onChange={(e, value) => {
                handleChange(`action.webPush.url`, value);
              }}
            />
          )}
          {urlToRender === URL_TYPES.DEEP_LINK && (
            <Field
              label={I18n.t("url")}
              name={`action.webPush.url`}
              placeholder={I18n.t("select from action selector")}
              validate={[required, validUrl]}
              component={TextBox}
              onChange={(e, value) => {
                handleChange(`action.webPush.url`, value);
              }}
              deepLinkBuilder
              FiledNotexist
              onlymdp
            />
          )}
          {urlToRender === URL_TYPES.SMART_LINK && (
            <CustomSelector
              label={I18n.t("shortLink")}
              options={smartLinkOptions}
              value={urlValue}
              onChange={handleURLValueChange}
            />
          )}
          {urlToRender === URL_TYPES.MICRO_PAGE && (
            <CustomSelector
              label={I18n.t("microPage")}
              options={microPagesOptions}
              value={urlValue}
              onChange={handleURLValueChange}
            />
          )}
        </div>

        {/* auto dismiss checkbox */}
        <Field
          label={I18n.t("webPush_autoDismiss")}
          name="action.webPush.requireInteraction"
          component={CustomCheckboxReduxForm}
          className={classes.checkbox}
          onChange={(e, value) => {
            handleChange("action.webPush.requireInteraction", value);
          }}
        />

        {/* silent checkbox */}
        <Field
          label={I18n.t("webPush_silent")}
          name="action.webPush.silent"
          component={CustomCheckboxReduxForm}
          className={classes.checkbox}
          onChange={(e, value) => {
            handleChange("action.webPush.silent", value);
          }}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: "pushMessageStep",
  destroyOnUnmount: false,
})(
  connect(
    (store) => ({
      smartLinks: store.smartlink,
      MicroPages: store.MicroPages,
      templates: store.template,
      user: store.user,
      myFormValues: getFormValues("pushMessageStep")(store),
    }),
    { ReduxChange, initialize }
  )(PushMessageStep)
);

PushMessageStep.propTypes = {
  handleChange: PropTypes.func,
  urlType: PropTypes.object,
  handleSelectUrl: PropTypes.func,
  smartLinks: PropTypes.object,
  MicroPages: PropTypes.object,
  TemplatesObjects: PropTypes.array,
  handleURLValueChange: PropTypes.func,
  selectedTemplate: PropTypes.object,
  urlValue: PropTypes.object,
  ReduxChange: PropTypes.func,
  handleTemplateSelect: PropTypes.func,
  user: PropTypes.object,
  activeSuitId: PropTypes.string,
  suits: PropTypes.array,
  suit: PropTypes.object,
  myFormValues: PropTypes.object,
};
