import StripPay from "api/StripPay";
import { RESET_ERRORS } from "reducers/user";

export const GET_STRIPPAY = "GET_STRIPPAY";

// / ------------------------------------
// Actions
// ------------------------------------

export function getStripPay(suitId, apiKey, landingpageId) {
  return {
    type: GET_STRIPPAY,
    payload: StripPay.getStripPay(suitId, apiKey, landingpageId),
  };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  result: [],
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function StripPayReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_STRIPPAY}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case `${GET_STRIPPAY}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case `${GET_STRIPPAY}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        result: action.payload,
      };

    default:
      return state;
  }
}
