import EP from "consts/api";
import api from "modules/api";

export default class appboost {
  static getSummaryData = (serv, suitId, apiKey, data, appid, master) =>
    api("post", EP.APPBOOST.summ(serv, suitId, apiKey, data))
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);

  static getDailyRevenueData = (serv, suitId, apiKey, data, appid, master) =>
    api("post", EP.APPBOOST.Dailyrevenue(serv, suitId, apiKey, data))
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);

  static getActiveUsersData = (serv, suitId, apiKey, data, appid, master) =>
    api("post", EP.APPBOOST.ActiveUsers(serv, suitId, apiKey, data))
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);

  static getRnTData = (serv, suitId, apiKey, data, appid, master) =>
    api("post", EP.APPBOOST.RnT(serv, suitId, apiKey, data))
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  //get By Date isn't working on PurchasedTransaction
  static getPurchasedTransaction = (
    serv,
    suitId,
    apiKey,
    data,
    appid,
    master,
    limitVal,
    skipVal
  ) =>
    api(
      "get",
      EP.APPBOOST.PurchasedTransaction(serv, suitId, apiKey),
      false,
      `where={"transactionAt":{"$gt":{"__type":"Date","iso":"${data.start}T00:00:00Z"},"$lt":{"__type":"Date","iso":"${data.end}T23:59:59Z"}}}&limit=${limitVal}&skip=${skipVal}`
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`)
      .set("Content-Type", "application/json");

  static getPurchasedTransactionCount = (
    serv,
    suitId,
    apiKey,
    data,
    appid,
    master
  ) =>
    api(
      "get",
      EP.APPBOOST.PurchasedTransactionCount(serv, suitId, apiKey),
      false,
      `where={"transactionAt":{"$gt":{"__type":"Date","iso":"${data.start}T00:00:00Z"},"$lt":{"__type":"Date","iso":"${data.end}T23:59:59Z"}}}&limit=0&count=1`
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`)
      .set("Content-Type", "application/json");

  static getUninstallData = (serv, suitId, apiKey, data, appid, master) =>
    api("post", EP.APPBOOST.uninstall(serv, suitId, apiKey, data))
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);

  static getTouchPointData = (suitId, apiKey, data) =>
    api("get", EP.APPBOOST.TouchPoint(suitId, apiKey, data)).set(
      "appApiKey",
      apiKey
    );

  static getAcquData = (suitId, apiKey, data) =>
    api("get", EP.APPBOOST.Acqu(suitId, apiKey, data)).set("appApiKey", apiKey);

  static getRevenueMetrics = (
    serverURL,
    appId,
    suitId,
    masterKey,
    apiKey,
    fromDate,
    toDate
  ) =>
    api(
      "post",
      EP.APPBOOST.getRevenueMetrics(serverURL, suitId, apiKey, fromDate, toDate)
    )
      .set("x-parse-application-id", appId)
      .set("x-parse-master-key", masterKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");
}
