import api from "modules/api";
import EP, { config } from "consts/api";

export default class Reports {
  static getReports = (suitId, apiKey, subdomain, start, end) => {
    return api(
      "post",
      EP.REPORTS.Reports(suitId, apiKey, subdomain, start, end)
    )
      .set("x-parse-master-key", `${config.Masterkey}`)
      .set("x-parse-application-id", `${config.applicationID}`);
  };
}
