import _ from "lodash";
import moment from "moment";
import { RESET_ERRORS } from "reducers/user";
import DigitalInvoice from "../api/DigitalInvoice";
import {
  BOOLEAN,
  CURRENCY,
  DATE_TYPE,
  NUMBER,
  OBJECT,
  STRING,
} from "../consts/TableColumnTypes";

const DIGITAL_INVOICES = "DIGITAL_INVOICES";
const DIGITAL_INVOICES_COUNT = "DIGITAL_INVOICES_COUNT";
const CREATE_DIGITAL_INVOICE = "CREATE_DIGITAL_INVOICE";
const DIGITAL_INVOICES_CHECK_DATA_TABLE_ROW =
  "DIGITAL_INVOICES_CHECK_DATA_TABLE_ROW";
const DIGITAL_INVOICES_CHECK_ALL_DATA_TABLE_ROWS =
  "DIGITAL_INVOICES_CHECK_ALL_DATA_TABLE_ROWS";
const DIGITAL_INVOICES_ON_COLUMN_TOGGLE = "DIGITAL_INVOICES_ON_COLUMN_TOGGLE";
const DIGITAL_INVOICES_TOGGLE_ROW_ACTION_MENU =
  "DIGITAL_INVOICES_TOGGLE_ROW_ACTION_MENU";
const DIGITAL_INVOICES_CLEAR_ACTION_MENU = "DIGITAL_INVOICES_CLEAR_ACTION_MENU";
const DIGITAL_INVOICES_ON_RESET_COLUMNS = "DIGITAL_INVOICES_ON_RESET_COLUMNS";
const SORT_DIGITAL_INVOICES = "SORT_DIGITAL_INVOICES";
const SET_DIGITAL_INVOICE_META_DATA = "SET_DIGITAL_INVOICE_META_DATA";
const REDEEM_COUPON = "REDEEM_COUPON";
const DELETE_INVOICE = "DELETE_INVOICE";
const SHARE_INVOICE_VIA_SMS = "SHARE_INVOICE_VIA_SMS";
const SHARE_VIA_WHATSAPP = "SHARE_VIA_WHATSAPP";

//  ------------------------------------
// Actions
// ------------------------------------

export function shareInvoiceViaSMS(subdomain, invoiceNumber, appApiKey) {
  return {
    type: SHARE_INVOICE_VIA_SMS,
    payload: DigitalInvoice.shareDigitalInvoiceViaSMS(
      subdomain,
      invoiceNumber,
      appApiKey
    ),
  };
}

export function shareViaWhatsApp(subdomain, invoiceNumber, appApiKey) {
  return {
    type: SHARE_VIA_WHATSAPP,
    payload: DigitalInvoice.shareDigitalInvoiceViaWhatsApp(
      subdomain,
      invoiceNumber,
      appApiKey
    ),
  };
}

export function deleteInvoice(subdomain, invoiceId) {
  return {
    type: DELETE_INVOICE,
    payload: DigitalInvoice.deleteDigitalInvoice(subdomain, invoiceId),
  };
}

export function redeemCoupon(subDomain, data) {
  return {
    type: REDEEM_COUPON,
    payload: DigitalInvoice.redeemCoupon(subDomain, data),
  };
}

export function setMetaData(data) {
  return {
    type: SET_DIGITAL_INVOICE_META_DATA,
    payload: data,
  };
}

export function getDigitalInvoices(subdomain, data) {
  return {
    type: DIGITAL_INVOICES,
    payload: DigitalInvoice.getDigitalInvoices(subdomain, data),
  };
}

export function createDigitalInvoice(subDomain, data) {
  return {
    type: CREATE_DIGITAL_INVOICE,
    payload: DigitalInvoice.createDigitalInvoice(subDomain, data),
  };
}

// Data table reducer functions
export function checkDataTableRow(idx) {
  return {
    type: DIGITAL_INVOICES_CHECK_DATA_TABLE_ROW,
    payload: idx,
  };
}

export function checkAllTableRows(newChecked) {
  return {
    type: DIGITAL_INVOICES_CHECK_ALL_DATA_TABLE_ROWS,
    payload: newChecked,
  };
}

export function onColumnToggle(idx) {
  return {
    type: DIGITAL_INVOICES_ON_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function toggleRowActionMenu(idx) {
  return {
    type: DIGITAL_INVOICES_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}

export function clearActionMenu(idx) {
  return {
    type: DIGITAL_INVOICES_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: DIGITAL_INVOICES_ON_RESET_COLUMNS,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_DIGITAL_INVOICES,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  invoices: [],
  tableData: null,
  invoicesCount: 0,
  isPending: false,

  createInvoicePending: false,

  // cash
  page: 1,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function getDigitalInvoicesReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${DIGITAL_INVOICES}_PENDING`:
    case `${DIGITAL_INVOICES_COUNT}_PENDING`:
    case `${DELETE_INVOICE}_PENDING`:
      return {
        ...state,
        isPending: true,
      };

    case `${DIGITAL_INVOICES}_REJECTED`:
    case `${DIGITAL_INVOICES_COUNT}_REJECTED`:
    case `${DELETE_INVOICE}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: true,
      };

    case `${DIGITAL_INVOICES}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        invoices: action.payload.body.result?.data?.invoices,
        tableData: prepareUserScreenTableDate(
          action.payload.body.result?.data?.invoices
        ),
      };
    case `${DIGITAL_INVOICES_COUNT}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        invoicesCount: action.payload.body.count,
      };

    case `${CREATE_DIGITAL_INVOICE}_PENDING`:
      return {
        ...state,
        createInvoicePending: true,
      };
    case `${CREATE_DIGITAL_INVOICE}_REJECTED`:
      return {
        ...state,
        createInvoicePending: false,
      };
    case `${CREATE_DIGITAL_INVOICE}_FULFILLED`:
      return {
        ...state,
        createInvoicePending: false,
      };
    case `${DELETE_INVOICE}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    // data table
    case DIGITAL_INVOICES_CHECK_DATA_TABLE_ROW:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, checked: !row.checked }
              : row;
          }),
        },
      };
    case DIGITAL_INVOICES_CHECK_ALL_DATA_TABLE_ROWS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };
    case DIGITAL_INVOICES_ON_COLUMN_TOGGLE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };
    case DIGITAL_INVOICES_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, actionsMenu: !row.actionsMenu }
              : row;
          }),
        },
      };
    case DIGITAL_INVOICES_CLEAR_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };
    case DIGITAL_INVOICES_ON_RESET_COLUMNS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };
    case SORT_DIGITAL_INVOICES: {
      const indexOfKey = state.tableData.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.tableData.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: tableRows,
        },
      };
    }
    case SET_DIGITAL_INVOICE_META_DATA:
      return {
        ...state,
        page: action.payload.page,
      };
    default:
      return state;
  }
}

// prepare user screen table date functions
const dateFormat = "DD MMM YY HH:mm:ss";

const prepareUserScreenTableDate = (tableData) => {
  if (tableData.length === 0) return; // safe return if tableData is empty

  const labels = [
    {
      name: "USER ID",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "userId",
      defaultValue: "N/A",
    },
    {
      name: "INVOICE NUMBER",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "invoice_number",
      defaultValue: "N/A",
    },
    {
      name: "TOTAL",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: "total",
      defaultValue: "N/A",
    },
    {
      name: "TO",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "name",
      defaultValue: "N/A",
    },
    {
      name: "MOBILE NUMBER",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "phone",
      defaultValue: "N/A",
    },
    {
      name: "PAID",
      has_sort: false,
      type: BOOLEAN,
      selected: true,
      varName: "paid",
      defaultValue: "N/A",
    },
    {
      name: "POINTS",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: "points",
      defaultValue: "N/A",
    },
    {
      name: "EMAIL",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "email",
      defaultValue: "N/A",
    },
    {
      name: "CREATED AT",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "updatedAt",
      defaultValue: "N/A",
    },
  ];

  const rows = tableData.map((row) => {
    return {
      checked: false,
      actionsMenu: false,
      id: row.objectId,
      values: labels.map((label) => {
        if (label.type === DATE_TYPE) {
          return moment
            .utc(_.get(row, label.varName, label.defaultValue))
            .local()
            .format(dateFormat);
        } else if (label.type === OBJECT) {
          return JSON.stringify(_.get(row, label.varName, label.defaultValue));
        } else if (label.type === NUMBER) {
          return _.get(row, label.varName, label.defaultValue);
        }
        return _.get(row, label.varName, label.defaultValue);
      }),
    };
  });

  return { labels, rows, actions: true };
};
