import userTimeline from "api/userTimline";
import { RESET_ERRORS } from "reducers/user";

const GET_USER_LOG_EVENTS = "GET_USER_LOG_EVENTS";

//  ------------------------------------
// Actions
// ------------------------------------

export function getUserLogEvents(appId, userId, page = 1) {
  return {
    type: GET_USER_LOG_EVENTS,
    payload: userTimeline.getUserLogEvents(appId, userId, page),
  };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  isPending: false,

  // user_timeline: [],
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function userLogEvents(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_USER_LOG_EVENTS}_PENDING`:
      return {
        ...state,
        isPending: true,
      };

    case `${GET_USER_LOG_EVENTS}_REJECTED`:
      return {
        ...state,
        isPending: false,
      };

    case `${GET_USER_LOG_EVENTS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };

    default:
      return state;
  }
}
