import NotifyLogs from "api/NotifyLogs";
import {
  CURRENCY,
  DATE_TYPE,
  NUMBER,
  OBJECT,
  STRING,
} from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";
import { RESET_ERRORS } from "reducers/user";

// ------------------------------------
// Constants
// ------------------------------------

const GET_ALL_NOTIFY_LOGS = "GET_ALL_NOTIFY_LOGS";
const SET_META_DATA = "SET_META_DATA";
const NOTIFICATION_LOGS_CHECK_DATA_TABLE_ROW =
  "NOTIFICATION_LOGS_CHECK_DATA_TABLE_ROW";
const NOTIFICATION_LOGS_CHECK_ALL_DATA_TABLE_ROWS =
  "NOTIFICATION_LOGS_CHECK_ALL_DATA_TABLE_ROWS";
const NOTIFICATION_LOGS_ON_COLUMN_TOGGLE = "NOTIFICATION_LOGS_ON_COLUMN_TOGGLE";
const NOTIFICATION_LOGS_TOGGLE_ROW_ACTION_MENU =
  "NOTIFICATION_LOGS_TOGGLE_ROW_ACTION_MENU";
const NOTIFICATION_LOGS_CLEAR_ACTION_MENU =
  "NOTIFICATION_LOGS_CLEAR_ACTION_MENU";
const NOTIFICATION_LOGS_ON_RESET_COLUMNS = "NOTIFICATION_LOGS_ON_RESET_COLUMNS";
const SORT_NOTIFICATION_LOGS_TABLE = "SORT_NOTIFICATION_LOGS_TABLE";
const CLEAR_NOTIFICATION_LOGS_CASH = "CLEAR_NOTIFICATION_LOGS_CASH";

// ------------------------------------
// Actions
// ------------------------------------

export function clearCash() {
  return {
    type: CLEAR_NOTIFICATION_LOGS_CASH,
  };
}

export function getAllNotifyLogs(appId, page = 1, start, end, type) {
  return {
    type: GET_ALL_NOTIFY_LOGS,
    payload: NotifyLogs.getAllNotifyLogs(appId, page, start, end, type),
  };
}

export function setMetaData(page, start, end, type, activeSuitId) {
  return {
    type: SET_META_DATA,
    payload: { page, start, end, type, activeSuitId },
  };
}

// data table functions

export function checkDataTableRow(idx) {
  return {
    type: NOTIFICATION_LOGS_CHECK_DATA_TABLE_ROW,
    payload: idx,
  };
}
export function checkAllTableRows(newChecked) {
  return {
    type: NOTIFICATION_LOGS_CHECK_ALL_DATA_TABLE_ROWS,
    payload: newChecked,
  };
}
export function onColumnToggle(idx) {
  return {
    type: NOTIFICATION_LOGS_ON_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function toggleRowActionMenu(idx) {
  return {
    type: NOTIFICATION_LOGS_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}

export function clearActionMenu(idx) {
  return {
    type: NOTIFICATION_LOGS_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: NOTIFICATION_LOGS_ON_RESET_COLUMNS,
  };
}

export function sortTable(name, type) {
  return {
    type: SORT_NOTIFICATION_LOGS_TABLE,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  isPending: false,
  error: false,
  data: null,
  count: 0,
  page: 1,
  start: "",
  end: "",
  tableData: null,
  type: "",
  currentSuitId: null,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function NotifyLogsReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_ALL_NOTIFY_LOGS}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_ALL_NOTIFY_LOGS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        data: action.payload.body.logs,
        tableData: prepareTableData(action.payload.body.logs),
        count: action.payload.body.count,
      };
    case SET_META_DATA:
      return {
        ...state,
        page: action.payload.page,
        start: action.payload.start,
        end: action.payload.end,
        type:
          action.payload.type && action.payload.type.value
            ? action.payload.type.value
            : "all",
        currentSuitId: action.payload.activeSuitId,
      };

    // data table

    case NOTIFICATION_LOGS_CHECK_DATA_TABLE_ROW:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, checked: !row.checked }
              : row;
          }),
        },
      };

    case NOTIFICATION_LOGS_CHECK_ALL_DATA_TABLE_ROWS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    case NOTIFICATION_LOGS_ON_COLUMN_TOGGLE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };

    case NOTIFICATION_LOGS_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, actionsMenu: !row.actionsMenu }
              : row;
          }),
        },
      };

    case NOTIFICATION_LOGS_CLEAR_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };

    case NOTIFICATION_LOGS_ON_RESET_COLUMNS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };

    case SORT_NOTIFICATION_LOGS_TABLE: {
      const indexOfKey = state.tableData.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.tableData.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: tableRows,
        },
      };
    }
    case CLEAR_NOTIFICATION_LOGS_CASH:
      return {
        ...state,
        data: null,
        count: 0,
        page: 1,
        start: "",
        end: "",
        tableData: null,
        type: "",
      };
    default:
      return state;
  }
}

export const prepareTableData = (tableData) => {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "CAMPAIGN NAME",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "campaign_name",
      defaultValue: "N/A",
    },
    {
      name: "CAMPAIGN ID",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "campaign_id",
      defaultValue: "N/A",
    },
    {
      name: "USER ID",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "userId",
      defaultValue: "N/A",
    },
    {
      name: "STATUS",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "status",
      defaultValue: "N/A",
    },
    {
      name: "RESPONSE CODE",
      has_sort: false,
      type: NUMBER,
      selected: true,
      varName: "response_code",
      defaultValue: "N/A",
    },
    {
      name: "SENT AT",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "sent_at",
      defaultValue: "N/A",
    },
    {
      name: "CHANNEL",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "message_info.channel",
      defaultValue: "N/A",
    },
    {
      name: "MESSAGE INFO",
      has_sort: false,
      type: OBJECT,
      selected: true,
      varName: "message_info",
      defaultValue: "N/A",
    },
  ];

  const rows = tableData.map((row) => {
    return {
      checked: false,
      actionsMenu: false,
      campaignId: row.campaign_id,
      userId: row.userId,
      type: _.get(row, "message_info.channel"),
      values: labels.map((label) => {
        if (label.type === DATE_TYPE) {
          return moment(
            moment.utc(_.get(row, label.varName, label.defaultValue))
          )
            .local()
            .format(dateFormat);
        } else if (label.type === OBJECT) {
          return JSON.stringify(_.get(row, label.varName, label.defaultValue));
        }
        return _.get(row, label.varName, label.defaultValue);
      }),
    };
  });

  return { labels, rows };
};
