import { FormControl, FormGroup, InputGroup, Label } from "components";
import _ from "lodash";
import React from "react";
import { I18n } from "react-redux-i18n";
import { Toast } from "../../../modules/toast";
import classes from "./ImageUploader.scss";

const adaptFileEventToValue =
  (delegate, requiredWidth, requiredHeight) => (e) => {
    if (e.target.files.length > 0) {
      const imageFile = e.target.files[0];
      var img = document.createElement("img");
      img.onload = function () {
        if (this.width === requiredWidth && this.height === requiredHeight) {
          delegate(imageFile);
        } else {
          Toast.error(
            `${I18n.t(
              "ImgUploader_sizeErr"
            )} ${requiredWidth}px * ${requiredHeight}px`
          );
        }
      };
      img.src = window.URL.createObjectURL(imageFile);
    }
  };
const FileUploadInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  hint,
  label,
  optional,
  required,
  ...props
}) => {
  let inputRef = null;
  return (
    <FormGroup>
      {label && <Label className={classes.space}>{label}</Label>}
      {optional && <span className="pull-right">{I18n.t("optional")}</span>}
      {required && <span className="pull-right color-red">*</span>}
      <div className={classes.wrapper}>
        <InputGroup>
          <i
            className={`fa fa-camera ${classes["notification-icon"]}`}
            aria-hidden="true"
            onClick={() => {
              inputRef.click();
            }}
          />
          {_.get(omitValue, "name", omitValue) && (
            <i
              className={`fa fa-times ${classes["times-icon"]}`}
              aria-hidden="true"
              onClick={() => {
                onChange("");
              }}
            />
          )}
          {_.get(omitValue, "name", omitValue) && (
            <i
              className={`${classes["placeholder-icon"]}`}
              aria-hidden="true"
              onClick={() => {
                onChange("");
              }}
            />
          )}

          <input
            readOnly
            type="text"
            placeholder={props.placeholder}
            className={classes.readOnly}
            value={_.get(omitValue, "name", omitValue)}
          />
          <input
            ref={(component) => (inputRef = component)}
            onChange={(e) => {
              adaptFileEventToValue(
                onChange,
                props.requiredWidth,
                props.requiredHeight
              )(e);
              e.target.value = null;
            }}
            onBlur={adaptFileEventToValue(onBlur)}
            type="file"
            className={classes.file}
            {...inputProps}
            {...props}
          />
        </InputGroup>
        {hint && <p>{hint}</p>}
        <FormControl.Feedback />
      </div>
    </FormGroup>
  );
};
export default FileUploadInput;
