import { config } from "../../../../consts/api";
import superAgentInstance from "../../../../modules/api";

const { NOTIFY_HOST } = config;

export const getSuitInvoiceTemplate = async (suitId) => {
  try {
    const response = await superAgentInstance(
      "GET",
      `${NOTIFY_HOST}/${suitId}/templates`
    );

    const templates = response.body?.Templates;
    const invoiceTemplate = templates?.find(
      (t) => t.template_name === "invoicetemplate" && t.type === "email"
    );

    return invoiceTemplate ?? null;
  } catch (error) {
    console.error(error);
  }
};

export const updateSuitInvoiceTemplate = async (suitId, templateId, data) => {
  try {
    const response = await superAgentInstance(
      "PUT",
      `${NOTIFY_HOST}/${suitId}/templates/${templateId}`,
      data
    );

    return response.body;
  } catch (error) {
    console.error(error);
  }
};

export const createSuitInvoiceTemplate = async (suitId, data) => {
  try {
    const response = await superAgentInstance(
      "POST",
      `${NOTIFY_HOST}/${suitId}/templates`,
      data
    );

    return response.body;
  } catch (error) {
    console.error(error);
  }
};
