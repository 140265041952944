import { Spin } from "antd";
import { CustomSelector, Form } from "components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import FacebookLogin from "react-facebook-login";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getCurrentSuit, updateSuit } from "reducers/user";
import facebookLogo from "static/facebook.png";
import zapierLogo from "static/zapier.png";
import { Toast } from "../../modules/toast";
import classes from "./AdAudience.scss";
import {
  createCustomAudiences,
  generateLongAccessToken,
  getAdAccount,
  getBusinessAccount,
  getCustomAudiences,
} from "./AdAudiencelogic";

class AdAudience extends Component {
  state = {
    stage: "selectPlatform", // selectPlatform,configAudience
    selectedAudienceType: "existed",
    selectedPlatform: "zapier",
    businessAccountOptions: [],
    adAccountOptions: [],
    customAudienceOptions: [],
    selectedBusinessAcccount: null,
    accessToken: null,
    selectedAdAccount: null,
    selectedCustomAudience: null,
    newAudienceName: "",
    newAudienceDescription: "",

    loading: false,
  };

  async componentDidMount() {
    const {
      user: { activeSuitId },
      getCurrentSuit,
    } = this.props;

    const response = await getCurrentSuit(activeSuitId);
    const currentSuit = response.value.body;

    if (currentSuit.FBSessionToken) {
      this.setState({
        accessToken: currentSuit.FBSessionToken,
        stage: "configAudience",
      });

      try {
        const response = await getBusinessAccount(currentSuit.FBSessionToken);

        if (response.data.data === 0) {
          Toast.error(
            I18n.t("CustomerSegmentsPage_AdAudience_modal_NobusAccErrMsg")
          );
          return this.setState({
            stage: "selectPlatform",
            accessToken: null,
          });
        }

        this.setState({
          stage: "configAudience",
          businessAccountOptions: response.data.data.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        }); // navigate to config audience tab
      } catch (error) {
        console.error(error);
        Toast.error(
          I18n.t("CustomerSegmentsPage_AdAudience_modal_NobusAccErrMsg")
        );

        this.setState({
          stage: "selectPlatform",
          accessToken: null,
        });
      }
    } else {
      this.setState({ accessToken: null, stage: "selectPlatform" });
    }
  }

  onFacebookLoginFail = () => {
    Toast.error(I18n.t("facebookErrMsg"));
  };

  handleConfigureAudienceBtnClick = () => {
    const { accessToken } = this.state;

    if (!accessToken) {
      return Toast.error(
        I18n.t("CustomerSegmentsPage_AdAudience_modal_accessTokenErrMsg")
      );
    }
    this.switchStage("configAudience");
  };

  handleConfirmBtnClick = async () => {
    const {
      selectedAudienceType,
      accessToken,
      selectedAdAccount,
      newAudienceName,
      newAudienceDescription,
      selectedCustomAudience,
    } = this.state;

    const { onConfirm } = this.props;

    if (selectedAudienceType === "existed") {
      onConfirm(
        {
          fb_audience: true,
          fb_payload_type: "HASHES_OR_USER_IDS",
          fb_custom_audience_id: selectedCustomAudience.value,
        },
        accessToken
      );
      this.setState({
        selectedAudienceType: "",
        selectedAdAccount: "",
        newAudienceName: "",
        newAudienceDescription: "",
        selectedCustomAudience: "",
      });
    } else {
      try {
        this.setState({ loading: true });
        const response = await createCustomAudiences(
          accessToken,
          newAudienceName,
          newAudienceDescription,
          selectedAdAccount.value
        );

        onConfirm(
          {
            fb_audience: true,
            fb_payload_type: "HASHES_OR_USER_IDS",
            fb_custom_audience_id: response.data.id,
          },
          accessToken
        );

        Toast.success(
          I18n.t("CustomerSegmentsPage_AdAudience_modal_createSuccessMsg")
        );
        this.setState({
          selectedAudienceType: "",
          selectedAdAccount: "",
          newAudienceName: "",
          newAudienceDescription: "",
          selectedCustomAudience: "",
        });
      } catch (error) {
        console.error(error);
        console.error(error.response.data);
        Toast.error(
          error.response.data.error
            ? error.response.data.error.message
            : I18n.t("CustomerSegmentsPage_AdAudience_modal_createErrMsg")
        );
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  handleSelectAdAccount = async (newValue) => {
    const { accessToken } = this.state;

    let customAudienceOptions = [];

    try {
      this.setState({ loading: true });
      const response = await getCustomAudiences(accessToken, newValue.value);

      customAudienceOptions = response.data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    } catch (error) {
      console.error(error);
      Toast.error(I18n.t("CustomerSegmentsPage_AdAudience_modal_busAccErrMsg"));
    } finally {
      this.setState({ loading: false });
    }

    this.setState({
      selectedAdAccount: newValue,
      customAudienceOptions,
    });
  };

  handleSelectBusinessAccount = async (newValue) => {
    const { accessToken } = this.state;
    // const { getAdAccount } = this.props;

    let adAccountOptions = [];

    try {
      this.setState({ loading: true });
      const response = await getAdAccount(accessToken, newValue.value);

      adAccountOptions = response.data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    } catch (error) {
      console.error(error);
      Toast.error("Failed to fetch advertise accounts");
    } finally {
      this.setState({ loading: false });
    }

    this.setState({
      selectedBusinessAcccount: newValue,
      adAccountOptions,
    });
  };

  handleSelectCustomAudience = (newValue) => {
    this.setState({
      selectedCustomAudience: newValue,
    });
  };

  responseFacebook = async (response) => {
    this.setState({ loading: true });

    const {
      user: { suit, suits, activeSuitId },
      updateSuit,
    } = this.props;

    const currentSuit = suit || suits.find((suit) => suit.id === activeSuitId);

    const shortToken = response.accessToken;

    let longTokenResponse;

    longTokenResponse = (await generateLongAccessToken(shortToken)).data;

    const longToken = longTokenResponse.access_token;

    if (!longToken) return console.error("No long access token found");

    await updateSuit(activeSuitId, {
      suit_name: currentSuit.name,
      FBSessionToken: longToken,
    });

    this.setState({ accessToken: longToken });

    try {
      this.setState({ loading: true });
      const response = await getBusinessAccount(longToken);

      if (response.data.data === 0) {
        Toast.error(
          I18n.t("CustomerSegmentsPage_AdAudience_modal_NobusAccErrMsg")
        );
      }
      this.setState({
        stage: "configAudience",
        businessAccountOptions: response.data.data.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      }); // navigate to config audience tab
    } catch (error) {
      console.error(error);
      Toast.error(I18n.t("CustomerSegmentsPage_AdAudience_modal_busAccErrMsg"));
      await updateSuit(activeSuitId, {
        suit_name: currentSuit.name,
        FBSessionToken: "",
      });
      this.setState({ accessToken: null });
    } finally {
      this.setState({ loading: false });
    }
  };

  selectPlatform = (platform) => {
    let { selectedPlatform } = this.state;
    if (selectedPlatform === platform) return;

    this.setState({ selectedPlatform: platform });
  };

  switchAudienceType = (type) => {
    let { selectedAudienceType } = this.state;
    if (selectedAudienceType === type) return;
    this.setState({ selectedAudienceType: type });
  };

  switchStage = (nextStage) => {
    let { stage } = this.state;
    if (stage === nextStage) return;

    this.setState({ stage: nextStage });
  };

  render() {
    let { onClose, display, isPending } = this.props;

    let {
      selectedBusinessAcccount,
      stage,
      selectedAudienceType,
      accessToken,
      selectedAdAccount,
      selectedCustomAudience,
      newAudienceName,
      businessAccountOptions,
      adAccountOptions,
      customAudienceOptions,
      newAudienceDescription,
      loading,
    } = this.state;

    return (
      <div className={classes.adAudience}>
        <Modal
          className={classes.modalStyle}
          show={display}
          onHide={onClose}
          dialogClassName={classes.modalDialog}
        >
          <Spin spinning={loading || isPending}>
            <Modal.Header bsClass={classes.modalHeader} closeButton>
              <Modal.Title bsClass={classes.modalTitle}>
                {I18n.t("CustomerSegmentsPage_AdAudience_modal_title")}
              </Modal.Title>

              <div className={classes.sectionNamePanel}>
                <button
                  type="button"
                  className={
                    stage === "selectPlatform" ? classes.selectedSection : ""
                  }
                  onClick={() => this.switchStage("selectPlatform")}
                >
                  {I18n.t("selectPlatform")}
                </button>
                <button
                  type="button"
                  className={
                    stage === "configAudience" ? classes.selectedSection : ""
                  }
                  onClick={this.handleConfigureAudienceBtnClick}
                >
                  {I18n.t(
                    "CustomerSegmentsPage_AdAudience_modal_ConfigureAudienceBtn"
                  )}
                </button>
              </div>
            </Modal.Header>
            <Modal.Body bsClass={classes.modalBody}>
              {/* Select platform */}
              {stage === "selectPlatform" && (
                <div className={classes.platforms}>
                  <div className={classes.platformBanner}>
                    <div className={classes.comingSoon}>
                      <p>
                        {I18n.t(
                          "CustomerSegmentsPage_AdAudience_modal_comingSoon"
                        )}
                      </p>
                    </div>
                    <div className={classes.logo}>
                      <img src={zapierLogo} alt="Zapier" />
                    </div>

                    <div className={classes.info}>
                      <p>
                        {I18n.t("CustomerSegmentsPage_AdAudience_modal_Zapier")}
                      </p>
                      <p>
                        {I18n.t(
                          "CustomerSegmentsPage_AdAudience_modal_syncAsZapier"
                        )}
                      </p>
                    </div>

                    <div className={classes.loginBtn}>
                      <button className={classes.zapier}>
                        {I18n.t(
                          "CustomerSegmentsPage_AdAudience_modal_loginBtn"
                        )}
                      </button>
                    </div>
                  </div>

                  <div className={classes.platformBanner}>
                    <div className={classes.logo}>
                      <img src={facebookLogo} alt="Facebook" />
                    </div>
                    <div className={classes.info}>
                      <p>
                        {I18n.t(
                          "CustomerSegmentsPage_AdAudience_modal_FacebookAds"
                        )}
                      </p>
                      <p>
                        {I18n.t("Sync your segment as a Facebook Ads audience")}
                      </p>
                    </div>

                    {!accessToken ? (
                      <div className={classes.loginBtn}>
                        <FacebookLogin
                          appId="2242381319117291"
                          callback={this.responseFacebook}
                          onFailure={this.onFacebookLoginFail}
                          scope="ads_management,ads_read,business_management,public_profile"
                        />
                      </div>
                    ) : (
                      <div className={classes.fbConnected}>
                        {I18n.t(
                          "CustomerSegmentsPage_AdAudience_modal_FACEBOOKCONNECTED"
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* config audience */}
              {stage === "configAudience" && (
                <div>
                  <Form>
                    <div className={classes.inputRow}>
                      <p>
                        {I18n.t(
                          "CustomerSegmentsPage_AdAudience_modal_stage_configAudience_BusinessAccount"
                        )}
                      </p>
                      <CustomSelector
                        value={selectedBusinessAcccount}
                        disabled={loading}
                        options={businessAccountOptions}
                        onChange={this.handleSelectBusinessAccount}
                      />
                    </div>
                    <div className={classes.inputRow}>
                      <p>
                        {I18n.t(
                          "CustomerSegmentsPage_AdAudience_modal_stage_configAudience_AdAccount"
                        )}
                      </p>
                      <CustomSelector
                        value={selectedAdAccount}
                        disabled={loading}
                        options={adAccountOptions}
                        onChange={this.handleSelectAdAccount}
                      />
                    </div>
                    <div className={classes.inputRow}>
                      <p>
                        {I18n.t(
                          "CustomerSegmentsPage_AdAudience_modal_stage_configAudience_CustomAudience"
                        )}
                      </p>
                      <div className={classes.sectionNamePanel}>
                        <button
                          type="button"
                          className={
                            selectedAudienceType === "existed"
                              ? classes.selectedSection
                              : ""
                          }
                          onClick={() => this.switchAudienceType("existed")}
                        >
                          {I18n.t(
                            "CustomerSegmentsPage_AdAudience_modal_stage_configAudience_CustomAudience_existedBtn"
                          )}
                        </button>
                        <button
                          type="button"
                          className={
                            selectedAudienceType === "new"
                              ? classes.selectedSection
                              : ""
                          }
                          onClick={() => this.switchAudienceType("new")}
                        >
                          {I18n.t("new")}
                        </button>
                      </div>
                      {selectedAudienceType === "existed" && (
                        <div className={classes.inputRow}>
                          <CustomSelector
                            value={selectedCustomAudience}
                            disabled={loading}
                            options={customAudienceOptions}
                            onChange={this.handleSelectCustomAudience}
                          />
                        </div>
                      )}

                      {selectedAudienceType === "new" && (
                        <div className={classes.inputRow}>
                          <input
                            className={classes.input}
                            value={newAudienceName}
                            onChange={(e) =>
                              this.setState({ newAudienceName: e.target.value })
                            }
                            placeholder={I18n.t(
                              "CustomerSegmentsPage_AdAudience_modal_stage_configAudience_newSelected_Name_placeholder"
                            )}
                            required
                          />
                        </div>
                      )}

                      {selectedAudienceType === "new" && (
                        <div className={classes.inputRow}>
                          <input
                            className={classes.input}
                            value={newAudienceDescription}
                            onChange={(e) =>
                              this.setState({
                                newAudienceDescription: e.target.value,
                              })
                            }
                            placeholder={I18n.t(
                              "CustomerSegmentsPage_AdAudience_modal_stage_configAudience_newSelected_CustomAudience_placeholder"
                            )}
                            required
                          />
                        </div>
                      )}
                    </div>
                  </Form>
                </div>
              )}
            </Modal.Body>
            <div className={classes.buttonsContainer}>
              <button
                onClick={onClose}
                type="button"
                className={classes.cancelBtn}
              >
                {I18n.t("cancelBtn")}
              </button>

              {stage !== "selectPlatform" && (
                <button
                  type="button"
                  onClick={this.handleConfirmBtnClick}
                  className={classes.confirmBtn}
                >
                  {selectedAudienceType === "existed"
                    ? I18n.t(
                        "CustomerSegmentsPage_AdAudience_modal_stage_selectPlatform_Connectbtn"
                      )
                    : I18n.t("createBtn")}
                </button>
              )}
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    adAudience: store.form.adAudience,
    user: store.user,
  }),
  {
    updateSuit,
    getCurrentSuit,
  }
)(AdAudience);

AdAudience.propTypes = {
  user: PropTypes.shape({
    suit: PropTypes.object,
    suits: PropTypes.array,
    activeSuitId: PropTypes.string,
  }),
  updateSuit: PropTypes.func,
  getCurrentSuit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
  createCustomAudiences: PropTypes.func,
  onConfirm: PropTypes.func,
};
