import axios from "axios";
import { config } from "consts/api";

const { Masterkey, applicationID } = config;

const axiosInstance = axios.create({
  headers: {
    "X-Parse-Application-Id": applicationID,
    "X-Parse-Master-Key": Masterkey,
  },
});

export default axiosInstance;
