import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { I18n } from "react-redux-i18n";
import { Toast } from "../../../modules/toast";
import classes from "./Copyicon.module.scss";

class Copyicon extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  handleCopy(e, value) {
    e.preventDefault();

    navigator.clipboard.writeText(value);

    Toast.success(I18n.t("Copied"));
  }

  render() {
    const { value } = this.props;
    return (
      <a
        className={classes.copyIcon}
        onClick={(e) => this.handleCopy(e, value)}
      >
        <Tooltip title={I18n.t("copy")}>
          <MdOutlineContentCopy className="copyButton" />
        </Tooltip>
      </a>
    );
  }
}

export default Copyicon;

Copyicon.propTypes = {
  value: PropTypes.string.isRequired,
};
