import ENDPOINTS from "../consts/api";
import superAgentInstance from "../modules/api";

export default class Config {
  static getNotifyConfig = (appId) =>
    superAgentInstance("get", ENDPOINTS.NOTIFY.CONFIG(appId));

  static setNotifyConfig = (appId, data) =>
    superAgentInstance("put", ENDPOINTS.NOTIFY.CONFIG(appId), data);

  // static getNotifyLogs = (appId, type = 'all') => superAgentInstance('get', ENDPOINTS.NOTIFY.CONFIG(appId, type))

  static sendPush = (data) =>
    superAgentInstance("post", ENDPOINTS.SEND_LATER.NOTIFY(), data);

  static sendDirectPush = (appId, data) =>
    superAgentInstance("post", ENDPOINTS.NOTIFY.SEND(appId), data);

  static deleteUser = (data) =>
    superAgentInstance(
      "post",
      ENDPOINTS.SEND_LATER.DELETE_USER(),
      data,
      false,
      false
    );
}
