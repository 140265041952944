/**
 * @description returns products of all plans
 *
 * @return array of plans
 */
export const mapPlansToProducts = (plans) => {
  const products = {};
  plans.forEach((plan) => {
    plan.product.forEach((product) => {
      if (!products.hasOwnProperty(product.label[0].label)) {
        products[product.label[0].label] = 1;
      } else {
        products[product.label[0].label]++;
      }
    });
  });
  return Object.keys(products).sort((x, y) => products[x] < products[y]);
};
export const mapPlansToFeatures = (plans) => {
  const products = {};
  plans.forEach((plan) => {
    if (plan.common_features !== null) {
      plan.common_features.forEach((product) => {
        if (!products.hasOwnProperty(product.label[0].label)) {
          products[product.label[0].label] = 1;
        } else {
          products[product.label[0].label]++;
        }
      });
    }
  });
  return Object.keys(products).sort((x, y) => products[x] < products[y]);
};
