import PropTypes from "prop-types";
import React from "react";
import classes from "./wizard.scss";

class Wizard extends React.Component {
  state = {
    activeStep: this.props.activeStep,
  };

  componentDidUpdate(prevProps) {
    const { activeStep } = this.props;
    if (prevProps.activeStep !== activeStep) {
      this.setState({
        activeStep,
      });
    }
  }

  stepClick = (id) => {
    const { canChange, onStepChanged } = this.props;
    if (canChange(id)) {
      this.setState({
        activeStep: id,
      });
      onStepChanged(id);
    }
  };

  render() {
    const { children } = this.props;
    const { activeStep } = this.state;

    return (
      <div className={classes.wizard}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            onClick: () => this.stepClick(child.props.id || ""),
            active: child.props.id === activeStep,
          })
        )}
      </div>
    );
  }
}

Wizard.defaultProps = {
  canChange: () => true,
};

Wizard.propTypes = {
  children: PropTypes.node,
  canChange: PropTypes.func,
  onStepChanged: PropTypes.func,
  activeStep: PropTypes.string,
};

export default Wizard;
