import api from "modules/api";
import EP from "consts/api";
import { config } from "consts/api";

export default class automessages {
  static getSuitAutomator = (suitId, page) =>
    api("get", EP.AUTOMATOR.AUTOMESSAGES(suitId, page));

  // user dorpped create new project
  static getUserDorppedCreateNewProjectAutomator = (userId) =>
    api("get", EP.AUTOMATOR.USERDROPPEDCREATENEWPROJECT(userId)).set(
      "appApiKey",
      `${config.userDroppedCreateNewProjectApiKey}`
    );

  static getSuitAutomatorTrue = (suitId) =>
    api("get", EP.AUTOMATOR.AUTOMESSAGESTRUE(suitId));

  static deleteAutomator = (suitId, msgId) =>
    api("delete", EP.AUTOMATOR.AUTOMESSAGE(suitId, msgId));

  static deleteAutomatorBulk = (suitId, automessageIds) =>
    api("delete", EP.AUTOMATOR.BULK_DELETE(suitId), automessageIds);

  static getCurrentAutomator = (suitId, msgId) =>
    api("get", EP.AUTOMATOR.AUTOMESSAGE(suitId, msgId));

  static createAutomator = (suitId, data, apiKey) =>
    api("post", EP.AUTOMATOR.AUTOMESSAGES(suitId), data).set(
      "appApiKey",
      apiKey
    );

  static editAutomator = (suitId, msgId, data) =>
    api("put", EP.AUTOMATOR.AUTOMESSAGE(suitId, msgId), data);
  static getAutomatorAnalytics = (suitId, msgId) =>
    api("get", EP.AUTOMATOR.ANALYTICS(suitId, msgId));
  // INVOCATIONS
  static getInvocations = (suitId, apiKey, data) =>
    api("get", EP.AUTOMATOR.INVOCATIONS(suitId, apiKey, data)).set(
      "appApiKey",
      apiKey
    );
}
