import { Col, Modal, Row } from "components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import { disableTourSlider, getUserSuits } from "reducers/user";
import basic from "static/basic.png";
import check from "static/check.png";
import inboxs from "static/inboxs.png";
import people from "static/people.png";
import classes from "./PrjectGuid.scss";

let index = 0;

class ProjectGuid extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.swiper = null;
    props.getUserSuits();
    this.state = {
      valid: true,
      done: true,
      show: false,
      activeSlide: null,
    };
  }
  componentWillMount() {
    index = 0;
    const {
      user: { suits },
    } = this.props;
    if (suits.length === 0) {
      this.show();
    }
  }

  goNext = () => {
    if (this.swiper) {
      this.swiper.slideNext();
    }
    // if (index < 3) {
    //   index = index + 1
    //   this.setState({ valid: false })
    // }
    // if (index === 2) {
    //   this.setState({ done: false })
    // }
    // if (index === 2) {
    //   this.setState({ createproject: true })
    // }

    if (this.swiper.activeIndex < 2) {
      this.setState({
        valid: false,
        done: true,
      });
    }

    if (this.swiper.activeIndex === 2) {
      this.setState({ done: false });
    }
  };

  goPrev = () => {
    if (this.swiper) {
      this.swiper.slidePrev();
    }
    if (index !== 0) {
      index = index - 1;
      this.setState({ done: true });
    }
    if (index === 0) {
      this.setState({ valid: true });
    }
  };

  handelswiper(node) {
    if (node) {
      this.swiper = node.swiper;
      this.swiper.on("slideChange", () => {
        index = node.swiper.activeIndex;
      });
    }
  }

  hide = () => {
    this.setState({ show: false }, () => {
      this.props.disableTourSlider();
    });
  };

  show = () => {
    this.setState({ show: true });
  };

  render() {
    const params = {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    };
    return (
      <div>
        <Modal
          bsSize="large"
          show={this.state.show}
          dialogClassName={classes.addModal}
        >
          <Modal.Body>
            <div className="mainContent">
              <Swiper {...params} ref={(node) => this.handelswiper(node)}>
                {/* first slide */}
                <div className={classes.slide}>
                  <Row className={classes.first_silder}>
                    <Col className={classes.track} md={4}>
                      <p>
                        <Translate value="Track your customers without any code" />
                      </p>
                    </Col>
                    <Col md={4}>
                      <img className={classes.people} src={people} />
                    </Col>
                    <Col md={4}>
                      <img className={classes.basic} src={basic} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className={classes.bulidmobileappsfaster} md={8}>
                      <p className={classes.bulidmobileappsfasterContainer}>
                        <Translate value="Track your customers who visit or bought from your store without any code and with a single button" />
                      </p>
                    </Col>
                  </Row>
                  <button onClick={this.goNext} className={classes.next}>
                    <Translate value="Next" />
                  </button>
                  <a className={classes.skip} onClick={this.hide}>
                    <Translate value={"Skip"} />
                  </a>
                </div>

                {/* second slide */}
                <div className={classes.slide}>
                  <Row className={classes.first_silder}>
                    <Col className={classes.track} md={6}>
                      <p>
                        <Translate value="Reach them easily" />
                      </p>
                    </Col>
                    <Col md={6}>
                      <img className={classes.check} src={check} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className={classes.Reach_your} md={8}>
                      <p className={classes.Reach_your_Container}>
                        <Translate value="Reach your users on their favourite social media platform,like Facebook,Twitter,Instgram,etc" />
                        .
                      </p>
                    </Col>
                  </Row>
                  <button
                    onClick={this.goNext}
                    className={`${classes.next} ${classes.nextBtnSlideTwo}`}
                  >
                    <Translate value="Next" />
                  </button>
                  <a className={classes.skip} onClick={this.goPrev}>
                    <Translate value={"Back"} />
                  </a>
                </div>

                {/* third slide */}
                <div className={classes.slide}>
                  <Row className={classes.first_silder}>
                    <Col className={classes.track} md={6}>
                      <p>
                        <Translate value="Re-target them anywhere" />
                      </p>
                    </Col>
                    <Col md={6}>
                      <img className={classes.check} src={inboxs} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className={classes.Reach_your} md={8}>
                      <p className={classes.Reach_your_prag}>
                        <Translate value="Re-target your users , through SMS , notifications ,email, or their favourite social media!" />
                      </p>
                    </Col>
                  </Row>
                  <button
                    className={classes.createproject}
                    onClick={() => {
                      this.props.disableTourSlider();
                      this.context.router.push(`/create-new-project`);
                    }}
                  >
                    <Translate value="Create your first project" />
                  </button>
                  <button onClick={this.hide} className={classes.done}>
                    <Translate value={"Done"} />
                  </button>
                </div>
              </Swiper>

              {/* {this.state.done && ( */}
              {
                //    <a
                //     className={classes.skip}
                //     // onClick={this.state.valid ? this.hide : this.goPrev}
                //    >
                //     {/* <Translate value={this.state.valid ? 'Skip' : 'Back'}/> */}
                //   </a>
                // )
              }

              {/* condition for button */}
              {/* {index ===2 ? <button
                   onClick= {this.state.done ? this.goNext : this.hide}
                   className={classes.done}
                  >
                   <Translate value={this.state.done ? 'Next' : 'Done'}
                   />
                 </button>   : <button
                   onClick= {this.state.done ? this.goNext : this.hide}
                   className={classes.next}
                   >
                   <Translate value={this.state.done ? 'Next' : 'Done'}
                   />
                 </button>}  */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    getUserSuits,
    disableTourSlider,
  }
)(ProjectGuid);
