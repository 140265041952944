import apiEndpoints, { config } from "../consts/api";
import superAgentInstance from "../modules/api";
import { Toast } from "../modules/toast";

const { applicationID } = config;

class CouponAPI {
  static async getAllCoupons(subdomain, skip = 0, limit = 10) {
    try {
      const response = await superAgentInstance(
        "POST",
        apiEndpoints.COUPONS.getCoupons(subdomain, skip, limit)
      ).set("x-parse-application-id", applicationID);

      if (response.body.result.status !== 200) {
        Toast.error(response.body.result.enMessage);
      } else {
        return response.body.result.data;
      }
    } catch (error) {
      return error;
    }
  }

  static async createCoupon(subdomain, coupon) {
    try {
      const response = await superAgentInstance(
        "POST",
        apiEndpoints.COUPONS.createCoupon(subdomain),
        coupon
      ).set("x-parse-application-id", applicationID);

      const status = response.body.result.status;
      if (status !== 201) {
        Toast.error(response.body.result.enMessage);
        return null;
      } else {
        return response.body.result.data;
      }
    } catch (error) {
      return error;
    }
  }

  static async shareCoupon(code, mobile, message, suitId, apiKey) {
    try {
      const response = await superAgentInstance(
        "POST",
        apiEndpoints.COUPONS.shareCoupon(),
        {
          code,
          mobile,
          message,
          suitId,
          appApiKey: apiKey,
        }
      ).set("x-parse-application-id", applicationID);

      const status = response.body.result.status;
      if (status !== 200) {
        Toast.error(response.body.result.enMessage);
        return null;
      } else {
        return response.body.result.data;
      }
    } catch (error) {
      return error;
    }
  }

  static async deleteCoupon(subdomain, code) {
    try {
      const response = await superAgentInstance(
        "POST",
        apiEndpoints.COUPONS.deleteCoupon(subdomain, code)
      ).set("x-parse-application-id", applicationID);

      const status = response.body.result.status;
      if (status !== 200) {
        Toast.error(response.body.result.enMessage);
        return null;
      } else {
        return response.body.result.data;
      }
    } catch (error) {
      return error;
    }
  }

  static async validateCoupon(subdomain, code) {
    try {
      const response = await superAgentInstance(
        "POST",
        apiEndpoints.COUPONS.validateCoupon(),
        {
          subdomain,
          code,
        }
      ).set("x-parse-application-id", applicationID);

      const status = response.body.result.status;
      if (status !== 200) {
        Toast.error(response.body.result.enMessage);
        return null;
      } else {
        return response.body.result.data;
      }
    } catch (error) {
      return error;
    }
  }

  static async validateCouponForUser(subdomain, code, userId, invoiceTotal) {
    try {
      const response = await superAgentInstance(
        "POST",
        apiEndpoints.COUPONS.validateCouponForUser(),
        {
          subdomain,
          code,
          userId,
          invoiceTotal,
        }
      ).set("x-parse-application-id", applicationID);

      const status = response.body.result.status;
      if (status !== 200) {
        Toast.error(response.body.result.enMessage);
        return null;
      } else {
        return response.body.result.data;
      }
    } catch (error) {
      return error;
    }
  }

  static async redeemCoupon(subdomain, code, userId, invoiceTotalAmount) {
    try {
      const response = await superAgentInstance(
        "POST",
        apiEndpoints.COUPONS.redeemCoupon(),
        {
          subdomain,
          code,
          userId,
          invoiceTotalAmount,
        }
      ).set("x-parse-application-id", applicationID);

      const status = response.body.result.status;
      if (status !== 200) {
        Toast.error(response.body.result.enMessage);
        return null;
      } else {
        return response.body.result.data;
      }
    } catch (error) {
      return error;
    }
  }
}

export default CouponAPI;
