export const DATE_TYPE = "DATE_TYPE";
export const CURRENCY = "CURRENCY";
export const STRING = "STRING";
export const NUMBER = "NUMBER";
export const URL = "URL";
export const TEMPLATE_MOBILE = "TEMPLATE_MOBILE";
export const HYPERLINK = "HYPERLINK";
export const OBJECT = "OBJECT";
export const IMAGE = "IMAGE";
export const BOOLEAN = "BOOLEAN";
