import Root from "Root";
import DefaultLayout from "layouts/DefaultLayout";
import ROUTES from "./routes";

export const createRoutes = () => ({
  path: "/",
  component: DefaultLayout,
  indexRoute: Root,
  childRoutes: ROUTES,
});

export default createRoutes;
