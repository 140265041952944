import { composeWithDevTools } from "@redux-devtools/extension";
import translations from "i18n";
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from "react-redux-i18n";
import { applyMiddleware, legacy_createStore as createStore } from "redux";
import promiseMiddleware from "redux-promise-middleware";
import thunk from "redux-thunk";
import { getFromLocalStore } from "./localStore";
import makeRootReducer from "./reducers";

export default () => {
  const middleware = [thunk, promiseMiddleware()];

  const store = createStore(
    makeRootReducer(),
    composeWithDevTools(applyMiddleware(...middleware))
  );

  store.asyncReducers = {};

  // translations
  let locale = getFromLocalStore("locale") || "en";
  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translations));
  store.dispatch(setLocale(locale));

  return store;
};
