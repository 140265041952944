const timezones = {
  "Pacific/Pago_Pago": "-1100",
  "Pacific/Honolulu": "-1000",
  "America/Los_Angeles": "-0800",
  "America/Tijuana": "-0800",
  "America/Denver": "-0700",
  "America/Phoenix": "-0700",
  "America/Mazatlan": "-0700",
  "America/Chicago": "-0600",
  "America/Mexico_City": "-0600",
  "America/Regina": "-0600",
  "America/Guatemala": "-0600",
  "America/Bogota": "-0500",
  "America/New_York": "-0500",
  "America/Lima": "-0500",
  "America/Caracas": "-0430",
  "America/Halifax": "-0400",
  "America/Guyana": "-0400",
  "America/La_Paz": "-0400",
  "/Argentina/Buenos_Aires": "-0300",
  "America/Godthab": "-0300",
  "America/Montevideo": "-0300",
  "America/St_Johns": "-0330",
  "America/Santiago": "-0300",
  "America/Sao_Paulo": "-0200",
  "Atlantic/South_Georgia": "-0200",
  "Atlantic/Azores": "-0100",
  "Atlantic/Cape_Verde": "-0100",
  "Africa/Casablanca": "+0000",
  "Europe/Dublin": "+0000",
  "Europe/Lisbon": "+0000",
  "Europe/London": "+0000",
  "Africa/Monrovia": "+0000",
  "Africa/Algiers": "+0100",
  "Europe/Amsterdam": "+0100",
  "Europe/Berlin": "+0100",
  "Europe/Brussels": "+0100",
  "Europe/Budapest": "+0100",
  "Europe/Belgrade": "+0100",
  "Europe/Prague": "+0100",
  "Europe/Copenhagen": "+0100",
  "Europe/Madrid": "+0100",
  "Europe/Paris": "+0100",
  "Europe/Rome": "+0100",
  "Europe/Stockholm": "+0100",
  "Europe/Vienna": "+0100",
  "Europe/Warsaw": "+0100",
  "Europe/Athens": "+0200",
  "Europe/Bucharest": "+0200",
  "Africa/Cairo": "+0200",
  "Asia/Jerusalem": "+0200",
  "Africa/Johannesburg": "+0200",
  "Europe/Helsinki": "+0200",
  "Europe/Kiev": "+0200",
  "Europe/Kaliningrad": "+0200",
  "Europe/Riga": "+0200",
  "Europe/Sofia": "+0200",
  "Europe/Tallinn": "+0200",
  "Europe/Vilnius": "+0200",
  "Europe/Istanbul": "+0300",
  "Asia/Baghdad": "+0300",
  "Africa/Nairobi": "+0300",
  "Europe/Minsk": "+0300",
  "Asia/Riyadh": "+0300",
  "Europe/Moscow": "+0300",
  "Asia/Tehran": "+0330",
  "Asia/Baku": "+0400",
  "Europe/Samara": "+0400",
  "Asia/Tbilisi": "+0400",
  "Asia/Yerevan": "+0400",
  "Asia/Kabul": "+0430",
  "Asia/Karachi": "+0500",
  "Asia/Yekaterinburg": "+0500",
  "Asia/Tashkent": "+0500",
  "Asia/Colombo": "+0530",
  "Asia/Almaty": "+0600",
  "Asia/Dhaka": "+0600",
  "Asia/Rangoon": "+0630",
  "Asia/Bangkok": "+0700",
  "Asia/Jakarta": "+0700",
  "Asia/Krasnoyarsk": "+0700",
  "Asia/Shanghai": "+0800",
  "Asia/Hong_Kong": "+0800",
  "Asia/Kuala_Lumpur": "+0800",
  "Asia/Irkutsk": "+0800",
  "Asia/Singapore": "+0800",
  "Asia/Taipei": "+0800",
  "Asia/Ulaanbaatar": "+0800",
  "Australia/Perth": "+0800",
  "Asia/Yakutsk": "+0900",
  "Asia/Seoul": "+0900",
  "Asia/Tokyo": "+0900",
  "Australia/Darwin": "+0930",
  "Australia/Brisbane": "+1000",
  "Pacific/Guam": "+1000",
  "Asia/Magadan": "+1000",
  "Asia/Vladivostok": "+1000",
  "Pacific/Port_Moresby": "+1000",
  "Australia/Adelaide": "+1030",
  "Australia/Hobart": "+1100",
  "Australia/Sydney": "+1100",
  "Pacific/Guadalcanal": "+1100",
  "Pacific/Noumea": "+1100",
  "Pacific/Majuro": "+1200",
  "Asia/Kamchatka": "+1200",
  "Pacific/Auckland": "+1300",
  "Pacific/Fakaofo": "+1300",
  "Pacific/Fiji": "+1300",
  "Pacific/Tongatapu": "+1300",
  "Pacific/Apia": "+1400",
};
export default timezones;
