import PropTypes from "prop-types";
import React, { Component } from "react";
import classes from "./Input.scss";

/**
 * A reusable input component.
 */
class Input extends Component {
  /**
   * Creates a new Input component.
   * @param {Object} props - The props for the Input component.
   * @param {string} [props.type='text'] - The type of the input field.
   * @param {string} props.name - The name of the input field.
   * @param {string|number} [props.value] - The value of the input field.
   * @param {string|number} [props.defaultValue] - The default value of the input field.
   * @param {function} [props.onChange] - A function that is called when the input field's value changes.
   * @param {string} [props.placeholder] - The placeholder text for the input field.
   * @param {string} [props.label] - The label for the input field.
   * @param {boolean} [props.required=false] - Whether the input field is required.
   * @param {boolean} [props.disabled=false] - Whether the input field is disabled.
   * @param {number} [props.maxLength] - The maximum length of the input field.
   * @param {number} [props.minLength] - The minimum length of the input field.
   * @param {string} [props.pattern] - A regular expression pattern that the input field's value must match.
   * @param {string} [props.autoComplete] - Whether the input field should have autocomplete enabled.
   * @param {boolean} [props.autoFocus=false] - Whether the input field should be focused on mount.
   * @param {boolean} [props.readOnly=false] - Whether the input field is read-only.
   * @param {string} [props.className] - Additional CSS classes to apply to the input container.
   * @param {string} [props.id] - The ID of the input field.
   * @param {Object} [props.style] - Additional CSS styles to apply to the input container.
   * @param {function} [props.inputRef] - A ref to the input field.
   * @param {function} [props.validate] - A function that validates the input value.
   */
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue || "",
      error: [],
    };
  }

  /**
   * Updates the state of the component when the input value changes.
   * @param {Object} event - The input change event.
   */
  handleInputChange = (event) => {
    const { value } = event.target;
    const { validate, onChange } = this.props;

    let errors = [];
    if (validate) {
      for (let i = 0; i < validate.length; i++) {
        const validationResult = validate[i](value);
        if (validationResult) {
          errors.push(validationResult);
        }
      }
    }

    if (onChange) {
      onChange(event);
    }

    if (!this.props.value) {
      this.setState({
        value,
        error: errors.length > 0 ? errors : null,
      });
    }
  };

  /**
   * Renders the Input component.
   * @returns {JSX.Element} The rendered component.
   */
  render() {
    const {
      type = "text",
      name,
      value,
      // defaultValue,
      placeholder,
      label,
      required = false,
      disabled = false,
      maxLength,
      minLength,
      pattern,
      autoComplete,
      autoFocus = false,
      readOnly = false,
      className = "",
      id,
      style = {},
      inputRef,
    } = this.props;

    const { error } = this.state;
    const inputValue = value !== undefined ? value : this.state.value;

    return (
      <div className={`${classes.inputContainer} ${className}`} style={style}>
        {label && (
          <label htmlFor={id || name}>
            {label}
            {required && <span className={classes.required}>*</span>}
          </label>
        )}
        <input
          type={type}
          name={name}
          value={inputValue}
          onChange={this.handleInputChange}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          pattern={pattern}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          readOnly={readOnly}
          id={id || name}
          ref={inputRef}
        />
        {error?.length > 0 && <span className={classes.error}>{error[0]}</span>}
      </div>
    );
  }
}

Input.propTypes = {
  /**
   * The type of the input field.
   */
  type: PropTypes.oneOf([
    "text",
    "password",
    "email",
    "number",
    "tel",
    "url",
    "search",
  ]),
  /**
   * The name of the input field.
   */
  name: PropTypes.string.isRequired,
  /**
   * The value of the input field.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The default value of the input field.
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * A function that is called when the input field's value changes.
   */
  onChange: PropTypes.func,
  /**
   * The placeholder text for the input field.
   */
  placeholder: PropTypes.string,
  /**
   * The label for the input field.
   */
  label: PropTypes.string,
  /**
   * Whether the input field is required.
   */
  required: PropTypes.bool,
  /**
   * Whether the input field is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The maximum length of the input field.
   */
  maxLength: PropTypes.number,
  /**
   * The minimum length of the input field.
   */
  minLength: PropTypes.number,
  /**
   * A regular expression pattern that the input field's value must match.
   */
  pattern: PropTypes.string,
  /**
   * Whether the input field should have autocomplete enabled.
   */
  autoComplete: PropTypes.oneOf(["on", "off"]),
  /**
   * Whether the input field should be focused on mount.
   */
  autoFocus: PropTypes.bool,
  /**
   * Whether the input field is read-only.
   */
  readOnly: PropTypes.bool,
  /**
   * Additional CSS classes to apply to the input container.
   */
  className: PropTypes.string,
  /**
   * The ID of the input field.
   */
  id: PropTypes.string,
  /**
   * Additional CSS styles to apply to the input container.
   */
  style: PropTypes.object,
  /**
   * A ref to the input field.
   */
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  /**
   * A function that validates the input value.
   */
  validate: PropTypes.arrayOf(PropTypes.func),
};

export default Input;
