import EP from "consts/api";
import api from "modules/api";

export default class WhatsApp {
  static getWhatsAppTemplates = (suitId) =>
    api("get", EP.WHATSAPP.getWhatsAppTemplates(suitId));

  static getWhatsAppPhoneNumbers = (suitId, wabaId) =>
    api("get", EP.WHATSAPP.getWhatsAppPhoneNumbers(suitId, wabaId));

  static getWhatsAppBussinessAccountIds = (suitId, businessId) =>
    api("get", EP.WHATSAPP.getWhatsAppBussinessAccountIds(suitId, businessId));
}
