import { DatePicker } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Label from "../Label";
import classes from "./CustomDatePickerReduxForm.module.scss";

/**
 * CustomDatePickerReduxForm is a custom date picker component for use with Redux Form.
 * It uses the DatePicker component from the Ant Design library.
 *
 * @param {Object} props - The properties that define the component.
 * @param {Object} props.input - The input properties provided by Redux Form.
 * @param {Function} props.input.onChange - The function to be called when the date changes.
 * @param {string} props.input.value - The current value of the date picker.
 * @param {Function} props.disabledDate - A function that determines whether a date should be disabled in the date picker.
 * @param {Object} rest - The rest of the properties passed to the component.
 * @returns {JSX.Element} The rendered component.
 */
const CustomDatePickerReduxForm = ({ input: { onChange, value }, ...rest }) => {
  /**
   * Handle the change of the date.
   *
   * @param {moment.Moment} date - The date selected by the user.
   * @param {string} dateString - The date selected by the user, formatted as a string.
   */
  const handleChange = (date, dateString) => {
    onChange(dateString); // or onChange(date) if you want to store a moment object
  };

  return (
    <div className={classes.datePicker}>
      {rest?.label && <Label>{rest.label}</Label>}
      <DatePicker
        style={{
          width: "100%",
        }}
        {...rest}
        onChange={handleChange}
        value={value ? moment(value) : null}
        disabledDate={rest.disabledDate}
      />
    </div>
  );
};

export default CustomDatePickerReduxForm;

CustomDatePickerReduxForm.propTypes = {
  input: PropTypes.object,
};
