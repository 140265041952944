import { FormControl, FormGroup, InputGroup, Label } from "components";
import _ from "lodash";
import React from "react";
import { I18n } from "react-redux-i18n";
import { Toast } from "../../../modules/toast";
import classes from "./VideoUploader.scss";

const adaptFileEventToValue = (delegate) => (e) => {
  if (e.target.files.length > 0) {
    const fileSize = e.target.files[0].size;
    const fileType = e.target.files[0].type;
    if (fileSize > 500000) {
      Toast.error("File Size Exceeded 500KB");
    } else if (fileType !== "video/mp4") {
      Toast.error("File Type must be mp4");
    } else {
      delegate(e.target.files[0]);
    }
  }
};
const VideoUploader = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: omitMeta,
  hint,
  label,
  optional,
  required,
  ...props
}) => {
  let inputUploader = null;
  return (
    <FormGroup>
      {label && <Label className={classes.space}>{label}</Label>}
      {optional && <span className="pull-right">{I18n.t("optional")}</span>}
      {required && <span className="pull-right color-red">*</span>}
      <div className={classes.wrapper}>
        <InputGroup>
          <i
            className={`fa fa-video-camera ${classes["notification-icon"]}`}
            aria-hidden="true"
            onClick={() => {
              inputUploader.click();
            }}
          />
          {_.get(omitValue, "name", omitValue) && (
            <i
              className={`fa fa-times ${classes["times-icon"]}`}
              aria-hidden="true"
              onClick={() => {
                onChange("");
              }}
            />
          )}

          <input
            readOnly={_.get(omitValue, "name", false)}
            type="text"
            className={classes.readOnly}
            placeholder={props.placeholder}
            onChange={onChange}
            onBlur={onBlur}
            value={_.get(omitValue, "name", omitValue)}
          />
          <input
            ref={(component) => (inputUploader = component)}
            onChange={(e) => {
              adaptFileEventToValue(onChange)(e);
              e.target.value = null;
            }}
            onBlur={adaptFileEventToValue(onBlur)}
            type="file"
            className={classes.file}
            {...inputProps}
            {...props}
          />
        </InputGroup>
        <div style={{ display: "flex" }}>
          {omitMeta.touched && omitMeta.error && (
            <span
              style={{ fontSize: "13px", marginTop: "3px" }}
              className="text-danger"
            >
              {omitMeta.error}
            </span>
          )}
          {hint && <p>{hint}</p>}
        </div>
        <FormControl.Feedback />
      </div>
    </FormGroup>
  );
};
export default VideoUploader;
