import UserAPI from "../api/User";

// Actions
const SET_PAYMENTS = "SET_PAYMENTS";
const SET_LOADING = "SET_LOADING";
const SET_ERROR = "SET_ERROR";

export const setPaymentsConfig = (data) => ({
  type: SET_PAYMENTS,
  payload: data,
});

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data,
});

export const setError = (data) => ({
  type: SET_ERROR,
  payload: data,
});

export const getPayments = (suitId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(setPaymentsConfig(null));

    const res = await UserAPI.getPaymentConfig(suitId);
    dispatch(setPaymentsConfig(res.body?.payments));
  } catch (err) {
    console.error("error", err);
    dispatch(setError(err));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updatePayments = (suitId, data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(setPaymentsConfig(null));

    await UserAPI.updatePaymentConfig(suitId, data);
    await dispatch(getPayments(suitId));
  } catch (err) {
    console.error("error", err);
    dispatch(setError(err));
  } finally {
    dispatch(setLoading(false));
  }
};

// Initial State
const initialState = {
  config: null,
  loading: false,
  error: null,
};

// Reducer
export default function PaymentsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENTS:
      return {
        ...state,
        config: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
