import PropTypes from "prop-types";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const CustomPhonePicker = (props) => {
  const { value, onChange, placeholder, required, name, inputStyle, country } =
    props;

  return (
    <PhoneInput
      country={country}
      enableSearch
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      autoFormat={false}
      countryCodeEditable={false}
      inputProps={{
        required,
        name,
      }}
      inputStyle={{
        width: "100%",
        ...inputStyle,
      }}
      isValid={(inputNumber, country) => {
        if (country.name === "Egypt") {
          return (
            inputNumber.length === 12 &&
            inputNumber[2] === "1" &&
            ["0", "1", "2", "5"].includes(inputNumber[3])
          );
        }
        return true;
      }}
    />
  );
};

export default CustomPhonePicker;

CustomPhonePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  inputStyle: PropTypes.object,
  country: PropTypes.string,
};
