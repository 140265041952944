import MobileAttributes from "api/MobileAttributes";
import { CURRENCY, DATE_TYPE, NUMBER, STRING } from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";

// const
const GET_MOBILE_ATTRIBUTION_DATA_FULLFILLED =
  "GET_MOBILE_ATTRIBUTION_DATA_FULLFILLED";
const GET_MOBILE_ATTRIBUTION_DATA_REJECTED =
  "GET_MOBILE_ATTRIBUTION_DATA_REJECTED";
const LOAD_SCREEN_ALERT = "LOAD_SCREEN_ALERT";
const SORT_MOBILE_ATTRIBUTES = "SORT_MOBILE_ATTRIBUTES";
const CHECK_DATATABLE_ROW = "CHECK_DATATABLE_ROW";
const CHECK_ALL_TABLE_ROWS = "CHECK_ALL_TABLE_ROWS";
const MOBILE_ATTRIBUTES_UNMOUNT = "MOBILE_ATTRIBUTES_UNMOUNT";
const MOBILE_ATTRIBUTES_COLUMN_TOGGLE = "MOBILE_ATTRIBUTES_COLUMN_TOGGLE";
const MOBILE_ATTRIBUTES_RESET_COLUMNS = "MOBILE_ATTRIBUTES_RESET_COLUMNS";

export function loadScreenAlert() {
  return {
    type: LOAD_SCREEN_ALERT,
  };
}

export function checkDataTableRow(idx) {
  return {
    type: CHECK_DATATABLE_ROW,
    payload: idx,
  };
}

export function checkAllTableRows(new_checked) {
  return {
    type: CHECK_ALL_TABLE_ROWS,
    payload: new_checked,
  };
}

export function onColumnToggle(idx) {
  return {
    type: MOBILE_ATTRIBUTES_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: MOBILE_ATTRIBUTES_RESET_COLUMNS,
  };
}

const getFilterData = async ({ suitId, apiKey }) => {
  const response = await MobileAttributes.getFilters(suitId, apiKey);
  return {
    tags: [
      ...new Set(
        response.body.smartlinks
          .map((result) => result.tag)
          .filter((result) => result)
      ),
    ],
    mediaSources: [
      ...new Set(
        response.body.smartlinks
          .map((result) => result.media_source)
          .filter((result) => result)
      ),
    ],
    campaigns: [
      ...new Set(
        response.body.smartlinks
          .map((result) => result.name)
          .filter((result) => result)
      ),
    ],
  };
};

const getCardsData = async ({ suitId, apiKey, start, end, filter, value }) => {
  const response = await MobileAttributes.getCardsData(
    suitId,
    apiKey,
    start,
    end,
    filter,
    value
  );
  return {
    totalCounts: response.body.total_counts,
    comparison: response.body.comparison,
  };
};

const getChartData = async ({ suitId, apiKey, start, end, filter, value }) => {
  const response = await MobileAttributes.getChartData(
    suitId,
    apiKey,
    start,
    end,
    filter,
    value
  );
  let data = response.body;
  data = data.map((el) => {
    let sortDate = el.date;
    sortDate += "-1";
    sortDate = moment(sortDate, "YYYY-MM-DD");
    return { ...el, sortDate };
  });
  return _.sortBy(data, "sortDate").map((element) => {
    return {
      clicksToOpen: element.clicks_to_open,
      clicksToInstall: element.clicks_to_install,
      totalClicks: element.total_clicks,
      date: element.date,
    };
  });
};

const getTableData = async ({ suitId, apiKey, start, end, filter, value }) => {
  const response = await MobileAttributes.getTableData(
    suitId,
    apiKey,
    start,
    end,
    filter,
    value
  );
  return response.body;
};

function capitalize(word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

function prepareTableDate(tableData, suit) {
  const suitEvents = suit.custom_events;
  const customEvents = [];
  let dataKeys = [];
  // get all keys

  tableData.forEach((row) => dataKeys.push(...Object.keys(row)));
  dataKeys = new Set(dataKeys);
  suitEvents?.forEach((event) => {
    if (event.actions) {
      event.actions.forEach((action) => {
        if (dataKeys.has(action)) {
          customEvents.push({
            name: capitalize(action),
            has_sort: true,
            type: NUMBER,
            selected: true,
            varName: [action],
            defaultValue: 0,
          });
        }
      });
    }
  });

  const labels = [
    {
      name: "Name",
      has_sort: true,
      type: STRING,
      selected: true,
      varName: ["_id", "name"],
      defaultValue: "",
    },
    {
      name: "Clicks",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: ["total_clicks"],
      defaultValue: 0,
    },
    {
      name: "Clicks to install",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: ["clicks_to_install"],
    },
    {
      name: "Clicks to open",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: ["clicks_to_open"],
    },
    {
      name: "Revenue",
      has_sort: true,
      type: CURRENCY,
      selected: true,
      varName: ["total_revenue"],
    },
    ...customEvents,
  ];

  const rows = tableData.map((row) => ({
    checked: false,
    values: [
      ...labels.map((label) => _.get(row, label.varName, label.defaultValue)),
    ],
  }));
  return { labels, rows };
}

export const getFilteredData =
  (suitId, apiKey, start, end, filter, value, suit) => async (dispatch) => {
    dispatch({ type: LOAD_SCREEN_ALERT });
    try {
      const params = { suitId, apiKey, start, end, filter, value, suit };
      const results = await Promise.allSettled([
        getFilterData(params),
        getTableData(params),
        getChartData(params),
        getCardsData(params),
      ]);

      dispatch({
        type: GET_MOBILE_ATTRIBUTION_DATA_FULLFILLED,
        payload: {
          filters: results[0].value,
          tableData: prepareTableDate(results[1].value, suit),
          chartData: results[2].value,
          cardsData: results[3].value,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_MOBILE_ATTRIBUTION_DATA_REJECTED,
        payload: error,
      });
    }
  };

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_MOBILE_ATTRIBUTES,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

export function unmountMobileAttribute() {
  return {
    type: MOBILE_ATTRIBUTES_UNMOUNT,
  };
}

const initialState = {
  filters: {
    tags: [],
    campaigns: [],
    mediaSources: [],
  },
  cardsData: null,
  chartData: null,
  tableData: null,
  isPending: false,
};

export default function MobileAttributeReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SCREEN_ALERT:
      return {
        ...state,
        isPending: true,
      };

    case MOBILE_ATTRIBUTES_UNMOUNT:
      return {
        ...initialState,
      };

    case GET_MOBILE_ATTRIBUTION_DATA_FULLFILLED:
      return {
        ...state,
        isPending: false,
        error: false,
        filters: action.payload.filters,
        tableData: action.payload.tableData,
        chartData: action.payload.chartData,
        cardsData: action.payload.cardsData,
      };
    case GET_MOBILE_ATTRIBUTION_DATA_REJECTED:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case CHECK_DATATABLE_ROW:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.tableData.rows[rowIdx],
                  checked: !state.tableData.rows[rowIdx].checked,
                }
              : state.tableData.rows[rowIdx];
          }),
        },
      };
    case CHECK_ALL_TABLE_ROWS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    case SORT_MOBILE_ATTRIBUTES: {
      const indexOfKey = state.tableData.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.tableData.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: tableRows,
        },
      };
    }

    case MOBILE_ATTRIBUTES_COLUMN_TOGGLE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };

    case MOBILE_ATTRIBUTES_RESET_COLUMNS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };
    default:
      return state;
  }
}
