import PropTypes from "prop-types";
import React from "react";
import { I18n } from "react-redux-i18n";
import PrimaryButton from "../Form/Buttons/PrimaryButton/PrimaryButton";
import NewBreadcrumb from "../NewBreadcrumb/NewBreadcrumb";
import classes from "./ClosedState.scss";

const ClosedState = ({
  title1,
  title2,
  sectionTitle,
  description,
  imgSrc,
  onClick,
}) => {
  return (
    <section className={classes.closedState}>
      {title1 && title2 && (
        <div className={classes.header}>
          <NewBreadcrumb fullPath={[title1, title2]} />
        </div>
      )}
      <div className={classes.container}>
        <div className={classes.infoCard}>
          <h1 className={classes.title}>{I18n.t(sectionTitle)}</h1>

          <p className={classes.desc}>{I18n.t(description)}</p>
          <PrimaryButton
            icon={() => <i className="fa fa-lock" />}
            style={{ width: "225px" }}
            onClick={onClick}
            value={I18n.t("Upgrade to Unlock")}
          />
        </div>

        <img src={imgSrc} alt={sectionTitle} className={classes.image} />
      </div>
    </section>
  );
};
export default ClosedState;

ClosedState.propTypes = {
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
