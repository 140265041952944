import React from "react";
import classes from "./CustomTooltip.scss";

const CustomTooltip = ({ style, className, position, behind, children }) => {
  return (
    <div style={style} className={`${classes["content"]} ${className}`}>
      <p>
        <span className={classes[`tooltip-${position}`]} data-tooltip={behind}>
          {children}
        </span>
      </p>
    </div>
  );
};

export default CustomTooltip;
