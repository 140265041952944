import { Col, EmojiPicker, Form, Label, Row, TextBox } from "components";
import GIFUploader from "components/Form/GIFUploader/GIFUploader";
import ImageUploader from "components/Form/ImageUploader/ImageUploader";
import TextArea from "components/Form/TextArea/TextArea";
import VideoUploader from "components/Form/VideoUploader/VideoUploader";
import validConsts from "consts/validations";
import $ from "jquery";
import _ from "lodash";
import {
  gifURL,
  maxLength,
  minLength,
  mp4URL,
  required,
  validUrl,
} from "modules/validations";
import React from "react";
import ReactDOM from "react-dom";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import Select from "react-select";
import { Field, change as ReduxChange, reduxForm } from "redux-form";
import classes from "../CreateAppPush.scss";
import GIF from "./CustomizedGIF";
import GIFComponent from "./GIF";

const urlOptions = [
  { value: "url", label: I18n.t("URL") },
  {
    value: "deepLink",
    label: I18n.t("appDeepLink"),
  },
  {
    value: "smartLink",
    label: I18n.t("smartLink"),
  },
  {
    value: "microPage",
    label: I18n.t("microPage"),
  },
];

const videoOptions = [
  {
    label: I18n.t("createAppPushPage_landscape_label"),
    value: "landscape",
  },
  {
    label: I18n.t("createAppPushPage_Portrait_label"),
    value: "portrait",
  },
];

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuList: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const maxLength160 = maxLength(160);
const maxLength38 = maxLength(38);
const minLength5 = minLength(5);
const maxLenght2000 = maxLength(2000);
const validateYoutubeUrl = (url) =>
  url.match(
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
  )
    ? undefined
    : "Must be a valid youtube url";

const globalValidUrl = (value) =>
  value && !validConsts.regex.validUrl.test(value)
    ? I18n.t("createAppPush_PushcontentStep_validUrlMsg")
    : undefined;

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

class PushContentStep extends React.Component {
  state = {
    videoSelection: {
      label: I18n.t("createAppPushPage_Portrait_label"),
      value: "portrait",
    },
    ApppushGifURL: null,
    validation: true,
    fetchedGifs: null,
  };

  componentWillMount() {
    if (
      this.props.pushContentStep.values.action.appPush.orientation ===
      "portrait"
    ) {
      this.props.changeVideoOrientation({
        label: I18n.t("createAppPushPage_Portrait_label"),
        value: "portrait",
      });
    } else {
      this.props.changeVideoOrientation({
        label: I18n.t("createAppPushPage_landscape_label"),
        value: "landscape",
      });
    }

    if (
      this.props.pushContentStep &&
      this.props.pushContentStep.values &&
      !this.props.pushContentStep.values.action.appPush.orientation
    ) {
      this.props.dispatch(
        ReduxChange("pushContentStep", "action.appPush.orientation", "portrait")
      );
      this.setState({
        videoSelection: {
          label: I18n.t("createAppPushPage_Portrait_label"),
          value: "portrait",
        },
      });
    }
  }

  // set the selected gif url in redux form  in the state to to set it as a value of the input feild
  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).hide().fadeIn(250);

    this.setState({
      ApppushGifURL: this.props.pushContentStep.values.apppushGif,
    });
  }

  // every time the gif url value changed set the new value in the state and send it into the prop to save it in redux form
  componentWillReceiveProps(nextProps) {
    let newApppushGif = nextProps.pushContentStep.values.apppushGif;
    let oldApppushGif = this.props.pushContentStep.values.apppushGif;
    if (newApppushGif !== oldApppushGif) {
      this.setState({
        ApppushGifURL: newApppushGif,
      });
      this.props.dispatch(
        ReduxChange("pushContentStep", "apppushGif", newApppushGif)
      );
    }

    let uploadedGif = nextProps.appPushGif;
    if (uploadedGif && uploadedGif !== oldApppushGif) {
      this.props.dispatch(
        ReduxChange("pushContentStep", "apppushGif", uploadedGif)
      );
    }
  }

  onGifClick = (idx) => {
    this.props.dispatch(
      ReduxChange(
        "pushContentStep",
        "action.appPush.apppushGif",
        this.state.fetchedGifs[idx].media[0].tinygif.url
      )
    );
    this.props.handleChange(
      "action.appPush.apppushGif",
      this.state.fetchedGifs[idx].media[0].tinygif.url
    );
    $(this.gifList).slideUp(500, () => {
      this.setState({ fetchedGifs: null });
    });
  };

  getValue = (templateName) => {
    const {
      templates: { TemplatesObjects },
    } = this.props;
    if (templateName === "noTemplate") {
      return this.props.initialValues.action.appPush.message;
    }
    for (let i = 0; i < TemplatesObjects.length; ++i) {
      if (TemplatesObjects[i].template_name === templateName) {
        return TemplatesObjects[i].content;
      }
    }
  };

  // check if the input value is a gif url or not
  apppushGifURLFun = (e) => {
    let girUrl = e.target.value;
    if (girUrl.match(/\.(gif)$/) !== null) {
      // save the gif url in the state and in redux form
      this.setState({
        ApppushGifURL: girUrl,
      });
      this.props.dispatch(ReduxChange("pushContentStep", "apppushGif", girUrl));
    } else {
      // clear redux form gif url value
      this.props.dispatch(ReduxChange("pushContentStep", "apppushGif", null));
    }
  };

  clearGif = () => {
    this.props.dispatch(ReduxChange("pushContentStep", "apppushGif", ""));
  };

  fetchGifs = (fetchedGifs) => {
    this.setState({ fetchedGifs });
  };

  handleToUpdate = (condition) => {
    this.setState({ validation: condition });
  };

  resetGifs = () => {
    this.setState({ fetchedGifs: null });
  };

  renderGif = () => {
    return (
      <GIF
        handleGifSelected={(val) => {
          this.props.dispatch(
            ReduxChange("pushContentStep", "apppushGif", val)
          );
        }}
      />
    );
  };

  render() {
    const {
      handleChange,
      type = "appPush",
      config,
      urlValue,
      handleURLValueChange,
      urlType,
      change,
      handleSelectUrl,
      urlTypeForOptionalImage,
      urlValueForOptionalImage,
      handleOptionalUrlImageSelect,
      handleOptionalUrlImageSelectType,
      selectedLocalization,
      handleSelectTemplate,
      templates: { TemplatesObjects },
      smartLinks: { smartlinks },
      MicroPages: { landingpages: microPages },
    } = this.props;

    return (
      <Form>
        {this.props.pushMessageStep.values.type
          .toLowerCase()
          .includes("text") && (
          <div>
            <div className={`form-row ${classes["message-form-input-cont"]}`}>
              {selectedLocalization.code === "ar" ? (
                <Field
                  name={`action.${type}.title-${selectedLocalization.code}`}
                  className={classes["message-form-input"]}
                  component={TextArea}
                  label={I18n.t("Title_label")}
                  validate={[minLength5, maxLength160]}
                  minRecommended={5}
                  maxRecommended={160}
                  value={
                    config.action.appPush[`title-${selectedLocalization.code}`]
                  }
                  onChange={(e, value) => {
                    handleChange(
                      `action.${type}.title-${selectedLocalization.code}`,
                      value
                    );
                  }}
                  placeholder={`Title (${capitalize(
                    selectedLocalization.name
                  )})`}
                />
              ) : config.action.appPush && config.action.appPush[`title-en`] ? (
                <Field
                  name={`action.${type}.title-${selectedLocalization.code}`}
                  className={classes["message-form-input"]}
                  component={TextArea}
                  label={I18n.t("Title_label")}
                  validate={[minLength5, maxLength160]}
                  minRecommended={5}
                  maxRecommended={160}
                  value={
                    config.action.appPush[`title-${selectedLocalization.code}`]
                  }
                  onChange={(e, value) => {
                    handleChange(
                      `action.${type}.title-${selectedLocalization.code}`,
                      value
                    );
                  }}
                  placeholder={`Title (${capitalize(
                    selectedLocalization.name
                  )})`}
                />
              ) : (
                <Field
                  name={`action.${type}.title-${selectedLocalization.code}`}
                  className={classes["message-form-input"]}
                  component={TextArea}
                  label={I18n.t("Title_label")}
                  validate={[minLength5, maxLength160]}
                  minRecommended={5}
                  maxRecommended={160}
                  value={config.action.appPush.title}
                  onChange={(e, value) => {
                    handleChange(
                      `action.${type}.title-${selectedLocalization.code}`,
                      value
                    );
                  }}
                  placeholder={`Title (${capitalize(
                    selectedLocalization.name
                  )})`}
                />
              )}
              <EmojiPicker
                handleChange={(emoji) => {
                  const path = `action.${type}.title-${selectedLocalization.code}`;
                  const value = `${_.get(config, path, "")}${emoji}`;
                  change(path, value);
                  handleChange(path, value);
                }}
              />
            </div>

            <div className={`form-row ${classes["message-form-input-cont"]}`}>
              <Field
                name={`action.${type}.message-${selectedLocalization.code}`}
                className={classes["message-form-input"]}
                component={TextArea}
                label={I18n.t("messageText")}
                validate={[
                  minLength5,
                  this.props.pushMessageStep.values.type
                    .toLowerCase()
                    .includes("image")
                    ? maxLength38
                    : maxLength160,
                ]}
                minRecommended={5}
                maxRecommended={
                  this.props.pushMessageStep.values.type
                    .toLowerCase()
                    .includes("image")
                    ? 38
                    : 160
                }
                value={
                  config.action.appPush[`message-${selectedLocalization.code}`]
                }
                onChange={(e, value) =>
                  handleChange(
                    `action.${type}.message-${selectedLocalization.code}`,
                    value
                  )
                }
                placeholder={`Message (${capitalize(
                  selectedLocalization.name
                )})`}
              />

              <EmojiPicker
                handleChange={(emoji) => {
                  const path = `action.${type}.message-${selectedLocalization.code}`;
                  const value = `${_.get(config, path, "")}${emoji}`;
                  change(path, value);
                  handleChange(path, value);
                }}
              />
            </div>
            <div>
              <Label className={classes["my-form-label"]}>
                {I18n.t("messagetemplate")}
              </Label>
              <Select
                styles={customStyles}
                className={classes["select-style"]}
                value={this.props.selectedTemplate}
                onChange={(selectedTemplate) => {
                  const value = selectedTemplate.label;

                  handleSelectTemplate(
                    selectedLocalization.code,
                    selectedTemplate
                  );
                  const template = TemplatesObjects.filter(
                    (template) =>
                      template.template_name === value &&
                      template.type === "appPush"
                  );
                  if (value === "Select Template") {
                    handleChange(
                      `action.${type}.message-${selectedLocalization.code}`,
                      ""
                    );
                    this.props.dispatch(
                      ReduxChange(
                        "pushContentStep",
                        `action.appPush.message-${selectedLocalization.code}`,
                        ""
                      )
                    );
                  } else {
                    handleChange(
                      `action.${type}.message-${selectedLocalization.code}`,
                      template[0].content
                    );
                    this.props.dispatch(
                      ReduxChange(
                        "pushContentStep",
                        `action.appPush.message-${selectedLocalization.code}`,
                        this.getValue(value)
                      )
                    );
                  }
                }}
                options={[
                  { label: "Select Template", value: "noTemplate" },
                  ...(!TemplatesObjects
                    ? []
                    : TemplatesObjects.map((template) => ({
                        label: template.template_name,
                        value: template.template_id,
                      }))),
                ]}
              />
            </div>
          </div>
        )}
        {this.props.pushMessageStep.values.type
          .toLowerCase()
          .includes("silent") && (
          <div className={classes["message-youtube-cont"]}>
            <Field
              label={I18n.t("createAppPush_PushcontentStep_operation_label")}
              className={classes["message-form-input"]}
              name={"action.appPush.silent"}
              placeholder={I18n.t(
                "createAppPush_PushcontentStep_operation_placeholder"
              )}
              validate={[required]}
              value={config.action.appPush.silent}
              component={TextBox}
            />
          </div>
        )}
        {this.props.pushMessageStep.values.action.appPush.selectedType
          .toLowerCase()
          .includes("web") &&
          this.props.pushMessageStep.values.action.appPush.selectedType
            .toLowerCase()
            .includes("view") &&
          this.props.pushMessageStep.values.action.appPush.selectedType
            .toLowerCase()
            .includes("html") && (
            <div
              style={{ marginTop: "1.5rem" }}
              className={classes["message-form-input-cont"]}
            >
              <Field
                name={"action.appPush.htmlcode"}
                label={I18n.t("createAppPush_PushcontentStep_HTML")}
                placeholder={I18n.t("createAppPush_PushcontentStep_HTML")}
                className={classes["message-form-input"]}
                maxRecommended={2000}
                validate={[required, maxLenght2000]}
                component={TextArea}
                rows={5}
                value={config.action.appPush.htmlcode}
                hint={I18n.t("createAppPush_PushcontentStep_HTMl_hint")}
              />
            </div>
          )}
        {this.props.pushMessageStep.values.type.toLowerCase().includes("web") &&
          this.props.pushMessageStep.values.type
            .toLowerCase()
            .includes("view") &&
          !this.props.pushMessageStep.values.type
            .toLowerCase()
            .includes("html") && (
            <div className={classes["message-youtube-cont"]}>
              <Field
                label={I18n.t("createAppPush_PushcontentStep_WebpageURL_label")}
                name={"action.appPush.htmlurl"}
                className={classes["message-form-input"]}
                placeholder={I18n.t(
                  "createAppPush_PushcontentStep_WebpageURL_placeholder"
                )}
                validate={[required, globalValidUrl]}
                value={config.action.appPush.htmlurl}
                component={TextBox}
              />
            </div>
          )}
        {this.props.pushMessageStep.values.type
          .toLowerCase()
          .includes("url") && (
          <div>
            <Label
              className={classes["my-form-label"]}
              style={{ marginTop: "1.4rem" }}
            >
              {I18n.t("LaunchURL")}
            </Label>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",
                gap: "16px",
              }}
            >
              <div
                style={{
                  gridColumn: "1 / span 2",
                }}
              >
                <Select
                  styles={customStyles}
                  className={classes["select-style"]}
                  value={urlType}
                  onChange={(selectedLanuchUrl) => {
                    const selectedType = selectedLanuchUrl.value;
                    const url =
                      selectedType === "smartLink"
                        ? _.get(smartlinks, "[0].url", null)
                        : selectedType === "deepLink" || selectedType === "url"
                          ? ""
                          : selectedType === "microPage"
                            ? _.get(microPages, "[0].url", null)
                            : null;
                    const name =
                      selectedType === "smartLink"
                        ? _.get(smartlinks, "[0].name", null)
                        : selectedType === "deepLink" || selectedType === "url"
                          ? ""
                          : selectedType === "microPage"
                            ? _.get(microPages, "[0].name", null)
                            : null;

                    const { label, value } = selectedLanuchUrl;
                    handleSelectUrl(label, value);
                    handleURLValueChange(name, url);
                    this.props.dispatch(
                      ReduxChange("pushContentStep", `action.${type}.url`, url)
                    );
                  }}
                  options={urlOptions
                    .filter(
                      (option) =>
                        !(
                          (option.value === "microPage" && !microPages) ||
                          (option.value === "smartLink" && !smartlinks)
                        )
                    )
                    .map(
                      ({ label, value }) =>
                        (type !== "webPush" ||
                          label !== I18n.t("app deep link")) && {
                          label,
                          value,
                        }
                    )}
                />
              </div>
              <div
                className={classes["optional-image-url"]}
                style={{
                  gridColumn: "3 / span 5",
                }}
              >
                {urlType.value === "url" ? (
                  <Field
                    name={`action.${type}.url`}
                    value={config.action.appPush.url}
                    className={classes["optional-image-url-input"]}
                    placeholder={I18n.t("enterURL")}
                    validate={[required, validUrl]}
                    component={TextBox}
                    onChange={(e, value) => {
                      handleChange(`action.${type}.url`, value);
                      this.props.dispatch(
                        ReduxChange(
                          "pushContentStep",
                          `action.${type}.url`,
                          value
                        )
                      );
                    }}
                  />
                ) : urlType.value === "deepLink" && type !== "webPush" ? (
                  <Field
                    name={`action.${type}.url`}
                    value={config.action.appPush.url}
                    placeholder={I18n.t("selectHint")}
                    validate={[required, validUrl]}
                    component={TextBox}
                    onChange={(e, value) => {
                      handleChange(`action.${type}.url`, value);
                      this.props.dispatch(
                        ReduxChange(
                          "pushContentStep",
                          `action.${type}.url`,
                          value
                        )
                      );
                    }}
                    deepLinkBuilder
                    FiledNotexist
                    onlymdp
                    readOnly
                  />
                ) : urlType.value === "smartLink" ? (
                  <Select
                    styles={customStyles}
                    className={classes["select-style"]}
                    value={urlValue}
                    onChange={(selectedSmartLinkUrl) => {
                      const e = {
                        target: { value: selectedSmartLinkUrl.value },
                      };
                      const { label, value } = selectedSmartLinkUrl;
                      handleURLValueChange(label, value);
                      this.props.dispatch(
                        ReduxChange(
                          "pushContentStep",
                          `action.${type}.url`,
                          e.target.value
                        )
                      );
                    }}
                    options={smartlinks.map((link) => ({
                      label: link.name,
                      value: link.url,
                    }))}
                  />
                ) : urlType.value === "microPage" ? (
                  <Select
                    styles={customStyles}
                    className={classes["select-style"]}
                    value={urlValue}
                    onChange={(selectedLandingPage) => {
                      const e = {
                        target: { value: selectedLandingPage.value },
                      };
                      const { label, value } = selectedLandingPage;
                      handleURLValueChange(label, value);
                      this.props.dispatch(
                        ReduxChange(
                          "pushContentStep",
                          `action.${type}.url`,
                          e.target.value
                        )
                      );
                    }}
                    options={microPages.map((link) => ({
                      label: link.name,
                      value: link.url,
                    }))}
                  />
                ) : null}
              </div>
            </div>
          </div>
        )}
        {this.props.pushMessageStep.values.type
          .toLowerCase()
          .includes("image") && (
          <div>
            <div className={classes["apppush-notification-icon"]}>
              <Field
                name="action.appPush.attachment"
                label={I18n.t(
                  "createAppPush_PushcontentStep_SingleImage_label"
                )}
                accept="image/*"
                validate={required}
                showNotification={this.props.showNotification}
                requiredWidth={512}
                requiredHeight={512}
                component={ImageUploader}
                iconClass={
                  this.props.imgLoading["socialmedia_settings.image"]
                    ? "fa fa-spinner fa-spin"
                    : "fa fa-plus"
                }
                onChange={(...args) => {
                  this.props.handleUploadImg(
                    "action.appPush.attachment",
                    ...args
                  );
                }}
                hint={I18n.t("SingleImage_hint")}
                placeholder={I18n.t("ImageSource")}
              />
            </div>

            <Row>
              <Col xs={5}>
                <div>
                  <Label className={classes["my-form-label"]}>
                    {I18n.t("LaunchURL")}
                  </Label>
                  <Select
                    styles={customStyles}
                    className={classes["select-style"]}
                    value={urlTypeForOptionalImage}
                    onChange={(selectedLanuchUrl) => {
                      const e = {
                        target: { value: selectedLanuchUrl.value },
                      };
                      const optionValueURL =
                        e.target.value === "smartLink"
                          ? _.get(smartlinks, "[0].url", null)
                          : e.target.value === "deepLink" ||
                              e.target.value === "url"
                            ? ""
                            : e.target.value === "microPage"
                              ? _.get(microPages, "[0].url", null)
                              : null;
                      const optionValueName =
                        e.target.value === "smartLink"
                          ? _.get(smartlinks, "[0].name", null)
                          : e.target.value === "deepLink" ||
                              e.target.value === "url"
                            ? ""
                            : e.target.value === "microPage"
                              ? _.get(microPages, "[0].name", null)
                              : null;

                      const { label, value } = selectedLanuchUrl;
                      handleOptionalUrlImageSelectType(label, value);
                      handleOptionalUrlImageSelect(
                        optionValueName,
                        optionValueURL
                      );
                      this.props.dispatch(
                        ReduxChange(
                          "pushContentStep",
                          `action.${type}.url`,
                          optionValueURL
                        )
                      );
                    }}
                    options={urlOptions
                      .filter(
                        (option) =>
                          !(
                            (option.value === "microPage" && !microPages) ||
                            (option.value === "smartLink" && !smartlinks)
                          )
                      )
                      .map(
                        ({ label, value }) =>
                          (type !== "webPush" ||
                            label !== I18n.t("app deep link")) && {
                            label,
                            value,
                          }
                      )}
                  />
                </div>
              </Col>
              <Col xs={7}>
                <div className={classes["optional-image-url"]}>
                  {urlTypeForOptionalImage.value === "url" ? (
                    <Field
                      name={`action.${type}.url`}
                      className={classes["optional-image-url-input"]}
                      placeholder={I18n.t("enterURL")}
                      validate={[validUrl]}
                      component={TextBox}
                      onChange={(e, value) => {
                        handleChange(`action.${type}.url`, value);
                        this.props.dispatch(
                          ReduxChange(
                            "pushContentStep",
                            `action.${type}.url`,
                            value
                          )
                        );
                      }}
                    />
                  ) : urlTypeForOptionalImage.value === "deepLink" &&
                    type !== "webPush" ? (
                    <Field
                      name={`action.${type}.url`}
                      placeholder={I18n.t("selectHint")}
                      validate={[validUrl]}
                      component={TextBox}
                      onChange={(e, value) => {
                        handleChange(`action.${type}.url`, value);
                        this.props.dispatch(
                          ReduxChange(
                            "pushContentStep",
                            `action.${type}.url`,
                            value
                          )
                        );
                      }}
                      deepLinkBuilder
                      FiledNotexist
                      onlymdp
                      readOnly
                    />
                  ) : urlTypeForOptionalImage.value === "smartLink" ? (
                    <Select
                      styles={customStyles}
                      className={classes["select-style"]}
                      value={urlValueForOptionalImage}
                      onChange={(selectedSmartLinkUrl) => {
                        const e = {
                          target: { value: selectedSmartLinkUrl.value },
                        };
                        const { label, value } = selectedSmartLinkUrl;
                        handleOptionalUrlImageSelect(label, value);
                        this.props.dispatch(
                          ReduxChange(
                            "pushContentStep",
                            `action.${type}.url`,
                            e.target.value
                          )
                        );
                      }}
                      options={smartlinks.map((link) => ({
                        label: link.name,
                        value: link.url,
                      }))}
                    />
                  ) : urlTypeForOptionalImage.value === "microPage" ? (
                    <Select
                      styles={customStyles}
                      className={classes["select-style"]}
                      value={urlValueForOptionalImage}
                      onChange={(selectedLandingPage) => {
                        const e = {
                          target: { value: selectedLandingPage.value },
                        };
                        const { label, value } = selectedLandingPage;
                        handleOptionalUrlImageSelect(label, value);
                        this.props.dispatch(
                          ReduxChange(
                            "pushContentStep",
                            `action.${type}.url`,
                            e.target.value
                          )
                        );
                      }}
                      options={microPages.map((link) => ({
                        label: link.name,
                        value: link.url,
                      }))}
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        )}
        {this.props.pushMessageStep.values.type
          .toLowerCase()
          .includes("gif") && (
          <div className={classes["message-form-input"]}>
            <div className={classes["gif-input-form-container"]}>
              <Field
                name="action.appPush.apppushGif"
                label={I18n.t("GIF")}
                accept="image/gif"
                clearGifAppPush={this.props.clearGifAppPush}
                component={GIFUploader}
                setVideoValue={this.props.setVideoValue}
                showNotification={this.props.showNotification}
                iconClass={
                  this.props.imgLoading["apppushGif"]
                    ? "fa fa-spinner fa-spin"
                    : "fa fa-plus"
                }
                handleChange={this.props.handleChange}
                value={config.action.appPush.apppushGif}
                onChange={(...args) => {
                  if (_.get(args[1], "name", false)) {
                    this.props.handleUploadImg(
                      "action.appPush.apppushGif",
                      ...args
                    );
                  } else {
                    this.props.setGifValue(args[1]);
                  }
                }}
                validate={[required, gifURL]}
                hint={I18n.t("createAppPush_PushcontentStep_GIF_hint")}
                placeholder={I18n.t(
                  "createAppPush_PushcontentStep_GIF_placeholder"
                )}
              />

              <div>{I18n.t("or")}</div>

              <GIFComponent
                fetchGifs={this.fetchGifs}
                resetGifs={this.resetGifs}
              />
            </div>
            {this.state.fetchedGifs && (
              <div
                ref={(component) => (this.gifList = component)}
                className={classes["gifs-list-container"]}
              >
                {this.state.fetchedGifs.length > 0 &&
                  this.state.fetchedGifs.map((gif, idx) => (
                    <div
                      key={idx}
                      className={gif.checked ? classes["active-gif"] : ""}
                      onClick={() => this.onGifClick(idx)}
                    >
                      <img src={gif.media[0].tinygif.url} />
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}
        {this.props.pushMessageStep.values.action.appPush.selectedType
          .toLowerCase()
          .includes("html") &&
          this.props.pushMessageStep.values.action.appPush.selectedType
            .toLowerCase()
            .includes("web") &&
          this.props.pushMessageStep.values.action.appPush.selectedType
            .toLowerCase()
            .includes("video") && (
            <div>
              <div className={classes["message-youtube-cont"]}>
                <Field
                  name="action.appPush.youtubeUrl"
                  className={classes["message-form-input"]}
                  label={I18n.t(
                    "createAppPush_PushcontentStep_YoutubeURL_label"
                  )}
                  validate={[required, validateYoutubeUrl]}
                  component={TextBox}
                  placeholder={I18n.t(
                    "createAppPush_PushcontentStep_YoutubeURL_placeholder"
                  )}
                  value={config.action.appPush.youtubeUrl}
                />
              </div>
              <div>
                <Label
                  className={`${classes["orientation"]} ${classes["my-form-label"]}`}
                >
                  {I18n.t("createAppPush_PushcontentStep_Orientation_label")}
                </Label>
                <Select
                  styles={customStyles}
                  className={classes["select-style"]}
                  value={this.props.videoSelection}
                  onChange={(videoSelection) => {
                    const value = videoSelection.label;
                    this.props.changeVideoOrientation(videoSelection);
                    this.props.dispatch(
                      ReduxChange(
                        "pushContentStep",
                        "action.appPush.orientation",
                        value
                      )
                    );
                  }}
                  options={videoOptions}
                />
              </div>
            </div>
          )}
        {this.props.pushMessageStep.values.type
          .toLowerCase()
          .includes("video") && (
          <div className={classes["video-input-container"]}>
            <Field
              name="action.appPush.appPushVideo"
              label={I18n.t("createAppPush_PushcontentStep_VideoURL_label")}
              accept="video/mp4"
              component={VideoUploader}
              setVideoValue={this.props.setVideoValue}
              showNotification={this.props.showNotification}
              iconClass={
                this.props.imgLoading["notification_video.src"]
                  ? "fa fa-spinner fa-spin"
                  : "fa fa-plus"
              }
              onChange={(...args) => {
                if (_.get(args[1], "name", false)) {
                  this.props.handleUploadImg(
                    "action.appPush.appPushVideo",
                    ...args
                  );
                } else {
                  this.props.setVideoValue(args[1]);
                }
              }}
              value={config.action.appPush.attachment}
              validate={[required, mp4URL]}
              hint={I18n.t("createAppPush_PushcontentStep_VideoURL_hint")}
              placeholder={I18n.t(
                "createAppPush_PushcontentStep_VideoURL_placeholder"
              )}
            />

            <div style={{ marginTop: "3rem" }}>
              <Label
                className={`${classes["orientation"]} ${classes["my-form-label"]}`}
              >
                {I18n.t("createAppPush_PushcontentStep_Orientation_label")}
              </Label>
              <Select
                styles={customStyles}
                className={classes["select-style"]}
                value={this.props.videoSelection}
                onChange={(videoSelection) => {
                  const value = videoSelection.label;
                  this.props.changeVideoOrientation(videoSelection);
                  ReduxChange(
                    "pushContentStep",
                    "action.appPush.orientation",
                    value
                  );
                }}
                options={videoOptions}
              />
              <p className={classes.hintSty}>
                {I18n.t("createAppPush_PushcontentStep_portraitUse")}
              </p>
            </div>
          </div>
        )}
      </Form>
    );
  }
}

export default reduxForm({
  form: "pushContentStep",
  destroyOnUnmount: false,
})(
  connect(
    (store) => ({
      smartLinks: store.smartlink,
      MicroPages: store.MicroPages,
      templates: store.template,
      pushContentStep: store.form.pushContentStep,
      pushMessageStep: store.form.pushMessageStep,
      notifications: store.notifications,
    }),
    { ReduxChange, showNotification: Notifications.show }
  )(PushContentStep)
);
