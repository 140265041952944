import PropTypes from "prop-types";
import React, { Component } from "react";
import classes from "./GIF.scss";

var bigGIF = "";

class GIF extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: null,
      anonId: "",
      previewGIF: "",
      bigGIF: "",
      view: false,
      value: "",
      viewSelected: false,
      loading: false,
      notFound: false,
      status: "",
    };
  }
  componentWillUnmount() {
    bigGIF = "";
  }

  selectGIF = (event) => {
    const { items } = this.state;
    var index = Number(event.target.id);

    bigGIF = items[index].media[0].gif.url;
    this.setState({ view: false, viewSelected: true });
  };

  renderGIFs() {
    const { items, view } = this.state;
    var rows = [];
    for (var i = 0; i < items.length - 3 && view; i += 1) {
      rows.push(
        <tr>
          <td className={classes.coln}>
            <button
              id={i + ""}
              className={classes.btn}
              onClick={this.selectGIF}
            ></button>
            <img
              src={items[i++].media[0].tinygif.url}
              height="175px"
              width="175px"
            />
          </td>
          <td className={classes.coln}>
            <button
              id={i + ""}
              className={classes.btn}
              onClick={this.selectGIF}
            ></button>
            <img
              src={items[i++].media[0].tinygif.url}
              height="175px"
              width="175px"
            />
          </td>
          <td className={classes.coln}>
            <button
              id={i + ""}
              className={classes.btn}
              onClick={this.selectGIF}
            ></button>
            <img
              src={items[i].media[0].tinygif.url}
              height="175px"
              width="175px"
            />
          </td>
        </tr>
      );
    }
    return (
      <div className={classes.scroller}>
        <table> {rows} </table>
      </div>
    );
  }

  gifFetch(value) {
    this.setState({ loading: true, view: false, notFound: false, status: "" });
    var lmt = 25;

    var apikey = "7F6Y5FIG5KUP";
    var search_url =
      "https://api.tenor.com/v1/search?tag=" +
      value +
      "&key=" +
      apikey +
      "&limit=" +
      lmt +
      "&anon_id=39885f6143c04dc8a10f8ad5fea1806b";

    fetch(search_url)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.results.length > 0) {
            this.setState({
              isLoaded: true,
              items: result.results,
              previewGIF: result.results[0].media[0].tinygif.url,
              view: true,
              viewSelected: false,
              loading: false,
            });

            var z = this.state.items;
            return z;
          } else {
            this.setState({
              notFound: true,
              loading: false,
              status: "no GIFs found :(",
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
            loading: false,
          });
        }
      );
  }
  handleGIFChange(event) {
    this.setState({ value: event.target.value });
  }

  render() {
    const { view, value, loading, notFound, status } = this.state;

    const { handleChange } = this.props;
    if (handleChange && bigGIF !== "") {
      handleChange("components[2].src", bigGIF);
    }
    return (
      <div>
        <input
          name="title"
          placeholder="Keyword"
          type="text"
          className={classes.input}
          onChange={this.handleGIFChange.bind(this)}
        />

        <button
          className={classes.butn}
          disabled={loading}
          onClick={() => this.gifFetch(value)}
        >
          Search Tenor GIFs
        </button>

        {view && (
          <button
            className={classes.butnHide}
            onClick={() => this.setState({ view: false })}
          >
            Hide
          </button>
        )}
        {loading && (
          <img
            src="https://media.tenor.com/images/d6cd5151c04765d1992edfde14483068/tenor.gif"
            height="25px"
            width="25px"
          />
        )}
        <br />
        <label className={classes.message}>
          Powered by <a href="https://tenor.com/">Tenor GIFs</a>
        </label>
        {view && this.renderGIFs()}

        {notFound && (
          <div>
            <br />
            <label className={classes.message}>{status} </label>
          </div>
        )}
      </div>
    );
  }
}
export default GIF;
