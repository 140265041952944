import CustomerSegments from "api/CustomerSegments";
import { CURRENCY, DATE_TYPE, NUMBER, STRING } from "consts/TableColumnTypes";
import { RESET_ERRORS } from "reducers/user";

import _ from "lodash";
import moment from "moment";

// ------------------------------------
// Constants
// ------------------------------------
const GET_SUIT_CUSTOMERSEGMENTS = "GET_SUIT_CUSTOMERSEGMENTS";
const CREATE_NEW_CUSTOMER_SEGMENT = "CREATE_NEW_CUSTOMER_SEGMENT";
const DELETE_SEGMENT = "DELETE_SEGMENT";
const REFERSHDATA = "REFERSHDATA";
const ADD_USERS_TO_SEGMENTS = "ADD_USERS_TO_SEGMENTS";
const CUSTOMER_SEGMENTS_CHECK_DATATABLE_ROW =
  "CUSTOMER_SEGMENTS_CHECK_DATATABLE_ROW";
const CUSTOMER_SEGMENTS_CHECK_ALL_TABLE_ROWS =
  "CUSTOMER_SEGMENTS_CHECK_ALL_TABLE_ROWS";
const CUSTOMER_SEGMENTS_COLUMN_TOGGLE = "CUSTOMER_SEGMENTS_COLUMN_TOGGLE";
const CUSTOMER_SEGMENTS_RESET_COLUMNS = "CUSTOMER_SEGMENTS_RESET_COLUMNS";
const SORT_CUSTOMER_SEGMENTS = "SORT_CUSTOMER_SEGMENTS";

const CUSTMOER_SEGMENT_TOGGLE_ROW_ACTION_MENU =
  "CUSTMOER_SEGMENT_TOGGLE_ROW_ACTION_MENU";

const CUSTOMER_SEGMENTS_CLEAR_ACTION_MENU =
  "CUSTOMER_SEGMENTS_CLEAR_ACTION_MENU";
const UPDATE_SEGMENT = "UPDATE_SEGMENT";
const FORCE_UPDATE_SEGMENTS = "FORCE_UPDATE_SEGMENTS";

// ------------------------------------
// Actions
// ------------------------------------
export function forceUpdateSegments(suitId) {
  return {
    type: FORCE_UPDATE_SEGMENTS,
    payload: CustomerSegments.forceUpdateSegments(suitId),
  };
}

export function checkDataTableRow(idx) {
  return {
    type: CUSTOMER_SEGMENTS_CHECK_DATATABLE_ROW,
    payload: idx,
  };
}

export function updateSegment(serv, appid, master, data, segmentId) {
  return {
    type: UPDATE_SEGMENT,
    payload: CustomerSegments.updateSegment(
      serv,
      appid,
      master,
      data,
      segmentId
    ),
  };
}

export function checkAllTableRows(newChecked) {
  return {
    type: CUSTOMER_SEGMENTS_CHECK_ALL_TABLE_ROWS,
    payload: newChecked,
  };
}

export function onColumnToggle(idx) {
  return {
    type: CUSTOMER_SEGMENTS_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: CUSTOMER_SEGMENTS_RESET_COLUMNS,
  };
}
export function clearActionMenu(idx) {
  return {
    type: CUSTOMER_SEGMENTS_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_CUSTOMER_SEGMENTS,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

export function getSuitCustomerSegments(serv, appid, master) {
  return {
    type: GET_SUIT_CUSTOMERSEGMENTS,
    payload: CustomerSegments.getSuitCustomerSegments(serv, appid, master),
  };
}

export function createNewSegment(data, serv, appid, master) {
  if (data.userIds && data.userIds.length > 0) {
    data.userIds = data.userIds.filter(function (item, pos) {
      return data.userIds.indexOf(item) == pos;
    });
  }
  return {
    type: CREATE_NEW_CUSTOMER_SEGMENT,
    payload: CustomerSegments.createNewSegment(data, serv, appid, master),
  };
}

export const deleteSegment =
  (serverURL, appId, masterKey, segmentId) => async (dispatch) => {
    try {
      dispatch({ type: `${DELETE_SEGMENT}_PENDING` });
      await CustomerSegments.deleteSegment(
        serverURL,
        appId,
        masterKey,
        segmentId
      );
      dispatch({
        type: `${DELETE_SEGMENT}_FULFILLED`,
        payload: segmentId,
      });
    } catch (error) {
      dispatch({ type: `${DELETE_SEGMENT}_REJECTED`, payload: error });
    }
  };

// data: {segmentId: String, users: String[]}
export function addUsersToSegment(appBackend, data) {
  return {
    type: ADD_USERS_TO_SEGMENTS,
    payload: CustomerSegments.addUsersToSegment(appBackend, data),
  };
}

export function refershData() {
  return {
    type: REFERSHDATA,
  };
}

export function toggleRowActionMenu(idx) {
  return {
    type: CUSTMOER_SEGMENT_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}

function description(jsonString) {
  try {
    let conditionArr,
      filterBy,
      filterByVal,
      filterCondition,
      filterConditionVal,
      filterConditionText,
      description,
      ans;

    description = JSON.parse(jsonString);

    if (description[0]["option"]) {
      const events = description.map((desc) => {
        const event = desc["option"];
        if (event[0] === "!") {
          return `has not done ${event.replace("!", "")} event`;
        }
        return `done ${event} event`;
      });
      ans = events.join(", ");
    } else {
      ans =
        description !== undefined
          ? ((conditionArr = []),
            description.map(
              (w) => (
                (filterBy = Object.keys(w)[0]),
                (filterCondition = Object.keys(Object.values(w)[0])[0]),
                (filterConditionVal = Object.values(Object.values(w)[0])[0]),
                filterConditionText,
                // used to check and convert filter condition into english words
                filterCondition === "$eq"
                  ? (filterConditionText = "=")
                  : filterCondition === "$ne"
                    ? (filterConditionText = "!=")
                    : filterCondition === "$regex"
                      ? (filterConditionText = "contains")
                      : filterCondition === "$regexe"
                        ? (filterConditionText = "Start With")
                        : filterCondition === "$regexc"
                          ? (filterConditionText = "End With")
                          : filterCondition === "$gte"
                            ? (filterConditionText = "after or equal")
                            : filterCondition === "$gt"
                              ? (filterConditionText = "after")
                              : filterCondition === "$lte" && filterBy !== "ltv"
                                ? (filterConditionText = "before or equal")
                                : filterCondition === "$lt" &&
                                    filterBy !== "ltv"
                                  ? (filterConditionText = "before")
                                  : filterCondition === "$lte" &&
                                      filterBy === "ltv"
                                    ? (filterConditionText =
                                        "less than or equal")
                                    : filterCondition === "$lt" &&
                                        filterBy !== "ltv"
                                      ? (filterConditionText = "less than")
                                      : null,
                // used to change filter type in case (createdAt and updatedAt) to another words
                filterBy === "createdAt"
                  ? (filterByVal = "App Install At")
                  : filterBy === "updatedAt"
                    ? (filterByVal = "Last Seen At")
                    : (filterByVal = filterBy),
                conditionArr.push(
                  filterByVal,
                  filterConditionText,
                  filterConditionVal
                )
              )
            ),
            conditionArr.join(" "))
          : "";
    }
    return ans;
  } catch (error) {
    console.error(error);
    return "";
  }
}

function prepareTableDate(tableData) {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "Name",
      has_sort: true,
      type: STRING,
      selected: true,
      varName: "name",
      defaultValue: "",
    },
    {
      name: "Created on",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "createdAt",
      defaultValue: 0,
    },
    {
      name: "Updated at",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "updatedAt",
    },
    {
      name: "Criteria",
      has_sort: false,
      type: STRING,
      selected: true,
      hasTooltip: true,
      tooltipBehind: "How the users selected",
      varName: "description",
    },
    {
      name: "Type",
      has_sort: false,
      type: STRING,
      selected: true,
      hasTooltip: true,
      tooltipBehind: "Type of segment",
      varName: "autoUpdate",
    },
    {
      name: "Size",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: "userIdsCount",
    },
    {
      name: "FB Audience",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "fb_audience",
    },
    {
      name: "FB Custom Audience ID",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "fb_custom_audience_id",
    },
    {
      name: "FB Payload Type",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "fb_payload_type",
    },
  ];

  const rows = tableData.map((row) => {
    const desc =
      (_.get(row, labels[3].varName, labels[3].defaultValue) &&
        description(_.get(row, labels[3].varName, labels[3].defaultValue))) ||
      "";

    const criteria = _.get(row, "criteria", "");
    return {
      checked: false,
      actionsMenu: false,
      objectId: row.objectId,

      values: [
        _.get(row, labels[0].varName, labels[0].defaultValue),

        moment(
          moment.utc(_.get(row, labels[1].varName, labels[1].defaultValue))
        )
          .local()
          .format(dateFormat),

        moment(
          moment.utc(_.get(row, labels[2].varName, labels[2].defaultValue))
        )
          .local()
          .format(dateFormat),

        criteria || desc,

        _.get(row, labels[4].varName, labels[4].defaultValue) === true
          ? "Live"
          : _.get(row, labels[4].varName, labels[4].defaultValue) === false
            ? "Past Behaviour"
            : "",
        _.get(row, labels[5].varName, labels[5].defaultValue),

        _.get(row, labels[6].varName, labels[6].defaultValue),
        _.get(row, labels[7].varName, labels[7].defaultValue),
        _.get(row, labels[8].varName, labels[8].defaultValue),
      ],
    };
  });

  return { labels, rows, actions: [{ type: "delete" }] };
}

// ------------------------------------
// Initial State
// ------------------------------------
const initialState = {
  Segments: null,
  CustomerSegment: null,
  SegmentsObj: null,
  isPending: false,
  error: false,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function CustomerSegmentsReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };

    case `${UPDATE_SEGMENT}_PENDING`:
    case `${GET_SUIT_CUSTOMERSEGMENTS}_PENDING`:
    case `${CREATE_NEW_CUSTOMER_SEGMENT}_PENDING`:
    case `${DELETE_SEGMENT}_PENDING`:
    case `${FORCE_UPDATE_SEGMENTS}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${ADD_USERS_TO_SEGMENTS}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${UPDATE_SEGMENT}_REJECTED`:
    case `${GET_SUIT_CUSTOMERSEGMENTS}_REJECTED`:
    case `${CREATE_NEW_CUSTOMER_SEGMENT}_REJECTED`:
    case `${DELETE_SEGMENT}_REJECTED`:
    case `${FORCE_UPDATE_SEGMENTS}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case `${ADD_USERS_TO_SEGMENTS}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case `${UPDATE_SEGMENT}_FULFILLED`:
    case `${ADD_USERS_TO_SEGMENTS}_FULFILLED`:
    case `${FORCE_UPDATE_SEGMENTS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case `${GET_SUIT_CUSTOMERSEGMENTS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        SegmentsObj: action.payload.body.result.data,
        Segments: prepareTableDate(action.payload.body.result.data),
      };
    case `${CREATE_NEW_CUSTOMER_SEGMENT}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case `${DELETE_SEGMENT}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        Segments: {
          ...state.Segments,
          rows: state.Segments.rows.filter(
            (row) => row.objectId !== action.payload
          ),
        },
      };

    case `${REFERSHDATA}`: {
      return {
        Segments: [],
        CustomerSegment: null,
        isPending: false,
        error: false,
      };
    }

    case CUSTMOER_SEGMENT_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        Segments: {
          ...state.Segments,
          rows: state.Segments.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.Segments.rows[rowIdx],
                  actionsMenu: !state.Segments.rows[rowIdx].actionsMenu,
                }
              : state.Segments.rows[rowIdx];
          }),
        },
      };
    case CUSTOMER_SEGMENTS_CHECK_DATATABLE_ROW:
      return {
        ...state,
        Segments: {
          ...state.Segments,
          rows: state.Segments.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.Segments.rows[rowIdx],
                  checked: !state.Segments.rows[rowIdx].checked,
                }
              : state.Segments.rows[rowIdx];
          }),
        },
      };
    case CUSTOMER_SEGMENTS_CHECK_ALL_TABLE_ROWS:
      return {
        ...state,
        Segments: {
          ...state.Segments,
          rows: state.Segments.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    case CUSTOMER_SEGMENTS_COLUMN_TOGGLE:
      return {
        ...state,
        Segments: {
          ...state.Segments,
          labels: state.Segments.labels.map((label, idx) => {
            return idx === action.payload
              ? { ...label, selected: !label.selected }
              : label;
          }),
        },
      };

    case CUSTOMER_SEGMENTS_RESET_COLUMNS:
      return {
        ...state,
        Segments: {
          ...state.Segments,
          labels: state.Segments.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };
    case SORT_CUSTOMER_SEGMENTS: {
      const indexOfKey = state.Segments.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.Segments.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.Segments.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.Segments.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.Segments.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        Segments: {
          ...state.Segments,
          rows: tableRows,
        },
      };
    }
    case CUSTOMER_SEGMENTS_CLEAR_ACTION_MENU:
      return {
        ...state,
        Segments: {
          ...state.Segments,
          rows: state.Segments.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };
    default:
      return state;
  }
}
