import {
  CURRENCY,
  DATE_TYPE,
  NUMBER,
  OBJECT,
  STRING,
} from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";
import { RESET_ERRORS } from "reducers/user";
import AppUser from "../api/AppUser";

const GET_APPUSER = "GET_APPUSER";
const GET_APPUSER_FIRST_TIME = "GET_APPUSER_FIRST_TIME";
const GET_APPUSER_WITH_FILTER = "GET_APPUSER_WITH_FILTER";
const GET_ALL_APPUSERS_WITH_FILTER_CONDITION =
  "GET_ALL_APPUSERS_WITH_FILTER_CONDITION";
const GET_ALL_APPUSERS_WITH_FILTER_CONDITION_LENGTH =
  "GET_ALL_APPUSERS_WITH_FILTER_CONDITION_LENGTH";
const GET_APPUSER_WITH_FILTER_BY_EVENT = "GET_APPUSER_WITH_FILTER_BY_EVENT";
const GET_APPUSER_WITH_FILTER_BY_EVENT_FIRST_TIME =
  "GET_APPUSER_WITH_FILTER_BY_EVENT_FIRST_TIME";
const POST_EXPORT_CSV_FOR_APPUSER_WITH_FILTER =
  "POST_EXPORT_CSV_FOR_APPUSER_WITH_FILTER";
const GET_APPUSER_WITH_FILTER_BY_EVENT_IDS =
  "GET_APPUSER_WITH_FILTER_BY_EVENT_IDS";
const RICH_USER_CHECK_DATA_TABLE_ROW = "RICH_USER_CHECK_DATA_TABLE_ROW";
const RICH_USER_CHECK_ALL_DATA_TABLE_ROWS =
  "RICH_USER_CHECK_ALL_DATA_TABLE_ROWS";
const RICH_USER_ON_COLUMN_TOGGLE = "RICH_USER_ON_COLUMN_TOGGLE";
const RICH_USER_TOGGLE_ROW_ACTION_MENU = "RICH_USER_TOGGLE_ROW_ACTION_MENU";
const RICH_USER_CLEAR_ACTION_MENU = "RICH_USER_CLEAR_ACTION_MENU";
const RICH_USER_ON_RESET_COLUMNS = "RICH_USER_ON_RESET_COLUMNS";
const SORT_RICH_USERS = "SORT_RICH_USERS";
const SET_META_DATA = "SET_META_DATA";
const CLEAR_CASH = "CLEAR_CASH";
const GET_USERS_DATA = "GET_USERS_DATA";

export function getUsersData(serverUrl, appId, masterKey, data) {
  return {
    type: GET_USERS_DATA,
    payload: AppUser.getUsersData(serverUrl, appId, masterKey, data),
  };
}

export function clearCash() {
  return {
    type: CLEAR_CASH,
  };
}

export function setMetaData(data) {
  return {
    type: SET_META_DATA,
    payload: data,
  };
}

export function getAllAppUsersWithFilterConditionLength(
  serv,
  appid,
  master,
  condition
) {
  return {
    type: GET_ALL_APPUSERS_WITH_FILTER_CONDITION_LENGTH,
    payload: AppUser.AllAppUsersWithFilterConditionLength(
      serv,
      appid,
      master,
      condition
    ),
  };
}

export function getAllAppUsersWithFilterCondition(
  serv,
  appid,
  master,
  condition,
  limitVal,
  skipVal
) {
  return {
    type: GET_ALL_APPUSERS_WITH_FILTER_CONDITION,
    payload: AppUser.AllAppUsersWithFilterCondition(
      serv,
      appid,
      master,
      condition,
      limitVal,
      skipVal
    ),
  };
}

export function getAllAppUsers(serv, appid, master, limitVal, skipVal, order) {
  if (!limitVal) {
    return {
      type: GET_APPUSER_FIRST_TIME,
      payload: AppUser.getAllAppUsersLength(serv, appid, master),
    };
  } else {
    return {
      type: GET_APPUSER,
      payload: AppUser.getAllAppUsers(
        serv,
        appid,
        master,
        limitVal,
        skipVal,
        order
      ),
    };
  }
}

// filter
export function getAllAppUsersWithFilter(
  serv,
  appid,
  master,
  limitVal,
  skipVal,
  filteringDataArray,
  apiKey,
  suitId
) {
  return {
    type: GET_APPUSER_WITH_FILTER,
    payload: AppUser.getAllAppUsersWithFilter(
      serv,
      appid,
      master,
      limitVal,
      skipVal,
      filteringDataArray,
      apiKey,
      suitId
    ),
  };
}

// filter by Event
export function AllAppUsersWithFilterByEvents(
  serv,
  appid,
  master,
  suitId,
  apiKey,
  skipVal,
  limitVal,
  data,
  subdomain
) {
  if (!limitVal) {
    return {
      type: GET_APPUSER_WITH_FILTER_BY_EVENT_FIRST_TIME,
      payload: AppUser.AllAppUsersWithFilterByEventsLength(
        serv,
        appid,
        master,
        suitId,
        apiKey,
        skipVal,
        limitVal,
        data,
        subdomain
      ),
    };
  } else {
    return {
      type: GET_APPUSER_WITH_FILTER_BY_EVENT,
      payload: AppUser.AllAppUsersWithFilterByEvents(
        serv,
        appid,
        master,
        suitId,
        apiKey,
        skipVal,
        limitVal,
        data,
        subdomain
      ),
    };
  }
}

export function AllAppUsersWithFilterByEventsIds(
  serv,
  appid,
  master,
  suitId,
  apiKey,
  skipVal,
  limitVal,
  data,
  subdomain
) {
  return {
    type: GET_APPUSER_WITH_FILTER_BY_EVENT_IDS,
    payload: AppUser.AllAppUsersWithFilterByEventsUserId(
      serv,
      appid,
      master,
      suitId,
      apiKey,
      skipVal,
      limitVal,
      data,
      subdomain
    ),
  };
}

// export csv with filter
export function exportCsvWithFilter(apiKey, suitId, data) {
  return {
    type: POST_EXPORT_CSV_FOR_APPUSER_WITH_FILTER,
    payload: AppUser.exportCsvWithFilter(apiKey, suitId, data),
  };
}

// handling selected segment (on segment click functionality)
export function setSelectedSegment(segment) {
  return {
    type: "SET_SELECTED_SEGMENT",
    payload: segment,
  };
}

// Data table reducer functions
export function checkDataTableRow(idx) {
  return {
    type: RICH_USER_CHECK_DATA_TABLE_ROW,
    payload: idx,
  };
}

export function checkAllTableRows(newChecked) {
  return {
    type: RICH_USER_CHECK_ALL_DATA_TABLE_ROWS,
    payload: newChecked,
  };
}

export function onColumnToggle(idx) {
  return {
    type: RICH_USER_ON_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function toggleRowActionMenu(idx) {
  return {
    type: RICH_USER_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}

export function clearActionMenu(idx) {
  return {
    type: RICH_USER_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: RICH_USER_ON_RESET_COLUMNS,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_RICH_USERS,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  isPending: false,
  users: [],
  usersCount: 0,
  filteredUserCount: false,
  selectedSegment: null,
  selectedSegmentCondition: null,
  usersDataTable: null,

  savedSelectedColumns: [],

  // cash
  page: 1,
  filterConditions: null,
  filterData: null,
  conditionsRelation: null,
  from: null,
  to: null,
  currentSuitId: null,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function AppUserReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_APPUSER}_PENDING`:
    case `${GET_APPUSER_WITH_FILTER}_PENDING`:
    case `${GET_APPUSER_WITH_FILTER_BY_EVENT}_PENDING`:
    case `${GET_APPUSER_FIRST_TIME}_PENDING`:
    case `${GET_APPUSER_WITH_FILTER_BY_EVENT_FIRST_TIME}_PENDING`:
    case `${GET_ALL_APPUSERS_WITH_FILTER_CONDITION}_PENDING`:
    case `${GET_ALL_APPUSERS_WITH_FILTER_CONDITION_LENGTH}_PENDING`:
    case `${GET_APPUSER_WITH_FILTER_BY_EVENT_IDS}_PENDING`:
    case `${GET_USERS_DATA}_PENDING`:
      return {
        ...state,
        isPending: true,
      };
    case `${GET_APPUSER}_REJECTED`:
    case `${GET_APPUSER_WITH_FILTER}_REJECTED`:
    case `${GET_APPUSER_WITH_FILTER_BY_EVENT}_REJECTED`:
    case `${POST_EXPORT_CSV_FOR_APPUSER_WITH_FILTER}_REJECTED`:
    case `${GET_APPUSER_FIRST_TIME}_REJECTED`:
    case `${GET_APPUSER_WITH_FILTER_BY_EVENT_FIRST_TIME}_REJECTED`:
    case `${GET_ALL_APPUSERS_WITH_FILTER_CONDITION}_REJECTED`:
    case `${GET_ALL_APPUSERS_WITH_FILTER_CONDITION_LENGTH}_REJECTED`:
    case `${GET_APPUSER_WITH_FILTER_BY_EVENT_IDS}_REJECTED`:
    case `${GET_USERS_DATA}_REJECTED`:
      return {
        ...state,
        isPending: false,
      };
    case `${GET_APPUSER}_FULFILLED`: {
      const tableData = prepareRichUserTableDate(
        action.payload.body.results,
        state.savedSelectedColumns
      );
      return {
        ...state,
        isPending: false,
        users: action.payload.body.results,
        usersDataTable: tableData,
      };
    }
    case `${GET_ALL_APPUSERS_WITH_FILTER_CONDITION}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        users: action.payload.body.result,
        usersDataTable: prepareRichUserTableDate(
          action.payload.body.result,
          state.savedSelectedColumns
        ),
      };
    case `${GET_ALL_APPUSERS_WITH_FILTER_CONDITION_LENGTH}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        usersCount: action.payload.body.result.sum,
      };
    case `${GET_APPUSER_FIRST_TIME}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        usersCount: action.payload.body.count,
        filteredUserCount: false,
      };
    case `${GET_APPUSER_WITH_FILTER}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        results: {
          body: action.payload.body,
        },
        result: [],
      };
    case `${GET_APPUSER_WITH_FILTER_BY_EVENT}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        users: action.payload.body.result,
        usersDataTable: prepareRichUserTableDate(
          action.payload.body.result,
          state.savedSelectedColumns
        ),
      };
    case `${GET_APPUSER_WITH_FILTER_BY_EVENT_FIRST_TIME}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        usersCount: action.payload.body.result.sum,
        filteredUserCount: true,
      };
    case `${GET_APPUSER_WITH_FILTER_BY_EVENT_IDS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case `${POST_EXPORT_CSV_FOR_APPUSER_WITH_FILTER}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case "SET_SELECTED_SEGMENT":
      return {
        ...state,
        isPending: false,
        selectedSegment: action.payload.selectedSegment,
      };
    case RICH_USER_CHECK_DATA_TABLE_ROW:
      return {
        ...state,
        usersDataTable: {
          ...state.usersDataTable,
          rows: state.usersDataTable.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, checked: !row.checked }
              : row;
          }),
        },
      };
    case RICH_USER_CHECK_ALL_DATA_TABLE_ROWS:
      return {
        ...state,
        usersDataTable: {
          ...state.usersDataTable,
          rows: state.usersDataTable.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };
    case RICH_USER_ON_COLUMN_TOGGLE: {
      const selectedColumns = state.usersDataTable.labels.map((label, idx) => {
        return idx === action.payload
          ? {
              ...label,
              selected: !label.selected,
            }
          : label;
      });
      const savedSelectedColumns = selectedColumns.filter(
        (label) => label.selected
      );
      return {
        ...state,
        usersDataTable: {
          ...state.usersDataTable,
          labels: selectedColumns,
        },
        savedSelectedColumns,
      };
    }
    case RICH_USER_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        usersDataTable: {
          ...state.usersDataTable,
          rows: state.usersDataTable.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, actionsMenu: !row.actionsMenu }
              : row;
          }),
        },
      };
    case RICH_USER_CLEAR_ACTION_MENU:
      return {
        ...state,
        usersDataTable: {
          ...state.usersDataTable,
          rows: state.usersDataTable.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };
    case RICH_USER_ON_RESET_COLUMNS: {
      const selectedColumns = state.usersDataTable.labels.map((label) => ({
        ...label,
        selected: true,
      }));
      const savedSelectedColumns = selectedColumns.filter(
        (label) => label.selected
      );
      return {
        ...state,
        usersDataTable: {
          ...state.usersDataTable,
          labels: selectedColumns,
        },
        savedSelectedColumns,
      };
    }
    case SORT_RICH_USERS: {
      const indexOfKey = state.usersDataTable.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.usersDataTable.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.usersDataTable.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.usersDataTable.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.usersDataTable.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        usersDataTable: {
          ...state.usersDataTable,
          rows: tableRows,
        },
      };
    }
    case SET_META_DATA:
      return {
        ...state,
        page: action.payload.page,
        filterConditions: action.payload.filterConditions,
        // filterString: action.payload.filterString,
        filterData: action.payload.filterData,
        conditionsRelation: action.payload.conditionsRelation,
        from: action.payload.from,
        to: action.payload.to,
        currentSuitId: action.payload.currentSuitId,
      };
    case CLEAR_CASH:
      return {
        ...state,
        page: 1,
        filterConditions: null,
        // filterString: null,
        filterData: null,
        conditionsRelation: null,
        from: null,
        to: null,
      };
    case `${GET_USERS_DATA}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        users: action.payload.body.result,
        usersDataTable: prepareRichUserTableDate(
          action.payload.body.result,
          state.savedSelectedColumns
        ),
        usersCount: action.payload.body.result.length,
      };
    default:
      return state;
  }
}

// prepare rich user table date functions
const dateFormat = "DD MMM YY HH:mm:ss";

const prepareLabelName = (name) => {
  if (!name) return undefined;
  if (name.includes("_")) {
    return name.replaceAll("_", " ");
  }
  return name.toUpperCase();
};

const hasSort = (type) => {
  if (type === "Date" || type === "Number") {
    return true;
  }
  return false;
};

const getType = (type) => {
  if (type === "Date") {
    return DATE_TYPE;
  } else if (type === "Number") {
    return NUMBER;
  }
  return STRING;
};

const selectedByDefault = [
  "ltv",
  "userId",
  // "name",
  "userEmail",
  // "madid",
  // "madidIdtype",
  "installationAt",
  "lastSeenAt",
  "usagecounter",
];

const getSelected = (key) => {
  if (selectedByDefault.includes(key)) {
    return true;
  }
  return false;
};

const datesVars = ["installationAt", "lastSeenAt"];

const getVarName = (key) => {
  if (datesVars.includes(key)) {
    return `${key}.iso`;
  }
  return key;
};

const prepareRichUserTableDate = (tableData, savedSelectedColumns) => {
  if (tableData.length === 0) return; // safe return if tableData is empty

  let schema = null;

  try {
    schema = JSON.parse(localStorage.getItem("schema"));
  } catch (error) {
    console.log("suit schema in local storage is not valid");
  }

  let newLabels = [
    {
      name: "USER ID",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "userId",
      defaultValue: "",
    },
  ];
  if (schema) {
    Object.keys(schema).forEach((key) => {
      if (key === "userId") return;
      newLabels.push({
        name: prepareLabelName(key),
        has_sort: hasSort(schema[key].type),
        type: getType(schema[key].type),
        varName: getVarName(key),
        defaultValue: "",
        selected: savedSelectedColumns.find(
          (label) => label.varName === getVarName(key)
        )
          ? true
          : getSelected(key),
      });
    });
  }

  const rows = tableData.map((row) => {
    return {
      checked: false,
      actionsMenu: false,
      id: row.userId,
      values: newLabels.map((label) => {
        if (label.type === DATE_TYPE) {
          return moment(
            moment.utc(_.get(row, label.varName, label.defaultValue))
          )
            .local()
            .format(dateFormat);
        } else if (label.type === OBJECT) {
          return JSON.stringify(_.get(row, label.varName, label.defaultValue));
        } else if (label.type === NUMBER) {
          return formatNumber(_.get(row, label.varName, label.defaultValue));
        }
        return _.get(row, label.varName, label.defaultValue);
      }),
    };
  });

  return { labels: newLabels, rows, actions: true };
};

function formatNumber(num) {
  return new Intl.NumberFormat("en-US").format(num);
}
