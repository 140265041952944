import api from "../modules/api";
import EP from "../consts/api";

export default class EventsDefinition {
  static addEventDefinition = ({ serverURL, appId, masterKey, data }) => {
    return api("POST", EP.EVENTS_DEFINITION.ADD_EVENT({ serverURL }), data)
      .set("X-Parse-Application-Id", appId)
      .set("X-Parse-REST-API-Key", masterKey);
  };

  static getEventsDefinition = (serverURL, appId, masterKey) => {
    return api("GET", EP.EVENTS_DEFINITION.GET_EVENTS(serverURL))
      .set("X-Parse-Application-Id", appId)
      .set("X-Parse-REST-API-Key", masterKey);
  };

  static deleteEventDefinition = ({ serverURL, appId, masterKey, objectId }) =>
    api("DELETE", EP.EVENTS_DEFINITION.DELETE_EVENT({ serverURL, objectId }))
      .set("X-Parse-Application-Id", appId)
      .set("X-Parse-REST-API-Key", masterKey);

  static updateEventDefinition = ({
    serverURL,
    appId,
    masterKey,
    objectId,
    data,
  }) =>
    api("PUT", EP.EVENTS_DEFINITION.UPDATE_EVENT({ serverURL, objectId }), data)
      .set("X-Parse-Application-Id", appId)
      .set("X-Parse-REST-API-Key", masterKey);
}
