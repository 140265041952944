/* 
* This module is responsible for extracting variables from a template
* @module template

* @param {string} template - The template to extract variables from
* @returns {Array} An array of variables found in the template

*/
const extractVariablesFromTemplate = (template) => {
  if (!template) return [];
  // variables are after $ sign
  const regex = /\$([a-zA-Z0-9_]+)/g;
  const matches = template.match(regex);
  if (!matches) return [];

  return matches.map((match) => match.substring(1));
};

const isTemplateContainsVariables = (template) => {
  return extractVariablesFromTemplate(template).length > 0;
};

export { extractVariablesFromTemplate, isTemplateContainsVariables };
