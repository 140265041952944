import Facebook from "api/Facebook";
import { RESET_ERRORS } from "reducers/user";

const GET_BUSINESS_ACCOUNTS = "GET_BUSINESS_ACCOUNTS";
const GET_FACEBOOK_AD_IDS = "GET_FACEBOOK_AD_IDS";
const GET_FACEBOOK_CUSTOM_AUDIENCE = "GET_FACEBOOK_CUSTOM_AUDIENCE";
const CREATE_FACEBOOK_CUSTOM_AUDIENCE = "CREATE_FACEBOOK_CUSTOM_AUDIENCE";
const GENERATE_LONG_ACCESS_TOKEN = "GENERATE_LONG_ACCESS_TOKEN";
const SYNC_SEGMENT = "SYNC_SEGMENT";

// / ------------------------------------
// Actions
// ------------------------------------

export function syncSegment(
  accessToken,
  suitId,
  customAudienceId,
  segmentId,
  appApiKey
) {
  return {
    type: SYNC_SEGMENT,
    payload: Facebook.syncSegment(
      accessToken,
      suitId,
      customAudienceId,
      segmentId,
      appApiKey
    ),
  };
}

export function getBusinessAccount(accessToken) {
  return {
    type: GET_BUSINESS_ACCOUNTS,
    payload: Facebook.getBusinessAccount(accessToken),
  };
}

export function getAdAccount(accessToken, businessId) {
  return {
    type: GET_FACEBOOK_AD_IDS,
    payload: Facebook.getAdAccount(accessToken, businessId),
  };
}

export function getCustomAudiences(accessToken, adAccountId) {
  return {
    type: GET_FACEBOOK_CUSTOM_AUDIENCE,
    payload: Facebook.getCustomAudiences(accessToken, adAccountId),
  };
}

export function generateLongAccessToken(accessToken) {
  return {
    type: GENERATE_LONG_ACCESS_TOKEN,
    payload: Facebook.generateLongAccessToken(accessToken),
  };
}

export function createCustomAudiences(
  accessToken,
  adAccountId,
  description,
  name
) {
  return {
    type: CREATE_FACEBOOK_CUSTOM_AUDIENCE,
    payload: Facebook.createCustomAudiences(
      accessToken,
      adAccountId,
      description,
      name
    ),
  };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  businessAccounts: [],
  adAccounts: [],
  customAudiences: [],
  isPending: false,
  newAudience: null,
  error: false,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function appboostReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };

    case `${CREATE_FACEBOOK_CUSTOM_AUDIENCE}_PENDING`:
    case `${GET_FACEBOOK_CUSTOM_AUDIENCE}_PENDING`:
    case `${GET_FACEBOOK_AD_IDS}_PENDING`:
    case `${GET_BUSINESS_ACCOUNTS}_PENDING`:
    case `${GENERATE_LONG_ACCESS_TOKEN}_PENDING`:
    case `${SYNC_SEGMENT}_PENDING`:
    case `${SYNC_SEGMENT}_FULFILLED`:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case `${CREATE_FACEBOOK_CUSTOM_AUDIENCE}_REJECTED`:
    case `${GET_FACEBOOK_CUSTOM_AUDIENCE}_REJECTED`:
    case `${SYNC_SEGMENT}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: true,
      };
    case `${GET_FACEBOOK_AD_IDS}_REJECTED`:
      return {
        ...state,
        customAudiences: [],
        isPending: false,
        error: true,
      };
    case `${GET_BUSINESS_ACCOUNTS}_REJECTED`:
    case `${GENERATE_LONG_ACCESS_TOKEN}_REJECTED`:
      return {
        ...state,
        adAccounts: [],
        customAudiences: [],
        isPending: false,
        error: true,
      };

    case `${CREATE_FACEBOOK_CUSTOM_AUDIENCE}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        error: false,
        newAudience: action.payload.body,
      };

    case `${GET_BUSINESS_ACCOUNTS}_FULFILLED`: // success
      return {
        ...state,
        isPending: false,
        error: false,
        businessAccounts: action.payload.body.data,
      };

    case `${GET_FACEBOOK_AD_IDS}_FULFILLED`: // success
      return {
        ...state,
        isPending: false,
        error: false,
        adAccounts: action.payload.body.data,
      };

    case `${GET_FACEBOOK_CUSTOM_AUDIENCE}_FULFILLED`: // success
      return {
        ...state,
        isPending: false,
        error: false,
        customAudiences: action.payload.body.data,
      };
    case `${GENERATE_LONG_ACCESS_TOKEN}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        error: false,
      };

    default:
      return state;
  }
}
