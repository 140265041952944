import { Icon, Input, Tooltip } from "antd";
import { DeepLinkBuilder } from "components";
import PropTypes from "prop-types";
import React from "react";
import classes from "./TextBox.module.scss";

export default function TextBox({
  id,
  input,
  hint,
  label,
  className,
  placeholder,
  meta,
  type,
  hasTooltip,
  tooltipBehind,
  rightInputHint,
  disabled,
  animationClassName,
  rightClickableHint,
  onRightClickableHint,
  disabledRightClick,
  maxLength,
  minLength,
  maxRecommended,
  minRecommended,
  rightBottomHint,
  readOnly,
  style,
  shadow,
  EmojiComponent,
  inputIcon,
  onChange,

  // deep link
  deepLinkBuilder,
  deepLinkType,
  FiledNotexist,
  nomail,
  noDeeplink,
  onlymdp,
}) {
  const inputLength = input?.value?.length;

  return (
    <div
      style={style}
      className={`${className} ${animationClassName} ${classes.textBox}`}
    >
      {label && (
        <label htmlFor={id} className={classes.label}>
          {label}
          {hasTooltip && (
            <Tooltip title={tooltipBehind}>
              <Icon type="info-circle" theme="twoTone" twoToneColor="#024da1" />
            </Tooltip>
          )}
        </label>
      )}

      <div className={classes.inputContainer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Input
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            className={`${
              rightClickableHint ? classes["input-right-clickable-hint"] : ""
            } ${classes.input}`}
            type={type}
            maxLength={maxLength}
            minLength={minLength}
            {...input}
            onChange={(e) => {
              input.onChange(e);
              if (onChange) onChange(e);
            }}
            readOnly={readOnly}
            style={{
              boxShadow: shadow,
              flex: 1,
            }}
            suffix={inputIcon}
            onWheel={(e) => {
              if (e.target.type === "number") {
                e.target.blur();
              }
            }}
            onMouseDown={(e) => {
              if (e.target.type === "number" && e.button === 1) {
                e.preventDefault();
              }
            }}
          />
          {deepLinkBuilder && (
            <DeepLinkBuilder
              type={deepLinkType}
              notIn={FiledNotexist}
              nomail={nomail}
              callBack={input.onChange}
              noDeeplink={noDeeplink}
              onlymdp={onlymdp}
            />
          )}
        </div>
        {EmojiComponent && (
          <div className={classes.emojiContainer}>
            <EmojiComponent />
          </div>
        )}
        {maxRecommended && (
          <div
            className={`${classes.maxRecommended} ${
              inputLength > maxRecommended ||
              (inputLength < minRecommended && inputLength !== 0)
                ? classes.maxRecommendedReached
                : ""
            }`}
          >
            {inputLength}/{maxRecommended}
          </div>
        )}
        {rightInputHint && (
          <div className={classes["right-input-hint"]}>{rightInputHint}</div>
        )}
        {rightClickableHint && (
          <div
            onClick={onRightClickableHint}
            className={`${disabledRightClick ? classes["not-allowed"] : ""} ${
              classes["right-clickable-input-hint"]
            }`}
          >
            {rightClickableHint}
          </div>
        )}
      </div>
      {hint && (
        <div className={classes.hint}>
          <span>{hint}</span>
        </div>
      )}

      <div style={{ display: "flex" }}>
        <div>
          {meta?.touched && meta?.error && (
            <span className={classes.error}>{meta.error}</span>
          )}
        </div>

        {rightBottomHint && (
          <div style={{ marginLeft: "auto" }}>
            <span style={{ marginBottom: "0" }}>{rightBottomHint}</span>
          </div>
        )}
      </div>
    </div>
  );
}

TextBox.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  tooltip: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  maxRecommended: PropTypes.number,
  minRecommended: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  style: PropTypes.object,
  type: PropTypes.string,
  hasTooltip: PropTypes.bool,
  tooltipBehind: PropTypes.string,
  rightInputHint: PropTypes.string,
  animationClassName: PropTypes.string,
  rightClickableHint: PropTypes.string,
  onRightClickableHint: PropTypes.func,
  disabledRightClick: PropTypes.bool,
  rightBottomHint: PropTypes.string,
  readOnly: PropTypes.bool,
  shadow: PropTypes.string,
  EmojiComponent: PropTypes.func,
  id: PropTypes.string,
  deepLinkBuilder: PropTypes.bool,
  deepLinkType: PropTypes.string,
  FiledNotexist: PropTypes.array,
  nomail: PropTypes.bool,
  noDeeplink: PropTypes.bool,
  onlymdp: PropTypes.bool,
  inputIcon: PropTypes.node,
  onChange: PropTypes.func,
};
