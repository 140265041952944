import React from "react";
import { I18n } from "react-redux-i18n";
import classes from "./DataBlock.scss";

const DataBlock = ({ className, title, children, style }) => {
  return (
    <div style={style} className={className}>
      <h3 className={classes.title}>{I18n.t(title)}</h3>
      {children}
    </div>
  );
};

export default DataBlock;
