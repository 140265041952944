import React from "react";
import IOSFrame from "static/ios-frame.png";
import classes from "./IOSPreview.scss";

const IosPreview = ({ type, data, title }) => {
  let classList = [classes["text-cont"]];
  if (type == "email") {
    classList.push(classes.emailContainer);
  }
  return (
    <div className={classes.container}>
      <img src={IOSFrame} />
      <div className={classList.join(" ")}>
        {type == "email" ? (
          <iframe style={{ height: "485px" }} srcDoc={data} />
        ) : type === "app" ? (
          <div>
            <h2 className={classes.title}>{title}</h2>
            <p className={classes.paragraph}>{data}</p>
          </div>
        ) : (
          <p className={classes.paragraph}>{data}</p>
        )}
      </div>
    </div>
  );
};

export default IosPreview;
