import { Spin } from "antd";
import {
  CustomCheckboxReduxForm,
  PrimaryButton,
  TextArea,
  TextBox,
} from "components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Field, getFormValues, initialize, reduxForm } from "redux-form";
import { CustomSelectorReduxForm } from "../../../../components";
import APIs from "../../../../consts/api";
import superAgentInstance from "../../../../modules/api";
import { getNotifyConfig } from "../../../../reducers/config";
import classes from "./WebPushSettings.module.scss";

const modalLanguageOptions = [
  { value: "en", label: I18n.t("English") },
  { value: "ar", label: I18n.t("Arabic") },
];

class WebPushSettings extends Component {
  state = {
    webPushConfig: null,
    isPending: false,
  };

  componentDidMount() {
    const { webPushConfig } = this.props;

    if (!webPushConfig) {
      this.fetchNotifyConfig();
    } else {
      this.setState({ webPushConfig });

      // initialize form
      this.initializeForm(webPushConfig);
    }
  }

  initializeForm = (webPushConfig) => {
    const { initialize } = this.props;

    // initialize form
    initialize("webPushSettings", {
      useCustomModal: webPushConfig?.useCustomModal,
      enabled: webPushConfig?.enabled,
      title: webPushConfig?.title,
      description: webPushConfig?.description,
      yesButton: webPushConfig?.yesButton,
      noButton: webPushConfig?.noButton,
      modalLanguage: webPushConfig?.modalLanguage
        ? modalLanguageOptions.find(
            (option) => option.value === webPushConfig?.modalLanguage
          )
        : null,
      modalImage: webPushConfig?.modalImage,
    });
  };

  fetchNotifyConfig = async () => {
    const {
      getNotifyConfig,
      user: { activeSuitId },
    } = this.props;

    try {
      this.setState({ isPending: true });
      const response = await getNotifyConfig(activeSuitId);

      const webPushConfig = response.value.body.channels.webPush;

      this.setState({ webPushConfig });

      // initialize form
      this.initializeForm(webPushConfig);
    } catch (error) {
      console.error("Failed to fetch notify config", error);
    } finally {
      this.setState({ isPending: false });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { formValues } = this.props;

    this.updateWebPushConfig(formValues);
  };

  updateWebPushConfig = async (formValues) => {
    const {
      user: { activeSuitId },
    } = this.props;

    delete formValues?.apiKey;
    delete formValues?.authorizationKey;
    delete formValues?.messagingSenderId;

    formValues.modalLanguage = formValues.modalLanguage?.value;

    try {
      this.setState({ isPending: true });

      await superAgentInstance("PUT", `${APIs.NOTIFY.CONFIG(activeSuitId)}`, {
        channels: {
          webPush: formValues,
        },
      });

      // refetch notify config
      this.fetchNotifyConfig();
    } catch (error) {
      console.error("Failed to update notify config", error);
    } finally {
      this.setState({ isPending: false });
    }
  };

  render() {
    const { pristine } = this.props;

    return (
      <div className={classes.webPushSettings}>
        <h1>{I18n.t("SettingsPage_webPushSettings")}</h1>

        <form className={classes.webPushForm} onSubmit={this.handleSubmit}>
          <Spin spinning={this.state.isPending} size="large">
            <div className={classes.formContent}>
              <Field
                name="enabled"
                component={CustomCheckboxReduxForm}
                label={I18n.t("SettingsPage_webPushSettings_enabled")}
              />

              <Field
                name="useCustomModal"
                component={CustomCheckboxReduxForm}
                label={I18n.t("SettingsPage_webPushSettings_useCustomModal")}
              />

              <Field
                name="title"
                component={TextBox}
                label={I18n.t("SettingsPage_webPushSettings_title")}
              />

              <Field
                name="description"
                component={TextArea}
                label={I18n.t("SettingsPage_webPushSettings_description")}
              />

              <Field
                name="yesButton"
                component={TextBox}
                label={I18n.t("SettingsPage_webPushSettings_yesButton")}
              />

              <Field
                name="noButton"
                component={TextBox}
                label={I18n.t("SettingsPage_webPushSettings_noButton")}
              />

              <Field
                name="modalLanguage"
                component={CustomSelectorReduxForm}
                label={I18n.t("SettingsPage_webPushSettings_modalLanguage")}
                options={modalLanguageOptions}
              />

              <Field
                name="modalImage"
                component={TextBox}
                label={I18n.t("SettingsPage_webPushSettings_modalImage")}
              />

              <PrimaryButton disabled={pristine} type="submit">
                {I18n.t("save")}
              </PrimaryButton>
            </div>
          </Spin>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: "webPushSettings",
})(
  connect(
    (store) => ({
      user: store.user,
      webPushConfig: store.config?.notify?.channels.webPush,
      formValues: getFormValues("webPushSettings")(store),
    }),
    {
      getNotifyConfig,
      initialize,
    }
  )(WebPushSettings)
);

WebPushSettings.contextTypes = {
  router: PropTypes.object.isRequired,
};

WebPushSettings.propTypes = {
  getNotifyConfig: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  webPushConfig: PropTypes.object,
  initialize: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
};
