import {
  DataBlock,
  DetailsList,
  PrimaryButton,
  SecondaryButton,
} from "components";
import { generateLongAccessToken } from "components/AdAudience/AdAudiencelogic";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import FacebookLogin from "react-facebook-login";
import { I18n } from "react-redux-i18n";
import { generateAPIKey, getCurrentSuit, updateSuit } from "reducers/user";
import { connect } from "routes/routedComponent";
import { SpinLoader } from "../../../../components";
import { Toast } from "../../../../modules/toast";
import classes from "./../Setting.scss";

class ExternalIntegrations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      lock: false,
      stripeKey: "",
      hotID: "",
      tiktok_pixel_id: "",
      fbID: "",
      twitterID: "",
      googleID: "",
      googleAds: "",
      snapID: "",
      linkedID: "",
      displayFacebookLogin: true,
    };
  }

  componentDidMount() {
    const {
      user: { suit, suits, activeSuitId },
    } = this.props;

    const currentSuit = suit || suits.find((suit) => suit.id === activeSuitId);
    const isFBLoggedIn =
      !!currentSuit.FBSessionToken && currentSuit.FBSessionToken !== "";

    if (isFBLoggedIn) {
      this.setState({ displayFacebookLogin: false });
    }
  }

  onFacebookLoginFail = (error) => {
    console.error("facebook login fail", error);
    Toast.error(I18n.t("facebookErrMsg"));
  };

  editFunction = () => {
    const {
      user: { suit },
    } = this.props;

    this.setState({
      stripeKey: suit.stripe_key ? suit.stripe_key : "NA",
      hotID: suit.hotjar_id ? suit.hotjar_id : "NA",
      fbID: suit.FBpixel_tracking_code ? suit.FBpixel_tracking_code : "NA",
      twitterID: suit.twitter_pixel_id ? suit.twitter_pixel_id : "NA",
      googleID: suit.google_ads_id ? suit.google_ads_id : "NA",
      snapID: suit.snapchat_id ? suit.snapchat_id : "NA",
      linkedID: suit.linkedIn_id ? suit.linkedIn_id : "NA",
      tiktok_pixel_id: suit.tiktok_pixel_id ? suit.tiktok_pixel_id : "NA",
      google_analytics: suit.google_analytics ? suit.google_analytics : "NA",
    });

    if (this.state.lock) {
      this.setState({ lock: false });
    } else {
      this.setState({ lock: true });
    }
    this.setState({ show: !this.state.show });
  };

  handleSave = () => {
    const {
      updateSuit,
      user: { activeSuitId, suit },
      getCurrentSuit,
    } = this.props;
    let data = _.pickBy({
      reset_fields: [],
      status: "active",
      google_analytics:
        this.state.google_analytics === suit.google_analytics ||
        this.state.google_analytics === "NA"
          ? null
          : this.state.google_analytics,
      google_ads_id:
        this.state.googleID === suit.google_ads_id ||
        this.state.googleID === "NA"
          ? null
          : this.state.googleID,
      hotjar_id:
        this.state.hotID === suit.hotjar_id || this.state.hotID === "NA"
          ? null
          : this.state.hotID,
      linkedIn_id:
        this.state.linkedID === suit.linkedIn_id || this.state.linkedID === "NA"
          ? null
          : this.state.linkedID,
      tiktok_pixel_id:
        this.state.tiktok_pixel_id === suit.tiktok_pixel_id ||
        this.state.tiktok_pixel_id === "NA"
          ? null
          : this.state.tiktok_pixel_id,
      FBpixel_tracking_code:
        this.state.fbID === suit.FBpixel_tracking_code ||
        this.state.fbID === "NA"
          ? null
          : this.state.fbID,
      snapchat_id:
        this.state.snapID === suit.snapchat_id || this.state.snapID === "NA"
          ? null
          : this.state.snapID,
      stripe_key:
        this.state.stripeKey === suit.stripe_key ||
        this.state.stripeKey === "NA"
          ? null
          : this.state.stripeKey,
      twitter_pixel_id:
        this.state.twitterID === suit.twitter_pixel_id ||
        this.state.twitterID === "NA"
          ? null
          : this.state.twitterID,
      suit_name: suit.name,
    });
    updateSuit(activeSuitId, { ...data })
      .then(() => {
        Toast.success(
          I18n.t("ProjectSettingPage_externalIntegrations_editedMsg")
        );
        getCurrentSuit(activeSuitId);
      })
      .then(this.setState({ lock: false }));
  };

  responseFacebook = async (response) => {
    const {
      updateSuit,
      user: { activeSuitId, suit, suits },
    } = this.props;

    const currentSuit =
      suit || suits.filter((suit) => suit.id === activeSuitId)[0];

    const shortToken = response.accessToken;

    try {
      const longTokenResponse = await generateLongAccessToken(shortToken);

      const longToken = longTokenResponse.data.access_token;

      await updateSuit(activeSuitId, {
        suit_name: currentSuit.name,
        FBSessionToken: longToken,
      });

      Toast.success(
        I18n.t("ProjectSettingPage_externalIntegrations_FBLinkedMsg")
      );
      this.setState({ displayFacebookLogin: false });
    } catch (error) {
      console.error(error);

      Toast.error(I18n.t("ProjectSettingPage_externalIntegrations_ErrMsg"));
    }
  };

  render() {
    const {
      user: { suit, isPending },
    } = this.props;

    let changedFields = {};

    if (suit) {
      changedFields = _.pickBy({
        google_analytics:
          this.state.google_analytics === suit.google_analytics ||
          this.state.google_analytics === "NA"
            ? null
            : this.state.google_analytics,
        reset_fields: [],
        status: "active",
        google_ads_id:
          this.state.googleID === suit.google_ads_id ||
          this.state.googleID === "NA"
            ? null
            : this.state.googleID,
        hotjar_id:
          this.state.hotID === suit.hotjar_id || this.state.hotID === "NA"
            ? null
            : this.state.hotID,
        linkedIn_id:
          this.state.linkedID === suit.linkedIn_id ||
          this.state.linkedID === "NA"
            ? null
            : this.state.linkedID,
        tiktok_pixel_id:
          this.state.tiktok_pixel_id === suit.tiktok_pixel_id ||
          this.state.tiktok_pixel_id === "NA"
            ? null
            : this.state.tiktok_pixel_id,
        FBpixel_tracking_code:
          this.state.fbID === suit.FBpixel_tracking_code ||
          this.state.fbID === "NA"
            ? null
            : this.state.fbID,
        snapchat_id:
          this.state.snapID === suit.snapchat_id || this.state.snapID === "NA"
            ? null
            : this.state.snapID,
        stripe_key:
          this.state.stripeKey === suit.stripe_key ||
          this.state.stripeKey === "NA"
            ? null
            : this.state.stripeKey,
        twitter_pixel_id:
          this.state.twitterID === suit.twitter_pixel_id ||
          this.state.twitterID === "NA"
            ? null
            : this.state.twitterID,
        suit_name: suit.name,
      });
    }

    return (
      <div>
        {isPending && <SpinLoader />}
        {suit && (
          <div className={classes.new}>
            <DataBlock
              title="ProjectSettingPage_externalIntegrations_DataBlock_title"
              titleClass="bg-grey-lightest"
            >
              <DetailsList
                data={[
                  {
                    key: "google",
                    value: suit.google_analytics || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_externalIntegrations_DetailsList_1_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    inputField: (
                      <input
                        value={this.state.google_analytics}
                        onChange={(e) =>
                          this.setState({ google_analytics: e.target.value })
                        }
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder={I18n.t("google_placeholder")}
                        type="text"
                      />
                    ),
                  },
                  {
                    key: "HotjarID",
                    value: suit.hotjar_id || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_externalIntegrations_DetailsList_2_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    inputField: (
                      <input
                        value={this.state.hotID}
                        onChange={(e) =>
                          this.setState({ hotID: e.target.value })
                        }
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder={I18n.t("HotjarID_placeholder")}
                        type="text"
                      />
                    ),
                  },
                  {
                    key: "StripePublishableKey",
                    value: suit.stripe_key || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_externalIntegrations_DetailsList_3_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    inputField: (
                      <input
                        value={this.state.stripeKey}
                        onChange={(e) =>
                          this.setState({ stripeKey: e.target.value })
                        }
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder={I18n.t("StripePublishableKey_placeholder")}
                        type="text"
                      />
                    ),
                  },
                  {
                    key: "facebook",
                    value: suit.FBpixel_tracking_code || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_externalIntegrations_DetailsList_4_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    displayConnectBtn: this.state.displayFacebookLogin,
                    connectBtn: (
                      <FacebookLogin
                        appId="2242381319117291"
                        callback={this.responseFacebook}
                        onFailure={this.onFacebookLoginFail}
                        cssClass={classes.facebookConnectBtn}
                        scope="ads_management,ads_read,business_management,public_profile"
                      />
                    ),
                    inputField: (
                      <input
                        value={this.state.fbID}
                        onChange={(e) =>
                          this.setState({ fbID: e.target.value })
                        }
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder={I18n.t("facebook_placeholder")}
                        type="text"
                      />
                    ),
                  },
                  {
                    key: "twitter",
                    value: suit.twitter_pixel_id || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_externalIntegrations_DetailsList_5_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    inputField: (
                      <input
                        value={this.state.twitterID}
                        onChange={(e) =>
                          this.setState({ twitterID: e.target.value })
                        }
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder={I18n.t("twitter_placeholder")}
                        type="text"
                      />
                    ),
                  },
                  {
                    key: "googleAds",
                    value: suit.google_ads_id || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_externalIntegrations_DetailsList_6_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    inputField: (
                      <input
                        value={this.state.googleID}
                        onChange={(e) =>
                          this.setState({ googleID: e.target.value })
                        }
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder={I18n.t("googleAds_placeholder")}
                        type="text"
                      />
                    ),
                  },
                  {
                    key: "snapchat",
                    value: suit.snapchat_id || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_externalIntegrations_DetailsList_7_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    inputField: (
                      <input
                        value={this.state.snapID}
                        onChange={(e) =>
                          this.setState({ snapID: e.target.value })
                        }
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder={I18n.t("snapchat_placeholder")}
                        type="text"
                      />
                    ),
                  },
                  {
                    key: "linkedin",
                    value: suit.linkedIn_id || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_externalIntegrations_DetailsList_8_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    inputField: (
                      <input
                        value={this.state.linkedID}
                        onChange={(e) =>
                          this.setState({ linkedID: e.target.value })
                        }
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder={I18n.t("linkedin_placeholder")}
                        type="text"
                      />
                    ),
                  },
                  {
                    key: "tiktok",
                    value: suit.tiktok_pixel_id || "NA",
                    description: I18n.t(
                      "ProjectSettingPage_externalIntegrations_DetailsList_9_description"
                    ),
                    canCopy: false,
                    info: true,
                    lock: this.state.lock,
                    inputField: (
                      <input
                        value={this.state.tiktok_pixel_id}
                        onChange={(e) =>
                          this.setState({ tiktok_pixel_id: e.target.value })
                        }
                        style={{
                          width: "220px",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                        placeholder={I18n.t("tiktok_placeholder")}
                        type="text"
                      />
                    ),
                  },
                ]}
              />

              {this.state.lock === true ? (
                <PrimaryButton
                  value={I18n.t("saveBtn")}
                  disabled={Object.keys(changedFields).length === 3}
                  onClick={() => {
                    this.handleSave();
                  }}
                />
              ) : (
                <SecondaryButton
                  value={I18n.t("edit")}
                  onClick={() => {
                    this.editFunction();
                  }}
                />
              )}
            </DataBlock>
          </div>
        )}
      </div>
    );
  }
}
export default connect(
  (store) => ({
    user: store.user,
    select: store.select,
  }),
  {
    generateAPIKey,
    getCurrentSuit,
    updateSuit,
  }
)(ExternalIntegrations);

ExternalIntegrations.propTypes = {
  user: PropTypes.shape({
    suit: PropTypes.object,
    suits: PropTypes.array,
    activeSuitId: PropTypes.string,
  }),
  updateSuit: PropTypes.func,
  generateAPIKey: PropTypes.func,
  getCurrentSuit: PropTypes.func,
};
