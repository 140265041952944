import ENDPOINTS from "../consts/api";
import superAgentInstance from "../modules/api";

export default class AI {
  static getAISuggestionsForApp = (serverUrl, appId, masterKey) =>
    superAgentInstance("GET", ENDPOINTS.AI.getAISuggestionsForApp(serverUrl))
      .set("X-Parse-Application-Id", appId)
      .set("X-Parse-Master-Key", masterKey);

  static getAISuggestionsForUser = (serverUrl, appId, masterKey, userId) =>
    superAgentInstance(
      "GET",
      ENDPOINTS.AI.getAISuggestionForUser(serverUrl, userId)
    )
      .set("X-Parse-Application-Id", appId)
      .set("X-Parse-Master-Key", masterKey);

  static getAISuggestionForSegment = (serverUrl, appId, masterKey, segmentId) =>
    superAgentInstance(
      "GET",
      ENDPOINTS.AI.getAISuggestionForSegment(serverUrl, segmentId)
    )
      .set("X-Parse-Application-Id", appId)
      .set("X-Parse-Master-Key", masterKey);
}
