import MediaLibrary from "api/MediaLibrary";

// constants

const MEDIALIBRARY = "MEDIALIBRARY";
const DELETEMEDIALIBRARY = "DELETEMEDIALIBRARY";

// functions
export function GetMediaLibrary(suitId) {
  return {
    type: MEDIALIBRARY,
    payload: MediaLibrary.GetMediaLibrary(suitId),
  };
}

export function DeleteMedia(suitId) {
  return {
    type: DELETEMEDIALIBRARY,
    payload: MediaLibrary.DeleteMedia(suitId),
  };
}

//   initial state

const initialState = {
  isPending: false,
  error: false,
  mediaLibraryData: null,
};

const MediaLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${MEDIALIBRARY}_PENDING`:
    case `${DELETEMEDIALIBRARY}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case `${MEDIALIBRARY}_REJECTED`:
    case `${DELETEMEDIALIBRARY}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case `${MEDIALIBRARY}_FULFILLED`:
    case `${DELETEMEDIALIBRARY}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        mediaLibraryData: action.payload.body,
      };
    default:
      return state;
  }
};

export default MediaLibraryReducer;
