import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import PropTypes from "prop-types";
import React from "react";
import OutsideClickHandler from "../../OutsideClickHandler/OutsdieClickHandler";
import classes from "./EmojiPicker.scss";

class EmojiPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: "😃",
    };
  }

  close = () => {
    this.setState({ open: false });
  };

  handleEmojiChange = ({ native }) => {
    this.setState({ selected: native });
    this.props.handleChange(native);
  };

  toggleOpen = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  render() {
    const { open, selected } = this.state;
    const { styledown } = this.props;
    return (
      <OutsideClickHandler onOutsideClick={this.close}>
        <div
          className={styledown === true ? classes.containdown : classes.contain}
        >
          <span className={classes.button} onClick={this.toggleOpen}>
            {selected}
          </span>
          {open && (
            <div className={classes.popOver}>
              <Picker
                native
                showPreview={false}
                onSelect={this.handleEmojiChange}
              />
            </div>
          )}
        </div>
      </OutsideClickHandler>
    );
  }
}

export default EmojiPicker;

EmojiPicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  styledown: PropTypes.bool,
};
