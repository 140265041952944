import { Modal } from "antd";
import React from "react";
import { RoutedComponent } from "../../routedComponent";
import UnlayerTemplateStep from "./RetailGainSteps/UnlayerTemplateStep";
import WelcomeStep from "./RetailGainSteps/WelcomeStep";

const RETAIL_GAIN_ON_BOARDING_STEPS = [
  {
    id: "1",
    Component: WelcomeStep,
  },
  {
    id: "2",
    Component: UnlayerTemplateStep,
  },
];

class RetailGainOnBoarding extends RoutedComponent {
  state = {
    steps: RETAIL_GAIN_ON_BOARDING_STEPS,
    currentStep: 0,

    modalIsOpen: false,
  };

  handleNextStep = () => {
    const { currentStep, steps } = this.state;

    if (currentStep < steps.length - 1) {
      this.setState({
        currentStep: currentStep + 1,
      });
    }
  };

  handlePreviousStep = () => {
    const { currentStep } = this.state;

    if (currentStep > 0) {
      this.setState({
        currentStep: currentStep - 1,
      });
    }
  };

  render() {
    const { steps, currentStep, modalIsOpen } = this.state;

    const ComponentToRender = steps[currentStep].Component;

    return (
      <Modal
        visible={modalIsOpen}
        footer={null}
        width="100%"
        bodyStyle={{
          borderRadius: "8px",
          background: "linear-gradient(180deg, #024DA133 0%, #D9D9D900 100%)",
        }}
        centered
        style={{
          borderRadius: "8px",
          maxWidth: "1000px",
        }}
        onCancel={() => this.setState({ modalIsOpen: false })}
      >
        <ComponentToRender
          onNextStep={this.handleNextStep}
          onPreviousStep={this.handlePreviousStep}
        />
      </Modal>
    );
  }
}

export default RetailGainOnBoarding;
