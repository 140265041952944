import LastOrder from "api/LastOrder";
import { RESET_ERRORS } from "reducers/user";

export const GET_LASTORDER = "GET_LASTORDER";

// / ------------------------------------
// Actions
// ------------------------------------

export function getLastOrder(subdomain, suitId, churn_days, apiKey) {
  return {
    type: GET_LASTORDER,
    payload: LastOrder.getLastOrder(subdomain, suitId, churn_days, apiKey),
  };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {};

// ------------------------------------
// Reducer
// ------------------------------------

export default function lastorderReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_LASTORDER}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case `${GET_LASTORDER}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case `${GET_LASTORDER}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        //   because that is emptyyy ,,,
        //  state: action.payload
      };

    default:
      return state;
  }
}
