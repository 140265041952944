import { I18n } from "react-redux-i18n";

const STRING = "String";
const NUMBER = "Number";
const BOOLEAN = "Boolean";
const DATE = "Date";

const stringOptions = [
  { value: "$eq", label: I18n.t("equals") },
  { value: "$ne", label: I18n.t("does not equal") },
  { value: "$regex", label: I18n.t("contains") },
  { value: "$regexe", label: I18n.t("start with") },
  { value: "$regexc", label: I18n.t("end with") },
  { value: "exist", label: I18n.t("exist") },
];

const numberOptions = [
  { value: "$eq", label: I18n.t("equals") },
  { value: "$ne", label: I18n.t("does not equal") },
  { value: "$gte", label: I18n.t("greater than or equal") },
  { value: "$gt", label: I18n.t("greater than ") },
  { value: "$lte", label: I18n.t("less than or equal") },
  { value: "$lt", label: I18n.t("less than") },
  { value: "exist", label: I18n.t("exist") },
];

const dateOptions = [
  { value: "$eq", label: I18n.t("equals") },
  { value: "$gt", label: I18n.t("is after") },
  { value: "$lt", label: I18n.t("is before") },
  { value: "rg", label: I18n.t("range") },
  { value: "since", label: I18n.t("since") },
];

const booleanOptions = [
  { value: "$eq", label: I18n.t("equals") },
  { value: "exist", label: I18n.t("exist") },
];

const generateOperatorOptions = (userProperty) => {
  if (!userProperty) return [];
  const type = userProperty.type;

  switch (type) {
    case STRING:
      return stringOptions;
    case NUMBER:
      return numberOptions;
    case DATE:
      return dateOptions;
    case BOOLEAN:
      return booleanOptions;
    default:
      break;
  }
};

export default generateOperatorOptions;
