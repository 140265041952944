import { userData } from "modules/user";
import EP from "../consts/api";
import api from "../modules/api";

export default class AppUser {
  static AllAppUsersWithFilterCondition = (
    serv,
    appid,
    master,
    condition,
    limitVal,
    skipVal,
    order
  ) => {
    return api(
      "post",
      EP.APPUSER.AllAppUsersWithFilterConditionUrl(
        serv,
        condition,
        skipVal,
        limitVal,
        order
      )
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };

  static AllAppUsersWithFilterConditionLength = (
    serv,
    appid,
    master,
    condition
  ) => {
    return api(
      "post",
      EP.APPUSER.AllAppUsersWithFilterConditionUrlLength(serv, condition)
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };

  static getAllAppUsers = (serv, appid, master, limitVal, skipVal, order) => {
    return api("get", EP.APPUSER.AllAppUsers(serv, limitVal, skipVal, order))
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };

  static getAllAppUsersLength = (serv, appid, master) => {
    return api("get", EP.APPUSER.NumberOfUsers(serv))
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };

  // filter
  static getAllAppUsersWithFilter = (
    serv,
    appid,
    master,
    limitVal,
    skipVal,
    filteringDataArray,
    apiKey,
    suitId
  ) => {
    const user = userData(),
      userId = user.admin_id || user.user_id;

    let filterDataArr = JSON.stringify(filteringDataArray);
    return api(
      "post",
      EP.APPUSER.AllAppUsersWithFilter(
        serv,
        limitVal,
        skipVal,
        filterDataArr,
        userId,
        apiKey,
        suitId
      )
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`)
      .set("authToken", user.auth_token);
  };

  // filter result length
  static getAllAppUsersWithFilterLength = (
    serv,
    appid,
    master,
    filteringDataArray,
    apiKey,
    suitId
  ) => {
    const user = userData(),
      userId = user.admin_id || user.user_id;

    let filterDataArr = JSON.stringify(filteringDataArray);

    return api(
      "post",
      EP.APPUSER.AllAppUsersWithFilterLength(
        serv,
        filterDataArr,
        userId,
        apiKey,
        suitId
      )
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`)
      .set("authToken", user.auth_token);
  };

  // filter result => return results (users) user Id
  static getAllAppUsersWithFilterUserIds = (
    serv,
    appid,
    master,
    filteringDataArray,
    apiKey,
    suitId
  ) => {
    const user = userData(),
      userId = user.admin_id || user.user_id;

    let filterDataArr = JSON.stringify(filteringDataArray);

    return api(
      "post",
      EP.APPUSER.AllAppUsersWithFilterUserIds(
        serv,
        filterDataArr,
        userId,
        apiKey,
        suitId
      )
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`)
      .set("authToken", user.auth_token);
  };

  // filter by Event
  static AllAppUsersWithFilterByEvents = (
    serv,
    appid,
    master,
    suitId,
    apiKey,
    skipVal,
    limitVal,
    data,
    subdomain
  ) => {
    const user = userData();
    const userId = user.admin_id || user.user_id;

    return api(
      "post",
      EP.APPUSER.AllAppUsersWithFilterByEvents(
        serv,
        suitId,
        apiKey,
        skipVal,
        limitVal,
        userId,
        subdomain
      ),
      data
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };

  // filter by Event Length
  static AllAppUsersWithFilterByEventsLength = (
    serv,
    appid,
    master,
    suitId,
    apiKey,
    skipVal,
    limitVal,
    data,
    subdomain
  ) => {
    const user = userData();
    const userId = user.admin_id || user.user_id;

    return api(
      "post",
      EP.APPUSER.AllAppUsersWithFilterByEventsLength(
        serv,
        suitId,
        apiKey,
        skipVal,
        limitVal,
        userId,
        subdomain
      ),
      data
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`)
      .set("Content-Type", "application/json");
  };

  // filter by Event user id
  static AllAppUsersWithFilterByEventsUserId = (
    serv,
    appid,
    master,
    suitId,
    apiKey,
    skipVal,
    limitVal,
    data,
    subdomain
  ) => {
    const user = userData();
    const userId = user.admin_id || user.user_id;

    return api(
      "post",
      EP.APPUSER.AllAppUsersWithFilterByEventsUserId(
        serv,
        suitId,
        apiKey,
        skipVal,
        limitVal,
        userId,
        subdomain
      ),
      data
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`)
      .set("Content-Type", "application/json");
  };

  static getAppUserslength = (serv, appid, master) => {
    const user = userData();

    return api("get", EP.APPUSER.NumberOfUsers(serv))
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`)
      .set("authToken", user.auth_token);
  };

  // export csv with filter
  static exportCsvWithFilter = (apiKey, suitId, data) => {
    return api("post", EP.APPUSER.ExportCsvWithFilter(suitId), data)
      .set({
        appApiKey: apiKey,
      })
      .set("Content-Type", "application/json");
  };

  // data = { userIds = [test1, test1, ...] }
  static getUsersData = (serverUrl, appId, masterKey, data) => {
    return api("post", EP.APPUSER.getUsersData(serverUrl), data)
      .set("x-parse-application-id", `${appId}`)
      .set("x-parse-master-key", `${masterKey}`);
  };
}
