import EventsDefinition from "api/EventsDefinition";
import _ from "lodash";
import moment from "moment";

import { STRING, OBJECT, DATE_TYPE } from "consts/TableColumnTypes";

// ------------------------------------
// Constants
// ------------------------------------

const GET_EVENTS = "GET_EVENTS";
const EVENTS_DEFINITION_ON_RESET_COLUMNS = "EVENTS_DEFINITION_ON_RESET_COLUMNS";
const EVENTS_DEFINITION_TOGGLE_ROW_ACTION_MENU =
  "EVENTS_DEFINITION_TOGGLE_ROW_ACTION_MENU";
const EVENTS_DEFINITION_ON_COLUMN_TOGGLE = "EVENTS_DEFINITION_ON_COLUMN_TOGGLE";
const EVENTS_DEFINITION_CLEAR_ACTION_MENU =
  "EVENTS_DEFINITION_CLEAR_ACTION_MENU";
const UPDATE_EVENT = "UPDATE_EVENT";
const DELETE_EVENT = "DELETE_EVENT";
const ADD_EVENT = "ADD_EVENT";
const EVENTS_DEFINITION_CHECK_DATA_TABLE_ROW =
  "EVENTS_DEFINITION_CHECK_DATA_TABLE_ROW";
const EVENTS_DEFINITION_CHECK_ALL_DATA_TABLE_ROWS =
  "EVENTS_DEFINITION_CHECK_ALL_DATA_TABLE_ROWS";

// ------------------------------------
// Actions
// ------------------------------------

export function getEventsDefinition(serverURL, appId, masterKey) {
  return {
    type: GET_EVENTS,
    payload: EventsDefinition.getEventsDefinition(serverURL, appId, masterKey),
  };
}

export function updateEventDefinition(data) {
  return {
    type: UPDATE_EVENT,
    payload: EventsDefinition.updateEventDefinition(data),
  };
}

export function deleteEventDefinition(data) {
  return {
    type: DELETE_EVENT,
    payload: EventsDefinition.deleteEventDefinition(data),
  };
}

export function addEventDefinition(data) {
  return {
    type: ADD_EVENT,
    payload: EventsDefinition.addEventDefinition(data),
  };
}

// DataTable

export function onResetColumns() {
  return {
    type: EVENTS_DEFINITION_ON_RESET_COLUMNS,
  };
}

export function toggleRowActionMenu(idx) {
  return {
    type: EVENTS_DEFINITION_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}

export function onColumnToggle(idx) {
  return {
    type: EVENTS_DEFINITION_ON_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function clearActionMenu(idx) {
  return {
    type: EVENTS_DEFINITION_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function checkDataTableRow(idx) {
  return {
    type: EVENTS_DEFINITION_CHECK_DATA_TABLE_ROW,
    payload: idx,
  };
}

export function checkAllTableRows(newChecked) {
  return {
    type: EVENTS_DEFINITION_CHECK_ALL_DATA_TABLE_ROWS,
    payload: newChecked,
  };
}

function prepareEventsDefinitionTableDate(tableData) {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "ID",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: ["objectId"],
      defaultValue: "N/A",
    },
    {
      name: "Event",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: ["events"],
      defaultValue: "N/A",
    },
    {
      name: "Description",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: ["description"],
      defaultValue: "N/A",
    },
    {
      name: "Actions",
      has_sort: false,
      type: OBJECT,
      selected: true,
      varName: ["actions"],
      defaultValue: "",
    },
    {
      name: "Values",
      has_sort: false,
      type: OBJECT,
      selected: true,
      varName: ["values"],
      // defaultValue: 'N/A',
    },
    {
      name: "Created At",
      has_sort: false,
      type: DATE_TYPE,
      selected: false,
      varName: ["createdAt"],
      defaultValue: "N/A",
    },
    {
      name: "Updated At",
      has_sort: false,
      type: DATE_TYPE,
      selected: false,
      varName: ["updatedAt"],
      defaultValue: "N/A",
    },
  ];

  const rows = tableData.map((row) => ({
    checked: false,
    actionsMenu: false,
    values: labels.map((label) => {
      if (label.type === DATE_TYPE) {
        return moment(moment.utc(_.get(row, label.varName, label.defaultValue)))
          .local()
          .format(dateFormat);
      } else if (label.type === OBJECT) {
        return JSON.stringify(_.get(row, label.varName, label.defaultValue));
      }
      return _.get(row, label.varName, label.defaultValue);
    }),
  }));

  return {
    labels,
    rows,
    actions: [{ type: "edit" }, { type: "delete" }],
  };
}

// ------------------------------------
// Initial State
// ------------------------------------
const initialState = {
  data: [],
  tableData: null,
  isPending: false,
  error: false,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function EventsDefinitionReducer(state = initialState, action) {
  switch (action.type) {
    case `${GET_EVENTS}_PENDING`:
    case `${UPDATE_EVENT}_PENDING`:
    case `${DELETE_EVENT}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_EVENTS}_REJECTED`:
    case `${UPDATE_EVENT}_REJECTED`:
    case `${DELETE_EVENT}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case `${GET_EVENTS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        data: action.payload.body.results,
        tableData: prepareEventsDefinitionTableDate(
          action.payload.body.results
        ),
      };
    case `${UPDATE_EVENT}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case `${DELETE_EVENT}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };

    // Data table
    case EVENTS_DEFINITION_ON_RESET_COLUMNS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };

    case EVENTS_DEFINITION_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, actionsMenu: !row.actionsMenu }
              : row;
          }),
        },
      };

    case EVENTS_DEFINITION_ON_COLUMN_TOGGLE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };

    case EVENTS_DEFINITION_CLEAR_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };

    case EVENTS_DEFINITION_CHECK_DATA_TABLE_ROW:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, checked: !row.checked }
              : row;
          }),
        },
      };
    case EVENTS_DEFINITION_CHECK_ALL_DATA_TABLE_ROWS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };
    default:
      return state;
  }
}
