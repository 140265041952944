import { config } from "consts/api";
import { userData } from "modules/user";
import { Toast } from "./toast";

const amzonupload = (e, prop, imgsrc, callback) => {
  const formData = new FormData();
  const componentName = e;

  const uploadFile = async (type) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(imgsrc);
    reader.onloadend = async () => {
      formData.append(type, imgsrc);
      try {
        const response = await fetch(
          `${config.API_HOST}/${prop.user.activeSuitId}/upload`,
          {
            method: "POST",
            body: formData,
            headers: {
              authToken: userData().auth_token,
            },
          }
        );
        let link = await response.text();
        link = `https://${link}`;
        callback({ data: { link } });
      } catch (error) {
        // handle error
      }
    };
  };

  const caseSucess = (type) => {
    if (type === "video" && imgsrc.size <= 500000) {
      uploadFile("video");
    } else {
      uploadFile("photo");
    }
  };

  if (imgsrc) {
    if (componentName === "action.appPush.appPushVideo") {
      if (imgsrc.size > 500000) {
        Toast.error("Maximum file size is 500 kb");
        callback(false);
      } else {
        caseSucess("video");
      }
    }
    const img = document.createElement("img");
    img.onload = function () {
      const validSizes = {
        "socialmedia_settings.image": { width: 512, height: 512 },
        app_icon_url: { width: 512, height: 512 },
        "action.appPush.attachment": { width: 512, height: 512 },
        "notification_image.src": { width: 64, height: 64 },
      };
      const size = validSizes[componentName];
      if (size && (this.width !== size.width || this.height !== size.height)) {
        Toast.error(
          `Please add the img with size ${size.width}px * ${size.height}px`
        );
        callback({ data: "false" });
      } else if (componentName) {
        caseSucess();
      }
    };
    const reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    };
    reader.readAsDataURL(imgsrc);
  }
};

export default amzonupload;
